import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util.js';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'

import { deleteAccount } from '../../api/account/accountApi.js';
import CloudAlertRight from '../../components/alerts/CloudAlertRight.js';

export const LabelMemberWithdraw = () => {
  let dispatch = useDispatch();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:13, idx:3}));
  },[])
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  let navigator = useNavigate();
  let emailInfo = localStorage.getItem('user-email')

  const reqDeleteAccount = async()=>{
    let temp = await deleteAccount();
  }

  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])

  return (
    <div className="cpo-member-withdraw-page-container">
      <div className='cpo-member-withdraw-section'>
        <div className="cpo-member-withdraw-header">
          <title>회원탈퇴</title>
        </div>
        <div className="cpo-member-withdraw-content">
          <div className="cpo-member-withdraw-content-title">
            <p>탈퇴 안내</p>
          </div>
          <div className='cpo-member-withdraw-email-info-container'>
            <p>{"현재 사용중인 계정 "}<strong>{emailInfo}</strong>{'은 탈퇴 시 문의가 필요합니다.'}</p>
          </div>
          <div className='cpo-member-withdraw-email-info-container' style={{marginBottom:'50px'}}>
            <p>{'아래의 이메일을 통해 관리자에게 문의해주세요.'}</p>
          </div>
          
          <div className="cpo-member-withdraw-button-list-container">
            <div className="cpo-member-withdraw-button cancel" onClick={(e)=>{e.preventDefault(); navigator('/cpo/dashboard')}}>취소</div>
            <div className={`cpo-member-withdraw-button submit label`}>
              <p>관리자 메일</p>
              <p>{`<monit@evmonit.com>`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LabelMemberWithdraw;