import React, { useRef, useState, useEffect } from 'react';
import ChargerItem from "../../../components/list/ChargerItem.js";
import ChargerDetailModal from "../../../components/modals/ChargerDetailModal.js";
import ChargerEnrollModal from '../../../components/modals/ChargerEnrollModal.js';
import ChargerDeleteModal from '../../../components/modals/ChargerDeleteModal.js';

import AsyncSearchSelectorNotCreatable from '../../../components/buttons/AsyncSearchSelectorNotCreatable.js'
import {getChargerHubByChargerId } from '../../../api/controlhub/controlhubApi.js';
import {getChargerListByStationId} from '../../../api/infra/infraApi.js';
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

import '../styles/ChargerManagementSimpleView.css';

import { LoadingList } from '../../../components/alerts/LoadingList.js';

export const ChargerManagementSimpleView = () => {
   // 충전기 데이터 Array<Object>
    const [chargerInfo, setChargerInfo]=useState(null);
    // 충전기 controlhub 데이터 Array<Object>
    const [chargerHubInfo, setChargerHubInfo]=useState(null);
    // chargerHub 데이터 로딩 상태
    const [chargerHubLoading, setChargerHubLoading] = useState(false);
  
    const [loadingListOn, setLoadingListOn] = useState(false);

    // chargerHub 정보 List API GET with ChargerId
    const getChargerHubList = async()=>{
      let temp = [];
      for await(const it of chargerInfo?.Results){
        let result = await getChargerHubByChargerId(it.ChargerId);
        if(result.status === 200){
          temp.push(result.data);
        }
      }
      setChargerHubLoading(false);
      setChargerHubInfo(temp);
    }
  
    // charger Infra 정보 API GET
    const getChargerInfo = async()=>{
      setLoadingListOn(true);
      let result = await getChargerListByStationId(selectedStationId, pageNum);
      if(result.status === 200){
        setChargerInfo(result.data);
      }
      setLoadingListOn(false);
    }
  
  
    const [chargerTypeStatus, setChargerTypeStatus] = useState({FAST_CHARGER:0, SLOW_CHARGER:0});
    const [chargerCategoryStatus, setChargerCategoryStatus] = useState({'완전공용':0, '부분공용':0, '비공용':0});
    // station list Ref[]
    const chargerRef = useRef([]);
    
  
    // 클릭한 charger list item의 idx state
    const [clickedCharger, setClickedCharger] = useState(null);
  
    // 상세 페이지 Modal
    const [detailModalOpen, setDetailModalOpen] = useState(false);
  
    // 클릭 시 상세 모달창 open
    useEffect(()=>{
      if(clickedCharger == -1 || clickedCharger == null || clickedCharger == undefined ){
        setDetailModalOpen(false);
      }
      else{
        setDetailModalOpen(true);
      }
    },[clickedCharger])
  
  
  
    // selector에서 클릭한 label (Station)
    const [dataLabel, setDataLabel] = useState();
    // 선택한 station id
    const [selectedStationId, setSelectedStationId] = useState(null);
    // 현재 페이지 번호
    const [pageNum, setPageNum] = useState(1);
    // 페이지 사이즈(한 페이지에 보여줄 charger 수)
    const [pageSize, setPageSize] = useState(10);
    // 총 페이지 수
    const pageCount = Math.ceil(chargerInfo?.Total/pageSize);
    const totalCount = chargerInfo?.Total;
    // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
    const getInitPage = (page)=>{
      if(page<1)return 1;
      if(pageCount === 0)return 1;
      if(page>pageCount)return pageCount;
      return (Math.ceil(page/5)-1)*5+1;
    }
    // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
    const getEndPage = (page)=>{
      if(page<1)return 1;
      if(pageCount === 0)return 1;
      if(page>pageCount)return pageCount;
      return (Math.ceil(page/5)-1)*5+4;
    }
    // 페이지 이동 함수
    const movePage = (page)=>{
      setPageNum(page);
    }
    // 페이지 버튼 렌더링 함수
    const pageSelectorRendering = ()=>{
      let page = pageNum?pageNum:1;
      let initPage = getInitPage(pageNum?pageNum:1);
      let endPage = initPage + 5;
  
      const result = [];
      // 화면에 최대 5개의 페이지 버튼 생성
      for (let id = initPage; id < endPage && id <= pageCount; id++) {
        result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
      }
      if(result.length === 0){
        result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
      }
      return result;
    }
  
    useEffect(()=>{
      if(dataLabel&&pageNum){
        getChargerInfo();
      }
    },[pageNum])
  
    useEffect(()=>{
      if(chargerInfo){
        setChargerHubLoading(true);
        getChargerHubList();
      }
    },[chargerInfo])
  
    // 클릭한 station idx
    const [selected, setSelected] = useState(0);
  
    useEffect(()=>{
      if(dataLabel){
        setSelected(dataLabel.idx)
        setSelectedStationId(dataLabel.id);
        setPageNum(1);
      }
    },[dataLabel])
  
    useEffect(()=>{
      if(clickedCharger !== null) setClickedCharger(-1);
      setCheckChargerList([]);
      setChecked(false)
    },[selected])
  
    useEffect(()=>{
      if(!dataLabel)return;
      localStorage.setItem("selectedStationCM", dataLabel.value);
    },[dataLabel])
    
    useEffect(()=>{
      if(selectedStationId){
        getChargerInfo();
      }
    },[selectedStationId])
  
  
  
    // check한 charger list
    const [checkChargerList, setCheckChargerList] = useState([]);
  
    // checked state (모든 아이템 check)
    const [checked, setChecked] = useState(false);
  
    
    useEffect(()=>{
      if(checked){
        let temp = new Array(chargerInfo?.Results.length).fill(0).map((_, i) => i)
        setCheckChargerList(temp);
      }
      else{
        setCheckChargerList([]);
      }
    },[checked])
  
    const [enrollModalOn, setEnrollModalOn] = useState(false);
    const [deleteModalOn, setDeleteModalOn] = useState(false);
  
    
  return (
    <>
      {enrollModalOn&&<ChargerEnrollModal setOnOff={setEnrollModalOn} chargingStationId={dataLabel.id} chargerCount={chargerInfo?.length} getChargerInfo={getChargerInfo}/>}
      {deleteModalOn&&<ChargerDeleteModal setOnOff={setDeleteModalOn} checkChargerList={checkChargerList} chargerList={chargerInfo.Results} setCheckChargerList={setCheckChargerList} getChargerInfo={getChargerInfo}/>}
      {chargerInfo!==null&&chargerHubInfo!==null&&chargerInfo.length!==0 ? <ChargerDetailModal pageNum={pageNum}  getChargerInfo={getChargerInfo} data={chargerInfo?.Results[clickedCharger]} chasData={chargerHubInfo[clickedCharger]} detailModalOpen={detailModalOpen} setDetailModalOpen={setDetailModalOpen} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} lastIdx={chargerInfo.Total-1}/>:<></>}
      <div className={`cpo-charger-management-grid-view-charger-list-wrapper-header`}>
        {dataLabel&&<p>{`${dataLabel?.value}의 충전기 목록`}</p>}
      </div>
      <div className={detailModalOpen ? 'charger-management-simple-view-list-container open':'charger-management-simple-view-list-container' }>
        <div className='charger-management-simple-view-charger-list-header'>
          <div className="charger-management-simple-view-selector-container">
            <AsyncSearchSelectorNotCreatable placeholder="충전소를 선택하세요" value={dataLabel} setValue={setDataLabel} defaultValue={localStorage.getItem('selectedStationCM')}/>
          </div>
          <div className={"charger-management-simple-view-charger-list-button-container " + `${detailModalOpen? "open":""}`}>
            <div className={'delete '+ `${checkChargerList.length == 0 ? "disabled":""}`} onClick={()=>{setDeleteModalOn(true);}}><p>삭제</p></div>
            <div className={`${dataLabel ? "":" disabled"}`} onClick={()=>{setEnrollModalOn(true)}}><p>등록</p></div>
          </div>
        </div>

        <div className="charger-management-simple-view-charger-list">
          <div className="charger-management-simple-view-charger-list-column">
            <div className='charger-management-simple-view-charger-list-column-item'>
              <label className="checkbox_label">
                <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                <span className="checkbox_icon"></span>
              </label>
            </div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>식별번호</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>모델명</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>충전기타입</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p><highlight>커넥터1</highlight> 타입</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>로밍</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>환경부 ID</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p><highlight>커넥터2</highlight> 타입</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>로밍</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>환경부 ID</p></div>
            <div className='charger-management-simple-view-charger-list-column-item'><p>운영여부</p></div>
          </div>
          {
            loadingListOn ? <LoadingList width={"500px"} height={"500px"}/>:
            <div className='charger-management-simple-view-list-content'>
              {
              chargerInfo &&
                (chargerInfo.Results.map((ia,idx)=>{
                  if(ia.IsDeleted)return <></>;
                  return(
                    <ChargerItem key={'charger- '+idx}  idx={idx} chargerRef={chargerRef} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} checkChargerList={checkChargerList} setCheckChargerList={setCheckChargerList} data={ia} controlHubData={chargerHubInfo?chargerHubInfo[idx]:null} controlhubLoading={chargerHubLoading}/>
                )
                }))
              }
            </div>
          }
          
        </div>
        <div className='page-selector-container'>
          {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
          {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
          {pageSelectorRendering()}
          {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
          {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
          <div className='page-total-info'>{`Total : ${totalCount}`}</div>
        </div>
      </div>
    </>
  )
}
export default ChargerManagementSimpleView;