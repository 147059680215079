import React, { useEffect, useState } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";

import { deleteChas } from '../../api/controlhub/controlhubApi.js';
import {putSubscriptionCancel} from '../../api/subscription/subscriptionApi.js';
import { Loading } from '../alerts/Loading.js';
import { useNavigate } from 'react-router-dom';


export const SubscriptionCancelModal = (props) => {
  const navigate = useNavigate();
  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [usedInfo, setUsedInfo] = useState(false);
  const [usedActionSetList, setUsedActionSetList] = useState([]);

  const reqDeleteChas = async()=>{
    setLoadingOn(true);
    let usedTemp = [];
    for await(const target of props.chasList){
      let result = await deleteChas(target.actionSetId);
      if(result.status === 400 && result.data?.ErrorCode === "ALREADY_USED_ENTITY"){
        usedTemp.push(target);
      }
    }
    if(usedTemp.length > 0){
      setUsedActionSetList(usedTemp);
    }
    else{
      setLoadingOn(false);
      props.setOnOff(false);
      navigate('/cpo/custom-chas');
    }
  }

  const reqCencelSubscription = async()=>{
    setLoadingOn(true);
    let result = await putSubscriptionCancel(props.data.subscriptionId);
    if(result.status === 200){
      props.getCurrentSubscriptionList();
      props.setOnOff(false);
    }
    else{
      setErrMsg("구독 해지에 실패하였습니다.");
    }
    setLoadingOn(false);
  }

  useEffect(()=>{
    if(errMsg){
      setTimeout(()=>{
        setErrMsg('');
      },3000)
    }
  },[errMsg])

  return (
    <div className="subscription-cancel-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="subscription-cancel-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="subscription-cancel-modal-header">
          <title>구독 해지</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='subscription-cancel-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='subscription-cancel-modal-msg'>구독을 해지 하시겠습니까?</p>
          <div className='subscription-cancel-modal-item'>{props.data.subscriptionProduct.normalizedName}</div>
          <div className='subscription-cancel-modal-alert-msg'>
            해지 시 만료 예정일까지 해당 상품을 사용할 수 있습니다.
          </div>
          {errMsg && <div className='subscription-cancel-modal-err-msg'>{errMsg}</div>}
        </div>
        <div>
          <div className={'subscription-cancel-modal-delete-button '} onClick={()=>{reqCencelSubscription()}}>
            {loadingOn ? <Loading/>:<p>해지</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default SubscriptionCancelModal

