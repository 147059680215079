import React, { useEffect, useState } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";



import { deleteFirmwareCpoUser } from '../../api/controlhub/controlhubApi.js';

import { Loading } from '../alerts/Loading.js';

export const FirmwareDeleteModal = (props) => {


  const [loadingOn, setLoadingOn] = useState(false);

  const reqDeleteFirmwares = async()=>{
    setLoadingOn(true);
    for await(const itemIdx of props.checkedItemList){
      let tempItem = props.firmwareList[itemIdx];
      await deleteFirmwareCpoUser(tempItem.firmwareId);
    }
    await props.reqGetFirmwareListCpoUser();
    setLoadingOn(false);
    props.setDeleteMode(false);
    props.setOnOff(false);
  }



  return (
    <div className="cpo-charger-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charger-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-delete-modal-header">
          <title>펌웨어 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-charger-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='cpo-charger-delete-modal-msg'>{props.checkedItemList?.length}개의 펌웨어를 삭제하시겠습니까?</p>
          <div className='cpo-charger-delete-modal-list-wrapper'>
            {
              props.checkedItemList && props.firmwareList && props.checkedItemList?.map((it, idx)=>{
                if(props.firmwareList[it].IsDeleted){
                  return;
                }
                return(
                  <>
                    <div className='cpo-charger-delete-modal-item'>{props.firmwareList[it].name}</div>

                  </>
                )
              })
            }
          </div>
        </div>
        <div>
          <div className={'cpo-charger-modal-delete-button '} onClick={()=>{reqDeleteFirmwares()}}>
            {loadingOn ? <Loading/> : <p>삭제</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default FirmwareDeleteModal

