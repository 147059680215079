import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'


import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";
import { getAllPricingEvent } from '../../api/chargerate/chargerateApi.js';
import ChargingFeeSettingModal from '../../components/modals/ChargingFeeSettingModal.js';
import ChargingEventCard from '../../components/list/ChargingEventCard.js';
import CpoChargingEventStationListModal from '../../components/modals/CpoChargingEventStationListModal.js';
import ChargingEventDeleteModal from '../../components/modals/ChargingEventDeleteModal.js';

const CpoChargingEventManagement = (props) => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:7}));
  },[dispatch])


  // 이벤트 리스트
  const [pricingEventList, setPricingEventList] = useState([]);



  // 이벤트 추가 모달
  const [modalOn, setModalOn] = useState(false);
  // 이벤트 추가 모달
  const [eventEditModalOn, setEventEditModalOn] = useState(false);
  const [eventEditTarget, setEventEditTarget] = useState(null);
  // 충전기 리스트 모달
  const [chargerListModalOn, setChargerListModalOn] = useState(false);
  const [cardIdx, setCardIdx] = useState(null);
  // 이벤트 삭제 모달
  const [deleteModalOn, setDeleteModalOn] = useState(false);


  const reqGetAllPricingEvent = async()=>{
    let result = await getAllPricingEvent({page:pageNum, size:10});
    if(result.status === 200){ 
      setPricingEventList(result.data?.results);
      setEventCount(result.data?.total);
    }
    else{
    }
  }

  useEffect(()=>{
    reqGetAllPricingEvent();
  },[])



  // 이벤트 개수
  const [eventCount, setEventCount] = useState(0);

  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(eventCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetAllPricingEvent();
    }
  },[pageNum])





  return (
    <div className="cpo-charging-event-management-page-container">
      {modalOn && <ChargingFeeSettingModal reqGetAllPricingEvent={reqGetAllPricingEvent} setOnOff={setModalOn} />}
      {eventEditModalOn && <ChargingFeeSettingModal reqGetAllPricingEvent={reqGetAllPricingEvent} setOnOff={setEventEditModalOn} edit={true} eventData={eventEditTarget} />}
      {chargerListModalOn && <CpoChargingEventStationListModal setOnOff={setChargerListModalOn} data={pricingEventList[cardIdx]}/>}
      {deleteModalOn && <ChargingEventDeleteModal setOnOff={setDeleteModalOn} data={eventEditTarget} reqGetAllPricingEvent={reqGetAllPricingEvent}/>}
      <div className="cpo-charging-event-management-inner">
        <div className="cpo-charging-event-management-header">
          <div className='cpo-charging-event-management-title-container'>
            <title>진행중인 이벤트</title>
          </div>
          <div className='cpo-charging-fee-management-event-setting-button' onClick={()=>{setModalOn(true)}}><p>이벤트요금 추가</p></div>
        </div>
        <div className='cpo-charging-event-management-content-container'>
          {pricingEventList.map((it, idx)=>{
            return(
              <ChargingEventCard key={idx} data={it} idx={idx} setChargerListModalOn={setChargerListModalOn} setCardIdx={setCardIdx} setEventEditModalOn={setEventEditModalOn} setEventEditTarget={setEventEditTarget} setDeleteModalOn={setDeleteModalOn}/>
            )
          })}
        </div>
        <div className='page-selector-container'>
          {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
          {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
          {pageSelectorRendering()}
          {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
          {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
          <div className='page-total-info'>{`Total : ${eventCount}`}</div>
        </div>
      </div>
    </div>
  )
}
export default CpoChargingEventManagement;