import React from 'react'
import { FaDotCircle } from "react-icons/fa";

const NavSubButton = (props) => {
  return (
    // Nav가 눌렸는지? 그렇다면 현재 sub가 눌렸는지?
    <div className={props.isActive ? props.isOn ?'nav-sub-button on':'nav-sub-button' :'nav-sub-button none'} onClick={(e)=>{}}>
      
      {props.isActive ? 
        <>
          {/* <FaDotCircle className='nav-sub-dot'/> */}
          <p>{props.name}</p>
        </>
      :
        null
      }
      
    </div>
  )
}
export default NavSubButton;