/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2024-12-12
 * @file 찾아ZooM 서비스와 연동하기 위한 API 계약 함수 파일 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import axios from 'axios';

export const getLatLngFromAddress = async(address) => {
    const encodedAddress = encodeURIComponent(address);
    const url = `/zoomsimulation/api/geocodeAddress/${encodedAddress}`;
    let rv = [];
    await axios.get(url)
    .then((res)=>{
        rv = res.data;
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });
    return rv;
}

/// <summary>
/// 충전사업자별 충전기 개수와 비율 데이터 요청 API
/// </summary>
export const getCpoRankingData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph11/${userSystemId}/${requestId}`;
    let rv = [];
    await axios.get(url)
    .then((res)=>{
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });

    return rv;     
}

/// <summary>
/// 강수량과 충전횟수사이의 관계 데이터 요청 API
/// </summary>
export const getWeeklyRainChargingRelationData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph6/${userSystemId}/${requestId}`;
    let rv = [];
    await axios.get(url)
    .then((res)=>{
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });

    return rv;      
}

/// <summary>
/// 온도와 충전횟수사이의 관계 데이터 요청 API
/// </summary>
export const getWeeklyTempChargingRelationData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph7/${userSystemId}/${requestId}`;
    let rv = [];
    await axios.get(url)
    .then((res)=>{
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });

    return rv;    
}

/// <summary>
/// 년간 충전소 설치 데이터 요청 API 
/// </summary>
export const getYearlyChargerCountData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph9/${userSystemId}/${requestId}`;
    let rv = [];
    await axios.get(url)
    .then((res)=>{
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });

    return rv;
}

/// <summary>
/// 월별 인구증감 데이터 요청 API
/// </summary>
export const getMonthlyPopulationData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph8/${userSystemId}/${requestId}`;
    let rv = [];
    await axios.get(url)
    .then((res)=>{
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });
    return rv;
}

/// <summary>
/// 차종별 월별 누적 등록 데이터 요청 API
/// </summary>
export const getMonthlyVehicleKindCumulativeData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph5/${userSystemId}/${requestId}`;
    let rv = [];
    await axios.get(url)
    .then((res)=>{
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=> {
        console.log('[EMOOV] Exception:', err.response);
    });

    return rv;
}

/// <summary>
/// 전기차 월별 등록 데이터 요청 API
/// </summary>
export const getMonthlyEvRegistrationData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph4/${userSystemId}/${requestId}`;

    let rv = [];
    await axios.get(url)
    .then((res)=>{
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });

    return rv;
}

/// <summary>
/// 전기차 종류와 비율 데이터 요청 API
/// </summary>
export const getEvKindRatioData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph3/${userSystemId}/${requestId}`;

    let rv = [];
    await axios.get(url)
    .then((res) => {
        if(res.data.count > 0) {
            rv = res.data.result;
        }
    })
    .catch((err)=>{
        console.log('[EMOOV] Exception:', err.response);
    });

    return rv;
}

/// <summary>
/// 주평균 온도와 강수량 데이터 요청 API
/// </summary>
export const getWeeklyTemperatureRainData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph2/${userSystemId}/${requestId}`;

    let rv = [];

    await axios.get(url)
    .then((res) => {
        if(res.data.count > 0) {
            rv = res.data.result;
        }
        //console.log('[EMOOV] 온도 강수량 데이터:', rv);
    })
    .catch((err)=>{
        console.log(err.response);
    })
    return rv;
}

/// <summary>
/// 요일별 충전량 합계 데이터 요청 API
/// </summary>
export const getDayChargingData = async(userSystemId, requestId) => {
    const url = `/zoomstat/api/graph1/${userSystemId}/${requestId}`;

    let rv = [];

    await axios.get(url)
    .then((res) => {
        if(res.data.count > 0) {
            rv = res.data.result;
        }
        console.log('[EMOOV] Data:', rv);
    })
    .catch((err)=>{
        console.log(err.response);
    })
    return rv;
}

/// <summary>
/// 새로운 통계 데이터 요청 API 
/// </summary>
export const postZoomStatRequest = async(data) => {
    let temp;
    await axios.post(`/zoomstat/api/submitStatistics`,
        {
            userSystemId: data.userSystemId,
            zscode: data.sigCode,
            fromDate: data.startDate,
            toDate: data.endDate
        },
        {
            headers: {

            }
        }
    )
    .then((res)=>{
        temp = res;
    })
    .catch((err)=>{
        temp = err.response;
        console.log('[EMOOV] Exception: ', temp);
    })
    return temp;
}

/// <summary>
/// 통계요청목록 가져오기 API
/// </summary>
export const getZoomStatRequest = async(userSystemId) => {
    let temp;
    const url = `/zoomstat/api/userStatisticsHistory/${userSystemId}`;
    await axios.get(url)
    .then((res)=>{
        //console.log(res.data);
        if(res.data.count === 0) {
            temp = [];
        }
        else {
            temp = res.data.result;
            /// 데이터를 시각적으로 보기좋게 가공
            const temp2 = [];
            for(let i=0; i<temp.length; i++) {
                /// 신청상태 레이블 수정 
                if(temp[i].status == 'completed') {
                    temp[i].status = '완료';
                }
                else {
                    temp[i].status = '진행중';
                }
                /// 등록일 수정 
                const regDate = new Date(temp[i].date);
                const regDateStr = `${regDate.getFullYear()}년 ${regDate.getMonth()+1}월 ${regDate.getDate()}일`;
                temp[i].date = regDateStr;
                /// 자료 시작일 형식 수정 
                const startDate = new Date(temp[i].fromDate);
                const startDateStr = `${startDate.getFullYear()}년 ${startDate.getMonth()+1}월 ${startDate.getDate()}일`;
                temp[i].fromDate = startDateStr;
                /// 자료 종료일 형식 수정 
                const endDate = new Date(temp[i].toDate);
                const endDateStr = `${endDate.getFullYear()}년 ${endDate.getMonth()+1}월 ${endDate.getDate()}일`;
                temp[i].toDate = endDateStr;
            }
        }
    })
    .catch((err)=>{
        console.log(err.response);
        temp = false;
    })

    return temp;
}

/// <summary>
/// 시뮬레이션요청목록 가져오기 API 
/// </summary>
export const getZoomSimRequest = async(userSystemId) => {
    let temp;
    const url = `/zoomsimulation/api/userRequests/${userSystemId}`;
    await axios.get(url)
    .then((res)=>{
        if(res.data.count === 0) {
            temp = [];
        }
        else {
            temp = res.data.result;
            for(let i=0; i<temp.length; i++) {
                /// 신청상태 레이블 수정 
                if(temp[i].status === 'complete') {
                    temp[i].status = '완료';
                }
                else if(temp[i].status === 'running') {
                    temp[i].status = '시뮬레이션중'
                }
                else {
                    temp[i].status = '준비중';
                }
                /// 등록일 수정 
                const regDate = new Date(temp[i].date);
                //const regDateStr = `${regDate.getFullYear()}년 ${regDate.getMonth()+1}월 ${regDate.getDate()}일`;
                const regDateStr = regDate.toLocaleDateString('ko-KR',{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                });
                temp[i].date = regDateStr; 
            }
        }
    })
    .catch((err)=>{
        console.log(err.response);
        temp = [];
    })

    return temp;
}

/// <summary>
/// 충전소입지 추천 시뮬레이션 지리정보 결과 가져오기 API
/// </summary>
export const getZoomSimRequestResult = async(userSystemId, requestId) => {
    let temp = null;
    const url = `/zoomsimulation/api/requestResult/${userSystemId}/${requestId}`;

    await axios.get(url)
    .then((res)=>{
        temp = res.data;
    })
    .catch((err)=>{
        console.log(err.response);
    });

    return temp;
};

/// <summary>
/// 그리드의 상세한 속성값 가져오기 API
/// </summary>
export const getGridPropertyData = async(zscode, gid) => {
    let temp = null;
    const url = `/zoomsimulation/api/getGeoData/${zscode}/${gid}`;

    await axios.get(url)
    .then((res) => {
        temp = res.data;
    })
    .catch((err) => {
        console.log(err.response);
    });
    
    return temp;
};

/// <summary>
/// 그리드의 지도상의 중점 위경도값 및 지도 범위크기를 가져오는 API 
/// </summary>
export const getGridBoundaryMapData = async(zscode, gid) => {
    let temp = null;
    const url = `/zoomsimulation/api/nearbyPolygons/${zscode}/${gid}`;

    await axios.get(url)
    .then((res) => {
        temp = res.data;
    })
    .catch((err) => {
        console.log(err.response);
    });

    return temp;
};

/// <summary>
/// 지역별 클러스트 유형에 대한 비교표를 가져오는 API 
/// </summary>
export const getClusterComparisionList = async(zscode, clusterType) => {
    let temp = null;
    const url = `/zoomsimulation/api/clusterCompare?zscode=${zscode}&clusterType=${clusterType}`;

    await axios.get(url)
    .then((res)=>{
        temp = res.data;
    })
    .catch((err)=>{
        console.log(err.response);
    });

    return temp;
};

/// <summary>
/// 새로운 충전소입지 추천 시뮬레이션 요청 API 
/// </summary>
export const postZoomSimRequest = async(data) => {
    let temp;
    let factor1SpaceRental = 1.0;
    let factor1Cafe = 1.0;
    let factor1Bakery = 1.0;
    let factor1FastFood = 1.0;
    let factor1Restaurant = 1.0;
    let factor1LeisureSports = 1.0;
    let factor1StudyCafe = 1.0;
    let factor1Pharmacy = 1.0;
    let factor1Theater = 1.0;
    let factor1KidsCafe = 1.0;
    let factor1FamilyRestaurant = 1.0;
    let factor1MedicalOthers = 1.0;

    let factor2ConvenienceStore = 1.0;
    let factor2Beauty = 1.0;
    let factor2RealEstate = 1.0;
    let factor2Shopping = 1.0;
    let factor2DailyConvenience = 1.0;

    let factor3Hotel = 1.0;
    let factor3Accommodation = 1.0;
    let factor3Finace = 1.0;
    let factor3Pub = 1.0;
    let factor3CultureCenter  = 1.0;
    let factor3Hospital = 1.0;
    let factor3PublicInstitution = 1.0;

    let factor4Apartment = 1.0;
    let factor4ElementaryMiddleHigh = 1.0;
    let factor4EducationFacility = 1.0;
    let factor4BusStop = 1.0;
    let factor4Academy = 1.0;
    let factor4ParkingLot = 1.0;
    let factor4Park = 1.0;

    let factor5ResearchFacility = 1.0;
    let factor5Company = 1.0;

    let factor6GasStation = 1.0;
    let factor6RestArea = 1.0;

    let factor7ResidentialFacility = 1.0;
    let factor7RoadFacifity = 1.0;
    let factor7Church = 1.0;
    let factor7ReligiousFacility = 1.0;
    let factor7Temple = 1.0;
    let factor7WellfareFacility = 1.0;

    let factor8TouristAttraction = 1.0;
    let factor8Culture = 1.0;

    let factor9CampGround = 1.0;
    let factor9AmusementFacilities = 1.0;
    let factor9Condo = 1.0;

    let factor10University = 1.0;

    let factor11GolfCourse = 1.0;
    let factor11GolfPracticeRange = 1.0;

    if(data.factor1Enable) {
        factor1SpaceRental = factor1SpaceRental * data.factor1Value;
        factor1Cafe = factor1Cafe * data.factor1Value;
        factor1Bakery = factor1Bakery * data.factor1Value;
        factor1FastFood = factor1FastFood * data.factor1Value;
        factor1Restaurant = factor1Restaurant * data.factor1Value;
        factor1LeisureSports = factor1LeisureSports * data.factor1Value;
        factor1StudyCafe = factor1StudyCafe * data.factor1Value;
        factor1Pharmacy = factor1Pharmacy * data.factor1Value;
        factor1Theater = factor1Theater * data.factor1Value;
        factor1KidsCafe = factor1KidsCafe * data.factor1Value;
        factor1FamilyRestaurant = factor1FamilyRestaurant * data.factor1Value;
        factor1MedicalOthers = factor1MedicalOthers * data.factor1Value;
    }
    else {
        factor1SpaceRental = 0.0;
        factor1Cafe = 0.0;
        factor1Bakery = 0.0;
        factor1FastFood = 0.0;
        factor1Restaurant = 0.0;
        factor1LeisureSports = 0.0;
        factor1StudyCafe = 0.0;
        factor1Pharmacy = 0.0;
        factor1Theater = 0.0;
        factor1KidsCafe = 0.0;
        factor1FamilyRestaurant = 0.0;
        factor1MedicalOthers = 0.0;
    }

    if(data.factor2Enable == true) {
        factor2ConvenienceStore = factor2ConvenienceStore * data.factor2Value;
        factor2Beauty = factor2Beauty * data.factor2Value;
        factor2RealEstate = factor2RealEstate * data.factor2Value;
        factor2Shopping = factor2Shopping * data.factor2Value;
        factor2DailyConvenience = factor2DailyConvenience * data
    }
    else {
        factor2ConvenienceStore = 0.0;
        factor2Beauty = 0.0;
        factor2RealEstate = 0.0;
        factor2Shopping = 0.0;
        factor2DailyConvenience = 0.0;
    }

    if(data.factor3Enable == true) {
        factor3Hotel = factor3Hotel * data.factor3Value;
        factor3Accommodation = factor3Accommodation * data.factor3Value;
        factor3Finace = factor3Finace * data.factor3Value;
        factor3Pub = factor3Pub * data.factor3Value;
        factor3CultureCenter = factor3CultureCenter * data.factor3Value;
        factor3Hospital = factor3Hospital * data.factor3Value;
        factor3PublicInstitution = factor3PublicInstitution * data.factor3Value
    }
    else{
        factor3Hotel = 0.0;
        factor3Accommodation = 0.0;
        factor3Finace = 0.0;
        factor3Pub = 0.0;
        factor3CultureCenter = 0.0;
        factor3Hospital = 0.0;
        factor3PublicInstitution = 0.0;
    }

    if(data.factor4Enable == true) {
        factor4Apartment = factor4Apartment * data.factor4Value;
        factor4ElementaryMiddleHigh = factor4ElementaryMiddleHigh * data.factor4Value;
        factor4EducationFacility = factor4EducationFacility * data.factor4Value;
        factor4BusStop = factor4BusStop * data.factor4Value;
        factor4Academy = factor4Academy * data.factor4Value;
        factor4ParkingLot = factor4ParkingLot * data.factor4Value;
        factor4Park = factor4Park * data
    }
    else {
        factor4Apartment = 0.0;
        factor4ElementaryMiddleHigh = 0.0;
        factor4EducationFacility = 0.0;
        factor4BusStop = 0.0;
        factor4Academy = 0.0;
        factor4ParkingLot = 0.0;
        factor4Park = 0.0;
    }

    if(data.factor5Enable == true) {
        factor5ResearchFacility = factor5ResearchFacility * data.factor5Value;
        factor5Company = factor5Company * data.factor
    }
    else {
        factor5ResearchFacility = 0.0;
        factor5Company = 0.0;
    }

    if(data.factor6Enable == true) {
        factor6GasStation = factor6GasStation * data.factor6Value;
        factor6RestArea = factor6RestArea * data.factor6Value;
    }
    else {
        factor6GasStation = 0.0;
        factor6RestArea = 0.0;
    }

    if(data.factor7Enable == true) {
        factor7ResidentialFacility = factor7ResidentialFacility * data.factor7Value;
        factor7RoadFacifity = factor7RoadFacifity * data.factor7Value;
        factor7Church = factor7Church * data.factor7Value;
        factor7ReligiousFacility = factor7ReligiousFacility * data.factor7Value;
        factor7Temple = factor7Temple * data.factor7Value;
        factor7WellfareFacility = factor7WellfareFacility * data.factor7Value;
    }
    else {
        factor7ResidentialFacility = 0.0;
        factor7RoadFacifity = 0.0;
        factor7Church = 0.0;
        factor7ReligiousFacility = 0.0;
        factor7Temple = 0.0;
        factor7WellfareFacility = 0.0;
    }

    if(data.factor8Enable == true) {
        factor8TouristAttraction = factor8TouristAttraction * data.factor8Value;
        factor8Culture = factor8Culture * data.factor8Value;
    }
    else {
        factor8TouristAttraction = 0.0;
        factor8Culture = 0.0;
    }

    if(data.factor9Enable == true) {
        factor9CampGround = factor9CampGround * data.factor9Value;
        factor9AmusementFacilities = factor9AmusementFacilities * data.factor9Value;
        factor9Condo = factor9Condo * data.factor9Value;
    }
    else {
        factor9CampGround = 0.0;
        factor9AmusementFacilities = 0.0;
        factor9Condo = 0.0;
    }

    if(data.factor10Enable == true) {   
        factor10University = factor10University * data.factor10Value;
    }
    else{
        factor10University = 0.0;
    }

    if(data.factor11Enable == true) {
        factor11GolfCourse = factor11GolfCourse * data.factor11Value;
        factor11GolfPracticeRange = factor11GolfPracticeRange * data.factor11Value;
    }
    else {
        factor11GolfCourse = 0.0;
        factor11GolfPracticeRange = 0.0;
    }

    await axios.post(`/zoomsimulation/api/submitRequest`,
        {
            userSystemId: data.userSystemId,
            zscode: data.sigCode,
            useFactor: 'true',
            /// 요인 가중치 
            factor1Value: data.factor1Value,
            factor2Value: data.factor2Value,
            factor3Value: data.factor3Value,
            factor4Value: data.factor4Value,
            factor5Value: data.factor5Value,
            factor6Value: data.factor6Value,
            factor7Value: data.factor7Value,
            factor8Value: data.factor8Value,
            factor9Value: data.factor9Value,
            factor10Value: data.factor10Value,
            factor11Value: data.factor11Value,
            /// 미지정 분류 
            workingPopulation: 1.0,
            officialLandPrice: 1.0,
            passengerCarCount: 1.0,
            freightTrunkCount: 1.0,
            specialVehicleCount: 1.0,
            averageLandCount: 1.0,
            averageWeekdayTraffic: 1.0,
            laneLengthKm: 1.0,
            nearbyRoadCount: 1.0,
            bridge: 1.0,
            transportation: 1.0,
            others: 1.0,
            curturalFacilities: 1.0,
            concertHall: 1.0,
            fishingSpot: 1.0,
            club: 1.0,
            cathedral: 1.0,
            aquarium: 1.0,
            trainingCenter: 1.0,
            subway: 1.0,
            cafeStreet: 1.0,
            themeStreet: 1.0,
            // 요인1 값 
            cafe: factor1Cafe,
            restaurant: factor1Restaurant,
            pharmacy: factor1Pharmacy,
            spaceRental: factor1SpaceRental,
            theater: factor1Theater,
            leisureSports: factor1LeisureSports,
            studyCafe: factor1StudyCafe,
            medicalOthers: factor1MedicalOthers,
            bakery: factor1Bakery,
            kidsCafe: factor1KidsCafe,
            familyRessaurant: factor1FamilyRestaurant,
            fastFood: factor1FastFood,
            // 요인2 값 
            dailyConvenience: factor2DailyConvenience,
            realEstate: factor2RealEstate,
            beauty: factor2Beauty,
            shopping: factor2Shopping,
            convenienceStore: factor2ConvenienceStore,
            // 요인3 값
            hotel: factor3Hotel,
            accommodation: factor3Accommodation,
            finance: factor3Finace,
            hospital: factor3Hospital,
            publicInstitution: factor3PublicInstitution,
            cultureCenter: factor3CultureCenter,
            pub: factor3Pub,
            // 요인4 값
            elementaryMiddleHigh: factor4ElementaryMiddleHigh,
            parkingLot: factor4ParkingLot,
            park: factor4Park,
            educationFacilities: factor4EducationFacility,
            apartment: factor4Apartment,
            busStop: factor4BusStop,
            academy: factor4Academy,
            // 요인5 값 
            company: factor5Company,
            researchFacilities: factor5ResearchFacility,
            // 요인6 값
            gasStation: factor6GasStation,
            restArea: factor6RestArea,
            // 요인7 값
            roadFacilities: factor7RoadFacifity,
            church: factor7Church,
            welfareFacilities: factor7WellfareFacility,
            temple: factor7Temple,
            residentialFacilities: factor7ResidentialFacility,
            religiousFacilities: factor7ReligiousFacility,
            // 요인8 값
            culture: factor8Culture,
            touristAttraction: factor8TouristAttraction,
            // 요인9 값
            amusementFacilities: factor9AmusementFacilities,
            campGround: factor9CampGround,
            condo : factor9Condo,
            // 요인10 값
            university: factor10University,
            // 요인11 값
            golfCourse: factor11GolfCourse,
            golfPracticeRange: factor11GolfPracticeRange
        },
        {
            headers: {

            }
        }
    )
    .then((res)=>{
        temp = res;
    })
    .catch((err)=>{
        temp = err.response;
        console.log('[EMOOV] Exception: ', temp);
    })
    return temp;
}