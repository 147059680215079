/**
 * @author kassid <lnamkyung@evmonit.com>
 * @create 2024-9-11
 * @file Dashboard
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import {setNum} from '../../store.js'
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { dateFormat2, getUTCTime, calculatePercentageChange, getWeekStartTimeFromDate } from '../../util/util.js';

import { TiArrowSortedUp, TiArrowSortedDown, TiMinus } from "react-icons/ti";

import { ChartComponent, SeriesCollectionDirective, Highlight, SeriesDirective, Inject, Tooltip, LineSeries, BarSeries, ColumnSeries, Legend, Category, DataLabel } from '@syncfusion/ej2-react-charts';

import { getChargerOperationStatistic } from '../../api/controlhub/controlhubApi.js';
import { getChargingStaticsTotal, getChargingStaticsGrouped, getSalesStatisticsBySubCpo, getSalesStatisticsByChargingStation} from '../../api/statistics/statisticsApi.js';

import { FaHandPaper } from "react-icons/fa";

import {getStationList, getAllCharger} from '../../api/infra/infraApi.js';

import {getEvUserCount, getEvUserMonthlyCount} from '../../api/account/accountApi.js';

export const CpoDashBoard = () => {
  let dispatch  = useDispatch();
  let rootCpoSysId = process.env.REACT_APP_SUPER_ID;
  const user = useSelector(state => state.user);
  useEffect(()=> {
    dispatch(setNum({id:0}))
  },[]);

  // 충전소 개수
  const [stationCount, setStationCount] = useState(0);
  // 충전기 개수
  const [chargerCount, setChargerCount] = useState(0);
  // 실시간 운영 현황
  const [chargerOperationStatistic, setChargerOperationStatistic] = useState([]);
  
  // 총 충전통계 조회 - 오늘
  const [chargingStaticsTotalToday, setChargingStaticsTotalToday] = useState([]);
  
  // 총 충전통계 조회 - 주간
  const [chargingStaticsTotalWeekly, setChargingStaticsTotalWeekly] = useState([]);
  // 총 충전통계 조회 - 주간 / 지난주
  const [chargingStaticsTotalWeeklyPrev, setChargingStaticsTotalWeeklyPrev] = useState([]);
  
  // 총 충전통계 조회 - 월간
  const [chargingStaticsTotalMonthly, setChargingStaticsTotalMonthly] = useState([]);
  // 총 충전통계 조회 - 월간 / 지난달
  const [chargingStaticsTotalMonthlyPrev, setChargingStaticsTotalMonthlyPrev] = useState([]);
  
  // 총 충전통계 조회 - 연간
  const [chargingStaticsTotalYearly, setChargingStaticsTotalYearly] = useState([]);
  // 총 충전통계 조회 - 연간 / 지난해
  const [chargingStaticsTotalYearlyPrev, setChargingStaticsTotalYearlyPrev] = useState([]);

  // 총 충전통계 조회 - 월간 / 급속
  const [chargingStaticsTotalMonthlyFast, setChargingStaticsTotalMonthlyFast] = useState([]);
  // 총 충전통계 조회 - 월간 / 급속 / 지난달
  const [chargingStaticsTotalMonthlyFastPrev, setChargingStaticsTotalMonthlyFastPrev] = useState([]);

  // 총 충전통계 조회 - 월간 / 완속
  const [chargingStaticsTotalMonthlySlow, setChargingStaticsTotalMonthlySlow] = useState([]);
  // 총 충전통계 조회 - 월간 / 완속 / 지난달
  const [chargingStaticsTotalMonthlySlowPrev, setChargingStaticsTotalMonthlySlowPrev] = useState([]);



  // 기간그룹별 충전통계 조회
  const [chargingStaticsGroupedFast, setChargingStaticsGroupedFast] = useState([]);
  const [chargingStaticsGroupedSlow, setChargingStaticsGroupedSlow] = useState([]);

  // 모든 ev사용자 수
  const [evUserCount, setEvUserCount] = useState(0);
  // 월별 ev사용자 가입수
  const [evUserMonthlyCount, setEvUserMonthlyCount] = useState([]);

  // 하위 CPO별 매출통계
  const [salesStatisticsBySubCpo, setSalesStatisticsBySubCpo] = useState({});
  // 충전소별 매출통계
  const [salesStatisticsByChargingStation, setSalesStatisticsByChargingStation] = useState({});



  // 충전소 개수 조회 함수
  const reqGetStationList = async() => {
    let res = await getStationList();
    if(res.status === 200){
      setStationCount(res.data.Total);
    }
    else{

    }
  }
  const reqGetAllCharger = async() => {
    let res = await getAllCharger();
    setChargerCount(res?.Total);
  }

  // 실시간 운영 현황 조회 함수
  const reqGetChargerOperationStatistic = async() => {
    let res = await getChargerOperationStatistic();
    if(res.status === 200){
      setChargerOperationStatistic(res.data);
    }
    else{

    }
  }

  useEffect(()=>{
    reqGetChargerOperationStatistic();
  },[]);

  // 총 충전통계 조회 함수 - 오늘
  const reqGetChargingStatisticTotalToday = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(now),
      endTime: null,
      chargerType: null
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalToday(res.data);
    }
    else{
    }
  }
  
  // 총 충전통계 조회 함수 - 주간
  const reqGetChargingStatisticTotalWeekly = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(getWeekStartTimeFromDate(now)),
      endTime: null,
      chargerType: null
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalWeekly(res.data);
    }
    else{
    }
  }
  // 총 충전통계 조회 함수 - 주간 - 지난주
  const reqGetChargingStatisticTotalWeeklyPrev = async() => {
    let last = new Date(new Date() - 7*24*60*60*1000);
    let now = new Date();
    let data = {
      startTime: getUTCTime(getWeekStartTimeFromDate(last)),
      endTime: getUTCTime(getWeekStartTimeFromDate(now)),
      chargerType: null
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalWeeklyPrev(res.data);
    }
    else{
    }
  }
  
  // 총 충전통계 조회 함수 - 월간
  const reqGetChargingStatisticTotalMonthly = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      endTime: null,
      chargerType: null
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalMonthly(res.data);
    }
    else{
    }
  }
  
  // 총 충전통계 조회 함수 - 월간 - 지난달
  const reqGetChargingStatisticTotalMonthlyPrev = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getMonth()===0?now.getFullYear()-1:now.getFullYear()} ${now.getMonth()===0?12:now.getMonth()} 1 0:0:0:0`),
      endTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      chargerType: null
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalMonthlyPrev(res.data);
    }
    else{
    }
  }
  // 총 충전통계 조회 함수 - 연간
  const reqGetChargingStatisticTotalYearly = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getFullYear()} 1 1 0:0:0:0`),
      endTime: null,
      chargerType: null
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalYearly(res.data);
    }
    else{
    }
  }
  // 총 충전통계 조회 함수 - 연간 / 지난해
  const reqGetChargingStatisticTotalYearlyPrev = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getFullYear()-1} 1 1 0:0:0:0`),
      endTime: getUTCTime(`${now.getFullYear()} 1 1 0:0:0:0`),
      chargerType: null
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalYearlyPrev(res.data);
    }
    else{
    }
  }
  // 총 충전통계 조회 함수 - 월간 / 급속
  const reqGetChargingStatisticTotalMonthlyFast = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      endTime: null,
      chargerType: "FAST_CHARGER"
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalMonthlyFast(res.data);
    }
    else{
    }
  }
  
  // 총 충전통계 조회 함수 - 월간 / 급속 / 지난달
  const reqGetChargingStatisticTotalMonthlyFastPrev = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getMonth()===0?now.getFullYear()-1:now.getFullYear()} ${now.getMonth()===0?12:now.getMonth()} 1 0:0:0:0`),
      endTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      chargerType: "FAST_CHARGER"
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalMonthlyFastPrev(res.data);
    }
    else{
    }
  }
  // 총 충전통계 조회 함수 - 월간 / 완속
  const reqGetChargingStatisticTotalMonthlySlow = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      endTime: null,
      chargerType: "SLOW_CHARGER"
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalMonthlySlow(res.data);
    }
    else{
    }
  }
  
  // 총 충전통계 조회 함수 - 월간 / 완속 / 지난달
  const reqGetChargingStatisticTotalMonthlySlowPrev = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getMonth()===0?now.getFullYear()-1:now.getFullYear()} ${now.getMonth()===0?12:now.getMonth()} 1 0:0:0:0`),
      endTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      chargerType: "SLOW_CHARGER"
    }
    let res = await getChargingStaticsTotal(data);
    if(res.status === 200){
      setChargingStaticsTotalMonthlySlowPrev(res.data);
    }
    else{
    }
  }


  // 모든 ev사용자 수 조회 함수
  const reqGetEvUserCount = async() => {
    let reqObj = {
      rootCpoSysId: rootCpoSysId
    }
    let res = await getEvUserCount(reqObj);
    if(res.status === 200){
      setEvUserCount(res.data);
    }
    else{
    }
  }
  // 월별 ev사용자 가입수 조회 함수
  const reqGetEvUserMonthlyCount = async() => {
    let now = new Date();
    let data = {
      rootCpoSysId: rootCpoSysId,
      startDate: getUTCTime(`${now.getFullYear()-1} ${now.getMonth()+2} 1 0:0:0:0`),
      endDate: getUTCTime(new Date(`${now.getFullYear()} ${now.getMonth()+1} ${now.getDate()}  0:0:0:0`) + 1000*60*60*24),
    }
    let res = await getEvUserMonthlyCount(data);
    if(res.status === 200){
      console.log(res.data)
      setEvUserMonthlyCount(res.data);
    }
    else{
    }
  }

  // 하위 CPO별 매출통계 조회 함수
  const reqGetSalesStatisticsBySubCpo = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      endTime: null,
      rootCpoSysId: rootCpoSysId
    }
    let res = await getSalesStatisticsBySubCpo(data);
    if(res.status === 200){
      setSalesStatisticsBySubCpo(res.data);
    }
    else{
    }
  }
  // 충전소별 매출통계 조회 함수
  const reqGetSalesStatisticsByChargingStation = async() => {
    let now = new Date();
    let data = {
      startTime: getUTCTime(`${now.getFullYear()} ${now.getMonth()+1} 1 0:0:0:0`),
      endTime: null,
      cpoSysId: user.cpoId
    }
    let res = await getSalesStatisticsByChargingStation(data);
    if(res.status === 200){
      setSalesStatisticsByChargingStation(res.data);
    }
    else{
    }
  }

  useEffect(()=>{
    reqGetStationList();
    reqGetAllCharger();
    reqGetChargingStatisticTotalToday();
    reqGetChargingStatisticTotalWeekly();
    reqGetChargingStatisticTotalWeeklyPrev();
    reqGetChargingStatisticTotalMonthly();
    reqGetChargingStatisticTotalMonthlyPrev();
    reqGetChargingStatisticTotalYearly();
    reqGetChargingStatisticTotalYearlyPrev();
    reqGetChargingStatisticTotalMonthlyFast();
    reqGetChargingStatisticTotalMonthlyFastPrev();
    reqGetChargingStatisticTotalMonthlySlow();
    reqGetChargingStatisticTotalMonthlySlowPrev();
    reqGetEvUserCount();
    reqGetEvUserMonthlyCount();
    reqGetSalesStatisticsBySubCpo();
    reqGetSalesStatisticsByChargingStation();
  },[]);

  // 기간그룹별 충전통계 조회 함수
  const reqGetChargingStaticsGroupedFast = async() => {
    // let now = new Date();
    let data = {
      startTime: null,
      endTime: null,
      chargerType: null,
      timeGroupType: null
    }
    let res = await getChargingStaticsGrouped(data);
    if(res.status === 200){
      setChargingStaticsGroupedFast(res.data);
    }
    else{

    }
  }
  const reqGetChargingStaticsGroupedSlow = async() => {
    // let now = new Date();
    let data = {
      startTime: null,
      endTime: null,
      chargerType: null,
      timeGroupType: null
    }
    let res = await getChargingStaticsGrouped(data);
    if(res.status === 200){
      setChargingStaticsGroupedSlow(res.data);
    }
    else{

    }
  }

  useEffect(()=>{
    reqGetChargingStaticsGroupedFast();
    reqGetChargingStaticsGroupedSlow();
  },[]);

  
  let cellSpacing = [20,20];
  
  // 전체 충전기 충전 현황
  let Cell0 = ()=>{
    return(
    <div className="cpo-dashboard-charger-info-grid-cell">
      <p className="cpo-dashboard-connector-title">충전소/충전기 운용 정보</p>
      <div className='cpo-dashboard-charger-info-all-charger'>
        <div className="cpo-dashboard-charger-image-container">
          <img className="dashboardStation" alt="" />
        </div>
        <div>
          <p className='cpo-dashboard-charger-info-all-charger-title'>충전소</p>
          
          <div className='cpo-dashboard-charger-info-all-charger-content'>
            <p>{stationCount ? stationCount.toLocaleString():0}</p>
            <p>개소</p>
          </div>
        </div>
      </div>
      <div className='cpo-dashboard-charger-info-all-charger'>
        <div className="cpo-dashboard-charger-image-container">
          <img className="dashboardCharger" alt="" />
        </div>
        <div>
          <p className='cpo-dashboard-charger-info-all-charger-title'>충전기</p>
          <div className='cpo-dashboard-charger-info-all-charger-content'>
            <p>{chargerCount ? chargerCount.toLocaleString():0}</p>
            <p>대</p>
          </div>
        </div>
      </div>
      
    </div>
    )
  }
  // 전체 충전기 충전 현황
  let Cell6 = ()=>{
    return(
      <div className="cpo-dashboard-charger-info-grid-cell">
      <p className="cpo-dashboard-connector-title">커넥터 운용 정보</p>
      <div className="cpo-dashboard-connector-image-container">
        <img className="connectorImg" alt="" />
      </div>

      <div className='cpo-dashboard-charger-info-category-wrapper'>
        <div>
          <p className='cpo-dashboard-charger-info-category-title able'>충전가능</p>
          <div className='cpo-dashboard-charger-info-category-content'>
            <p>{chargerOperationStatistic?.available ? chargerOperationStatistic?.available.toLocaleString() : 0}</p>
            <p>개</p>
          </div>
        </div>
        <div>
          <p className='cpo-dashboard-charger-info-category-title charging'>충전중</p>
          <div className='cpo-dashboard-charger-info-category-content'>
            <p>{chargerOperationStatistic?.charging ? chargerOperationStatistic?.charging.toLocaleString() : 0}</p>
            <p>개</p>
          </div>
        </div>
        <div>
          <p className='cpo-dashboard-charger-info-category-title disable'>고장</p>
          <div className='cpo-dashboard-charger-info-category-content'>
            <p>{chargerOperationStatistic?.faulted ? chargerOperationStatistic?.faulted.toLocaleString() : 0}</p>
            <p>개</p>
          </div>
        </div>
      </div>
    </div>
    )
  }
  // 오늘의 현황
  let Cell1 = ()=>{
    return(
      <div className='cpo-dashboard-pay-report-info-tody-container'>
        <div className='cpo-dashboard-pay-report-info-tody-header'>
          <p>오늘의 현황</p>
          <p>{dateFormat2(new Date())}</p>
        </div>
        <div className='cpo-dashboard-pay-report-info-tody-content-section'>
          <div className="cpo-dashboard-pay-report-info-tody-inner">
            <div>
              <p className='cpo-dashboard-pay-report-info-tody-title'>수익</p>
              <div className='cpo-dashboard-pay-report-info-tody-content'>
                <div className='cpo-dashboard-pay-report-info-tody-content-image'>
                  <img className="rectCoin" alt="" />
                </div>
                <div>
                  <p>
                    {chargingStaticsTotalToday?.revenue ? chargingStaticsTotalToday?.revenue.toLocaleString() : "0"}
                  </p>
                  <p>원</p>
                </div>
              </div>
            </div>
            <div>
              <p className='cpo-dashboard-pay-report-info-tody-title'>충전량</p>
              <div className='cpo-dashboard-pay-report-info-tody-content'>
                <div className='cpo-dashboard-pay-report-info-tody-content-image'>
                  <img className="rectBattery" alt="" />
                </div>
                <div>
                  <p>
                    {chargingStaticsTotalToday?.energyCharged ? chargingStaticsTotalToday?.energyCharged/1000 : "0"}
                  </p>
                  <p>kWh</p>
                </div>
              </div>
            </div>
            <div>
              <p className='cpo-dashboard-pay-report-info-tody-title'>충전 횟수</p>
              <div className='cpo-dashboard-pay-report-info-tody-content'>
                <div className='cpo-dashboard-pay-report-info-tody-content-image'>
                  <img className="rectOutlet" alt="" />
                </div>
                <div>
                  <p>
                    {chargingStaticsTotalToday?.chargingSessions ? chargingStaticsTotalToday?.chargingSessions.toLocaleString() : "0"}
                  </p>
                  <p>회</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  // 주간/월간/연간 현황
  let Cell2 = ()=>{
    const [CurrentTab, setCurrentTab] = useState(0);

    return(<div className='cpo-dashboard-pay-report-info-category-container'>
      <div className="cpo-dashboard-pay-report-info-category-header">
        <div className={'cpo-dashboard-pay-report-info-category-button '+`${CurrentTab==0 ? "clicked":""}`} onClick={()=>{setCurrentTab(0)}}>주간 현황</div>
        <div className={'cpo-dashboard-pay-report-info-category-button '+`${CurrentTab==1 ? "clicked":""}`} onClick={()=>{setCurrentTab(1)}}>월간 현황</div>
        <div className={'cpo-dashboard-pay-report-info-category-button '+`${CurrentTab==2 ? "clicked":""}`} onClick={()=>{setCurrentTab(2)}}>연간 현황</div>
      </div>
      <div className="cpo-dashboard-pay-report-info-category-content-section">
        <div className='cpo-dashboard-pay-report-info-category-content-container top'>
          <div className='cpo-dashboard-pay-report-info-category-content-title'>
            <p>수익</p>
            <p>
              {
              CurrentTab == 0 ? 
                calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.revenue, chargingStaticsTotalWeekly?.revenue)
              : CurrentTab == 1 ? 
                calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.revenue, chargingStaticsTotalMonthly?.revenue)
              : 
                calculatePercentageChange(chargingStaticsTotalYearlyPrev?.revenue, chargingStaticsTotalYearly?.revenue)
              }
            </p>
            <p>%</p>
            {
            CurrentTab == 0 ? 
              calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.revenue, chargingStaticsTotalWeekly?.revenue)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.revenue, chargingStaticsTotalWeekly?.revenue)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            : CurrentTab == 1 ? 
              calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.revenue, chargingStaticsTotalMonthly?.revenue)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.revenue, chargingStaticsTotalMonthly?.revenue)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            : 
              calculatePercentageChange(chargingStaticsTotalYearlyPrev?.revenue, chargingStaticsTotalYearly?.revenue)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalYearlyPrev?.revenue, chargingStaticsTotalYearly?.revenue)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            }
          </div>
          <div className='cpo-dashboard-pay-report-info-category-content top'>
            <p>
              {CurrentTab == 0 ? chargingStaticsTotalWeekly?.revenue ? chargingStaticsTotalWeekly?.revenue.toLocaleString():"0" : CurrentTab == 1 ? chargingStaticsTotalMonthly?.revenue ? chargingStaticsTotalMonthly?.revenue.toLocaleString():"0" : chargingStaticsTotalYearly?.revenue ? chargingStaticsTotalYearly?.revenue.toLocaleString():"0"}
            </p>
            <p>원</p>
          </div>
        </div>
        <div className='cpo-dashboard-pay-report-info-category-content-container left'>
          <div className='cpo-dashboard-pay-report-info-category-content-title'>
            <p>충전량</p>
            <p>
            {
              CurrentTab == 0 ? 
                calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.energyCharged, chargingStaticsTotalWeekly?.energyCharged)
              : CurrentTab == 1 ? 
                calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.energyCharged, chargingStaticsTotalMonthly?.energyCharged)
              : 
                calculatePercentageChange(chargingStaticsTotalYearlyPrev?.energyCharged, chargingStaticsTotalYearly?.energyCharged)
              }
            </p>
            <p>%</p>
            {
            CurrentTab == 0 ? 
              calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.energyCharged, chargingStaticsTotalWeekly?.energyCharged)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.energyCharged, chargingStaticsTotalWeekly?.energyCharged)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            : CurrentTab == 1 ? 
              calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.energyCharged, chargingStaticsTotalMonthly?.energyCharged)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.energyCharged, chargingStaticsTotalMonthly?.energyCharged)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            : 
              calculatePercentageChange(chargingStaticsTotalYearlyPrev?.energyCharged, chargingStaticsTotalYearly?.energyCharged)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalYearlyPrev?.energyCharged, chargingStaticsTotalYearly?.energyCharged)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            }
            </div>
          <div className='cpo-dashboard-pay-report-info-category-content'>
            <p>
            {CurrentTab == 0 ? chargingStaticsTotalWeekly?.energyCharged?chargingStaticsTotalWeekly?.energyCharged/1000:"0" : CurrentTab == 1 ? chargingStaticsTotalMonthly?.energyCharged ? chargingStaticsTotalMonthly?.energyCharged/1000:"0" : chargingStaticsTotalYearly?.energyCharged ? chargingStaticsTotalYearly?.energyCharged/1000:"0"}
            </p>
            <p>kWh</p>
          </div>              </div>
        <div className='cpo-dashboard-pay-report-info-category-content-container right'>
          <div className='cpo-dashboard-pay-report-info-category-content-title'>
            <p>충전 횟수</p>
            <p>
            {
              CurrentTab == 0 ? 
                calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.chargingSessions, chargingStaticsTotalWeekly?.chargingSessions)
              : CurrentTab == 1 ? 
                calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.chargingSessions, chargingStaticsTotalMonthly?.chargingSessions)
              : 
                calculatePercentageChange(chargingStaticsTotalYearlyPrev?.chargingSessions, chargingStaticsTotalYearly?.chargingSessions)
              }
            </p>
            <p>%</p>
            {
            CurrentTab == 0 ? 
              calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.chargingSessions, chargingStaticsTotalWeekly?.chargingSessions)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalWeeklyPrev?.chargingSessions, chargingStaticsTotalWeekly?.chargingSessions)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            : CurrentTab == 1 ? 
              calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.chargingSessions, chargingStaticsTotalMonthly?.chargingSessions)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalMonthlyPrev?.chargingSessions, chargingStaticsTotalMonthly?.chargingSessions)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            : 
              calculatePercentageChange(chargingStaticsTotalYearlyPrev?.chargingSessions, chargingStaticsTotalYearly?.chargingSessions)>0?<TiArrowSortedUp className="increase"/>:calculatePercentageChange(chargingStaticsTotalYearlyPrev?.chargingSessions, chargingStaticsTotalYearly?.chargingSessions)<0?<TiArrowSortedDown className="decrease"/>:<TiMinus/>
            }
          </div>
          <div className='cpo-dashboard-pay-report-info-category-content'>
            <p>
            {CurrentTab == 0 ? chargingStaticsTotalWeekly?.chargingSessions ? chargingStaticsTotalWeekly?.chargingSessions.toLocaleString():"0" : CurrentTab == 1 ? chargingStaticsTotalMonthly?.chargingSessions ? chargingStaticsTotalMonthly?.chargingSessions.toLocaleString():"0" : chargingStaticsTotalYearly?.chargingSessions ? chargingStaticsTotalYearly?.chargingSessions.toLocaleString():"0"}
            </p>
            <p>회</p>
          </div>
        </div>
      </div>
    </div>
    );
  }
  // 급속/완속 매출 추이 그래프
  let Cell3 = ()=>{
    // 급속-완속-전체 탭
    const [salesTab, setSalesTab] = useState(0);
    // 급속 매출 데이터 생성 함수
    const getSalesDataFast = async() => {
      let temp = [];
      for await (let data of chargingStaticsGroupedFast){
        temp.push({
          x: data.date,
          y : data.revenue
        });
      }
      setFastSalesData(temp);
    }
    // 완속 매출 데이터 생성 함수
    const getSalesDataSlow = async() => {
      let temp = [];
      for await (let data of chargingStaticsGroupedSlow){
        temp.push({
          x: data.date,
          y : data.revenue
        });
      }
      setLowSalesData(temp);
    }

    useEffect(()=>{
      if(chargingStaticsGroupedSlow && chargingStaticsGroupedFast){
        getSalesDataFast();
        getSalesDataSlow();
      }
    },[chargingStaticsGroupedSlow]);
    
    // 급속 매출 데이터
    const [fastSalesData, setFastSalesData] = useState([]);
    // 완속 매출 데이터
    const [lowSalesData, setLowSalesData] = useState([]);
    
    return(
      <div className='cpo-dashboard-pay-report-graph-grid-cell'>
        <div className="cpo-dashboard-pay-report-graph-grid-header">
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 0 ?'clicked':""}`} onClick={()=>{setSalesTab(0)}}>급속 매출 추이</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 1 ?'clicked':""}`} onClick={()=>{setSalesTab(1)}}>완속 매출 추이</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 2 ?'clicked':""}`} onClick={()=>{setSalesTab(2)}}>전체 매출 추이</div>
        </div>
        <div className="cpo-dashboard-pay-report-graph-grid-content-section">
          <div></div>
          <div>
          <ChartComponent id="chart1" style={{ textAlign: 'center' }}
            primaryXAxis={{ valueType: 'Category', title:"날짜",  labelIntersectAction:'Rotate45' }}
            primaryYAxis={{ title: '원', rangePadding: 'None', minimum: 0, lineStyle: { width: 0 }, labelFormat:'{value}원',
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 } }} chartArea={{ border: { width: 0 } }}
            tooltip={{ enable: true, enableHighlight: true }}
            legendSettings={{ enableHighlight: true, position:"Top" }} width={"90%"}
            >
              <Inject services={[LineSeries,  Legend, Tooltip, DataLabel, Category]}/>
              <SeriesCollectionDirective>
                {/* 급속 그래프 */}
                {salesTab===1?null:<SeriesDirective fill='hsl(139, 70%, 50%)' dataSource={fastSalesData} xName="x" yName="y" name="급속" width={2} marker={{ visible: true, width: 7, height: 7, shape: 'Circle', isFilled: true }} type="Line"></SeriesDirective>}
                {/* 완속 그래프 */}
                {salesTab===0?null:<SeriesDirective fill='hsl(205, 70%, 50%)' dataSource={lowSalesData} xName="x" yName="y" name="완속" width={2} marker={{ visible: true, width: 6, height: 6, shape: 'Triangle', isFilled: true }} type="Line"></SeriesDirective>}
              </SeriesCollectionDirective>
          </ChartComponent>
          </div>
        </div>
      </div>
    )
  }
  // 급속/완속 전력공급량 그래프
  let Cell4 = ()=>{
    // 급속-완속-전체 탭
    const [salesTab, setSalesTab] = useState(0);
    // 급속 전력공급량 데이터 생성 함수
    const getSalesDataFast = async() => {
      let temp = [];
      for await (let data of chargingStaticsGroupedFast){
        temp.push({
          x: data.date,
          y : data.energyCharged/1000
        });
      }
      setFastSalesData(temp);
    }
    // 완속 전력공급량 데이터 생성 함수
    const getSalesDataSlow = async() => {
      let temp = [];
      for await (let data of chargingStaticsGroupedSlow){
        temp.push({
          x: data.date,
          y : data.energyCharged/1000
        });
      }
      setLowSalesData(temp);
    }

    useEffect(()=>{
      if(chargingStaticsGroupedSlow && chargingStaticsGroupedFast){
        getSalesDataFast();
        getSalesDataSlow();
      }
    },[chargingStaticsGroupedSlow]);
    // 급속 전력공급량 데이터
    const [fastSalesData, setFastSalesData] = useState([]);
    // 완속 전력공급량 데이터
    const [lowSalesData, setLowSalesData] = useState([]);
    return(
      <div className='cpo-dashboard-pay-report-graph-grid-cell'>
        <div className="cpo-dashboard-pay-report-graph-grid-header">
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 0 ?'clicked':""}`} onClick={()=>{setSalesTab(0)}}>급속 전력공급량</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 1 ?'clicked':""}`} onClick={()=>{setSalesTab(1)}}>완속 전력공급량</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 2 ?'clicked':""}`} onClick={()=>{setSalesTab(2)}}>전체 전력공급량</div>
        </div>
        <div className="cpo-dashboard-pay-report-graph-grid-content-section">
          <div></div>
          <div>
          <ChartComponent id="charts" style={{ textAlign: 'center' }}
            primaryXAxis={{ valueType: 'Category', title:"날짜",  labelIntersectAction:'Rotate45' }}
            primaryYAxis={{ title: 'kWh', rangePadding: 'None', minimum: 0, lineStyle: { width: 0 }, labelFormat:'{value}kWh',
            majorTickLines: { width: 0 }, minorTickLines: { width: 0 } }} chartArea={{ border: { width: 0 } }}
            tooltip={{ enable: true, enableHighlight: true }}
            legendSettings={{ enableHighlight: true, position:"Top" }} width={"90%"}
            >
              <Inject services={[LineSeries,  Legend, Tooltip, Highlight, Category]}/>
              <SeriesCollectionDirective>
                  {salesTab===1?null:<SeriesDirective fill='hsl(287, 89%, 71%)' dataSource={fastSalesData} xName="x" yName="y" name="급속" width={2} marker={{ visible: true, width: 7, height: 7, shape: 'Circle', isFilled: true }} type="Line"></SeriesDirective>}
                  {salesTab===0?null:<SeriesDirective fill='hsl(40, 92%, 69%)' dataSource={lowSalesData} xName="x" yName="y" name="완속" width={2} marker={{ visible: true, width: 6, height: 6, shape: 'Triangle', isFilled: true }} type="Line"></SeriesDirective>}
              </SeriesCollectionDirective>
          </ChartComponent>
          </div>
        </div>
      </div>
    )
  }
  // 월간 평균 통계
  let Cell5 = ()=>{
    return(
      <div className="cpo-dashboard-average-report-grid-cell">
          <div className='cpo-dashboard-average-report-grid-header'>
            <p>월간 평균 통계</p>
            <div>
              {/* <p>회원</p>
              <p>195</p> */}
            </div>
          </div>


          <div className='cpo-dashboard-average-report-grid-section left'>
            <div className='cpo-dashboard-average-report-header'>
              <p>급속</p>
              <p>{chargingStaticsTotalMonthlyFast?.chargingTime}</p>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="coinBlue" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 수익</p>
                <p>
                  {calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.revenue/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.revenue/chargingStaticsTotalMonthlyFast?.ChargingSessions) ? calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.revenue/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.revenue/chargingStaticsTotalMonthlyFast?.ChargingSessions) : "0"}
                </p>
                {calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.revenue/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.revenue/chargingStaticsTotalMonthlyFast?.ChargingSessions)<0 ? <TiArrowSortedUp className="increase"/> : calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.revenue/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.revenue/chargingStaticsTotalMonthlyFast?.ChargingSessions)>0 ? <TiArrowSortedDown className="decrease"/> : <TiMinus/>}
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='revenue'>
                  {chargingStaticsTotalMonthlyFast?.revenue/chargingStaticsTotalMonthlyFast?.ChargingSessions ? (chargingStaticsTotalMonthlyFast?.revenue/chargingStaticsTotalMonthlyFast?.ChargingSessions).toLocaleString() : "0"}
                </p>
              </div>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="sandClockBlue" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 충전 시간</p>
                <p>
                  {calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.chargingTime/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.chargingTime/chargingStaticsTotalMonthlyFast?.ChargingSessions) ? calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.chargingTime/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.chargingTime/chargingStaticsTotalMonthlyFast?.ChargingSessions) : "0"}
                </p>
                {calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.chargingTime/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.chargingTime/chargingStaticsTotalMonthlyFast?.ChargingSessions)>0 ? <TiArrowSortedUp className="increase"/> : calculatePercentageChange(chargingStaticsTotalMonthlyFastPrev?.chargingTime/chargingStaticsTotalMonthlyFastPrev?.ChargingSessions, chargingStaticsTotalMonthlyFast?.chargingTime/chargingStaticsTotalMonthlyFast?.ChargingSessions)<0 ? <TiArrowSortedDown className="decrease"/> : <TiMinus/>}
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='time'>
                  {chargingStaticsTotalMonthlyFast?.chargingTime/chargingStaticsTotalMonthlyFast?.ChargingSessions ? (chargingStaticsTotalMonthlyFast?.chargingTime/chargingStaticsTotalMonthlyFast?.ChargingSessions).toLocaleString() : "0"}
                </p>
              </div>
            </div>
          </div>



          <div className='cpo-dashboard-average-report-grid-section right'>
            <div className='cpo-dashboard-average-report-header'>
              <p>완속</p>
              <p>{chargingStaticsTotalMonthlySlow.chargingTime}</p>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="coinGreen" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 수익</p>
                <p>
                  {calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.revenue/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.revenue/chargingStaticsTotalMonthlySlow?.ChargingSessions) ? calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.revenue/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.revenue/chargingStaticsTotalMonthlySlow?.ChargingSessions) : "0"}
                </p>
                {calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.revenue/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.revenue/chargingStaticsTotalMonthlySlow?.ChargingSessions)<0 ? <TiArrowSortedUp className="increase"/> : calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.revenue/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.revenue/chargingStaticsTotalMonthlySlow?.ChargingSessions)>0 ? <TiArrowSortedDown className="decrease"/> : <TiMinus/>}
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='revenue'>
                  {chargingStaticsTotalMonthlySlow?.revenue/chargingStaticsTotalMonthlySlow?.ChargingSessions ? (chargingStaticsTotalMonthlySlow?.revenue/chargingStaticsTotalMonthlySlow?.ChargingSessions).toLocaleString() : "0"}
                </p>
              </div>
            </div>
            <div className='cpo-dashboard-average-report-content-container'>
              <div className='cpo-dashboard-average-report-image'>
                <img className="sandClockGreen" alt="" />
              </div>
              <div className='cpo-dashboard-average-report-title'>
                <p>평균 충전 시간</p>
                <p>
                {calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.chargingTime/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.chargingTime/chargingStaticsTotalMonthlySlow?.ChargingSessions) ? calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.chargingTime/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.chargingTime/chargingStaticsTotalMonthlySlow?.ChargingSessions) : "0"}
                </p>
                {calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.chargingTime/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.chargingTime/chargingStaticsTotalMonthlySlow?.ChargingSessions)>0 ? <TiArrowSortedUp className="increase"/> : calculatePercentageChange(chargingStaticsTotalMonthlySlowPrev?.chargingTime/chargingStaticsTotalMonthlySlowPrev?.ChargingSessions, chargingStaticsTotalMonthlySlow?.chargingTime/chargingStaticsTotalMonthlySlow?.ChargingSessions)<0 ? <TiArrowSortedDown className="decrease"/> : <TiMinus/>}
              </div>
              <div className='cpo-dashboard-average-report-context'>
                <p className='time'>
                  {chargingStaticsTotalMonthlySlow?.chargingTime/chargingStaticsTotalMonthlySlow?.ChargingSessions ? (chargingStaticsTotalMonthlySlow?.chargingTime/chargingStaticsTotalMonthlySlow?.ChargingSessions).toLocaleString() : "0"}
                </p>
              </div>
            </div>
          </div>
        </div>
    );
  }

  let Cell7 = ()=>{
    // 급속-완속-전체 탭
    const [salesTab, setSalesTab] = useState(0);
    const [evUserMonthlyData, setEvUserMonthlyData] = useState([]);
  
    const now = new Date();
    const startDate = Date.UTC(now.getFullYear() - 1, now.getMonth() + 1, 1);
    const endDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    // EV사용자 데이터 생성 함수
    const getEvUserMonthlyData = async() => {
      let temp = [];

      let targetMonthList = await generateYearMonthPairs(startDate, endDate);
      for await (let data of targetMonthList){
        temp.push({
          x: data,
          y : evUserMonthlyCount[data] ? evUserMonthlyCount[data] : 0
        });
      }
      temp.push({
        x: "합계",
        y: evUserCount
      })
      console.log(temp);
      setEvUserMonthlyData(temp);
    }

    const generateYearMonthPairs = async(startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const result = [];
    
      while (start <= end) {
        const year = start.getUTCFullYear();
        const month = String(start.getUTCMonth() + 1).padStart(2, "0");
        result.push(`${year}-${month}`);
        
        start.setUTCMonth(start.getUTCMonth() + 1);
      }
    
      return result;
    }

    useEffect(()=>{
      if(evUserMonthlyCount){
        getEvUserMonthlyData();
      }
    },[evUserMonthlyCount]);

    return(
      <div className='cpo-dashboard-pay-report-graph-grid-cell'>
        <div className="cpo-dashboard-pay-report-graph-grid-header">
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 0 ?'clicked':""}`} onClick={()=>{setSalesTab(0)}}>월별 EV사용자 가입 현황</div>
          {/* <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 1 ?'clicked':""}`} onClick={()=>{setSalesTab(1)}}>완속 전력공급량</div>
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 2 ?'clicked':""}`} onClick={()=>{setSalesTab(2)}}>전체 전력공급량</div> */}
        </div>
        <div className="cpo-dashboard-pay-report-graph-grid-content-section">
          <div></div>
          <div>
          <ChartComponent id="chart2"
            style={{ textAlign: 'center' }}
            primaryXAxis={{ valueType: 'Category', title:"", isIndexed: true}}
            primaryYAxis={{ title: '명', labelFormat:'{value}명',
            }}
            tooltip={{ enable: true, enableHighlight: true }}
            legendSettings={{ enableHighlight: true, position:"Top" }} width={"90%"}
            >
              <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]}/>
              <SeriesCollectionDirective>
                <SeriesDirective dataSource={evUserMonthlyData} xName="x" yName="y" name="EV사용자 가입수" type="Column"></SeriesDirective>
              </SeriesCollectionDirective>
          </ChartComponent>
          </div>
        </div>
      </div>
    )
  }
  
  let Cell8 = ()=>{
    const [salesTab, setSalesTab] = useState(0);
    const [salesDataByStations, setSalesDataByStations] = useState([]);
  
    // EV사용자 데이터 생성 함수
    const getSalesDataByStations = async() => {
      let temp = [];
      for await (let data of Object.keys(salesStatisticsByChargingStation)){
        temp.push({
          x: data,
          y : salesStatisticsByChargingStation[data] ? salesStatisticsByChargingStation[data] : 0
        });
      }
      let sum = sumObjectValues(salesStatisticsByChargingStation);
      temp.push({
        x: "합계",
        y: sum
      })
      console.log(temp);
      setSalesDataByStations(temp);
    }
    const sumObjectValues = (obj) => {
      return Object.values(obj).reduce((sum, value) => sum + value, 0);
    }

    useEffect(()=>{
      if(salesStatisticsByChargingStation){
        getSalesDataByStations();
      }
    },[salesStatisticsByChargingStation]);

    return(
      <div className='cpo-dashboard-pay-report-graph-grid-cell'>
        <div className="cpo-dashboard-pay-report-graph-grid-header">
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 0 ?'clicked':""}`} onClick={()=>{setSalesTab(0)}}>
            {`${user.cpoId === rootCpoSysId ? "직영지점": "충전소"}별 매출 통계`}
          </div>
        </div>
        <div className="cpo-dashboard-pay-report-graph-grid-content-section">
          <div></div>
          <div>
          <ChartComponent id="chartSalesByStations"
            style={{ textAlign: 'center' }}
            primaryXAxis={{ valueType: 'Category', title:"", isIndexed: true}}
            primaryYAxis={{ title: '원', labelFormat:'{value}원',
            }}
            tooltip={{ enable: true, enableHighlight: true }}
            legendSettings={{ enableHighlight: true, position:"Top" }} width={"90%"}
            >
              <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]}/>
              <SeriesCollectionDirective>
                <SeriesDirective dataSource={salesDataByStations} xName="x" yName="y" name={`${user.cpoId === rootCpoSysId ? "직영지점": "충전소"}별 매출 통계`} type="Column"></SeriesDirective>
              </SeriesCollectionDirective>
          </ChartComponent>
          </div>
        </div>
      </div>
    )
  }
  let Cell9 = ()=>{
    const [salesTab, setSalesTab] = useState(0);
    const [salesDataBySubCpo, setSalesDataBySubCpo] = useState([]);
    // salesStatisticsBySubCpo
    // EV사용자 데이터 생성 함수
    const getSalesDataBySubCpo = async() => {
      let temp = [];
      for await (let data of Object.keys(salesStatisticsBySubCpo)){
        temp.push({
          x: data,
          y : salesStatisticsBySubCpo[data] ? salesStatisticsBySubCpo[data] : 0
        });
      }
      let sum = sumObjectValues(salesStatisticsBySubCpo);
      temp.push({
        x: "합계",
        y: sum
      })
      console.log(temp);
      setSalesDataBySubCpo(temp);
    }
    const sumObjectValues = (obj) => {
      return Object.values(obj).reduce((sum, value) => sum + value, 0);
    }

    useEffect(()=>{
      if(salesStatisticsBySubCpo){
        getSalesDataBySubCpo();
      }
    },[salesStatisticsBySubCpo]);

    return(
      <div className='cpo-dashboard-pay-report-graph-grid-cell'>
        <div className="cpo-dashboard-pay-report-graph-grid-header">
          <div className={'cpo-dashboard-pay-report-graph-grid-button '+`${salesTab == 0 ?'clicked':""}`} onClick={()=>{setSalesTab(0)}}>
            {`위탁사업자별 매출 통계`}
          </div>
        </div>
        <div className="cpo-dashboard-pay-report-graph-grid-content-section">
          <div></div>
          <div>
          <ChartComponent id="chartSalesBySubCpo"
            style={{ textAlign: 'center' }}
            primaryXAxis={{ valueType: 'Category', title:"", isIndexed: true}}
            primaryYAxis={{ title: '원', labelFormat:'{value}원',
            }}
            tooltip={{ enable: true, enableHighlight: true }}
            legendSettings={{ enableHighlight: true, position:"Top" }} width={"90%"}
            >
              <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]}/>
              <SeriesCollectionDirective>
                <SeriesDirective dataSource={salesDataBySubCpo} xName="x" yName="y" name={`위탁사업자별 매출 통계`} type="Column"></SeriesDirective>
              </SeriesCollectionDirective>
          </ChartComponent>
          </div>
        </div>
      </div>
    )
  }


  let dashboardObj;
  let restoreModel = [];
  function onRestore(args) {
      dashboardObj.panels = restoreModel;
  }
  let onSave = async(args) => {
    if(dashboardObj){

      restoreModel = dashboardObj?.serialize();
      let tempArr = [Cell0, Cell1, Cell2, Cell3, Cell4, Cell5];
      let idx = 0;
      for await(const item of tempArr){
        restoreModel[idx].content = item;
        idx += 1;
      }
    }
  }
  const panels = [
    { "id":"0", "sizeX": 2, "sizeY": 1, "row": 0, "col": 0, content: Cell0},
    { "id":"6", "sizeX": 2, "sizeY": 1, "row": 0, "col": 2, content: Cell6},
    { "id":"1", "sizeX": 2, "sizeY": 3, "row": 1, "col": 0, content: Cell1},
    { "id":"2", "sizeX": 2, "sizeY": 3, "row": 1, "col": 2, content: Cell2},
    { "id":"3", "sizeX": 4, "sizeY": 3, "row": 4, "col": 0, content: Cell3},
    { "id":"4", "sizeX": 4, "sizeY": 3, "row": 7, "col": 0, content: Cell4},
    { "id":"5", "sizeX": 4, "sizeY": 3, "row": 10, "col": 0, content: Cell5},
    { "id":"7", "sizeX": 4, "sizeY": 3, "row": 13, "col": 0, content: Cell7},
    { "id":"8", "sizeX": 4, "sizeY": 3, "row": 16, "col": 0, content: Cell8},
  ];
  const panels2 = [
    { "id":"0", "sizeX": 2, "sizeY": 1, "row": 0, "col": 0, content: Cell0},
    { "id":"6", "sizeX": 2, "sizeY": 1, "row": 0, "col": 2, content: Cell6},
    { "id":"1", "sizeX": 2, "sizeY": 3, "row": 1, "col": 0, content: Cell1},
    { "id":"2", "sizeX": 2, "sizeY": 3, "row": 1, "col": 2, content: Cell2},
    { "id":"3", "sizeX": 4, "sizeY": 3, "row": 4, "col": 0, content: Cell3},
    { "id":"4", "sizeX": 4, "sizeY": 3, "row": 7, "col": 0, content: Cell4},
    { "id":"5", "sizeX": 4, "sizeY": 3, "row": 10, "col": 0, content: Cell5},
    { "id":"7", "sizeX": 4, "sizeY": 3, "row": 13, "col": 0, content: Cell7},
    { "id":"8", "sizeX": 4, "sizeY": 3, "row": 16, "col": 0, content: Cell8},
    { "id":"Cell9", "sizeX": 4, "sizeY": 3, "row": 19, "col": 0, content: Cell9},
  ];
  
  useEffect(()=>{
    if(rootCpoSysId === user.cpoId && dashboardObj){
      dashboardObj.addPanel({ "id":"Cell9", "sizeX": 4, "sizeY": 3, "row": 19, "col": 0, content: Cell9});
    }
  },[])
  return (<div>
    <div className="cpo-dashboard-container">
      <div className="cpo-dashboard-inner">
        <div className='cpo-dashboard-guide-msg' onClick={()=>{
          dashboardObj.removePanel("Cell9");
        }}>블록을 원하는대로 배치해보세요 <FaHandPaper/></div>
        <div className="control-section">
          <DashboardLayoutComponent id='defaultLayout' created={onSave} ref={s => (dashboardObj = s)} cellSpacing={cellSpacing} cellAspectRatio={100 / 50} panels={rootCpoSysId === user.cpoId ? panels2 : panels} columns={4}/>
        </div>
        
      </div>
    </div>
  </div>);
}
export default CpoDashBoard;