import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util.js';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'

import { deleteAccount } from '../../api/account/accountApi.js';
import CloudAlertRight from '../../components/alerts/CloudAlertRight.js';

export const LabelUserMemberWithdraw = () => {
  let dispatch = useDispatch();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:13, idx:3}));
  },[])
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  let navigator = useNavigate();
  let emailInfo = localStorage.getItem('user-email')

  const reqDeleteAccount = async()=>{
    let temp = await deleteAccount();
  }

  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])

  return (
    <div className="cpo-member-withdraw-page-container">
      <div className='cpo-member-withdraw-section'>
        <div className="cpo-member-withdraw-header">
          <title>회원탈퇴</title>
        </div>
        <div className="cpo-member-withdraw-content">
          <div className="cpo-member-withdraw-content-title">
            <p>탈퇴 안내</p>
            <sup>*</sup>
            <p>매우 중요한 내용입니다. 안내사항 전체를 꼼꼼히 읽어주세요. </p>
          </div>
          <div className='cpo-member-withdraw-email-info-container'>
            <p>{"현재 사용중인 계정 "}<strong>{emailInfo}</strong>{' 의 아이디는 탈퇴 즉시 모든 관련 정보가 영구 삭제 됩니다.'}</p>
          </div>
          <div className='cpo-member-withdraw-list-container'>
            <p>EMOOV의 충전 관리 관련 모든 서비스 사용이 불가하며 서비스 이용 기록이 삭제됩니다.</p>
            <p>구독 관련 서비스 모두가 해지 및 종료되고 이력이 삭제됩니다.</p>
          </div>
          <div className='cpo-member-withdraw-check-list-container'>
            <div className='cpo-member-withdraw-check-container'>
              <div>
                <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                  <input type="checkbox" checked={checked1} onChange={()=>{setChecked1(!checked1)}} />
                  <span className="checkbox_icon"></span>
                </label>
              </div>
              <p>안내 사항을 모두 숙지하였으며, 이에 동의합니다.</p>  
            </div>
            <div className='cpo-member-withdraw-check-container'>
              <div>
                <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                  <input type="checkbox" checked={checked2} onChange={()=>{setChecked2(!checked2)}} />
                  <span className="checkbox_icon"></span>
                </label>
              </div>
              <p>EMOOV의 사업자 및 일반 회원으로서의 계정과 정보들을 영구 삭제하고자 합니다.</p>
            </div>
          </div>
          <div className="cpo-member-withdraw-button-list-container">
            <div className="cpo-member-withdraw-button cancel" onClick={(e)=>{e.preventDefault(); navigator('/cpo/dashboard')}}>취소</div>
            <div className={`cpo-member-withdraw-button submit ${checked1&&checked2 ? "":"none"}`} onClick={()=>{
              reqDeleteAccount();
              // setCloudAlertOn(true);
              }}>
              {/* {cloudAlertOn&&<CloudAlertRight data={"업데이트 예정인 기능입니다."}/>} */}
              
              회원탈퇴</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LabelUserMemberWithdraw;