import Lottie from "react-lottie";
import animationSplash from '../../lotties/loading3.json'

export function Loading() {
  const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationSplash,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};	
return (
  <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Lottie options={defaultOptions} />
  </div>
)
}