import axios from 'axios';
import { getCookie, refresh } from '../../util/util';


export const getChargingTransactions = async(page, count, query)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ChargingTransactions?Size=${count}&Page=${page}${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}

export const getOccpMessageHistoriesList = async(page, query)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/OcppMessageHistories?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}
export const getOccpActionMessageHistoriesList = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp = [];
  await axios.get(`/controlhub/api/cpouser/v1/OcppActionHistories?Action=null&OcppTransactionId=${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}

export const getOccpMessageHistoriesByUniqueId = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}

  let temp = [];
  await axios.get(`/controlhub/api/cpouser/v1/OcppMessageHistories?UniqueId=${id}&SortOrder=Asc`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}


export const getChargerHubByChargerId = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}

  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/Chargers/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp=res;
  })
  .catch((err)=>{
    temp=err.response;
  })
  return temp;
}

export const getChargerHub = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp=res;
  })
  .catch((err)=>{
    temp=err.response;
  })
  return temp;
}

// chargerHub 정보 API GET
export const getChargerHubListByStationId = async(stationList)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp = [];
  try {
    for await(const it of stationList){
      await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
      .catch(()=>{
      })
    }
  } catch (err) {
    temp = err.response;
  }
  return temp;
}

// chargerHub 정보 API GET by ChargerId
export const getChargerHubListByChargerId = async(chargerId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/Chargers/${chargerId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{    
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  }
  )
  return temp;
}








export const getTagAuthorization = async(page, query)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp = [];
  console.log(query);

  await axios.get(`/controlhub/api/cpouser/v1/TagAuthorization?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}

export const getChargerStatusHistories = async(page , query)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  console.log(page)
  let temp = [];

  await axios.get(`/controlhub/api/cpouser/v1/ChargerStatusHistories?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}
export const getChargerErrorHistories = async(page , query)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp = [];

  await axios.get(`/controlhub/api/cpouser/v1/ChargerStatusHistories?Status=Faulted&Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}


// Chas 리스트 get
export const getChasList = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// Chas 추가
export const postChas = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {name, description} = data;
  await axios.post(`/controlhub/api/cpouser/v1/ActionSets`,{
    "name": name,
    "description": description
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    // console.log(res.data)
    // props.getCHASList();
    // props.setOnOff(false);
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}


// 이름으로 Chas Get
export const getChasByName = async(chasName)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets/Validation-Name?name=${chasName}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    console.log(res);
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 특정 ActionSet GET
export const getChas = async(actionSetId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}
// 모든 Action GET
export const getActions = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get('/controlhub/api/ControlHubActions',{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}
// 액션셋>특정 메세지의 액션리스트 GET
export const getActionOfMessageList = async(actionSetId, messageType)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}/${messageType}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 액션셋>DataTransfer 액션리스트 PUT
export const putActionOfDataTransferMessage = async(actionSetId, messageType, data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {controlHubActionInfoIds, dataTransferMessageId} = data;
  let temp;
  await axios.put(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}/${messageType}`,{
    "controlHubActionInfoIds": controlHubActionInfoIds,
    "dataTransferMessageId": dataTransferMessageId,
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 액션셋>DataTransfer 액션 삭제
export const deleteActionOfDataTransferMessage = async(actionSetId, messageActionMapId, dataTransferMessageId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}/MessageActionMaps/${messageActionMapId}`,{
    dataTransferMessageId: dataTransferMessageId,
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 액션셋>특정 메세지의 액션리스트 PUT
export const putActionOfMessageList = async(actionSetId, messageType, data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {controlHubActionInfoIds} = data;
  let temp;
  await axios.put(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}/${messageType}`,{
    "controlHubActionInfoIds": controlHubActionInfoIds
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// 특정 ActionSet Delete
export const deleteChas = async(actionSetId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSet 중복이름 체크
export const getChasValidationName = async(chasName)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSets/Validation-Name?name=${chasName}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}
// ActionSet 기본정보 수정
export const putChasInfo = async(actionSetId, data)=>{
  let signedToken =  getCookie('signedToken');
  let {name, description} = data;
  let temp;
  await axios.put(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}`,{
    "name": name,
    "description": description
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}


export const getChargerOperationStatistic = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get('/controlhub/api/cpouser/v1/ChargerOperationStatistics',{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{temp = err.response})
  return temp;
}





// ******************************************************************************************
// * ActionSet Profile SysAdmin
// ******************************************************************************************

// ActionSetProfile 커스텀 조회 - 시스템 관리자
export const getActionSetProfileListSysAdmin = async(data)=>{
  let {page, size, minCreatedAt, maxCreatedAt, minUpdatedAt, maxUpdatedAt, sortField, sortOrder, ocppVersion, keyword, minDeletedAt, maxDeletedAt, isDeleted, published} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/sysadmin/v1/ActionSetProfiles?${page?`Page=${page}&`:""}${size?`Size=${size}&`:""}${minCreatedAt?`MinCreatedAt=${minCreatedAt}&`:""}${maxCreatedAt?`MaxCreatedAt=${maxCreatedAt}&`:""}${minUpdatedAt?`MinUpdatedAt=${minUpdatedAt}&`:""}${maxUpdatedAt?`MaxUpdatedAt=${maxUpdatedAt}&`:""}${sortField?`SortField=${sortField}&`:""}${sortOrder?`SortOrder=${sortOrder}&`:""}${ocppVersion?`OcppVersion=${ocppVersion}&`:""}${keyword?`Keyword=${keyword}&`:""}${minDeletedAt?`MinDeletedAt=${minDeletedAt}&`:""}${maxDeletedAt?`MaxDeletedAt=${maxDeletedAt}&`:""}${isDeleted?`IsDeleted=${isDeleted}&`:""}${published?`Published=${published}`:""}`
    ,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 추가 - 시스템 관리자
export const postActionSetProfileSysAdmin = async(data)=>{
  let {name, platform, ocppVersion, description} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.post(`/controlhub/api/sysadmin/v1/ActionSetProfiles`,{
    "name": name,
    "platform": platform,
    "ocppVersion": ocppVersion,
    "description": description
  },{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile with 액션셋Profile ID - 시스템 관리자
export const getActionSetProfileWithActionSetProfileIdSysAdmin = async(actionSetProfileId)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.get(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}`,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 수정 - 시스템 관리자
export const putActionSetProfileSysAdmin = async(actionSetProfileId, data)=>{
  let {name, platform, ocppVersion, description} = data;
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.put(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}`,{
    "name": name,
    "platform": platform,
    "ocppVersion": ocppVersion,
    "description": description
  },{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 삭제 - 시스템 관리자
export const deleteActionSetProfileSysAdmin = async(actionSetProfileId)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.delete(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}`,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 배포 - 시스템 관리자
export const putActionSetProfilePublishSysAdmin = async(actionSetProfileId)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.put(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}/Publish`,{}, {headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 배포 중단 - 시스템 관리자
export const putActionSetProfileUnpublishSysAdmin = async(actionSetProfileId)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.put(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}/UnPublish`,{}, {headers  : {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 버전 추가 - 시스템 관리자
export const postActionSetProfileVersionSysAdmin = async(actionSetProfileId, data)=>{
  let {version, actionSetId, description} = data;
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.post(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}/Versions`,{
    "version": version,
    "actionSetId": actionSetId,
    "description": description
  }, {headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 버전 정보 업데이트 - 시스템 관리자
export const putActionSetProfileVersionSysAdmin = async(actionSetProfileId, actionSetProfileVersionId, data)=>{
  let {description} = data;
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.put(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}/Versions/${actionSetProfileVersionId}`,{
    "description": description
  }, {headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 버전 삭제 - 시스템 관리자
export const deleteActionSetProfileVersionSysAdmin = async(actionSetProfileId, actionSetProfileVersionId)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.delete(`/controlhub/api/sysadmin/v1/ActionSetProfiles/${actionSetProfileId}/Versions/${actionSetProfileVersionId}`, {headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}


// ******************************************************************************************
// * ActionSet Profile CpoUser
// ******************************************************************************************

// ActionSetProfile 커스텀 조회 - CpoUser
export const getActionSetProfileListCpoUser = async(data)=>{
  let {page, size, minCreatedAt, maxCreatedAt, minUpdatedAt, maxUpdatedAt, sortField, sortOrder, ocppVersion, keyword} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSetProfiles?${page?`Page=${page}&`:""}${size?`Size=${size}&`:""}${minCreatedAt?`MinCreatedAt=${minCreatedAt}&`:""}${maxCreatedAt?`MaxCreatedAt=${maxCreatedAt}&`:""}${minUpdatedAt?`MinUpdatedAt=${minUpdatedAt}&`:""}${maxUpdatedAt?`MaxUpdatedAt=${maxUpdatedAt}&`:""}${sortField?`SortField=${sortField}&`:""}${sortOrder?`SortOrder=${sortOrder}&`:""}${ocppVersion?`OcppVersion=${ocppVersion}&`:""}${keyword?`Keyword=${keyword}&`:""}`
    ,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// ActionSetProfile 버전 카피 - CpoUser
export const postActionSetProfileVersionCopyCpoUser = async(actionSetProfileId, actionSetProfileVersionId, data)=>{
  let {actionSetName} = data;
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.post(`/controlhub/api/cpouser/v1/ActionSetProfiles/${actionSetProfileId}/Versions/${actionSetProfileVersionId}`,{
    "actionSetName": actionSetName
  }, {headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// Profile 액션셋 정보 조회
export const getActionSetOfProfileVersion = async(actionSetProfileId, actionSetProfileVersionId)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSetProfiles/${actionSetProfileId}/Versions/${actionSetProfileVersionId}/ActionSet`,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// Profile 액션셋 메세지-액션 조회
export const getActionOfProfileVersion = async(actionSetProfileId, actionSetProfileVersionId, type, dataTransferMessageId)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ActionSetProfiles/${actionSetProfileId}/Versions/${actionSetProfileVersionId}/ActionSet/${type}${dataTransferMessageId ? `?dataTransferMessageId=${dataTransferMessageId}` : ""}`
    ,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}




// ******************************************************************************************
// * Firmware CpoUser
// ******************************************************************************************

// Firmware 리스트 조회 - CpoUser
export const getFirmwareList = async(data)=>{
  let {page, size} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/Firmwares?${page?`Page=${page}&`:""}${size?`Size=${size}&`:""}`
    ,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}

// Firmware 추가
export const postFirmwareCpoUser = async(data)=>{
  let {name, description} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  
  let temp;
  await axios.post(`/controlhub/api/cpouser/v1/Firmwares`,{
    "name": name,
    "description": description
  },{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}
// Firmware 수정 
export const putFirmwareCpoUser = async(firmwareId, data)=>{
  let {name, description} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.put(`/controlhub/api/cpouser/v1/Firmwares/${firmwareId}`,{
    "name": name,
    "description": description
  },{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}
// Firmware 삭제
export const deleteFirmwareCpoUser = async(firmwareId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/controlhub/api/cpouser/v1/Firmwares/${firmwareId}`,{headers: {
    Authorization: `Bearer ${signedToken}`
  }})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}
// Firmware 버전 정보 조회
export const getFirmwareVersion = async(firmwareId, versionId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/Firmwares/${firmwareId}/Versions/${versionId}`,{headers: {
    Authorization: `Bearer ${signedToken}`
  }})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}
// Firmware 버전 추가
export const postFirmwareVersion = async(firmwareId, data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {version, file, description} = data;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('version', version);
  formData.append('description', description);
  let temp;
  await axios.post(`/controlhub/api/cpouser/v1/Firmwares/${firmwareId}/Versions`,formData
    ,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}
// Firmware 버전 수정
export const putFirmwareVersion = async(firmwareId, versionId, data)=>{
  let {description} = data;
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.put(`/controlhub/api/cpouser/v1/Firmwares/${firmwareId}/Versions/${versionId}`,{
    "description": description
  },{headers: {
    Authorization: `Bearer ${signedToken}`
  }})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}
// Firmware 버전 삭제
export const deleteFirmwareVersion = async(firmwareId, versionId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/controlhub/api/cpouser/v1/Firmwares/${firmwareId}/Versions/${versionId}`,{headers: {
    Authorization: `Bearer ${signedToken}`
  }})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}




// Firmware 적용 요청 - FTP
export const postFirmwareApplyRequestFTP = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargerId, firmwareId, firmwareVersionId, retrievedDate} = data;
  await axios.post(`/controlhub/api/v1/Ocpp16Messages/${chargerId}/UpdateFirmware`,{
    "firmwareId": firmwareId,
    "firmwareVersionId": firmwareVersionId,
    "retrievedDate": retrievedDate
  },{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}
// Firmware 적용 요청 - URL
export const postFirmwareApplyRequestURL = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, location} = data;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/updateFirmware?chargingStationIdentity=${chargingStationIdentity}&location=${location}`,{
    headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}





// SubCpo의 충전기 정보
export const getControlHubInfoAboutSubCpo = async(data)=>{
  let signedToken =  getCookie('signedToken');
  let temp;
  let {chargerSysId, subCpoSysId} = data;
  await axios.get(`/controlhub/api/cpouser/v1/SubCpoChargers/${chargerSysId}?subCpoSysId=${subCpoSysId}`,{headers: {Authorization: `Bearer ${signedToken}`}})
  .then((res)=>{temp = res;})
  .catch((err)=>{temp = err.response})
  return temp;
}