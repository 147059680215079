import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";

import { TfiClose } from "react-icons/tfi";

import {getRoamingCpoUserCheck, postRoamingCpoUser, postRoamingChargingStation} from '../../api/roaming/roamingApi.js';
import {getMoeCpoByCpoId, postMoeCpo} from '../../api/moe/moeApi.js';
import { Loading } from '../alerts/Loading.js';
import { useSelector } from 'react-redux';

import { handleNumericInput } from '../../util/util.js';

export const RoamingChargingStationModal = (props) => {
  const user = useSelector(state => state.user);
  const [tabIdx, setTabIdx] = useState(0);
  
  const [errMsg, setErrMsg] = useState(null);

  const [loadingOn, setLoadingOn] = useState(false);
  const [roamingCpoUserLoading, setRoamingCpoUserLoading] = useState(true);

  useEffect(()=>{
    if(errMsg){
      setTimeout(()=>{
        setErrMsg(null);
      },3000);
    }
  },[errMsg])
  

  // 로밍CPO 확인
  const reqGetRoamingCpoUserCheck = async()=>{
    setRoamingCpoUserLoading(true);
    let res = await getRoamingCpoUserCheck();
    setTimeout(() => {
      if(res.status === 200){
        if(res.data.exist){
          setTabIdx(1);
        }
      }
      else{
        
      }
      setRoamingCpoUserLoading(false);
    }, 1500);
  }

  useEffect(()=>{
    reqGetRoamingCpoUserCheck();
  },[])

  const [roamingCpoUser, setRoamingCpoUser] = useState({
    "moeBId": "",
    "name": user.name
  });

  const reqPostRoamingUser = async()=>{
    setLoadingOn(true);
    let res = await postRoamingCpoUser(roamingCpoUser);
    if(res.status === 201){
      setTabIdx(1);
    }
    else{
      setErrMsg("로밍 CPO 등록에 실패하였습니다.");
    }
    setLoadingOn(false);
  }

  const reqGetMoeCpoByCpoId = async()=>{
    setRoamingCpoUserLoading(true);
    let res = await getMoeCpoByCpoId(user.cpoId);
    setTimeout(() => {
      if(res.status === 200){
        setTabIdx(2);
      }
      else{

      }
      setRoamingCpoUserLoading(false);
    }, 1500);
  }

  const [moeCpoUser, setMoeCpoUser] = useState({
    "cpoId": user.cpoId,
    "cpoName": user.name,
    "bid": "",
    "bkey": "",
  });

  const reqPostMoeCpo = async()=>{
    setLoadingOn(true);
    let res = await postMoeCpo({
      "cpoId": user.cpoId,
      "cpoName": user.name,
      "bid": moeCpoUser.bid,
      "bkey": moeCpoUser.bkey,
    });
    if(res.status === 201){
      setTabIdx(2);
    }
    else{
      setErrMsg("환경부 CPO 등록에 실패하였습니다.");
    }
    setLoadingOn(false);
  }

  useEffect(()=>{
    if(tabIdx === 1){
      reqGetMoeCpoByCpoId();
    }
  },[tabIdx])


  const [roamingChargingStation, setRoamingChargingStation] = useState({
    "moEChargingStationId": "",
    "emoovChargingStationId": props.roamingTarget,
  });

  const reqPostRoamingChargingStation = async()=>{
    setLoadingOn(true);
    let res = await postRoamingChargingStation(roamingChargingStation);
    if(res.status === 201){
      props.callback();
      props.setOnOff(false);
    }
    else{
      setErrMsg("충전소 로밍 등록에 실패하였습니다.");
    }
    setLoadingOn(false);
  }

  return (
    <div className="roaming-charging-station-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="roaming-charging-station-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="roaming-charging-station-modal-header">
          <title>충전소 로밍</title>
          <div className='roaming-charging-station-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="roaming-charging-station-modal-content">
          {
            tabIdx === 0 ? // 로밍 확인
              <>
                <div className="roaming-charging-station-modal-content-header">
                  <p className="roaming-charging-station-modal-content-title">{roamingCpoUserLoading ? "로밍 CPO 확인":"로밍 CPO 등록"}</p>
                </div>
                {
                  roamingCpoUserLoading ?
                  <div className='roaming-charging-station-modal-loading-wrapper'>
                    <div>
                      <Loading/> 
                    </div> 
                  </div>
                  :
                  <>
                    <div className="roaming-charging-station-modal-content-wrapper">
                      <p>moeBId</p>
                      <div className="roaming-charging-station-modal-content-input-container">
                        <input type="text"  placeholder='moeBId' value={roamingCpoUser.moeBId} onChange={(e)=>{setRoamingCpoUser({...roamingCpoUser,"moeBId":e.target.value})}}/>
                      </div>
                      <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                    </div>
                    <div className='roaming-charging-station-modal-button-container'>
                      {/* <div className="roaming-charging-station-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                      <div></div>
                      <div className={`roaming-charging-station-modal-button ${!roamingCpoUser.moeBId ? "disabled":""}`} onClick={()=>{reqPostRoamingUser()}}>
                        등록하기
                      </div>
                    </div>
                  </>
                }

              </>
            :
            tabIdx === 1 ? // 환경부 CPO 등록
              <>
                <div className="roaming-charging-station-modal-content-header">
                  <p className="roaming-charging-station-modal-content-title">{roamingCpoUserLoading ? "환경부 CPO 등록 확인":"환경부 CPO 등록"}</p>
                </div>
                {
                  roamingCpoUserLoading ?
                  <div className='roaming-charging-station-modal-loading-wrapper'>
                    <div>
                      <Loading/> 
                    </div> 
                  </div>
                  :
                  <>
                    <div className="roaming-charging-station-modal-content-wrapper">
                      <p>bid</p>
                      <div className="roaming-charging-station-modal-content-input-container">
                        <input type="text"  placeholder='bid' value={moeCpoUser.bid} onChange={(e)=>{setMoeCpoUser({...moeCpoUser, "bid": e.target.value})}}/>
                      </div>
                      <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                    </div>
                    <div className="roaming-charging-station-modal-content-wrapper">
                      <p>bkey</p>
                      <div className="roaming-charging-station-modal-content-input-container">
                        <input type="text"  placeholder='bkey' value={moeCpoUser.bkey} onChange={(e)=>{setMoeCpoUser({...moeCpoUser, "bkey": e.target.value})}}/>
                      </div>
                      <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                    </div>
                    <div className='roaming-charging-station-modal-button-container'>
                      {/* <div className="roaming-charging-station-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                      <div>
                        {errMsg && <p className='roaming-charging-station-modal-errMsg'>{errMsg}</p>}
                      </div>
                      <div className={`roaming-charging-station-modal-button ${!moeCpoUser.bid && !moeCpoUser.bkey ? "disabled":""}`} onClick={()=>{reqPostMoeCpo()}}>
                        {loadingOn ? <Loading/> : "등록하기"}
                      </div>
                    </div>
                  </>
                }
              </>
            :
            tabIdx === 2 ? // 충전소 로밍 등록
              <>
                <div className="roaming-charging-station-modal-content-header">
                  <p className="roaming-charging-station-modal-content-title">충전소 로밍</p>
                </div>
                <div className="roaming-charging-station-modal-content-wrapper">
                  <p>환경부 충전소 ID</p>
                  <div className="roaming-charging-station-modal-content-input-container">
                    <input type="text"  placeholder='환경부 충전소 ID - 6자리 숫자열을 입력해주세요.' value={roamingChargingStation.moEChargingStationId} maxLength={6} onInput={handleNumericInput} onChange={(e)=>{setRoamingChargingStation({...roamingChargingStation, "moEChargingStationId": e.target.value})}}/>
                  </div>
                  <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                </div>
                <div className='roaming-charging-station-modal-button-container'>
                  {/* <div className="roaming-charging-station-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                  <div>
                    {errMsg && <p className='roaming-charging-station-modal-errMsg'>{errMsg}</p>}
                  </div>
                  <div className={`roaming-charging-station-modal-button ${!roamingChargingStation.moEChargingStationId || roamingChargingStation.moEChargingStationId.length !== 6 ? "disabled":""}`} onClick={()=>{reqPostRoamingChargingStation()}}>
                    {loadingOn ? <Loading/> : "등록하기"}
                  </div>
                </div>
              </>
            :
            <></>
          }
          
          {/* <div>
            <div className="roaming-charging-station-modal-content-header">
              <p className="roaming-charging-station-modal-content-title">담당자 정보</p>
              <p className='roaming-charging-station-modal-content-desc'>등록 이후에도 담당자 추가/수정이 가능합니다</p>
            </div>
            <div className="roaming-charging-station-modal-content-wrapper">
              <p>이름</p>
              <div className="roaming-charging-station-modal-content-input-container">
                <input type="text"  placeholder='이름' value={name} onChange={(e)=>{setName(e.target.value)}}/>
              </div>
            </div>
            <div className="roaming-charging-station-modal-content-wrapper">
              <p>휴대전화번호</p>
              <div className="roaming-charging-station-modal-content-input-container">
                <input type="text"  placeholder='휴대전화번호' value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
              </div>
            </div>
            <div className="roaming-charging-station-modal-content-wrapper divide">
              <div>
                <p>부서 이름</p>
                <div className="roaming-charging-station-modal-content-input-container">
                  <input type="text"  placeholder='부서 이름' value={division} onChange={(e)=>{setDivision(e.target.value)}}/>
                </div>
              </div>
              <div>
                <p>직책</p>
                <div className="roaming-charging-station-modal-content-input-container">
                  <input type="text"  placeholder='직책' value={role} onChange={(e)=>{setRole(e.target.value)}}/>
                </div>
              </div>
            </div>
          </div> */}

        </div>
        
      </div>
    </div>
  )
}
export default RoamingChargingStationModal
