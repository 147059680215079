import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import {setNum} from '../../store.js'
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { getCookie } from '../../util/util.js';
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { HiSpeakerphone } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { GoArrowUpRight } from "react-icons/go";

import Selector2 from '../../components/buttons/Selector2.js';

import { getStationList } from '../../api/infra/infraApi.js';
import { exceptDeletedStation, adjustColor } from '../../util/util';

import { getDefaultPricingPlan } from '../../api/chargerate/chargerateApi.js';

import { Loading } from '../../components/alerts/Loading.js';

import { putDefaultPricingPlan, patchDefaultPricingPlan } from '../../api/chargerate/chargerateApi.js';
import { numberInput } from '../../util/util.js';


import { getAllCharger, postCharger, postStation, getChargerDisplaySerialNumberDuplicationCheck, getChargerModelList } from '../../api/infra/infraApi.js';
import { getChargerLimit, getSubscription } from '../../api/subscription/subscriptionApi.js';

import ChargerLimitAlert from '../../components/alerts/ChargerLimitAlert.js';
import { LoadingList } from '../../components/alerts/LoadingList.js';
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

import FastEnrollChargerModelListItem from '../../components/list/FastEnrollChargerModelListItem.js';
import CpoChargerModelEnrollModal from '../../components/modals/CpoChargerModelEnrollModal.js';
const LabelFastEnroll = (props) => {
  let navigator = useNavigate();

  // redux
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  useEffect(()=>{
    dispatch(setNum({id:1, idx:0}));
  },[])

  useEffect(()=>{
    reqGetChargerLimit();
  },[])
  const reqGetChargerLimit = async()=>{
    let chargerResult = await getAllCharger();
    let limitCount = await getChargerLimit();
    setChargerTotal(chargerResult?.Total);
    setChargerLimit(limitCount?.count);
  }
  
  const [chargerTotal, setChargerTotal] = useState();
  const [chargerLimit, setChargerLimit] = useState();

  const stationNameInputRef = useRef(null);
  
  const chargerIdInputRef = useRef(null);


  const [category, setCategory] = useState(null);

  const [nameContent, setNameContent] = useState();
  
  const [chargerIdContent, setChargerIdContent] = useState();
  
  const [chargerOCCPContent, setChargerOCCPContent] = useState();
  
  const [memberRate, setMemberRate] = useState(null);
  const [nonMemberRate, setNonMemberRate] = useState(null);
  // 선택자 클릭 여부
  const [occpActive, setOccpActive] = useState('');
  const [occpSelect, setOccpSelect] = useState('none');
  
  const [stationActive, setStationActive] = useState('');
  const [stationSelect, setStationSelect] = useState('none');

  const activeReset = ()=>{
    setStationActive(false);
    setOccpActive(false);
  }

  

  //  validation
  const [nameContentValidation, setNameContentValidation] = useState(null);
  const [stationValidation, setStationValidation] = useState(null);
  const [nameContentDuplication, setNameContentDuplication] = useState(null);
  const [chargerIdContentValidation, setChargerIdContentValidation] = useState(null);
  const [chargerOCCPContentValidation, setChargerOCCPContentValidation] = useState(null);



  const [buttonTab1, setButtonTab1] =useState(false);

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);

  useEffect(()=>{
    reqGetStationList();
    reqGetDefaultPricingPlan();
  },[])
  
  // cpo가 소유한 station GET
  const reqGetStationList = async(id)=>{  
    let result = await getStationList();
    if(result.status === 200){
      let temp = exceptDeletedStation(result);
      setStationInfo(temp);
    }
  }



  const stationNameDuplicationCheck = async()=>{
    await axios.get(`/infra/api/cpouser/v1/ChargingStations/isExist?codeName=${nameContent}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      if(!res.data){
        setNameContentDuplication(true);
      }
      else{
        setNameContentDuplication("disable");
      }
    })
  }

  const inputValidation = ()=>{
    if(category == 1){
      if(!nameContent || nameContentDuplication == "disable" || !nameContentDuplication){setNameContentValidation("invalid");}else{setNameContentValidation("");}
    }
    else{
      if(stationSelect == 'none'){setStationValidation("invalid")}else{setStationValidation("")}
    }
    if(!chargerDisplaySerialNumber || chargerDisplaySerialNumberDuplication == "disable" || !chargerDisplaySerialNumberDuplication){setChargerDisplaySerialNumberValidation("invalid");}else{setChargerDisplaySerialNumberValidation("");}
    if(!chargerIdContent){setChargerIdContentValidation("invalid");}else{setChargerIdContentValidation("");}
    if(!chargerOCCPContent){setChargerOCCPContentValidation("invalid");}else{setChargerOCCPContentValidation("");}
    if( (category == 1 ?nameContentDuplication:stationSelect)&& chargerDisplaySerialNumber && chargerIdContent && chargerOCCPContent) return true;
    return false;
  }

  const [chargerDisplaySerialNumber, setChargerDisplaySerialNumber] = useState(null);
  const [chargerDisplaySerialNumberValidation, setChargerDisplaySerialNumberValidation] = useState(null);
  const [chargerDisplaySerialNumberDuplication, setChargerDisplaySerialNumberDuplication] = useState(null);
  
  const reqGetChargerSerialNumberDuplicationCheck = async()=>{
    let result = await getChargerDisplaySerialNumberDuplicationCheck(chargerDisplaySerialNumber);
    if(result.status === 200){
      if(result.data.IsExist === true){
        setChargerDisplaySerialNumberDuplication("disable");
      }
      else{
        setChargerDisplaySerialNumberDuplication(true);
      }
    }
    else{
      setChargerDisplaySerialNumberDuplication("disable");
    }
  }


  const enroll = async()=>{
    setLoadingOn(true);
    if(!inputValidation()){return;}
    let postStationResult = await postStation(nameContent);
    if(postStationResult.status === 201){
      setStationEnrollCheck(postStationResult.data.ChargingStationId);
    }
    else{
      setErrMsg("충전소 등록에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      return;
    };
  }

  const enrollCharger = async()=>{
    let postChargerResult = await postCharger({
      "displaySerialNumber": chargerDisplaySerialNumber,
      "chargerModelId": ChargerModel.Id,
      "chargingStationId": stationEnrollCheck,
      "protocol": chargerOCCPContent == "OCPP 1.6" ? "OCPP_1_6" : "OCPP_2_0_1",
      "chargePointSerialNumber": chargerIdContent
    });
    if(postChargerResult?.status === 200 || postChargerResult?.status === 201){
      setChargerId(postChargerResult.data.ChargerId);
    }
    else{
      setErrMsg("충전기 등록에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      return;
    }
    setLoadingOn(false);
  }

  const [stationEnrollCheck, setStationEnrollCheck] = useState(null);

  useEffect(()=>{
    if(!stationEnrollCheck)return;
    enrollCharger();
  },[stationEnrollCheck])
  


  const enroll2 = async()=>{
    setLoadingOn(true);
    if(!inputValidation()){return;}


    let result = await axios.post('/infra/api/cpouser/v1/Chargers',{
        "displaySerialNumber": chargerDisplaySerialNumber,
        "chargerModelId": ChargerModel.Id,
        "chargingStationId": stationSelect.ChargingStationId,
        "protocol": chargerOCCPContent == "OCPP 1.6" ? "OCPP_1_6" : "OCPP_2_0_1",
        "chargePointSerialNumber": chargerIdContent
      },{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        setChargerId(res.data.ChargerId);
      })
      .catch((err)=>{
      })
    setLoadingOn(false);
  }


  const [tabIdx, setTabIdx] = useState(0);
  const [chargerId, setChargerId] = useState(null);
  
  // 기본 설정 단가 get
  const reqGetDefaultPricingPlan = async()=>{
    let result = await getDefaultPricingPlan();
    if(result.status === 200){
      setMemberRate(result.data.memberPlan?.defaultUnitPrice);
      setNonMemberRate(result.data.nonMemberPlan?.defaultUnitPrice);
    }
    else {
    }
  }



  const reqPatchDefaultPricingPlan = async(pricingType)=>{
    let reqObj = {
      "pricingType": pricingType,
      "currency": "KRW",
      "defaultUnitPrice": pricingType==="Member"?memberRate:nonMemberRate
    }

    let result = await patchDefaultPricingPlan(reqObj);
    if(result.status === 200){
      pricingType==="Member"?setMemberRateCheck(true):setNonMemberRateCheck(true);
    }
    else if(result.status === 400){
      if(result.data.ErrorCode ==="PLAN_NOT_EXIST"){
        await reqPutDefaultPricingPlan(pricingType);
      }
      else{
        setErrMsg("요금설정에 실패하였습니다.");
        setTimeout(() => {
          setErrMsg("");
        }, 3000);
        pricingType==="Member"?setMemberRateCheck(false):setNonMemberRateCheck(false);
      }
    }
    reqGetDefaultPricingPlan();
  }
  const reqPutDefaultPricingPlan = async(pricingType)=>{
    let reqObj = {
      "pricingType": pricingType,
      "currency": "KRW",
      "defaultUnitPrice": pricingType==="Member"?memberRate:nonMemberRate,
      "unitPrices":[]
    }
    let result = await putDefaultPricingPlan(reqObj);
    if(result.status === 200){
      pricingType==="Member"?setMemberRateCheck(true):setNonMemberRateCheck(true);
    }
    else {
      setErrMsg("요금설정에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      pricingType==="Member"?setMemberRateCheck(false):setNonMemberRateCheck(false);
    }
    reqGetDefaultPricingPlan();
  }

  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  const reqGetSubscription = async()=>{
    let result = await getSubscription();
    if(result?.status === 200){
      setSubscriptionInfo(result.data);
    }
    else{
    }
  }

  useEffect(()=>{
    reqGetSubscription();
  },[])


  const [enrollCheck, setEnrollCheck] = useState(false);
  const [rateInputMode, setRateInputMode] = useState(false);
  
  const [loadingOn, setLoadingOn] = useState(false);
  const [chargerLimitAlertOn, setChargerLimitAlertOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const [memberRateCheck, setMemberRateCheck] = useState(false);
  const [nonMemberRateCheck, setNonMemberRateCheck] = useState(false);

  useEffect(()=>{
    if(memberRateCheck && nonMemberRateCheck) setTabIdx(4);
  },[memberRateCheck , nonMemberRateCheck])


  const [ChargerModel, setChargerModel] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState();
  
  const [chargerModelList, setChargerModelList] = useState();
  const [chargerModelCount, setChargerModelCount] = useState(0);
  const [checkedItemList, setCheckedItemList] = useState([]);

  useEffect(()=>{
    if(checkedItemList.length !== 0){
      setChargerModel(checkedItemList[0]);
    }
    else{
      setChargerModel(null);
    }
  },[checkedItemList])

  useEffect(()=>{
    reqGetChargerModelList();
  },[])

  const reqGetChargerModelList = async(page=pageNum, data)=>{
    setLoadingListOn(true);
    let result = await getChargerModelList(page, data);
    if(result.status === 200){
      let temp = [...result.data.Results];
      setChargerModelList(temp);
      setChargerModelCount(result.data.Total);
    }
    setLoadingListOn(false);
  }

  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(chargerModelCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetChargerModelList(pageNum,{"keyword": searchKeyword});
    }
  },[pageNum])


  const [loadingListOn, setLoadingListOn] = useState(false);

  const handleKeyPress = async(e) => {
    if (e.key === 'Enter') {
      reqSearchKeyword();
    }
  };
  const reqSearchKeyword = async()=>{
    setLoadingListOn(true);
      if(pageNum !== 1) setPageNum(1);
      else await reqGetChargerModelList(1,{"keyword": searchKeyword});
      setLoadingListOn(false);
  }

  const [selectedModelTabOpen, setSelectedModelTabOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [funcTabOpen, setFuncTabOpen] = useState(false);

  const modalRef = useRef(null);
  useEffect(() => {
    if (funcTabOpen&&modalRef.current) {
      modalRef.current.focus();
    }
  }, [funcTabOpen]);

  return (
    <div className='cpo-fast-enroll-container'>
      {chargerLimitAlertOn && <ChargerLimitAlert setOnOff={setChargerLimitAlertOn} total={chargerTotal} limit={chargerLimit}/>}
      {modalOpen&&<CpoChargerModelEnrollModal getChargerModelList={reqGetChargerModelList} setOnOff={setModalOpen} editData={chargerModelList[checkedItemList[0]]} setCheckedItemList={setCheckedItemList} editMode={false}/>}
        <div className='cpo-fast-enroll-grid-container'>
          <div className='cpo-fast-enroll-front' style={{
            background: `linear-gradient(133deg, ${process.env.PUBLIC_URL + process.env.REACT_APP_MAIN_COLOR} 30%, ${adjustColor(process.env.PUBLIC_URL + process.env.REACT_APP_MAIN_COLOR, 0.8, 1.1)} 100%)`,
            backgroundColor: process.env.PUBLIC_URL + process.env.REACT_APP_MAIN_COLOR}}>
            
            <img className='label-fast-enroll-logo' src={process.env.PUBLIC_URL+process.env.REACT_APP_WHITE_LOGO}/>
          </div>
          <div className='fast-enroll-back-wrapper'>
            {
              errMsg&&<div className={"cpo-fast-enroll-msg"}>{errMsg}</div>
            }
            {
              tabIdx == 0 ?
              <div className={'cpo-fast-enroll-button '+`${category==null?"none":""}`} onClick={()=>{
                setTabIdx(1);
                }}>
                <p>다음</p>
                <FaChevronRight/>
              </div>
              :
              tabIdx == 1 ?
              <div className={'cpo-fast-enroll-button '+`${category==null?"none":""} ${ChargerModel !==null ? "":"none"}`} onClick={()=>{
                if((chargerTotal&&chargerLimit)&&chargerTotal>=chargerLimit){
                  setChargerLimitAlertOn(true);
                }
                else{
                  setTabIdx(2);
                }
                }}>
                <p>다음</p>
                <FaChevronRight/>
              </div>
              :
              tabIdx === 2 ?
              
                enrollCheck ?

                <div className={'cpo-fast-enroll-button ' + `${buttonTab1 ? "none":""}`} onClick={()=>{
                  if(!inputValidation()){return;}
                  if(category == 1){ enroll();}
                  else{enroll2();}
                  setTabIdx(3);
                  }}>
                  {
                    !loadingOn ?
                    <>
                      <p>등록</p>
                      <FaChevronRight/>
                    </>
                    :
                    <Loading/>
                  }
                </div>
                :
                <div className={'cpo-fast-enroll-button ' + `${buttonTab1 ? "none":""}`} onClick={()=>{
                  if(!inputValidation()){return;}
                  setEnrollCheck(true);
                  }}>
                  <p>다음</p>
                  <FaChevronRight/>
                </div>
              :
              tabIdx === 3 ?
                !rateInputMode?

                <div className='cpo-fast-enroll-button' onClick={()=>{
                  setTabIdx(4);
                }}>
                  <p>건너뛰기</p>
                  <FaChevronRight/>
                </div>
                :
                <div className='cpo-fast-enroll-button' onClick={async()=>{
                  setLoadingOn(true);
                  await reqPatchDefaultPricingPlan("Member");
                  await reqPatchDefaultPricingPlan("NonMember");
                  setLoadingOn(false);
                  
                }}>
                  {
                  !loadingOn ?
                  <>
                    <p>설정</p>
                    <FaChevronRight/>
                  </>
                  :
                  <Loading/>
                  }
                </div>
              
              :
              <></>
            }
            {
              tabIdx == 0 ?
              <></>
              :
              tabIdx == 1 ?
                <div className='cpo-fast-enroll-button left' onClick={()=>{
                  setTabIdx(0);
                  }}>
                  <FaChevronLeft/>
                  <p>이전</p>
                </div>
              :
              tabIdx == 2 ?
                enrollCheck?
                <div className='cpo-fast-enroll-button left' onClick={()=>{
                  setEnrollCheck(false);
                  }}>
                  <FaChevronLeft/>
                  <p>이전</p>
                </div>
                :
                <div className='cpo-fast-enroll-button left' onClick={()=>{
                  setTabIdx(1);
                  setEnrollCheck(false);
                  }}>
                  <FaChevronLeft/>
                  <p>이전</p>
                </div>
              :
              tabIdx == 3 ?
              !rateInputMode ?
              <></>
              :
              <div className='cpo-fast-enroll-button left' onClick={()=>{
                setRateInputMode(false);
              }}>
                <FaChevronLeft/>
                <p>이전</p>
              </div>
              :
              <></>
            }
            {
              tabIdx !== 0 ?
              <div className='cpo-fast-enroll-step-container'>
                <div className={'cpo-fast-enroll-step '+`${tabIdx == 1 ?"current":""}`}>1</div>
                <p className={tabIdx == 1 ? 'current':''}>충전기 모델 선택</p>
                <FaChevronRight className={tabIdx == 1 ? 'current':''}/>
                
                <div className={'cpo-fast-enroll-step '+`${tabIdx == 2 ?"current":""}`}>2</div>
                <p className={tabIdx == 2 ? 'current':''}>충전소&충전기 등록</p>
                <FaChevronRight className={tabIdx == 2 ? 'current':''}/>

                <div className={'cpo-fast-enroll-step '+`${tabIdx == 3 ?"current":""}`}>3</div>
                <p className={tabIdx == 3 ? 'current':''}>요금 설정</p>
                <FaChevronRight className={tabIdx == 3 ? 'current':''}/>
                
                <div className={'cpo-fast-enroll-step '+`${tabIdx == 4 ?"current":""}`}>4</div>
                <p className={tabIdx == 4 ? 'current':''}>빠른 등록 완료!</p>   
              </div>
              :<></>
            }
            {
            tabIdx == 0 ?
            <div className='cpo-fast-enroll-back initial'>
              {/* <div className='cpo-fast-enroll-sub-title'>구독 정보</div>
              <div>
                {
                  subscriptionInfo && subscriptionInfo.length !== 0 ? 
                    subscriptionInfo?.map((it,idx)=>{
                      return(
                        <>
                          <p className='cpo-fast-enroll-subscription-info'>{it.subscriptionProduct?.name}</p>
                        </>
                      )})

                  :
                  <p className='cpo-fast-enroll-subscription-info empty'>구독된 상품이 없습니다.</p>
                }
              </div> */}
              <div className='cpo-fast-enroll-sub-title'>충전기 등록 현황</div>
              <div className='cpo-fast-enroll-charger-limit-info-container'>
                <p className={`${parseInt(chargerTotal) === parseInt(chargerLimit) ? "limited":""}`}>{chargerTotal?chargerTotal:0}</p><p>대</p>
              </div>
              <div className='cpo-fast-enroll-back-initial-title'>
                <p>원하시는</p><p>유형을 선택</p><p>해주세요</p>  
              </div>
              <div className={'cpo-fast-enroll-category-button '+`${category == 0 ? "selected":""}`} onClick={()=>{setCategory(0)}}>
                <div className='cpo-fast-enroll-category-select-outer'>
                  <div className='cpo-fast-enroll-category-select-inner'></div>
                </div>
                <div className='cpo-fast-enroll-category-content-container'>
                  <p>기존 등록</p>
                  <p>이미 충전소와 충전기가 있어요</p>
                </div>
              </div>
              <div className={'cpo-fast-enroll-category-button '+`${category == 1 ? "selected":""}`} onClick={()=>{setCategory(1)}}>
                <div className='cpo-fast-enroll-category-select-outer'>
                  <div className='cpo-fast-enroll-category-select-inner'></div>
                </div>
                <div className='cpo-fast-enroll-category-content-container'>
                  <p>신규 등록</p>
                  <p>새로운 충전소와 충전기를 등록하고 싶어요</p>
                </div>
              </div>
            </div>
            :
            tabIdx == 1 ?
            <>
            <div className='cpo-fast-enroll-input-list-container'>
              <div className='cpo-fast-enroll-charger-model-header'>
                <div className="cpo-station-management-search-input-container">
                  <input type="text" placeholder={'모델명으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}} onKeyDown={handleKeyPress}/>
                  <FaSearch onClick={()=>{reqSearchKeyword()}}/>
                </div>
                <div className='cpo-fast-enroll-charger-model-function-handle' onMouseDown={(e) => e.preventDefault()} onClick={() => setFuncTabOpen(!funcTabOpen)}><HiOutlineDotsVertical/></div>
                <div className={`cpo-fast-enroll-charger-model-function-container ${funcTabOpen ? "visible":""}`} tabIndex={-1} autoFocus ref={modalRef} onBlur={(e)=>{e.stopPropagation(); setFuncTabOpen(false)}}> 
                  <div className='cpo-fast-enroll-charger-model-function-item' onClick={()=>{setModalOpen(true);setFuncTabOpen(false)}}>충전기모델 등록</div>
                  <div className='cpo-fast-enroll-charger-model-function-item' onClick={()=>{navigator("/cpo/charger-model-management")}}>충전기모델관리<GoArrowUpRight/></div>
                </div>
              </div>
              {
                ChargerModel&&
                <>
                  <p>선택된 충전기모델</p>
                  <div className='cpo-fast-enroll-selected-charger-model-container'>
                    <FastEnrollChargerModelListItem isSelectedItem={true} setSelectedModelTabOpen={setSelectedModelTabOpen} data={ChargerModel} checkMode={false} checkList={checkedItemList} setCheckList={setCheckedItemList} clickedModel={ChargerModel} settingMode={false} />
                  </div>
                </>
              }
              <div className="cpo-fast-enroll-charger-model-list-container">
                <div className="cpo-fast-enroll-charger-model-list-column">
                  <p></p>
                  <p>제조사</p>
                  <p>모델명</p>
                  <p>종류</p>
                  <p>동시충전지원</p>
                  <p></p>
                </div>
                <div className={`cpo-fast-enroll-charger-model-list-item-container ${selectedModelTabOpen ? "selected":""}`}>
                  {
                    chargerModelList && chargerModelList.map((it, idx)=>{
                      return(
                        <>
                          {/* <div className="cpo-fast-enroll-charger-model-list-item">
                          <div><p>{it?.Manufacturer?.Company?.Name}</p></div>
                            <div><p>{it?.Name}</p></div>
                            <div><p>{chargerTypeConverter(it?.ChargerType)}</p></div>
                            <div>{it?.Connectors?.length > 1 ? "지원":"미지원"}</div>
                          </div> */}
                          <FastEnrollChargerModelListItem  data={it} idx={idx} checkMode={true} checkList={checkedItemList} setCheckList={setCheckedItemList} settingMode={"edit"}/>
                        </>
                      );
                    })
                  }
                </div>
                <div className='page-selector-container'>
                  {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
                  {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
                  {pageSelectorRendering()}
                  {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
                  {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
                  <div className='page-total-info'>{`Total : ${chargerModelCount}`}</div>
                </div>
              </div>
            </div>
            </>
            :
            tabIdx == 2 ?

            enrollCheck === false ?
              <div className='cpo-fast-enroll-input-list-container'>  
                <p>충전소 명</p>
                {
                  category == 1 ?
                  <div className="fast-enroll-input-container name">
                    <input ref={stationNameInputRef} type="text" placeholder='충전소 명을 입력해주세요' value={nameContent} onChange={(e)=>{ setNameContent(e.target.value); setNameContentDuplication(null);}} onClick={(e)=>{e.stopPropagation(); }}/>
                    <div className={`fast-enroll-station-name-validation ` + `${nameContent==""||!nameContent?"disable":""}`} onClick={()=>{stationNameDuplicationCheck()}}><p>중복확인</p></div>
                    {
                      nameContentDuplication == "disable" ? 
                        <p className={'fast-enroll-input-validation-msg alert'}>중복된 충전소명 입니다.</p>
                      :
                        nameContentDuplication ? 
                          <p className={'fast-enroll-input-validation-msg able'}>사용가능한 충전소명 입니다.</p>
                        :
                          nameContent == "" || nameContent == null ?
                            nameContentValidation == "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>충전소명을 입력해주세요.</p>:<></>
                          :
                          <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
                    }
                  </div>
                  :
                  <div className="fast-enroll-input-container">
                    <Selector2 name={'ChargingStationName'} dataList={stationInfo} select={stationSelect} setSelect={setStationSelect} active={stationActive} setActive={setStationActive} activeReset={activeReset}/>
                    {
                    stationValidation == "invalid" ?
                    <p className={'fast-enroll-input-validation-msg alert'}>충전소를 선택해주세요.</p>
                    :
                    <></>
                  }
                  </div>
                }
                <p>충전기 식별번호</p>
                <div className="fast-enroll-input-container">
                  <input type="text" placeholder='충전기 식별번호를 입력해주세요' value={chargerDisplaySerialNumber} onChange={(e)=>{ setChargerDisplaySerialNumber(e.target.value); setChargerDisplaySerialNumberDuplication(null);}} onClick={(e)=>{e.stopPropagation(); }}/>
                  <div className={`fast-enroll-station-name-validation ` + `${chargerDisplaySerialNumber===""||!chargerDisplaySerialNumber?"disable":""}`} onClick={()=>{reqGetChargerSerialNumberDuplicationCheck()}}><p>중복확인</p></div>
                  {
                    chargerDisplaySerialNumberDuplication === "disable" ? 
                      <p className={'fast-enroll-input-validation-msg alert'}>중복된 식별번호 입니다.</p>
                    :
                    chargerDisplaySerialNumberDuplication ? 
                        <p className={'fast-enroll-input-validation-msg able'}>사용가능한 식별번호 입니다.</p>
                      :
                        chargerDisplaySerialNumber === "" || chargerDisplaySerialNumber == null ?
                        chargerDisplaySerialNumberValidation === "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>식별번호를 입력해주세요.</p>:<></>
                        :
                        <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
                  }
                </div>
                <p>충전기 하드웨어 일련번호</p> 
                <div className="fast-enroll-input-container">
                  <input ref={chargerIdInputRef} type="text" placeholder='충전기 하드웨어 일련번호를 입력해주세요' value={chargerIdContent} onChange={(e)=>{ setChargerIdContent(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  {
                    chargerIdContentValidation == "invalid" ?
                    <p className={'fast-enroll-input-validation-msg alert'}>충전기 하드웨어 일련번호를 입력해주세요.</p>
                    :
                    <></>
                  }
                </div>
                <p>OCPP</p>
                <div className="fast-enroll-input-container occp">
                  <div className={'fast-enroll-selector '+occpActive} onClick={(e)=>{e.stopPropagation(); 
                    if(occpSelect == ''){setOccpSelect("none")}else{setOccpSelect("")} 
                    if(occpActive == ''){setOccpActive("active")}else{setOccpActive("")} 
                  }}>
                    <p className={chargerOCCPContent==null ?'none':""}>{chargerOCCPContent==null ? "프로토콜 선택하기" : chargerOCCPContent}</p>
                  </div>
                  <FaCaretDown className='cpo-occp-down-icon' onClick={(e)=>{e.stopPropagation(); 
                    if(occpSelect == ''){setOccpSelect("none")}else{setOccpSelect("")} 
                    if(occpActive == ''){setOccpActive("active")}else{setOccpActive("")} 
                  }}/>
                  <div className={'fast-enroll-selector-item-list ' + occpSelect}>
                    <div className={'fast-enroll-selector-item ' + occpSelect} onClick={()=>{setChargerOCCPContent("OCPP 1.6"); setOccpSelect('none');}}>
                      <p>OCPP 1.6</p>
                      {
                        chargerOCCPContent == "OCPP 1.6"?
                        <FaCheck className='cpo-occp-check-icon'/>
                        :null
                      }
                    </div>
                    {/* <div className={'fast-enroll-selector-item ' + occpSelect} onClick={()=>{setChargerOCCPContent("OCPP 2.0.1"); setOccpSelect('none');}}>
                      <p>OCPP 2.0.1</p>
                      {
                        chargerOCCPContent == "OCPP 2.0.1"?
                        <FaCheck className='cpo-occp-check-icon'/>
                        :null
                      }
                    </div> */}
                  </div>
                  {
                    chargerOCCPContentValidation == "invalid" ? 
                    <p className={'fast-enroll-input-validation-msg alert'}>OCPP 프로토콜을 선택해주세요.</p>
                    :
                    <></>
                  }
                </div>
              </div>
              :
              <>
              <div className="cpo-fast-enroll-input-list-container">
                <p className='cpo-fast-enroll-input-check-msg'><HiSpeakerphone/>등록할 충전소 및 충전기 정보를 확인 후 등록 버튼을 눌러주세요.</p>
                <p className='cpo-fast-enroll-input-check-title'>충전소 정보</p>
                <div className='cpo-fast-enroll-input-check-container station'>
                  <div>
                    <div>충전소 명</div>
                    <div>
                    {
                      category == 1?
                      nameContent
                      :
                      stationSelect.ChargingStationName
                    }
                    </div>
                  </div>
                </div>
                
                <p className='cpo-fast-enroll-input-check-title'>충전기 정보</p>
                <div className='cpo-fast-enroll-input-check-container'>
                  <div>
                    <div>충전기 식별번호</div>
                    <div>{chargerDisplaySerialNumber}</div>
                  </div>
                  <div>
                    <div>충전기 하드웨어 일련번호</div>
                    <div>{chargerIdContent}</div>
                  </div>
                  <div>
                    <div>OCPP</div>
                    <div>{chargerOCCPContent}</div>
                  </div>
                  <div>
                    <div>충전기 모델명</div>
                    <div>{ChargerModel.Name}</div>
                  </div>
                </div>
              </div>
              </>


            :
            tabIdx == 3 ?
            <div className='cpo-fast-enroll-input-list-container'>
              <p className='cpo-fast-enroll-input-check-msg'><HiSpeakerphone/>{!rateInputMode ?"첫 충전소와 충전기를 등록할 경우 요금 설정이 필요합니다!":"회원과 비회원의 요금을 설정하세요"}</p>
              {
                !rateInputMode ?
                <>
                <div className={'cpo-fast-enroll-category-button '} onClick={()=>{setRateInputMode(true)}}>
                  <div className='cpo-fast-enroll-category-select-outer'>
                    <div className='cpo-fast-enroll-category-select-inner'></div>
                  </div>
                  <div className='cpo-fast-enroll-category-content-container'>
                    <p>요금 설정하기</p>
                    <p>회원, 비회원 별 요금을 설정해요</p>
                  </div>
                </div>
                </>
                :
                <form onSubmit={(e)=>{}}>
                  <div>
                    <p className='fast-enroll-input-title rate'>완속 요금 <color>(원 kWh, 부가세 포함)</color></p>
                    <div className="fast-enroll-input-flex-wrapper">
                      <div>
                        <p className="cpo-fast-enroll-sub-title">회원</p>
                        <div className="fast-enroll-input-container">
                          <input type="text" placeholder='요금을 설정해주세요' value={memberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setMemberRate(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                        </div>
                      </div>
                      <div>
                        <p className='cpo-fast-enroll-sub-title'>비회원</p>
                        <div className="fast-enroll-input-container">
                          <input type="text" placeholder='요금을 설정해주세요' value={nonMemberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setNonMemberRate(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                        </div>
                      </div>
                    </div>
                    <p className='fast-enroll-input-title rate'>급속 요금 <color>(원 kWh, 부가세 포함)</color></p>
                    <div className="fast-enroll-input-flex-wrapper">
                      <div>
                        <p className="cpo-fast-enroll-sub-title">회원</p>
                        <div className="fast-enroll-input-container">
                          <input type="text" placeholder='요금을 설정해주세요' value={memberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setMemberRate(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                        </div>
                      </div>
                      <div>
                        <p className='cpo-fast-enroll-sub-title'>비회원</p>
                        <div className="fast-enroll-input-container">
                          <input type="text" placeholder='요금을 설정해주세요' value={nonMemberRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{ setNonMemberRate(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              }
            </div>
            :
            <div className='cpo-fast-enroll-done-container'>
              <p>등록이 완료되었어요</p>
              <div onClick={()=>{navigator('/cpo')}}><p>대시보드로 이동</p></div>
            </div>
          }
          </div>
        </div>
        
    </div>
  )
}
export default LabelFastEnroll;