import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {setNum} from '../../store.js'



import { getActionSetProfileListCpoUser, } from '../../api/controlhub/controlhubApi.js';

import CpoActionSetProfileListItem from '../../components/list/CpoActionSetProfileListItem.js';
import ChasPreviewModal from '../../components/modals/ChasPreviewModal.js';

export const CpoSystemChas = () => {
  const navigator = useNavigate();


  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:12, idx:1}));
  },[])  




  // page 인덱스
  const [pageNum, setPageNum] = useState(1);
  // page 사이즈
  const [pageSize, setPageSize] = useState(10);
  // 검색 필터
  const [minCreatedAt, setMinCreatedAt] = useState(null);
  const [maxCreatedAt, setMaxCreatedAt] = useState(null);
  const [minUpdatedAt, setMinUpdatedAt] = useState(null);
  const [maxUpdatedAt, setMaxUpdatedAt] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [ocppVersion, setOcppVersion] = useState(null);
  const [keyword, setKeyword] = useState(null);


  // actionSet Profile 리스트
  const [actionSetProfileList, setActionSetProfileList] = useState([]);
  const [actionSetProfileCount, setActionSetProfileCount] = useState(0);

  // actionSet Profile 조회
  const reqGetActionSetProfileListCpoUser = async()=>{
    let data = {
      page : pageNum,
      size : pageSize,
      minCreatedAt : minCreatedAt,
      maxCreatedAt : maxCreatedAt,
      minUpdatedAt : minUpdatedAt,
      maxUpdatedAt : maxUpdatedAt,
      sortField : sortField,
      sortOrder : sortOrder,
      ocppVersion : ocppVersion,
      keyword : keyword};
    let result = await getActionSetProfileListCpoUser(data);
    
    if(result.status === 200){
      setActionSetProfileList(result.data.results);
      setActionSetProfileCount(result.data.total);
    }
    else{

    }
  }

  useEffect(()=>{
    reqGetActionSetProfileListCpoUser();
  },[])

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProfileIdx, setSelectedProfileIdx] = useState(null);
  const [selectedVersionIdx, setSelectedVersionIdx] = useState(null);
  return (
    <div className="cpo-system-chas-container">
      {
        modalOpen && <ChasPreviewModal profileData={actionSetProfileList[selectedProfileIdx]} versionData={actionSetProfileList[selectedProfileIdx]?.versions[selectedVersionIdx]} setOnOff={setModalOpen}/>
      }
      <div className="cpo-system-chas-inner">
        <div className="cpo-system-chas-content-container">
          <div className="cpo-system-chas-content-item-list-container">
            <div className="cpo-system-chas-content-item-column">
              <div>CHAS명</div>
              <div>플랫폼</div>
              <div>OCPP</div>
              <div>설명</div>
            </div>

            {
              actionSetProfileList&&actionSetProfileList.map((it, idx)=>{
                return(
                  <CpoActionSetProfileListItem data={it} idx={idx} setSelectedProfileIdx={setSelectedProfileIdx} setSelectedVersionIdx={setSelectedVersionIdx} setModalOpen={setModalOpen} />
                );
              })
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoSystemChas;