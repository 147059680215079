import axios from 'axios';
import { getCookie, signedOut, refresh } from '../../util/util';

export const getSendMagicLinkEmail = async(email)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/account/api/NearCpoAuth/send-magic-link-email?email=${email}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}

export const getSendEmailList = async()=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/account/api/cpoadmin/CpoInvitations`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}

export const postSendEmail = async(email)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.post(`/account/api/cpoadmin/CpoInvitations`,{
    "invitedEmail": email
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}

export const postRemoveAccount = async(email)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.post(`/account/api/NearCpoAuth/remove-account`,{
    email
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}

export const postSignOut = async()=>{
  const signedToken =  getCookie('signedToken');
  let temp;
  await axios.post(`/account/api/NearCpoAuth/signout`,{},{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{temp=res})
  .catch((err)=>{temp=err.response})
  return temp;
}

export const deleteAccount = async(email)=>{
  const signedToken =  getCookie('signedToken');
  let temp;
  await axios.delete(`/account/api/cpouser/Account`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then(async(res)=>{
    temp = res;
    await signedOut();
  })
  .catch((err)=>{
    console.log(err);
    temp = err.response;
  })
  return temp;
}


// CPO관리자 회원가입
export const postSignUpCpoAdmin = async(data)=>{
  let temp;
  let {email, password, businessRegistrationNumber, businessName, managerPhoneNumber, managerName} = data;
  await axios.post("/account/api/NearCpoAuth/CpoSignUp", 
  {
    "email": email,
    "password": password,
    "businessRegistrationNumber": businessRegistrationNumber,
    "businessName": businessName,
    "managerPhoneNumber": managerPhoneNumber,
    "managerName": managerName 
  },{withCredentials : true})
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

export const postSignUpCpoUser = async(data)=>{
  let temp;
  let {email, password, realName} = data;
  await axios.post("/account/api/NearCpoAuth/personal-signup", 
  {
    "email": email,
    "password": password,
    "realName": realName 
  },{withCredentials : true})
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 하위 CPO관리자 - CPO회원 가입(Labeling)
export const postSignUpCpoMember = async(data)=>{
  let temp;
  let {email, password, businessRegistrationNumber, businessName, managerPhoneNumber, managerName, superCpoId} = data;
  await axios.post("/account/api/NearCpoAuth/SubCpoSignUp", 
  {
    "email": email,
    "password": password,
    "businessRegistrationNumber": businessRegistrationNumber,
    "businessName": businessName,
    "managerPhoneNumber": managerPhoneNumber,
    "managerName": managerName,
    "superCpoId": superCpoId
  },{withCredentials : true})
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}


// CPO관리자 - CPO회원 조회
export const getCpoMembers = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/account/api/cpoadmin/CpoMembers`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// CPO관리자 - CPO회원 삭제
export const deleteCpoMember = async(email)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/account/api/cpoadmin/CpoMembers/${email}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 비밀번호찾기
export const postForgetPassword = async(data)=>{
  let temp;
  let {email} = data;
  await axios.post("/account/api/NearCpoAuth/forget-password", 
  {
    "value": email
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 비밀번호리셋
export const postResetPassword = async(data)=>{
  let temp;
  let {email, password, token} = data;
  await axios.post("/account/api/NearCpoAuth/reset-password", 
  {
    "email":email,
    "password": password,
    "token": token
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 회원 조회
// /account/api/cpouser/EvUsers
export const getEvUsers = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/account/api/cpouser/EvUsers`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 모든 EvUser 사용자의 수를 반환
export const getEvUserCount = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {rootCpoSysId} = data;
  await axios.get(`/account/api/cpouser/EvUsers/total-evuser-count?rootCpoSysId=${rootCpoSysId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// EvUser 사용자의 월별 가입 통계
export const getEvUserMonthlyCount = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {rootCpoSysId, startDate, endDate} = data;
  await axios.get(`/account/api/cpouser/EvUsers/monthly-evuser-signup-count?rootCpoSysId=${rootCpoSysId}&startDate=${startDate}&endDate=${endDate}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 하위 CPO 정보 조회
export const getSubCpoList = async(rootCpoSysId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/account/api/cpouser/SubCpos?rootCpoSysId=${rootCpoSysId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}