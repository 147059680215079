import React,{useEffect, useState} from 'react'
import { AsyncPaginate } from "react-select-async-paginate";
import { getStationListForSelect } from '../../api/infra/infraApi';

export const AsyncSearchSelectorNotCreatable = (props) => {
  const [value, setValue] = useState(null);
  useEffect(()=>{
    if(props.defaultValue){
      getDefault();
    }
  },[props.defaultValue])

  const onMenuOpen = () => {
    setTimeout(()=>{
      const selectedEl = document.getElementsByClassName("react-select__option--is-selected")[0];
      if(selectedEl){
        selectedEl.scrollIntoView({behavior:'instant', block:'nearest', inline: 'start'});
      }
    },15);
  };
  
  const getDefault = async()=>{
    let data = await getStationListForSelect(props.defaultValue, 1);
    let result= await data.json();
    if(result.Total !== 0){
      let temp = null;
      result.Results.map((it)=>{
        temp = {id:it.ChargingStationId, label:it.ChargingStationName, value:it.ChargingStationName};
      });
      setValue(temp);
      props.setValue(temp);
    }
    else{
      setValue(null);
    }
  }

  const loadOptions = async (search, prevOptions, { page }) => {
    const response = await getStationListForSelect(search, page);
    const responseJSON = await response.json();
    let temp = [];
    let idx = 0;
    for await(const it of responseJSON.Results){
      temp.push({id:it.ChargingStationId, label:it.ChargingStationName, value:it.ChargingStationName, idx:(page-1)*10+idx});
    }
    return {
      options: temp,
      hasMore: responseJSON.Total > page * 10,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
    {
      <AsyncPaginate options={props.options}
      {...props}
      className="react-select-container"
      classNamePrefix="react-select"
      value={value}
      onChange={(newValue) => {props.setValue(newValue); setValue(newValue);}}
      loadOptions={loadOptions}
      additional={{
        page: 1,
      }}
      onMenuOpen={onMenuOpen}
      />
    }
    </>
  )
}
export default AsyncSearchSelectorNotCreatable;