import React, { useState, useEffect } from 'react';
import {occpConverter, chargerTypeConverter, connectorTypeConverter} from "../../util/util.js";
import { TbDotsVertical } from "react-icons/tb";

import RoamingChargerModal from '../modals/RoamingChargerModal.js';

import {getRoamingChargerByEmoovId} from '../../api/roaming/roamingApi.js';
import { useSelector } from 'react-redux';
export const ChargerItem = (props) => {
  const user = useSelector(state => state.user);
  // checked 상태
  const [checked, setChecked] = useState(false);

  const clickEvent = ()=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    if(props.clickedCharger !== props.idx){
      props.setClickedCharger(props.idx)
    }
    else{
      props.setClickedCharger(-1)
    } 
  }

  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkChargerList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      props.setCheckChargerList([...props.checkChargerList, props.idx]);
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx == -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkChargerList];
        temp.splice(itemIdx,1);
        props.setCheckChargerList(temp);
      }
    }
  },[checked])
  

  useEffect(()=>{
    let itemIdx = props.checkChargerList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx == -1 && checked){
      setChecked(false);
    }
  },[props.checkChargerList])

  const[btnOpen, setBtnOpen] = useState(false);



  const [roamingCharger1, setRoamingCharger1] = useState(null);
  const [roamingCharger2, setRoamingCharger2] = useState(null);


  const clickRoaming = ()=>{
    setRoamingModalOpen(true);
  }


  const reqGetRoamingChargerByEmoovId = async()=>{
    let res = await getRoamingChargerByEmoovId(props.data?.ChargerId, 1);
    if(res.status === 200){
      setRoamingCharger1(res.data);
    }
    
    if(props.data.ChargerModel?.Connectors && props.data.ChargerModel?.Connectors[1]){
      let res = await getRoamingChargerByEmoovId(props.data?.ChargerId, 2);
      if(res.status === 200){
        setRoamingCharger2(res.data);
      }
      else{
        setRoamingCharger2(null);
      }
    }
    else{
      setRoamingCharger2("none");
    }
  }

  useEffect(()=>{
    reqGetRoamingChargerByEmoovId();
  },[])

  const [roamingModalOpen, setRoamingModalOpen] = useState(false);

  return (
    <>
      {roamingModalOpen &&<RoamingChargerModal reqGetRoamingChargerByEmoovId={reqGetRoamingChargerByEmoovId} setOnOff={setRoamingModalOpen} controlHubData={props.controlHubData} roamingChargerId={props.data?.ChargerId} roamingStationId={props.data?.ChargingStation?.ChargingStationId} roamingCharger1={roamingCharger1} roamingCharger2={roamingCharger2}/>}
      <div className={"cpo-charger-management-list-content-item " +`${props.clickedCharger === props.idx ? "clicked":""}`}
      ref={(el)=>{props.chargerRef.current[props.idx]=el}}
      onClick={()=>{clickEvent()}}
      >
        <div>
          <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
            <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
            <span className="checkbox_icon"></span>
          </label>
        </div>
        <div><p>{props.data.DisplaySerialNumber}</p></div>
        <div><p>{props.data.ChargerModel?.Name ? props.data.ChargerModel?.Name : "-"}</p></div>
        <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.ChargerModel?.ChargerType ? chargerTypeConverter(props.data.ChargerModel?.ChargerType) : "-"}</p></div>
        <div>
          {
            props.data?.ChargerModel && props.data.ChargerModel?.Connectors && props.data.ChargerModel?.Connectors[0]&&props.data.ChargerModel?.Connectors[0]?.ConnectorTypes?.map((it)=>{
              if(!it)return;
              return(
                <div className="cpo-charger-management-connector-badge">
                  <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
                </div>
              )
            })
          }
        </div>
        <div>
          <div className={`cpo-charger-roaming-check-badge ${roamingCharger1 ? "":"none"}`}>
            <p>{roamingCharger1 ? "등록":"미등록"}</p>
          </div>
        </div>
        <div className='centerAlign'>
          {
            roamingCharger1 &&
            <p>{roamingCharger1?.moEChargerId}</p>
          }
        </div>
        <div>
          {
            props.data?.ChargerModel && props.data.ChargerModel?.Connectors && props.data.ChargerModel?.Connectors[1]&&props.data.ChargerModel?.Connectors[1]?.ConnectorTypes?.map((it)=>{
              if(!it)return;
              return(
                <div className="cpo-charger-management-connector-badge">
                  <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
                </div>
              )
            })
          }
        </div>
        <div>
          {
            roamingCharger2 !== "none"&&
            <div className={`cpo-charger-roaming-check-badge ${roamingCharger2 ? "":"none"}`}>
              <p>{roamingCharger2 ? "등록":"미등록"}</p>
            </div>
          }
        </div>
        <div className='centerAlign'>
          {
            roamingCharger2 !== "none"&&
            <p>{roamingCharger2?.moEChargerId}</p>
          }
        </div>
        <div>
          {
            props.controlhubLoading ?
            <div className={'cpo-charger-management-status-item loading'}></div>
            :
            <div className={'cpo-charger-management-status-item ' +`${props.controlHubData?.used ? "":"none"}`}>
              <p>{props.controlHubData?.used ? 'YES' : "NO"}</p>
            </div>
          }
        </div>
        {
          user.cpoId === process.env.REACT_APP_SUPER_ID &&
        <div className='handle'>
          <div className="cpo-station-management-list-content-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
            <div className={"cpo-station-management-list-content-item-function-button-list " + `${btnOpen ? "open":""}`}>
              {
                !props.roamingStationInfo && <div>
                <div className="cpo-station-management-list-content-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(false); clickRoaming();}}>
                  <div className='roaming'>로밍 등록</div>
                </div>
              </div>}
            </div>
            <TbDotsVertical/>
          </div>
        </div>
        }
      </div>
    </>
  )
}
export default ChargerItem;