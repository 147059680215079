import { useState, useRef, useEffect} from 'react';
import { Loading } from '../alerts/Loading.js';
import SearchSelectorNotCreatable from '../buttons/SearchSelectorNotCreatable';
import * as octtApi from "../../api/controlhub/octtApi.js";

import { useChargingProfile } from '../../hook/useChargingProfile.js';
import { numberInput } from '../../util/util.js';
import { TfiClose } from "react-icons/tfi";


const ChargerCardEventTab6 = (props) => {
  const messageList = [
    {value: "reset", label: "reset", idx: 1},
    {value: "getConfiguration", label: "getConfiguration", idx: 2},
    {value: "changeConfiguration", label: "changeConfiguration", idx: 3},
    {value: "updatePassword", label: "updatePassword", idx: 4},
    {value: "clearCache", label: "clearCache", idx: 5},
    {value: "getLocalListVersion", label: "getLocalListVersion", idx: 6},
    {value: "sendLocalList", label: "sendLocalList", idx: 7},
    {value: "requestStartTransaction", label: "requestStartTransaction", idx: 8},
    {value: "requestStopTransaction", label: "requestStopTransaction", idx: 9},
    {value: "unlockConnector", label: "unlockConnector", idx: 10},
    {value: "triggerMessage", label: "triggerMessage", idx: 11},
    {value: "extendedTriggerMessage", label: "extendedTriggerMessage", idx: 12},
    {value: "changeAvailability", label: "changeAvailability", idx: 13},
    {value: "setChargingProfile", label: "setChargingProfile", idx: 14},
    {value: "setChargingProfiles", label: "setChargingProfiles", idx: 15},
    {value: "clearChargingProfile", label: "clearChargingProfile", idx: 16},
    {value: "getCompositeSchedule", label: "getCompositeSchedule", idx: 17},
    {value: "reserveNow", label: "reserveNow", idx: 18},
    {value: "cancelReservation", label: "cancelReservation", idx: 19},
    {value: "updateFirmware", label: "updateFirmware", idx: 20},
    {value: "signedUpdateFirmware", label: "signedUpdateFirmware", idx: 21},
    {value: "getInstalledCertificateIds", label: "getInstalledCertificateIds", idx: 22},
    {value: "installCertificate", label: "installCertificate", idx: 23},
    {value: "deleteCertificate", label: "deleteCertificate", idx: 24},
    {value: "deleteCertificateByManual", label: "deleteCertificateByManual", idx: 25},
    {value: "getDiagnostics", label: "getDiagnostics", idx: 26},
    {value: "getLog", label: "getLog", idx: 27},
  ];
  const [dataLabel, setDataLabel] = useState();


  const getMsgBlock = (msg)=>{
    switch (msg) {
      case "reset":
        return <ResetMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "getConfiguration":
        return <GetConfigurationMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "changeConfiguration":
        return <ChangeConfigurationMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "updatePassword":
        return <UpdatePasswordMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "clearCache":
        return <ClearCacheMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "getLocalListVersion":
        return <GetLocalListVersionMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "sendLocalList":
        return <SendLocalListMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "requestStartTransaction":
        return <RequestStartTransactionMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "requestStopTransaction":
        return <RequestStopTransactionMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "unlockConnector":
        return <UnlockConnectorMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "triggerMessage":
        return <TriggerMessageMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "extendedTriggerMessage":
        return <ExtendedTriggerMessageMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "changeAvailability":
        return <ChangeAvailabilityMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "setChargingProfile":
        return <SetChargingProfileMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "setChargingProfiles":
        return <SetChargingProfilesMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "clearChargingProfile":
        return <ClearChargingProfileMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "getCompositeSchedule":
        return <GetCompositeScheduleMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "reserveNow":
        return <ReserveNowMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "cancelReservation":
        return <CancelReservationMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "updateFirmware":
        return <UpdateFirmwareMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "signedUpdateFirmware":
        return <SignedUpdateFirmwareMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "getInstalledCertificateIds":
        return <GetInstalledCertificateIdsMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "installCertificate":
        return <InstallCertificateMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "deleteCertificate":
        return <DeleteCertificateMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "deleteCertificateByManual":
        return <DeleteCertificateByManualMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "getDiagnostics":
        return <GetDiagnosticsMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      case "getLog":
        return <GetLogMsgBlock chargingStationIdentity={props.data.DisplaySerialNumber}/>;
      default:
        break;
    }
  }

  return (
    <div className='chargerCardEventTab6 ocpp16-auth-container'>
      <p className='ocpp16-auth-title'>전송할 메세지</p>
      <div className='ocpp16-auth-search-container'>
        <SearchSelectorNotCreatable options={messageList} placeholder="메세지를 선택하세요"  value={dataLabel} setValue={setDataLabel}/>
      </div>
      <div className='ocpp16-auth-content-container'>
        {dataLabel ? 
        <>
          <div className='ocpp16-auth-message-block'>
            <div className='ocpp16-auth-message-block-header'>
              <p>{dataLabel.value} 메세지 전송</p>
              <div></div>
            </div>
            {getMsgBlock(dataLabel.value)}
          </div>
        </>
        :
        <>
          <p className='ocpp16-auth-message-select-alert-msg'>전송할 메세지를 선택해주세요.</p>
        </>}
      </div>
    </div>
  )
}
export default ChargerCardEventTab6;








// Message Block Components

const ResetMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, type: dataLabel?.value?dataLabel?.value:null};
    let result = await octtApi.postOcpp16reset(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }

  const [dataLabel, setDataLabel] = useState();
  const typeList = [
    {value: "Hard", label: "Hard", idx: 1},
    {value: "Soft", label: "Soft", idx: 2},
  ]
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>type</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={typeList} placeholder="type을 선택하세요"  value={dataLabel} setValue={setDataLabel}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const GetConfigurationMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, key: key};
    let result = await octtApi.postOcpp16getConfiguration(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }

  const [key, setKey] = useState("");

  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>key</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='key를 입력해주세요' value={key} onChange={(e)=>{setKey(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const ChangeConfigurationMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, key: key, value: value};
    let result = await octtApi.postOcpp16changeConfiguration(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }

  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>key</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='key를 입력해주세요' value={key} onChange={(e)=>{setKey(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>value</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='value를 입력해주세요' value={value} onChange={(e)=>{setValue(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const UpdatePasswordMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity};
    let result = await octtApi.postOcpp16updatePassword(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const ClearCacheMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity};
    let result = await octtApi.postOcpp16clearCache(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const GetLocalListVersionMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity};
    let result = await octtApi.postOcpp16getLocalListVersion(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const SendLocalListMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, updateType: updateTypeLabel?.value?updateTypeLabel?.value:null, versionNumber: versionNumber, idTags: idTags.replaceAll(" ","").split(",")};
    let result = await octtApi.postOcpp16sendLocalList(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [updateTypeLabel, setUpdatgeTypeLabel] = useState();
  const updateTypeList = [
    {value: "Differential", label: "Differential", idx: 1},
    {value: "Full", label: "Full", idx: 2},
  ]
  const [versionNumber, setVersionNumber] = useState("");
  const [idTags, setIdTags] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>updateType</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={updateTypeList} placeholder="업데이트 타입을 선택하세요"  value={updateTypeLabel} setValue={setUpdatgeTypeLabel}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>versionNumber</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='versionNumber를 입력해주세요' value={versionNumber} onChange={(e)=>{setVersionNumber(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>idTags</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='idTags를 입력해주세요 ( , 로 구분)' value={idTags} onChange={(e)=>{setIdTags(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const RequestStartTransactionMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    // let tChargingProfile = {chargingStationIdentity: props.chargingStationIdentity,
    //   chargingProfileId: chargingProfile.chargingProfileId ? parseInt(chargingProfile.chargingProfileId):null,
    //   transactionId: chargingProfile.transactionId ? parseInt(chargingProfile.transactionId):null,
    //   stackLevel: chargingProfile.stackLevel ? parseInt(chargingProfile.stackLevel):null,
    //   chargingProfilePurpose: chargingProfile?.chargingProfilePurpose?.value ? chargingProfile?.chargingProfilePurpose?.value: null,
    //   chargingProfileKind: chargingProfile?.chargingProfileKind?.value ? chargingProfile?.chargingProfileKind?.value : null,
    //   recurrencyKind: chargingProfile?.recurrencyKind?.value ? chargingProfile?.recurrencyKind?.value:null,
    //   validFrom: chargingProfile.validFrom ? new Date(chargingProfile.validFrom).toISOString(): null,
    //   validTo: chargingProfile.validTo ? new Date(chargingProfile.validTo).toISOString(): null,
    //   chargingSchedule: {
    //     duration:chargingProfile?.chargingSchedule?.duration ? parseInt(chargingProfile?.chargingSchedule?.duration):null,
    //     startSchedule:chargingProfile?.chargingSchedule?.startSchedule ? new Date(chargingProfile?.chargingSchedule?.startSchedule).toISOString():null,
    //     chargingRateUnit:chargingProfile?.chargingSchedule?.chargingRateUnit?.value ? chargingProfile?.chargingSchedule?.chargingRateUnit?.value:null,
    //     minChargingRate:chargingProfile?.chargingSchedule?.minChargingRate ? parseFloat(chargingProfile?.chargingSchedule?.minChargingRate):null,
    //     chargingSchedulePeriod: chargingProfile?.chargingSchedule?.chargingSchedulePeriod ? chargingProfile?.chargingSchedule?.chargingSchedulePeriod : null
    //   }
    // };
    let data = {chargingStationIdentity: props.chargingStationIdentity, 
      // idTag: idTag, connectorId: parseInt(connectorId), chargingProfile: tChargingProfile
      body: JSON.parse(reqBody?reqBody:"{}")
    };
    let result = await octtApi.postOcpp16requestStartTransaction(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [idTag, setIdTag] = useState("");
  const [connectorId, setConnectorId] = useState("");
  const { chargingProfile, updateChargingProfile, updateChargingSchedule } = useChargingProfile();

  const purposeList = [
    {value: "ChargePointMaxProfile", label: "ChargePointMaxProfile", idx: 1},
    {value: "TxDefaultProfile", label: "TxDefaultProfile", idx: 2},
    {value: "TxProfile", label: "TxProfile", idx: 3},
  ]

  const kindList =[
    {value: "Absolute", label: "Absolute", idx: 1},
    {value: "Recurring", label: "Recurring", idx: 2},
    {value: "Relative", label: "Relative", idx: 3},
  ]
  const recurrencyList = [
    {value: "Daily", label: "Daily", idx: 1},
    {value: "Weekly", label: "Weekly", idx: 2},
  ]
  const unitList = [
    {value: "W", label: "W", idx: 1},
    {value: "A", label: "A", idx: 2},
  ]
  const [reqBody, setReqBody] = useState("{}");
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // 기본 탭 동작 방지

      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const tab = "    "; // 또는 "    " (공백 4칸)

      // 새로운 텍스트 값 설정
      const newText = reqBody.substring(0, start) + tab + reqBody.substring(end);
      setReqBody(newText);

      // 비동기 처리 후 커서 위치 조정
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + tab.length;
      }, 0);
    }
  };
  return(
    <>
      <div className="ocpp16-charging-profile-container">
        <div className='ocpp16-auto-message-block-input-wrapper textarea'>
          <div className='ocpp16-auto-message-block-input-container textarea'>
            <textarea value={reqBody} onChange={(e)=>{setReqBody(e.target.value)}} onKeyDown={handleKeyDown} placeholder='요청 바디를 입력해주세요' style={{ whiteSpace: "pre-wrap" }}>
            </textarea>
          </div>
        </div>
      </div>
      {/* <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>idTag</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='idTag를 입력해주세요' value={idTag} onChange={(e)=>{setIdTag(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요'  onInput={(e)=>numberInput(e.target)} value={connectorId} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
      </div> */}


      {/* <div className="ocpp16-charging-profile-container">
          <p className='ocpp16-charging-profile-title'>ChargingProfile</p>
          <div></div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p onClick={()=>{console.log(chargingProfile)}}>chargingProfileId</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='chargingProfileId를 입력해주세요' value={chargingProfile.chargingProfileId}  onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("chargingProfileId", e.target.value);}}/>
            </div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>transactionId</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='transactionId를 입력해주세요' value={chargingProfile.transactionId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("transactionId", e.target.value);}}/>
            </div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>stackLevel</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='stackLevel를 입력해주세요' value={chargingProfile.stackLevel} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("stackLevel", e.target.value);}}/>
            </div>
          </div>
          <div></div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>profilePurpose</p>
            <div className='ocpp16-auto-message-block-input-container selector'>
              <SearchSelectorNotCreatable options={purposeList} placeholder="chargingProfilePurpose를 선택하세요" customSave={true} value={chargingProfile.chargingProfilePurpose} keyValue={"chargingProfilePurpose"} setValue={updateChargingProfile}/>
            </div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>ProfileKind</p>
            <div className='ocpp16-auto-message-block-input-container selector'>
              <SearchSelectorNotCreatable options={kindList} placeholder="chargingProfileKind를 선택하세요" customSave={true} value={chargingProfile.chargingProfileKind} keyValue={"chargingProfileKind"} setValue={updateChargingProfile}/>
            </div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>recurrencyKind</p>
            <div className='ocpp16-auto-message-block-input-container selector'>
              <SearchSelectorNotCreatable options={recurrencyList} placeholder="recurrencyKind를 선택하세요" customSave={true} value={chargingProfile.recurrencyKind} keyValue={"recurrencyKind"} setValue={updateChargingProfile}/>
            </div>
          </div>
          <div></div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>validFrom</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.validFrom} onChange={(e)=>{updateChargingProfile("validFrom", e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>validTo</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.validTo}validFrom} onChange={(e)=>{updateChargingProfile("validTo", e.target.value)}}onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          
        
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p className='ocpp16-charging-profile-sub-title'>chargingSchedule</p>
          </div>
          <div></div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>duration</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='duration을 입력해주세요' value={chargingProfile.chargingSchedule.duration} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("duration", e.target.value);}}/>
            </div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>startSchedule</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.chargingSchedule.startSchedule} onChange={(e)=>{updateChargingSchedule("startSchedule", e.target.value)}}onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>chargingRateUnit</p>
            <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={unitList} placeholder="chargingRateUnit을 선택하세요" customSave={true} value={chargingProfile.chargingSchedule.chargingRateUnit} keyValue={"chargingRateUnit"} setValue={updateChargingSchedule}/>
            </div>
          </div>
          
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>minChargingRate</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='minChargingRate를 입력해주세요' value={chargingProfile.chargingSchedule.minChargingRate} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("minChargingRate", e.target.value);}}/>
            </div>
          </div>
          
          <div className='ocpp16-auto-message-block-input-wrapper'>
            <p>chargingSchedulePeriod</p>
          </div>
          <div></div>
          <div className='ocpp16-auto-message-block-input-wrapper three-col'>
            <p>startPeriod</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='startPeriod를 입력해주세요' value={chargingProfile.chargingSchedule.tStartPeriod} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tStartPeriod", e.target.value);}}/>
            </div>
            <p>limit</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='limit를 입력해주세요' value={chargingProfile.chargingSchedule.tLimit} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tLimit", e.target.value);}}/>
            </div>
            <p>numberPhases</p>
            <div className='ocpp16-auto-message-block-input-container text'>
              <input type="text" placeholder='numberPhases를 입력해주세요' value={chargingProfile.chargingSchedule.tNumberPhases} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tNumberPhases", e.target.value);}}/>
            </div>
            <div className={`ocpp16-auto-message-block-schedule-append-button ${chargingProfile.chargingSchedule.tStartPeriod?.length>0 && chargingProfile.chargingSchedule?.tLimit?.length > 0 && chargingProfile.chargingSchedule.tNumberPhases?.length>0 ? "":"disabled"}`} onClick={()=>{
              updateChargingSchedule("chargingSchedulePeriod", [...chargingProfile.chargingSchedule.chargingSchedulePeriod, {"startPeriod":parseInt(chargingProfile.chargingSchedule.tStartPeriod),"limit":parseInt(chargingProfile.chargingSchedule.tLimit),"numberPhases":parseInt(chargingProfile.chargingSchedule.tNumberPhases)}]);
              updateChargingSchedule("tStartPeriod", "");updateChargingSchedule("tLimit", "");updateChargingSchedule("tNumberPhases", "");
            }}>추가</div>
            <div className={`ocpp16-auto-message-block-schedule-append-button ${chargingProfile.chargingSchedule.chargingSchedulePeriod?.length>0 ? "":"disabled"}`} onClick={()=>{
              updateChargingSchedule("chargingSchedulePeriod", [...chargingProfile.chargingSchedule.chargingSchedulePeriod.slice(0,chargingProfile.chargingSchedule.chargingSchedulePeriod.length-1)]);
            }}>삭제</div>
          </div>
          <div className='ocpp16-auto-message-block-input-wrapper textarea'>
            <div className='ocpp16-auto-message-block-input-container textarea'>
              <textarea disabled value={JSON.stringify(chargingProfile.chargingSchedule.chargingSchedulePeriod)} onChange={(e)=>{updateChargingSchedule("chargingSchedulePeriod")}}></textarea>
            </div>
          </div>
          
      </div> */}



      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const RequestStopTransactionMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, transactionId: transactionId};
    let result = await octtApi.postOcpp16requestStopTransaction(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [transactionId, setTransactionId] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>transactionId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='transactionId를 입력해주세요' value={transactionId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setTransactionId(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const UnlockConnectorMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, connectorId: connectorId};
    let result = await octtApi.postOcpp16unlockConnector(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [connectorId, setConnectorId] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요' value={connectorId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const TriggerMessageMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, requestMessage: requestMessageLabel?.value, connectorId: connectorId};
    let result = await octtApi.postOcpp16triggerMessage(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [connectorId, setConnectorId] = useState("");
  const requestMessageList =[
    {value: "BootNotification", label: "BootNotification", idx: 1},
    {value: "DiagnosticsStatusNotification", label: "DiagnosticsStatusNotification", idx: 2},
    {value: "FirmwareStatusNotification", label: "FirmwareStatusNotification", idx: 3},
    {value: "Heartbeat", label: "Heartbeat", idx: 4},
    {value: "MeterValues", label: "MeterValues", idx: 5},
    {value: "StatusNotification", label: "StatusNotification", idx: 6},
    {value: "LogStatusNotification", label: "LogStatusNotification", idx: 7},
    {value: "SignChargePointCertificate", label: "SignChargePointCertificate", idx: 8},
  ]
  const [requestMessageLabel, setReqMessageLabel] = useState();
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요' value={connectorId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>requestMessage</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={requestMessageList} placeholder="requestMessage를 선택하세요"  value={requestMessageLabel} setValue={setReqMessageLabel}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}

const ExtendedTriggerMessageMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, requestMessage: requestMessageLabel?.value, connectorId: connectorId};
    let result = await octtApi.postOcpp16extendedTriggerMessage(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [connectorId, setConnectorId] = useState("");
  const requestMessageList =[
    {value: "BootNotification", label: "BootNotification", idx: 1},
    {value: "DiagnosticsStatusNotification", label: "DiagnosticsStatusNotification", idx: 2},
    {value: "FirmwareStatusNotification", label: "FirmwareStatusNotification", idx: 3},
    {value: "Heartbeat", label: "Heartbeat", idx: 4},
    {value: "MeterValues", label: "MeterValues", idx: 5},
    {value: "StatusNotification", label: "StatusNotification", idx: 6},
    {value: "LogStatusNotification", label: "LogStatusNotification", idx: 7},
    {value: "SignChargePointCertificate", label: "SignChargePointCertificate", idx: 8},
  ]
  const [requestMessageLabel, setReqMessageLabel] = useState();
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요' value={connectorId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>requestMessage</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={requestMessageList} placeholder="requestMessage를 선택하세요"  value={requestMessageLabel} setValue={setReqMessageLabel}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const ChangeAvailabilityMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, operationalStatus: operationalStatusLabel?.value, connectorId: connectorId};
    let result = await octtApi.postOcpp16changeAvailability(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [connectorId, setConnectorId] = useState("");
  const operationalStatusList =[
    {value: "Inoperative", label: "Inoperative", idx: 1},
    {value: "Operative", label: "Operative", idx: 2},
  ]
  const [operationalStatusLabel, setOperationalStatus] = useState();
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요' value={connectorId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>operationalStatus</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={operationalStatusList} placeholder="operationalStatus를 선택하세요"  value={operationalStatusLabel} setValue={setOperationalStatus}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const SetChargingProfileMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    // let tChargingProfile = {
    //   chargingStationIdentity: props.chargingStationIdentity,
    //   chargingProfileId: parseInt(chargingProfile.chargingProfileId),
    //   transactionId: parseInt(chargingProfile.transactionId),
    //   stackLevel: parseInt(chargingProfile.stackLevel),
    //   chargingProfilePurpose: chargingProfile.chargingProfilePurpose.value,
    //   chargingProfileKind: chargingProfile.chargingProfileKind.value,
    //   recurrencyKind: chargingProfile.recurrencyKind?.value,
    //   validFrom: new Date(chargingProfile.validFrom).toISOString(),
    //   validTo: new Date(chargingProfile.validTo).toISOString(),
    //   chargingSchedule: {
    //     duration:parseInt(chargingProfile.chargingSchedule.duration),
    //     startSchedule:new Date(chargingProfile.chargingSchedule.startSchedule).toISOString(),
    //     chargingRateUnit:chargingProfile.chargingSchedule.chargingRateUnit.value,
    //     minChargingRate:parseFloat(chargingProfile.chargingSchedule.minChargingRate),
    //     chargingSchedulePeriod: chargingProfile.chargingSchedule.chargingSchedulePeriod
    //   }
    // };
    // console.log(tChargingProfile);
    let data = {chargingStationIdentity: props.chargingStationIdentity, 
      // connectorId: parseInt(connectorId), chargingProfile: tChargingProfile
      body: JSON.parse(reqBody?reqBody:"{}")
    };
    let result = await octtApi.postOcpp16setChargingProfile(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [connectorId, setConnectorId] = useState("");
  const { chargingProfile, updateChargingProfile, updateChargingSchedule } = useChargingProfile();

  const purposeList = [
    {value: "ChargePointMaxProfile", label: "ChargePointMaxProfile", idx: 1},
    {value: "TxDefaultProfile", label: "TxDefaultProfile", idx: 2},
    {value: "TxProfile", label: "TxProfile", idx: 3},
  ]

  const kindList =[
    {value: "Absolute", label: "Absolute", idx: 1},
    {value: "Recurring", label: "Recurring", idx: 2},
    {value: "Relative", label: "Relative", idx: 3},
  ]
  const recurrencyList = [
    {value: "Daily", label: "Daily", idx: 1},
    {value: "Weekly", label: "Weekly", idx: 2},
  ]
  const unitList = [
    {value: "W", label: "W", idx: 1},
    {value: "A", label: "A", idx: 2},
  ]
  const [reqBody, setReqBody] = useState("{}");
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // 기본 탭 동작 방지

      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const tab = "    "; // 또는 "    " (공백 4칸)

      // 새로운 텍스트 값 설정
      const newText = reqBody.substring(0, start) + tab + reqBody.substring(end);
      setReqBody(newText);

      // 비동기 처리 후 커서 위치 조정
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + tab.length;
      }, 0);
    }
  };
  return(
    <>
      <div className="ocpp16-charging-profile-container">
        <div className='ocpp16-auto-message-block-input-wrapper textarea'>
          <div className='ocpp16-auto-message-block-input-container textarea'>
            <textarea value={reqBody} onChange={(e)=>{setReqBody(e.target.value)}} onKeyDown={handleKeyDown} placeholder='요청 바디를 입력해주세요' style={{ whiteSpace: "pre-wrap" }}>
            </textarea>
          </div>
        </div>
      </div>
      {/* <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요'  onInput={(e)=>numberInput(e.target)} value={connectorId} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
        <div></div>
      </div>


      <div className="ocpp16-charging-profile-container">
        <p className='ocpp16-charging-profile-title'>ChargingProfile</p>
        <div></div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p onClick={()=>{console.log(chargingProfile)}}>chargingProfileId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='chargingProfileId를 입력해주세요' value={chargingProfile.chargingProfileId}  onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("chargingProfileId", e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>transactionId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='transactionId를 입력해주세요' value={chargingProfile.transactionId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("transactionId", e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>stackLevel</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='stackLevel를 입력해주세요' value={chargingProfile.stackLevel} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("stackLevel", e.target.value);}}/>
          </div>
        </div>
        <div></div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>profilePurpose</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={purposeList} placeholder="chargingProfilePurpose를 선택하세요" customSave={true} value={chargingProfile.chargingProfilePurpose} keyValue={"chargingProfilePurpose"} setValue={updateChargingProfile}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>ProfileKind</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={kindList} placeholder="chargingProfileKind를 선택하세요" customSave={true} value={chargingProfile.chargingProfileKind} keyValue={"chargingProfileKind"} setValue={updateChargingProfile}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>recurrencyKind</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={recurrencyList} placeholder="recurrencyKind를 선택하세요" customSave={true} value={chargingProfile.recurrencyKind} keyValue={"recurrencyKind"} setValue={updateChargingProfile}/>
          </div>
        </div>
        <div></div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>validFrom</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.validFrom} onChange={(e)=>{updateChargingProfile("validFrom", e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>validTo</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.validTo}validFrom} onChange={(e)=>{updateChargingProfile("validTo", e.target.value)}}onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        
      
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p className='ocpp16-charging-profile-sub-title'>chargingSchedule</p>
        </div>
        <div></div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>duration</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='duration을 입력해주세요' value={chargingProfile.chargingSchedule.duration} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("duration", e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>startSchedule</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.chargingSchedule.startSchedule} onChange={(e)=>{updateChargingSchedule("startSchedule", e.target.value)}}onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>chargingRateUnit</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
          <SearchSelectorNotCreatable options={unitList} placeholder="chargingRateUnit을 선택하세요" customSave={true} value={chargingProfile.chargingSchedule.chargingRateUnit} keyValue={"chargingRateUnit"} setValue={updateChargingSchedule}/>
          </div>
        </div>
        
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>minChargingRate</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='minChargingRate를 입력해주세요' value={chargingProfile.chargingSchedule.minChargingRate} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("minChargingRate", e.target.value);}}/>
          </div>
        </div>
        
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>chargingSchedulePeriod</p>
        </div>
        <div></div>
        <div className='ocpp16-auto-message-block-input-wrapper three-col'>
          <p>startPeriod</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='startPeriod를 입력해주세요' value={chargingProfile.chargingSchedule.tStartPeriod} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tStartPeriod", e.target.value);}}/>
          </div>
          <p>limit</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='limit를 입력해주세요' value={chargingProfile.chargingSchedule.tLimit} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tLimit", e.target.value);}}/>
          </div>
          <p>numberPhases</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='numberPhases를 입력해주세요' value={chargingProfile.chargingSchedule.tNumberPhases} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tNumberPhases", e.target.value);}}/>
          </div>
          <div className={`ocpp16-auto-message-block-schedule-append-button ${chargingProfile.chargingSchedule.tStartPeriod?.length>0 && chargingProfile.chargingSchedule?.tLimit?.length > 0 && chargingProfile.chargingSchedule.tNumberPhases?.length>0 ? "":"disabled"}`} onClick={()=>{
            updateChargingSchedule("chargingSchedulePeriod", [...chargingProfile.chargingSchedule.chargingSchedulePeriod, {"startPeriod":parseInt(chargingProfile.chargingSchedule.tStartPeriod),"limit":parseInt(chargingProfile.chargingSchedule.tLimit),"numberPhases":parseInt(chargingProfile.chargingSchedule.tNumberPhases)}]);
            updateChargingSchedule("tStartPeriod", "");updateChargingSchedule("tLimit", "");updateChargingSchedule("tNumberPhases", "");
          }}>추가</div>
          <div className={`ocpp16-auto-message-block-schedule-append-button ${chargingProfile.chargingSchedule.chargingSchedulePeriod?.length>0 ? "":"disabled"}`} onClick={()=>{
            updateChargingSchedule("chargingSchedulePeriod", [...chargingProfile.chargingSchedule.chargingSchedulePeriod.slice(0,chargingProfile.chargingSchedule.chargingSchedulePeriod.length-1)]);
          }}>삭제</div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper textarea'>
          <div className='ocpp16-auto-message-block-input-container textarea'>
            <textarea disabled value={JSON.stringify(chargingProfile.chargingSchedule.chargingSchedulePeriod)} onChange={(e)=>{updateChargingSchedule("chargingSchedulePeriod")}}></textarea>
          </div>
        </div>
          
      </div> */}



      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const SetChargingProfilesMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity,
      // chargingProfiles: chargingProfiles
      body: JSON.parse(reqBody?reqBody:"{}")
    };
    let result = await octtApi.postOcpp16setChargingProfiles(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [chargingProfiles, setChargingProfiles] = useState([]);
  const [modalOn, setModalOn] = useState(false);

  const [reqBody, setReqBody] = useState("{}");
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // 기본 탭 동작 방지

      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const tab = "    "; // 또는 "    " (공백 4칸)

      // 새로운 텍스트 값 설정
      const newText = reqBody.substring(0, start) + tab + reqBody.substring(end);
      setReqBody(newText);

      // 비동기 처리 후 커서 위치 조정
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + tab.length;
      }, 0);
    }
  };

  return(
    <>
      {/* {modalOn&&<ProfileAppendModal setOnOff={setModalOn} setChargingProfiles={setChargingProfiles} chargingProfiles={chargingProfiles}/>}
      <div className='ocpp16-auth-charging-profile-append-title-container'>
        <p className='ocpp16-charging-profile-title'>ChargingProfiles</p>
        <div className='ocpp16-auth-append-profile-button-container'>
          <div className='ocpp16-auth-append-profile-button' onClick={()=>{setModalOn(true)}}>Profile 추가</div>
          <div className={`ocpp16-auth-append-profile-button delete ${chargingProfiles.length===0?"disabled":""}`} onClick={()=>{setChargingProfiles([...chargingProfiles.slice(0,chargingProfiles.length-1)])}}>삭제</div>
        </div>
      </div>
      <div className="ocpp16-charging-profile-container">
        <div className='ocpp16-auto-message-block-input-wrapper textarea'>
          <div className='ocpp16-auto-message-block-input-container textarea'>
            <textarea disabled value={JSON.stringify(chargingProfiles, null, 4)}
            style={{ whiteSpace: "pre-wrap" }}></textarea>
          </div>
        </div>
      </div> */}
      <div className="ocpp16-charging-profile-container">
        <div className='ocpp16-auto-message-block-input-wrapper textarea'>
          <div className='ocpp16-auto-message-block-input-container textarea'>
            <textarea value={reqBody} onChange={(e)=>{setReqBody(e.target.value)}} onKeyDown={handleKeyDown} placeholder='요청 바디를 입력해주세요' style={{ whiteSpace: "pre-wrap" }}>
            </textarea>
          </div>
        </div>
      </div>



      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const ClearChargingProfileMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, chargingProfileId: chargingProfileId, connectorId: connectorId, chargingProfilePurpose: purposeLabel?.value, stackLevel: stackLevel};
    let result = await octtApi.postOcpp16clearChargingProfile(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const purposeList = [
    {value: "ChargePointMaxProfile", label: "ChargePointMaxProfile", idx: 1},
    {value: "TxDefaultProfile", label: "TxDefaultProfile", idx: 2},
    {value: "TxProfile", label: "TxProfile", idx: 3},
  ]
  const [chargingProfileId, setChargingProfileId] = useState("");
  const [connectorId, setConnectorId] = useState("");
  const [purposeLabel, setPurposeLabel] = useState();
  const [stackLevel, setStackLevel] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>chargingProfileId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='chargingProfileId를 입력해주세요' value={chargingProfileId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setChargingProfileId(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요' value={connectorId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>purpose</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={purposeList} placeholder="purpose를 선택하세요"  value={purposeLabel} setValue={setPurposeLabel}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>stackLevel</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='stackLevel를 입력해주세요' value={stackLevel} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setStackLevel(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const GetCompositeScheduleMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, connectorId: connectorId, duration: duration, chargingRateUnit: chargingRateUnitLabel?.value};
    let result = await octtApi.postOcpp16getCompositeSchedule(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [connectorId, setConnectorId] = useState("");
  const [duration, setDuration] = useState("");
  const [chargingRateUnitLabel, setChargingRateUnitLabel] = useState(null);
  const chargingRateUnitList = [
    {value: "W", label: "W", idx: 1},
    {value: "A", label: "A", idx: 2},
  ]
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요' value={connectorId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>duration</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='duration을 입력해주세요' value={duration} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setDuration(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>chargingRateUnit</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={chargingRateUnitList} placeholder="chargingRateUnit를 선택하세요"  value={chargingRateUnitLabel} setValue={setChargingRateUnitLabel}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const ReserveNowMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, expiryDate: expiryDate ?new Date(expiryDate).toISOString():null, idTag: idTag, connectorId: connectorId, parentIdTag: parentIdTag};
    let result = await octtApi.postOcpp16reserveNow(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [expiryDate, setExpiryDate] = useState("");
  const [idTag, setIdTag] = useState("");
  const [connectorId, setConnectorId] = useState("");
  const [parentIdTag, setParentIdTag] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>expiryDate</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={expiryDate} onChange={(e)=>{setExpiryDate(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>idTag</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='idTag를 입력해주세요' value={idTag} onChange={(e)=>{setIdTag(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>connectorId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='connectorId를 입력해주세요' value={connectorId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setConnectorId(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>parentIdTag</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='parentIdTag를 입력해주세요' value={parentIdTag} onChange={(e)=>{setParentIdTag(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const CancelReservationMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, reservationId: reservationId};
    let result = await octtApi.postOcpp16cancelReservation(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [reservationId, setReservationId] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>reservationId</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='reservationId를 입력해주세요' value={reservationId} onChange={(e)=>{setReservationId(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const UpdateFirmwareMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, location: location};
    let result = await octtApi.postOcpp16updateFirmware(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [location, setLocation] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>location</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='location을 입력해주세요(https://)' value={location} onChange={(e)=>{setLocation(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
        </div>{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const SignedUpdateFirmwareMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, location: location, retrieveDateTime: retrieveDateTime ? new Date(retrieveDateTime).toISOString():null, signingCertificate: signingCertificate, signature: signature};
    let result = await octtApi.postOcpp16signedUpdateFirmware(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [location, setLocation] = useState("");
  const [retrieveDateTime, setRetrieveDateTime] = useState("");
  const [signingCertificate, setSigningCertificate] = useState("");
  const [signature, setSignature] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>location</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='location을 입력해주세요(https://)' value={location} onChange={(e)=>{setLocation(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>retrieveDateTime</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={retrieveDateTime} onChange={(e)=>{setRetrieveDateTime(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>signingCertificate</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='signingCertificate를 입력해주세요' value={signingCertificate} onChange={(e)=>{setSigningCertificate(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>signature</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='signature를 입력해주세요' value={signature} onChange={(e)=>{setSignature(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const GetInstalledCertificateIdsMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, certificateType: certificateTypeLabel?.value};
    let result = await octtApi.postOcpp16getInstalledCertificateIds(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [certificateTypeLabel, setCertificateTypeLabel] = useState(null);
  const certificateTypeList = [
    {value: "CentralSystemRootCertificate", label: "CentralSystemRootCertificate", idx: 1},
    {value: "ManufacturerRootCertificate", label: "ManufacturerRootCertificate", idx: 2},
  ]
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>certificateType</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={certificateTypeList} placeholder="certificateType를 선택하세요"  value={certificateTypeLabel} setValue={setCertificateTypeLabel}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
        </div>{resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const InstallCertificateMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, 
      // certificateType: certificateType, certificate: certificate
      body: JSON.parse(reqBody?reqBody:"{}")
    };
    let result = await octtApi.postOcpp16installCertificate(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [certificateType, setCertificateType] = useState("");
  const [certificate, setCertificate] = useState("");

  const [reqBody, setReqBody] = useState("{}");
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // 기본 탭 동작 방지

      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const tab = "    "; // 또는 "    " (공백 4칸)

      // 새로운 텍스트 값 설정
      const newText = reqBody.substring(0, start) + tab + reqBody.substring(end);
      setReqBody(newText);

      // 비동기 처리 후 커서 위치 조정
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + tab.length;
      }, 0);
    }
  };
  return(
    <>
      <div className="ocpp16-charging-profile-container">
        <div className='ocpp16-auto-message-block-input-wrapper textarea'>
          <div className='ocpp16-auto-message-block-input-container textarea'>
            <textarea value={reqBody} onChange={(e)=>{setReqBody(e.target.value)}} onKeyDown={handleKeyDown} placeholder='요청 바디를 입력해주세요' style={{ whiteSpace: "pre-wrap" }}>
            </textarea>
          </div>
        </div>
      </div>
      {/* <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>certificateType</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='certificateType를 입력해주세요' value={certificateType} onChange={(e)=>{setCertificateType(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>certificate</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='certificate를 입력해주세요' value={certificate} onChange={(e)=>{setCertificate(e.target.value);}}/>
          </div>
        </div>
      </div> */}


      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const DeleteCertificateMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, hashAlgorithm: hashAlgorithm, certificate: certificate};
    let result = await octtApi.postOcpp16deleteCertificate(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [hashAlgorithm, setHashAlgorithm] = useState("");
  const [certificate, setCertificate] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>hashAlgorithm</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='hashAlgorithm를 입력해주세요' value={hashAlgorithm} onChange={(e)=>{setHashAlgorithm(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>certificate</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='certificate를 입력해주세요' value={certificate} onChange={(e)=>{setCertificate(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const DeleteCertificateByManualMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, hashAlgorithm: hashAlgorithm, issuerNameHash: issuerNameHash, issuerKeyHash: issuerKeyHash, serialNumber: serialNumber};
    let result = await octtApi.postOcpp16deleteCertificateByManual(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [hashAlgorithm, setHashAlgorithm] = useState("");
  const [issuerNameHash, setIssuerNameHash] = useState("");
  const [issuerKeyHash, setIssuerKeyHash] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>hashAlgorithm</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='hashAlgorithm를 입력해주세요' value={hashAlgorithm} onChange={(e)=>{setHashAlgorithm(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>issuerNameHash</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='issuerNameHash를 입력해주세요' value={issuerNameHash} onChange={(e)=>{setIssuerNameHash(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>issuerKeyHash</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='issuerKeyHash를 입력해주세요' value={issuerKeyHash} onChange={(e)=>{setIssuerKeyHash(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>serialNumber</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='serialNumber를 입력해주세요' value={serialNumber} onChange={(e)=>{setSerialNumber(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const GetDiagnosticsMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, location: location, startTime: startTime ? new Date(startTime).toISOString():null, stopTime: stopTime ? new Date(stopTime).toISOString():null, retries: retries, retryInterval: retryInterval};
    let result = await octtApi.postOcpp16getDiagnostics(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [location, setLocation] = useState("");
  const [startTime, setStartTime] = useState("");
  const [stopTime, setStopTime] = useState("");
  const [retries, setRetries] = useState("");
  const [retryInterval, setRetryInterval] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>location</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='location을 입력해주세요(https://)' value={location} onChange={(e)=>{setLocation(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>startTime</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={startTime} onChange={(e)=>{setStartTime(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>retries</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='retries를 입력해주세요' value={retries} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setRetries(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>stopTime</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={stopTime} onChange={(e)=>{setStopTime(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>retryInterval</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='retryInterval를 입력해주세요' value={retryInterval} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setRetryInterval(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}
const GetLogMsgBlock = (props)=>{
  const [loadingOn, setLoadingOn] = useState(false);
  const [resMsg, setResMsg] = useState(null);
  useEffect(()=>{
    if(resMsg){
      setTimeout(()=>{
        setResMsg(null);
      }, 3000)
    }
  },[resMsg])
  const request = async()=>{
    setLoadingOn(true);
    let data = {chargingStationIdentity: props.chargingStationIdentity, logType: logTypeLabel?.value, remoteLocation: remoteLocation, oldestTimestamp: oldestTimestamp ? new Date(oldestTimestamp).toISOString():null, latestTimestamp: latestTimestamp ? new Date(latestTimestamp).toISOString():null, retries: retries, retryInterval: retryInterval};
    let result = await octtApi.postOcpp16getLog(data);
    if(result.status === 200 || result.status === 201){
      setResMsg({status:"success", msg:"명령 전송을 성공하였습니다."});
    }
    else{
      setResMsg({status:"failure", msg:"명령 전송을 실패하였습니다."});
    }
    setLoadingOn(false);
  }
  const [logTypeLabel, setLogTypeLabel] = useState(null);
  const logTypeList = [
    {value: "DiagnosticsLog", label: "DiagnosticsLog", idx: 1},
    {value: "SecurityLog", label: "SecurityLog", idx: 2},
  ]
  const [remoteLocation, setRemoteLocation] = useState("");
  const [oldestTimestamp, setOldestTimestamp] = useState("");
  const [latestTimestamp, setLatestTimestamp] = useState("");
  const [retries, setRetries] = useState("");
  const [retryInterval, setRetryInterval] = useState("");
  return(
    <>
      <div className='ocpp16-auth-message-block-content'>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>logType</p>
          <div className='ocpp16-auto-message-block-input-container selector'>
            <SearchSelectorNotCreatable options={logTypeList} placeholder="logType를 선택하세요"  value={logTypeLabel} setValue={setLogTypeLabel}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>remoteLocation</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='remoteLocation을 입력해주세요' value={remoteLocation} onChange={(e)=>{setRemoteLocation(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>oldestTimestamp</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='YYYY-MM-DD 00:00' value={oldestTimestamp} onChange={(e)=>{setOldestTimestamp(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>retries</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='retries를 입력해주세요' value={retries} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{setRetries(e.target.value);}}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>latestTimestamp</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" value={latestTimestamp} placeholder='YYYY-MM-DD 00:00' onChange={(e)=>{setLatestTimestamp(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
          </div>
        </div>
        <div className='ocpp16-auto-message-block-input-wrapper'>
          <p>retryInterval</p>
          <div className='ocpp16-auto-message-block-input-container text'>
            <input type="text" placeholder='retryInterval를 입력해주세요' value={retryInterval} onInput={(e)=>numberInput(e.target)}
            onChange={(e)=>{setRetryInterval(e.target.value);}}/>
          </div>
        </div>
      </div>
      <div className='ocpp16-auth-message-block-footer'>
        <div className='ocpp16-auth-message-block-msg-container'>
          {resMsg ? <p className={`ocpp16-auth-message-block-msg-response ${resMsg.status}`}>{resMsg.msg}</p> : ""}
        </div>
        <div className={`ocpp16-auth-message-block-button`} onClick={()=>{request()}}>
          {loadingOn ? <Loading/> : "전송"}
        </div>
      </div>
    </>
  )
}





// Profile Append Modal
const ProfileAppendModal = (props)=>{
  const [connectorId, setConnectorId] = useState("");
  const { chargingProfile, updateChargingProfile, updateChargingSchedule } = useChargingProfile();

  const purposeList = [
    {value: "ChargePointMaxProfile", label: "ChargePointMaxProfile", idx: 1},
    {value: "TxDefaultProfile", label: "TxDefaultProfile", idx: 2},
    {value: "TxProfile", label: "TxProfile", idx: 3},
  ]

  const kindList =[
    {value: "Absolute", label: "Absolute", idx: 1},
    {value: "Recurring", label: "Recurring", idx: 2},
    {value: "Relative", label: "Relative", idx: 3},
  ]
  const recurrencyList = [
    {value: "Daily", label: "Daily", idx: 1},
    {value: "Weekly", label: "Weekly", idx: 2},
  ]
  const unitList = [
    {value: "W", label: "W", idx: 1},
    {value: "A", label: "A", idx: 2},
  ]
  const append = ()=>{
    let tChargingProfile = {
      connectorId: parseInt(connectorId),
      chargingProfile:{
        chargingProfileId: parseInt(chargingProfile.chargingProfileId),
        transactionId: parseInt(chargingProfile.transactionId),
        stackLevel: parseInt(chargingProfile.stackLevel),
        chargingProfilePurpose: chargingProfile.chargingProfilePurpose.value,
        chargingProfileKind: chargingProfile.chargingProfileKind.value,
        recurrencyKind: chargingProfile.recurrencyKind?.value,
        validFrom: new Date(chargingProfile.validFrom).toISOString(),
        validTo: new Date(chargingProfile.validTo).toISOString(),
        chargingSchedule: {
          duration:parseInt(chargingProfile.chargingSchedule.duration),
          startSchedule:new Date(chargingProfile.chargingSchedule.startSchedule).toISOString(),
          chargingRateUnit:chargingProfile.chargingSchedule.chargingRateUnit.value,
          minChargingRate:parseFloat(chargingProfile.chargingSchedule.minChargingRate),
          chargingSchedulePeriod: chargingProfile.chargingSchedule.chargingSchedulePeriod
        }
      }
    };
    props.setChargingProfiles([...props.chargingProfiles, tChargingProfile]);
    props.setOnOff(false);
  }
  return(
    <>
    <div className="cpo-group-invitation-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-group-invitation-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-group-invitation-modal-header">
          <title>Profile 추가</title>
          <div className='cpo-group-invitation-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div>
          <div className='ocpp16-auth-message-block-content'>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>connectorId</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='connectorId를 입력해주세요'  onInput={(e)=>numberInput(e.target)} value={connectorId} onChange={(e)=>{setConnectorId(e.target.value);}}/>
              </div>
            </div>
            <div></div>
          </div>
          <div className="ocpp16-charging-profile-container">
            <p className='ocpp16-charging-profile-title'>ChargingProfile</p>
            <div></div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p onClick={()=>{console.log(chargingProfile)}}>chargingProfileId</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='chargingProfileId를 입력해주세요' value={chargingProfile.chargingProfileId}  onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("chargingProfileId", e.target.value);}}/>
              </div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>transactionId</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='transactionId를 입력해주세요' value={chargingProfile.transactionId} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("transactionId", e.target.value);}}/>
              </div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>stackLevel</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='stackLevel를 입력해주세요' value={chargingProfile.stackLevel} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingProfile("stackLevel", e.target.value);}}/>
              </div>
            </div>
            <div></div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>profilePurpose</p>
              <div className='ocpp16-auto-message-block-input-container selector'>
                <SearchSelectorNotCreatable options={purposeList} placeholder="chargingProfilePurpose를 선택하세요" customSave={true} value={chargingProfile.chargingProfilePurpose} keyValue={"chargingProfilePurpose"} setValue={updateChargingProfile}/>
              </div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>ProfileKind</p>
              <div className='ocpp16-auto-message-block-input-container selector'>
                <SearchSelectorNotCreatable options={kindList} placeholder="chargingProfileKind를 선택하세요" customSave={true} value={chargingProfile.chargingProfileKind} keyValue={"chargingProfileKind"} setValue={updateChargingProfile}/>
              </div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>recurrencyKind</p>
              <div className='ocpp16-auto-message-block-input-container selector'>
                <SearchSelectorNotCreatable options={recurrencyList} placeholder="recurrencyKind를 선택하세요" customSave={true} value={chargingProfile.recurrencyKind} keyValue={"recurrencyKind"} setValue={updateChargingProfile}/>
              </div>
            </div>
            <div></div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>validFrom</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.validFrom} onChange={(e)=>{updateChargingProfile("validFrom", e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>validTo</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.validTo} onChange={(e)=>{updateChargingProfile("validTo", e.target.value)}}onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>
            
          
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p className='ocpp16-charging-profile-sub-title'>chargingSchedule</p>
            </div>
            <div></div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>duration</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='duration을 입력해주세요' value={chargingProfile.chargingSchedule.duration} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("duration", e.target.value);}}/>
              </div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>startSchedule</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='YYYY-MM-DD 00:00' value={chargingProfile.chargingSchedule.startSchedule} onChange={(e)=>{updateChargingSchedule("startSchedule", e.target.value)}}onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>chargingRateUnit</p>
              <div className='ocpp16-auto-message-block-input-container selector'>
              <SearchSelectorNotCreatable options={unitList} placeholder="chargingRateUnit을 선택하세요" customSave={true} value={chargingProfile.chargingSchedule.chargingRateUnit} keyValue={"chargingRateUnit"} setValue={updateChargingSchedule}/>
              </div>
            </div>
            
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>minChargingRate</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='minChargingRate를 입력해주세요' value={chargingProfile.chargingSchedule.minChargingRate} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("minChargingRate", e.target.value);}}/>
              </div>
            </div>
            
            <div className='ocpp16-auto-message-block-input-wrapper'>
              <p>chargingSchedulePeriod</p>
            </div>
            <div></div>
            <div className='ocpp16-auto-message-block-input-wrapper three-col'>
              <p>startPeriod</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='startPeriod를 입력해주세요' value={chargingProfile.chargingSchedule.tStartPeriod} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tStartPeriod", e.target.value);}}/>
              </div>
              <p>limit</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='limit를 입력해주세요' value={chargingProfile.chargingSchedule.tLimit} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tLimit", e.target.value);}}/>
              </div>
              <p>numberPhases</p>
              <div className='ocpp16-auto-message-block-input-container text'>
                <input type="text" placeholder='numberPhases를 입력해주세요' value={chargingProfile.chargingSchedule.tNumberPhases} onInput={(e)=>numberInput(e.target)} onChange={(e)=>{updateChargingSchedule("tNumberPhases", e.target.value);}}/>
              </div>
              <div className={`ocpp16-auto-message-block-schedule-append-button ${chargingProfile.chargingSchedule.tStartPeriod?.length>0 && chargingProfile.chargingSchedule?.tLimit?.length > 0 && chargingProfile.chargingSchedule.tNumberPhases?.length>0 ? "":"disabled"}`} onClick={()=>{
                updateChargingSchedule("chargingSchedulePeriod", [...chargingProfile.chargingSchedule.chargingSchedulePeriod, {"startPeriod":parseInt(chargingProfile.chargingSchedule.tStartPeriod),"limit":parseInt(chargingProfile.chargingSchedule.tLimit),"numberPhases":parseInt(chargingProfile.chargingSchedule.tNumberPhases)}]);
                updateChargingSchedule("tStartPeriod", "");updateChargingSchedule("tLimit", "");updateChargingSchedule("tNumberPhases", "");
              }}>추가</div>
              <div className={`ocpp16-auto-message-block-schedule-append-button ${chargingProfile.chargingSchedule.chargingSchedulePeriod?.length>0 ? "":"disabled"}`} onClick={()=>{
                updateChargingSchedule("chargingSchedulePeriod", [...chargingProfile.chargingSchedule.chargingSchedulePeriod.slice(0,chargingProfile.chargingSchedule.chargingSchedulePeriod.length-1)]);
              }}>삭제</div>
            </div>
            <div className='ocpp16-auto-message-block-input-wrapper textarea'>
              <div className='ocpp16-auto-message-block-input-container textarea'>
                <textarea disabled value={JSON.stringify(chargingProfile.chargingSchedule.chargingSchedulePeriod)} onChange={(e)=>{updateChargingSchedule("chargingSchedulePeriod")}}></textarea>
              </div>
            </div>
          </div>
          <div className='ocpp16-auth-message-block-footer'>
            <div className='ocpp16-auth-message-block-msg-container' onClick={()=>{console.log(chargingProfile.stackLevel)}}>
              <div></div>
            </div>
            <div className={`ocpp16-auth-message-block-button`}
              onClick={()=>{append()}}>
              {"추가"}
            </div>
          </div>
        </div>
      </div>
    </div>


      
    </>
  )
}