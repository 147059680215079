import React, { useRef, useState, useEffect } from 'react';
import SubCpoStationListForCharger from '../components/SubCpoStationListForCharger.js';
import SubCpoChargerCardList from '../components/SubCpoChargerCardList.js';
import '../styles/SubCpoChargerControlGridView.css';
import { HiCursorClick } from "react-icons/hi";
import { BsEvStationFill } from "react-icons/bs";

export const SubCpoChargerControlGridView = (props) => {
  const [clickedStation, setClickedStation] = useState(null);

  return (
    <>
      <div className='sub-cpo-charger-management-grid-view-charger-list-header'>
        {`${props.selectedCpo?.Name} 충전소 목록`}
      </div>
      <SubCpoStationListForCharger setClickedStation={setClickedStation} selectedCpo={props.selectedCpo}/>
      <div className='sub-cpo-charger-management-grid-view-charger-list-wrapper'>
        {
          clickedStation
          ?
          <>
            <SubCpoChargerCardList selectedCpo={props.selectedCpo} clickedStation={clickedStation} />
          </>
          :
          <>
          <div className='sub-cpo-charger-management-grid-view-before-select'>
            <div>
              <BsEvStationFill/>
              <HiCursorClick/>
            </div>
            충전소를 선택해주세요.
          </div>
          </>
        }
      </div>
    </>
  )
}
export default SubCpoChargerControlGridView;