import React, { useState, useEffect } from 'react';
const GridViewStationItem = (props) => {

  // station item 클릭 이벤트 콜백 function
  const clickEvent = ()=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    props.setClickedStation(props.idx)
  }



  return (
    <>
      <div className={props.clickedStation === props.idx ? 'charger-management-grid-view-list-content-item clicked':'charger-management-grid-view-list-content-item'}
      ref={(el)=>{props.stationRef.current[props.idx]=el}}
      onClick={()=>{clickEvent()}}
      >
        <div><p>{`${props.data.RegionName ? props.data.RegionName:"-"}`}</p></div>
        <div><p>{props.data.ChargingStationName}</p></div>
        <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.Address ?props.data.Address+' '+(props.data.DetailedAddress ? props.data.DetailedAddress:""):"-"}</p></div>
        <div>
          <div className={`charger-management-grid-view-cpo-charger-roaming-check-badge ${props.roamingStationInfo ? "":"none"}`}>
            <p>{props.roamingStationInfo ? "등록":"미등록"}</p>
          </div>
        </div>
        <div>
            <p>{props.roamingStationInfo?.moEChargingStationId}</p>
        </div>
        <div className='centerAlign'><p style={{color:props.chargerHubInfo&&props.chargerHubInfo?.filter(it=>it.used === true).length !== 0?'var(--emoovBlue)':'#ddd',fontWeight:"800"}}>
          {props.chargerHubInfo?.filter(it=>it.used === true).length}</p></div>
        <div className='centerAlign'><p>{props.totalCount}</p></div>
      </div>
    </>
  )
}
export default GridViewStationItem;