import React, { useState, useEffect } from 'react';
import { chargerTypeConverter, connectorTypeConverter2 } from '../../util/util';
import { FaChevronDown } from "react-icons/fa6";
import {TbDotsVertical} from 'react-icons/tb';

import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

export const CpoCompanyListItem = (props) => {
  
  const [tabOpen, setTabOpen] = useState(false);
  const [checked, setChecked] = useState(null);

  useEffect(()=>{
    if(props.checkMode){
      setTabOpen(false);
    }
    else{
      setTabOpen(false);

      setChecked(false);
    }
  },[props.checkMode])



  
  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      if(props.settingMode === "edit"){
        props.setCheckList([props.idx]);
      }
      else{
        props.setCheckList([...props.checkList, props.idx]);
      }
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkList];
        temp.splice(itemIdx,1);
        props.setCheckList(temp);
      }
    }
  },[checked])

  useEffect(()=>{
    let itemIdx = props.checkList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkList])



  const [btnOpen, setBtnOpen] = useState(false);

  return (
    <div className={'cpo-company-list-item-container '+`${tabOpen ? "open":""}`}>
      <div className={'cpo-company-list-item-content '+`${props.checkMode?"checkMode ":""}` +`${checked?"checked":""}`} onClick={()=>{setTabOpen(!tabOpen)}}>
        {
          !props.checkMode ?
          <>
            <div><p>{props.data.Name}</p></div>
            <div className='handle'>
              <div className="cpo-company-list-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
                <div className={"cpo-company-list-item-function-button-list " + `${btnOpen ? "open":""}`}>
                  <div className='cpo-company-list-item-function-button edit'>
                    <div>
                      <AiFillEdit/>
                    </div>
                  </div>
                  <div className='cpo-company-list-item-function-button delete'>
                    <div>
                      <MdDelete/>
                    </div>
                  </div>
    
                </div>
                <TbDotsVertical/>
              </div>
            </div>
          </>
          :
          <>
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
          <div><p>{props.data?.Name}</p></div>
          <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
        </>
        }
        
      </div>
    </div>
  )
}
export default CpoCompanyListItem;