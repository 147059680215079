import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate } from "react-router-dom";
import QnaItem from '../../components/list/QnaItem.js';
import { MdSearch } from "react-icons/md";

import { getEnquiryList } from '../../api/cs/csApi.js';

export const CpoQna = (props) => {
  let navigator = useNavigate();
  const user = useSelector((state) => state.user );
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:11, idx:1}));
  },[])
  // let qnaList = [{
  //   "EnquiryPostId": 1,
  //   "Category": "기타",
  //   "Contents": "기타적인 질문입니다.기타적인 질문입니다.기타적인 질문입니다.기타적인 질문입니다.기타적인 질문입니다.기타적인 질문입니다.\n\n\n\n\n기타적인 질문입니다.기타적인 질문입니다.",
  //   "CallbackEmail": "edeward@gmail.com",
  //   "PostDate": "0001-01-01T00:00:00",
  //   "CallbackDate": "2024-07-08T21:22:26.317261",
  //   "CallbackContents": "문의에 대한 답변을 추가한다."
  // }]
  const [qnaList, setQnaList] = useState([]);
  const getQnaList = async()=>{
    //TODO cpo id를 계정으로 부터 받아서 쿼리에 넣을 예정
    let temp = []
    let result = await getEnquiryList(user.cpoId);
    if(result.status === 200){
      temp = result.data;
    }
    setQnaList(temp);
  }
  
  useEffect(()=>{
    getQnaList();
  },[])


  const tabName = (idx)=>{
    switch (idx) {
      case 0: return"전체";
      case 1: return"충전기 설치";
      case 2: return"충전기 이용";
      case 3: return"충전기 A/S";
      case 4: return"회원계정";
      case 5: return"회원카드";
      case 6: return"요금/결제";
      case 7: return"앱 이용방법";
      case 8: return"기타";
      default: return "전체";
    }
  }
  let tabList = [
    {id:"",name:"충전기 설치"},
    {id:"",name:"충전기 이용"},
    {id:"",name:"충전기 A/S"},
    {id:"",name:"회원계정"},
    {id:"",name:"회원카드"},
    {id:"",name:"요금/결제"},
    {id:"",name:"앱 이용방법"},
    {id:"",name:"기타"},
  ];
  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }

  return (
    <div className='cpo-customer-service-container'>
      <div className="cpo-customer-service-inner notice">
        <div className="cpo-qna-container">
          <div className={"cpo-qna-header "}>
            <div className='cpo-faq-tab-container'>
              <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx == 0 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(0);}}><p>{tabName(0)}</p></div>
              <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx == 1 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(1)}}><p>{tabName(1)}</p></div>
              <div ref={el=>tabBtnRef.current[2]=el} className={tabIdx == 2 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(2)}}><p>{tabName(2)}</p></div>
              <div ref={el=>tabBtnRef.current[3]=el} className={tabIdx == 3 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(3)}}><p>{tabName(3)}</p></div>
              <div ref={el=>tabBtnRef.current[4]=el} className={tabIdx == 4 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(4)}}><p>{tabName(4)}</p></div>
              <div ref={el=>tabBtnRef.current[5]=el} className={tabIdx == 5 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(5)}}><p>{tabName(5)}</p></div>
              <div ref={el=>tabBtnRef.current[6]=el} className={tabIdx == 6 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(6)}}><p>{tabName(6)}</p></div>
              <div ref={el=>tabBtnRef.current[7]=el} className={tabIdx == 7 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(7)}}><p>{tabName(7)}</p></div>
              <div ref={el=>tabBtnRef.current[8]=el} className={tabIdx == 8 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(8)}}><p>{tabName(8)}</p></div>
              <div className={'faq-tab-bar '+'tab'+tabIdx}></div>
            </div>
            <div>
              <div className="cpo-charger-management-keyword-search-container">
                <input type="text" placeholder='키워드로 검색하기' onClick={(e)=>{e.stopPropagation(); }}/>
                <button><MdSearch className='search-icon'/></button>
              </div>
            </div>
          </div>
          <div className="cpo-qna-list-container">
            {
              qnaList.map((it, idx)=>{
                return(<QnaItem key={idx} category={tabName(tabIdx)} data={it} getQnaList={getQnaList}/>);
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoQna;