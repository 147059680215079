import { useState, useEffect} from 'react';
import Selector from '../buttons/Selector.js';

const ChargerDetailTab1 = (props) => {


  // 토글 클릭 여부
  const [isItInOperation, setIsItInOperation] = useState(null);

  // 선택자 클릭 여부
  const [CommonCategory, setCommonCategory] = useState("");
  const [CommonCategoryVal, setCommonCategoryVal] = useState("");
  const [CommonCategoryActive, setCommonCategoryActive] = useState(null);
  const [Protocol, setProtocol] = useState("");
  const [ProtocolVal, setProtocolVal] = useState("");
  const [ProtocolActive, setProtocolActive] = useState(null);

  const [InstallationDate, setInstallationDate] = useState("");
  const [OperationStartDate, setOperationStartDate] = useState("");

  const [AuthenticationKey, setAuthenticationKey] = useState("");
  const [KEPCOCustomerNumber, setKEPCOCustomerNumber] = useState("");
  const [ParentCustomerNumber, setParentCustomerNumber] = useState("");
  const [ChargePointSerialNumber, setChargePointSerialNumber] = useState("");
  const [OpenApiChargerId, setOpenApiChargerId] = useState("");

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.number}_ChargerDetailTab1`);
    if(temp){
      temp = JSON.parse(temp);

      if(temp['expiredTime']){
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_ChargerDetailTab1`);
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_ChargerDetailTab1`);
        return;
      }


      if(temp['CommonCategory']){

        setCommonCategory(JSON.parse(temp['CommonCategory']));
        setCommonCategoryVal(CommonCategoryList.filter(it=>it.name == JSON.parse(temp['CommonCategory']))[0]);
      }
      if(temp['Protocol']){
        setProtocol(JSON.parse(temp['Protocol']));
        setProtocolVal(ProtocolList.filter(it=>it.val == JSON.parse(temp['Protocol']))[0]);
      }
      if(temp['AuthenticationKey'])setAuthenticationKey(JSON.parse(temp['AuthenticationKey']));
      if(temp['KEPCOCustomerNumber'])setKEPCOCustomerNumber(JSON.parse(temp['KEPCOCustomerNumber']));
      if(temp['ParentCustomerNumber'])setParentCustomerNumber(JSON.parse(temp['ParentCustomerNumber']));
      if(temp['ChargePointSerialNumber'])setChargePointSerialNumber(JSON.parse(temp['ChargePointSerialNumber']));
      if(temp['OpenApiChargerId'])setOpenApiChargerId(JSON.parse(temp['OpenApiChargerId']));
      if(temp['InstallationDate'])setInstallationDate(JSON.parse(temp['InstallationDate']));
      if(temp['OperationStartDate'])setOperationStartDate(JSON.parse(temp['OperationStartDate']));
      if(temp['isItInOperation'])setIsItInOperation(temp['isItInOperation']);
    }
  },[])
  useEffect(()=>{
    if(!props.data)return;
    let temp = localStorage.getItem(`${props.number}_ChargerDetailTab1`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        console.log(new Date(temp['expiredTime']) < new Date())
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_ChargerDetailTab1`);
        }
        else{
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_ChargerDetailTab1`);
      }
    }
    setCommonCategory(props.data.CommonCategory);
    setCommonCategoryVal(CommonCategoryList.filter(it=>it.name == props.data.CommonCategory)[0]);
    setProtocol(props.data.Protocol);
    setProtocolVal(ProtocolList.filter(it=>it.val == props.data.Protocol)[0]);
    setAuthenticationKey(props.data.AuthenticationKey);
    setKEPCOCustomerNumber(props.data.KEPCOCustomerNumber);
    setParentCustomerNumber(props.data.ParentCustomerNumber);
    setChargePointSerialNumber(props.data.ChargePointSerialNumber);
    setOpenApiChargerId(props.data.OpenApiChargerId);
    setInstallationDate(props.data.InstallationDate);
    setOperationStartDate(props.data.OperationStartDate);
    setIsItInOperation(props.chas.used);
    },[])

  useEffect(()=>{
    if(!props.open)return;
    if(CommonCategory == "" && Protocol =="" && AuthenticationKey=="" && KEPCOCustomerNumber=="" && ParentCustomerNumber=="" && ChargePointSerialNumber==""
      && OpenApiChargerId=="" 
      && InstallationDate=="" && OperationStartDate=="" && isItInOperation==null)return;
    if(CommonCategory == props.data.CommonCategory && Protocol == props.data.Protocol && AuthenticationKey==props.data.AuthenticationKey && KEPCOCustomerNumber==props.data.KEPCOCustomerNumber && ParentCustomerNumber==props.data.ParentCustomerNumber && ChargePointSerialNumber==props.data.ChargePointSerialNumber
      && OpenApiChargerId==props.data.OpenApiChargerId 
      && InstallationDate==props.data.InstallationDate && OperationStartDate==props.data.OperationStartDate && isItInOperation==props.chas.used)return;
    localStorage.setItem(`${props.number}`+'_ChargerDetailTab1', JSON.stringify( {CommonCategory:JSON.stringify(CommonCategory), Protocol:JSON.stringify(Protocol), AuthenticationKey:JSON.stringify(AuthenticationKey), KEPCOCustomerNumber:JSON.stringify(KEPCOCustomerNumber), ParentCustomerNumber:JSON.stringify(ParentCustomerNumber), ChargePointSerialNumber:JSON.stringify(ChargePointSerialNumber), OpenApiChargerId:JSON.stringify(OpenApiChargerId), InstallationDate:JSON.stringify(InstallationDate), OperationStartDate:JSON.stringify(OperationStartDate), isItInOperation:isItInOperation,  expiredTime: new Date(new Date().getTime() + 60 * 60 * 1000)}))
    props.setSaveBtnStatus(true);
  },[CommonCategory, Protocol, AuthenticationKey, KEPCOCustomerNumber, ParentCustomerNumber, ChargePointSerialNumber, OpenApiChargerId, InstallationDate, OperationStartDate, isItInOperation])
  
  useEffect(()=>{
    if(!ProtocolVal)return;
    setProtocol(ProtocolVal.val);
  },[ProtocolVal])
  
  useEffect(()=>{
    if(!CommonCategoryVal)return;
    setCommonCategory(CommonCategoryVal.name);
  },[CommonCategoryVal])


  const activeReset = ()=>{
    setCommonCategoryActive(false);
    setProtocolActive(false);   
    setProtocolActive(false);
  }

  const CommonCategoryList =[
    {id:'a',name:'완전공용'},
    {id:'b',name:'부분공용'},
    {id:'c',name:'비공용'},
  ];
  const ProtocolList =[
    {name:'OCPP 1.6', val:'OCPP_1_6'},
    // {name:'OCPP 2.0.1', val:'OCPP_2_0_1'},
  ];


  return (
    <div className='charger-detail-tab tab1'>
      <div>
        <div className='charger-detail-tab-selector-container'>
          <p className='charger-input-title'>공용구분</p>
          <Selector dataList={CommonCategoryList} select={CommonCategoryVal} setSelect={setCommonCategoryVal} active={CommonCategoryActive} setActive={setCommonCategoryActive} activeReset={activeReset}/>
        </div>
        <div>
          <p className='charger-input-title'>인증&식별</p>
          <div className="charger-detail-info-input-wrapper">
            <p>인증키</p>
            <div className="charger-detail-info-input-container chargerTab1">
              <input type="text" placeholder='' value={AuthenticationKey} onChange={(e)=>{setAuthenticationKey(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className="charger-detail-info-input-wrapper">
            <p>한전고객번호</p>
            <div className="charger-detail-info-input-container chargerTab1">
              <input type="text" placeholder='' value={KEPCOCustomerNumber} onChange={(e)=>{setKEPCOCustomerNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className="charger-detail-info-input-wrapper">
            <p>모고객번호</p>
            <div className="charger-detail-info-input-container chargerTab1">
              <input type="text" placeholder='' value={ParentCustomerNumber} onChange={(e)=>{setParentCustomerNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className="charger-detail-info-input-wrapper">
            <p>기기일련번호</p>
            <div className="charger-detail-info-input-container chargerTab1">
              <input type="text" placeholder='' value={ChargePointSerialNumber} onChange={(e)=>{setChargePointSerialNumber(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          {/* <div className="charger-detail-info-input-wrapper">
            <p>환경부식별번호</p>
            <div className="charger-detail-info-input-container chargerTab1">
              <input type="text" placeholder='' value={OpenApiChargerId} onChange={(e)=>{setOpenApiChargerId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div> */}

        </div>
      </div>
      <div>
        <div>
          <p className='charger-input-title'>운영정보</p>
          <div className="charger-detail-info-input-wrapper chargerTab1-2">
            <p>설치일</p>
            <div className="charger-detail-info-input-container chargerTab1">
              <input type='date' placeholder='' value={InstallationDate?.split(' ')[0]} max={OperationStartDate?.split(' ')[0]} onChange={(e)=>{setInstallationDate(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div> 
          <div className="charger-detail-info-input-wrapper chargerTab1-2">
            <p>운영시작일</p>
            <div className="charger-detail-info-input-container chargerTab1">
              <input type='date' placeholder='' value={OperationStartDate?.split(' ')[0]} min={InstallationDate?.split(' ')[0]} onChange={(e)=>{setOperationStartDate(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
        </div>
        <div className='station-detail-tab-selector-container occp'>
          <p className='charger-input-title'>OCPP 프로토콜</p>
          <Selector dataList={ProtocolList} select={ProtocolVal} setSelect={setProtocolVal} active={ProtocolActive} setActive={setProtocolActive} activeReset={activeReset}/>
        </div>
        <div>
          <div className='charger-detail-tab-selector-wrapper'>
            <div>
              <p className='charger-input-title'>운영 여부</p>
              <div className={'charger-detail-info-badge '+`${isItInOperation?"":"disabled"}`}>
                <p>{isItInOperation?"운영":"미운영"}</p>
                {/* <Toggle disabled={true} on={'운영'} off={'미운영'} onOff={isItInOperation} setOnOff={setIsItInOperation}/> */}
              </div>
            </div>
            <div className='station-detail-tab-selector-container actionset'>
              <p className='charger-input-title'>연결된 CHAS</p>
              <div className={"charger-detail-info-badge chas " +`${props.chas?.name ?"":"disabled"}`}>
                <p>{props.chas?.actionSetName ?props.chas?.actionSetName:"미설정"}</p>
              </div>
            </div>
          </div>
        </div>
        

        
        
      </div>
    </div>
  )
}
export default ChargerDetailTab1;
