import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoRefreshCircleSharp } from "react-icons/io5";

function ErrorBoundaryWrapper({ children }) {
  useEffect(() => {
    const errorHandler = (event) => {
      if (process.env.REACT_APP_STAGE === "Development") {
        console.error("Error caught by ErrorBoundary:", event.error);
      } else {
        toast(
          <div style={{ position: "relative", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <p style={{margin: "0"}}>예기치 않은 오류가 발생했습니다.</p>
            <IoRefreshCircleSharp onClick={() => window.location.reload()} style={buttonStyle}>
              새로고침
            </IoRefreshCircleSharp>
          </div>,
          {
            type: "error",
            autoClose: false,
            style: { width: "450px" }, // 개별 토스트 스타일 지정
          }
        );
      }
    };

    window.addEventListener("error", errorHandler);
    window.addEventListener("unhandledrejection", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
      window.removeEventListener("unhandledrejection", errorHandler);
    };
  }, []);

  return children;
}

// 버튼 스타일 추가
const buttonStyle = {
  color: "#fff",
  fontSize: "2rem",
  cursor: "pointer",
};

export default ErrorBoundaryWrapper;
