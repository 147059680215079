import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import ChargerDetailTab1 from '../tabs/ChargerDetailTab1.js';
import ChargerDetailTab2 from '../tabs/ChargerDetailTab2.js';
import ChargerDetailTab3 from '../tabs/ChargerDetailTab3.js';
import { FiEdit3 } from "react-icons/fi";
import { FaChevronRight, FaCheck } from "react-icons/fa6";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { getCookie } from '../../util/util.js';

import { getChargerModelList } from '../../api/infra/infraApi.js';

import ChargerSerialNumberEditModal from './ChargerSerialNumberEditModal.js';


const ChargerDetailModal = (props) => {
  // redux
  let signedToken = getCookie("signedToken");

  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  // useEffect(()=>{
  //   console.log(props.data);
  //   console.log(props.chasData);
  // },[])
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }
  // /infra/api/ChargerModels

  const [chargerModelList, setChargerModelList] = useState();
  
  useEffect(()=>{
    reqGetChargerModelList();
  },[])

  const reqGetChargerModelList = async()=>{
    let result = await getChargerModelList();
    if(result.status === 200){
      let temp = [...result.data.Results];
      setChargerModelList(temp);
    }
  }

  function RenderTab({idx}){
    return [ <ChargerDetailTab1 setSaveBtnStatus={setSaveBtnStatus} data={props.data} chas={props.chasData} number={props.data?.DisplaySerialNumber} open={props.detailModalOpen}/>, <ChargerDetailTab2 setSaveBtnStatus={setSaveBtnStatus} modelList={chargerModelList} data={props.data} number={props.data?.DisplaySerialNumber} open={props.detailModalOpen}/>, <ChargerDetailTab3 open={props.detailModalOpen} number={props.data?.DisplaySerialNumber} data={props.data} modelList={chargerModelList}/>][idx]
  }

  const slideEvent = (pre)=>{
    if(pre == -1){
      if(props.clickedCharger == 0) return;
      else props.setClickedCharger(props.clickedCharger-1);
    }
    else{
      if(props.clickedCharger == props.lastIdx) return;
      else props.setClickedCharger(props.clickedCharger+1);
    }
  }

  let save = async()=>{
    let t0 = JSON.parse(localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_DisplaySerialNumber'));
    let t1 = JSON.parse(localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1'));
    let t2 = JSON.parse(localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2'));

    
    // setIsItInOperation(temp['isItInOperation']);

    let data ={
      "displaySerialNumber": t0 ? t0["DisplaySerialNumber"] :props.data?.DisplaySerialNumber,
      "chargingStationId": props.data?.ChargingStation.ChargingStationId,
      "chargerModelId": t2 ? JSON.parse(t2['ChargerModel']):props.data?.ChargerModel ? props.data?.ChargerModel.Id:null,
      "protocol": t1 ? JSON.parse(t1['Protocol']):props.data?.Protocol,
      "chargePointSerialNumber": t1 ? JSON.parse(t1['ChargePointSerialNumber']):props.data?.ChargePointSerialNumber,
      "chargeBoxSerialNumber": props.data?.ChargeBoxSerialNumber,
      "commonCategory": t1 ? JSON.parse(t1['CommonCategory']):props.data?.CommonCategory,
      "installationDate": t1 ? JSON.parse(t1['InstallationDate']):props.data?.InstallationDate,
      "operationStartDate": t1 ? JSON.parse(t1['OperationStartDate']):props.data?.OperationStartDate,
      "authenticationKey": t1 ? JSON.parse(t1['AuthenticationKey']):props.data?.AuthenticationKey,
      "kepcoCustomerNumber": t1 ? JSON.parse(t1['KEPCOCustomerNumber']):props.data?.KEPCOCustomerNumber,
      "parentCustomerNumber": t1 ? JSON.parse(t1['ParentCustomerNumber']):props.data?.ParentCustomerNumber,
      "openApiChargerId": t1 ? JSON.parse(t1['OpenApiChargerId']):props.data?.OpenApiChargerId,
    }


    
    await axios.put(`/infra/api/cpouser/v1/Chargers/${props.data?.ChargerId}`,data,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1');
      localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2');
      
      props.getChargerInfo();
      // props.getChargerHubInfo();
      setSaveBtnStatus(false);
    })
    .catch((err)=>{
      console.log(err);
    })


    // localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1');
    // localStorage.removeItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2');
  }

  const [saveBtnStatus, setSaveBtnStatus] = useState(false);
  useEffect(()=>{

    const storageKey = props.data?.DisplaySerialNumber;
    if(!storageKey) return;

    const tabs = [
      localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_DisplaySerialNumber'),
      localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab1'),
      localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_ChargerDetailTab2')
    ]

    let hasValidTab = false;

    tabs.forEach((t, index) => {
      if (t) {
        let parsedT = JSON.parse(t); // 새로운 변수에 저장
        if (!parsedT['expiredTime'] || new Date(parsedT['expiredTime']) < new Date()) {
          if(index ===0)localStorage.removeItem(`${storageKey}_DisplaySerialNumber`);
          else localStorage.removeItem(`${storageKey}_ChargerDetailTab${index}`);
        } else {
          hasValidTab = true; // 하나라도 유효한 탭이 있으면 true 설정
        }
      }
    });

    setSaveBtnStatus(hasValidTab);
    
  },[props.data])


  const [serialEditModalOpen, setSerialEditModalOpen] = useState(false);

  const [displaySerialNumber, setDisplaySerialNumber] = useState(null);

  useEffect(()=>{
    let temp = localStorage.getItem(`${props.data?.DisplaySerialNumber}`+'_DisplaySerialNumber');
    if(temp){
      setDisplaySerialNumber(temp['DisplaySerialNumber']);
    }
    else{
      setDisplaySerialNumber(props.data?.DisplaySerialNumber);
    }
  },[props.data])

  useEffect(()=>{
    if(props.data?.DisplaySerialNumber && displaySerialNumber && props.data?.DisplaySerialNumber !== displaySerialNumber){
      localStorage.setItem(`${props.data?.DisplaySerialNumber}`+'_DisplaySerialNumber', JSON.stringify({DisplaySerialNumber:displaySerialNumber}));
      setSaveBtnStatus(true);
    }
  },[displaySerialNumber])

  return (
    <div className={props.detailModalOpen ? 'charger-detail-modal-container open': 'charger-detail-modal-container'}>
      {serialEditModalOpen && <ChargerSerialNumberEditModal setOnOff={setSerialEditModalOpen} displaySerialNumber={displaySerialNumber} setDisplaySerialNumber={setDisplaySerialNumber}/>}
      <div className='charger-detail-modal-header'>
        <div className='charger-detail-modal-title-container'>
          <p className='charger-detail-modal-title'>{displaySerialNumber}</p>
          <FiEdit3 className='charger-detail-modal-title-edit-button' onClick={()=>{setSerialEditModalOpen(true)}}/>
        </div>
        <div className={"charger-detail-modal-button-container "}>
          {saveBtnStatus ? <><p className='charger-detail-modal-save-alert'>변경사항을 저장해주세요</p><FaChevronRight/></>: null}
          <div className={'save '+ `${saveBtnStatus ? "":"off"}`} onClick={()=>{save();}}><p>저장</p></div>
          <div className='close' onClick={()=>{props.setDetailModalOpen(false); props.setClickedCharger(null)}}><p>닫기</p></div>
        </div>
        {/* <p className='charger-detail-modal-desc'>Charger Details</p> */}
      </div>
      <div className="tab-button-container">
        <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx == 0 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(0)}}><p>기본</p></div>
        <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx == 1 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(1)}}><p>모델</p></div>
        <div ref={el=>tabBtnRef.current[2]=el} className={tabIdx == 2 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(2)}}><p>QR코드</p></div>
        <div className={'tab-bar '+'tab'+tabIdx}></div>
      </div>
      <div className="tab-content-container">
        <div className="tab-content">
          {
            <RenderTab idx={tabIdx}/>
          }
        </div>
      </div>
      <div className='charger-detail-modal-left-button-container' onClick={(e)=>{e.stopPropagation(); slideEvent(-1)}}>
        <BsChevronCompactLeft/>
      </div>
      <div className='charger-detail-modal-right-button-container' onClick={(e)=>{e.stopPropagation(); slideEvent(1)}}>
        <BsChevronCompactRight/>
      </div>
    </div>
  )
}
export default ChargerDetailModal;