import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import {setNum} from '../../store.js'
import NoticeItem from "../../components/list/NoticeItem.js";


import {getBoardList, getBoard, postBoard, putBoard, deleteBoard} from '../../api/board/boardApi.js';


import NoticeDeleteModal from '../../components/modals/NoticeDeleteModal.js';

export const CpoNotice = () => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:11, idx:2}));
  },[])
  // let noticeList = [{
  //   "NoticePostId": 1,
  //   "Title": "공지입니다.",
  //   "Contents": `공지글`,
  //   "OwnerId": "kassid",
  //   "PostDate": "2024.07.03"
  // }]

  const [noticeList, setNoticeList] = useState([]);

  const reqGetBoardList = async()=>{
    let res = await getBoardList({page:1, size:10});
    setNoticeList(res.data?.posts);
  }




  useEffect(()=>{
    reqGetBoardList();
  }
  ,[])

  const [deleteModalOn, setDeleteModalOn] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  return (
    <div className='cpo-customer-service-container'>
      {deleteModalOn && <NoticeDeleteModal setOnOff={setDeleteModalOn} callback={reqGetBoardList} deleteId={deleteId}/>}
      <div className="cpo-customer-service-inner notice">
        <div className="cpo-notice-container">
          <div className={"cpo-notice-header "}>
            <div className='cpo-notice-append-button' onClick={()=>{navigator('./edit')}}>
              <p>새 공지 등록</p>
            </div>
            <div className='cpo-faq-tab-container'>
              <div className={"faq-tab-button selected"}><p>{'공지'}</p></div>
              <div className={'faq-tab-bar '+'tab0'}></div>
            </div>
          </div>
          <div className="cpo-notice-list-container">
            {
              noticeList&&noticeList.map((it, idx)=>{
                return(<NoticeItem key={idx} data={it} setDeleteModalOn={setDeleteModalOn} setDeleteId={setDeleteId}/>);
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoNotice;