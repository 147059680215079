import axios from 'axios';
import { getCookie, refresh } from '../../util/util';

// ******************************************************************************************
// * moe Cpo
// ******************************************************************************************
// moe Cpo 조회
// /moe/api/v1/cpos/

export const getMoeCpoByCpoId = async(cpoId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/moe/api/v1/cpos/${cpoId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// moe Cpo 등록
export const postMoeCpo = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {cpoId, cpoName, bid, bkey} = data;
  await axios.post(`/moe/api/v1/cpos`, {
    "cpoId": cpoId,
    "cpoName": cpoName,
    "bid": bid,
    "bkey": bkey,
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}