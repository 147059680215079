import axios from 'axios';
import { getCookie, refresh } from '../../util/util';




// 모든 충전소의 기본요금 조회
export const getChargeRateListOfStation = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {page, size, chargingStationName} = data || {};
  let temp;
  await axios.get(`/chargerate/api/cpouser/ChargingStations?Page=${page}&Size=${size}&Deleted=false${chargingStationName ? `&ChargingStationName=${chargingStationName}`:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}
// 충전기 기본요금 조회
export const getChargeRateOfCharger = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/chargerate/api/Chargers/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}
// 충전소의 기본요금 조회
export const getChargeRateOfStation = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/chargerate/api/cpouser/ChargingStations/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cpo의 기본요금 조회
export const getDefaultPricingPlan = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/chargerate/api/cpouser/DefaultPricingPlan`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cpo의 기본요금 수정(PUT)
export const putDefaultPricingPlan = async(plan)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const {pricingType, currency, defaultUnitPrice, unitPrices} = plan;
  let temp;
  await axios.put(`/chargerate/api/cpouser/DefaultPricingPlan`,{
    "pricingType": pricingType,
    "currency": currency,
    "defaultUnitPrice": defaultUnitPrice,
    "unitPrices": unitPrices
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cpo의 기본요금 부분수정(PATCH)
export const patchDefaultPricingPlan = async(plan)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const {pricingType, currency, defaultUnitPrice, unitPrices} = plan;
  let temp;
  await axios.patch(`/chargerate/api/cpouser/DefaultPricingPlan`,{
    "pricingType": pricingType,
    "currency": currency,
    "defaultUnitPrice": defaultUnitPrice,
    "unitPrices": unitPrices
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 단가 이벤트 리스트 조회
export const getAllPricingEvent = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {page, size} = data || {};
  await axios.get(`/chargerate/api/cpouser/PricingEvents?Page=${page?page:1}&${size? `Size=${size}`:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 단가 이벤트 조회
export const getPricingEvent = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/chargerate/api/cpouser/PricingEvents/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 단가 이벤트 추가
export const postPricingEvent = async(event)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const {eventName, memberPrice, nonMemberPrice, startDateTime, endDateTime, stationIds} = event;
  let temp;
  await axios.post(`/chargerate/api/cpouser/PricingEvents`,{
    "eventName": eventName,
    "memberPrice": memberPrice,
    "nonMemberPrice": nonMemberPrice,
    "startDateTime": startDateTime,
    "endDateTime": endDateTime,
    "stationIds": stationIds
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 단가 이벤트 수정(PUT)
export const putPricingEvent = async(id, event)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const {eventName, memberPrice, nonMemberPrice, startDateTime, endDateTime, stationIds} = event;
  let temp;
  await axios.put(`/chargerate/api/cpouser/PricingEvents/${id}`,{
    "eventName": eventName,
    "memberPrice": memberPrice,
    "nonMemberPrice": nonMemberPrice,
    "startDateTime": startDateTime,
    "endDateTime": endDateTime,
    "stationIds": stationIds
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 단가 이벤트 부분 수정(PATCH)
export const patchPricingEvent = async(id, event)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const {eventName, memberPrice, nonMemberPrice, startDateTime, endDateTime, stationIds} = event;
  let temp;
  await axios.patch(`/chargerate/api/cpouser/PricingEvents/${id}`,{
    "eventName": eventName,
    "memberPrice": memberPrice,
    "nonMemberPrice": nonMemberPrice,
    "startDateTime": startDateTime,
    "endDateTime": endDateTime,
    "stationIds": stationIds
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 단가 이벤트 삭제
export const deletePricingEvent = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/chargerate/api/cpouser/PricingEvents/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 충전소 단가정책 조회
export const getPricingPlanOfStation = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/chargerate/api/cpouser/ChargingStations/${id}/PricingPlan`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 충전소 단가정책 수정(PUT)
export const putChargeRateOfStation = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const {chargingStationId, pricingType, currency, defaultUnitPrice, unitPrices} = data;
  let temp;
  await axios.put(`/chargerate/api/cpouser/ChargingStations/${chargingStationId}/PricingPlan`,{
    "pricingType": pricingType,
    "currency": currency,
    "defaultUnitPrice": defaultUnitPrice,
    "unitPrices": unitPrices
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 충전소 단가정책 부분수정(PATCH)
export const patchChargeRateOfStation = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const {chargingStationId, pricingType, currency, defaultUnitPrice, unitPrices} = data;
  let temp;
  await axios.patch(`/chargerate/api/cpouser/ChargingStations/${chargingStationId}/PricingPlan`,{
    "pricingType": pricingType,
    "currency": currency,
    "defaultUnitPrice": defaultUnitPrice,
    "unitPrices": unitPrices
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}