import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { getCookie, signedOut, adjustColor } from '../../util/util.js';
import { useDispatch } from 'react-redux';
import { setNum } from '../../store.js';
import { useSelector } from "react-redux";
export const LabelInitPage = () => {
  let dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  useEffect(()=>{
    dispatch(setNum({id:0}));
  },[])
  let navigator = useNavigate();
  let signedToken = getCookie("signedToken");

  return (
    <div className='label-init-page-container'>
      <div className="label-init-page-title" onClick={()=>{console.log(user)}}>
        <span>C</span><span>h</span><span>a</span><span>r</span><span>g</span><span>i</span><span>n</span><span>g</span>
        <span> </span>
        <span>S</span><span>t</span><span>a</span><span>t</span><span>i</span><span>o</span><span>n</span>
        <span> </span>
        <span>M</span><span>a</span><span>n</span><span>a</span><span>g</span><span>e</span><span>m</span><span>e</span><span>n</span><span>t</span>
        <span> </span>
        <span>S</span><span>y</span><span>s</span><span>t</span><span>e</span><span>m</span>
      </div>

      <div className="label-init-page-logo-container">
        <img className="label-init-page-logo" src={process.env.PUBLIC_URL+process.env.REACT_APP_LOGO}/>
      </div>  
      {signedToken &&<div className='label-init-page-user-info'>
        <span>{user.name}</span><span>님 안녕하세요!</span>
      </div>}
      {signedToken &&<div className='label-init-page-button navigator' onClick={()=>{navigator("/cpo");}}><p>관리페이지</p></div>}
      {
        signedToken ?
        <div className='label-init-page-button logout' onClick={()=>{signedOut();}}><p>로그아웃</p></div>
        :<div className='label-init-page-button login' onClick={()=>{navigator("/login");}}><p>로그인</p></div>
      }
      {
        !signedToken &&
        <div className='label-init-page-button signup' onClick={()=>{navigator("/sign-up");}}><p>시스템 사용자 가입</p></div>
      }
    </div>
  )
}
export default LabelInitPage;




// .btn-grad {background-image: linear-gradient(to right, #ECE9E6 0%, #FFFFFF  51%, #ECE9E6  100%)}
// .btn-grad {
//    margin: 10px;
//    padding: 15px 45px;
//    text-align: center;
//    text-transform: uppercase;
//    transition: 0.5s;
//    background-size: 200% auto;
//    color: white;            
//    box-shadow: 0 0 20px #eee;
//    border-radius: 10px;
//    display: block;
//  }

//  .btn-grad:hover {
//    background-position: right center; /* change the direction of the change here */
//    color: #fff;
//    text-decoration: none;
//  }
