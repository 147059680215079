import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import Toggle from '../buttons/Toggle.js';
import Selector from '../buttons/Selector.js';
import { MdElectricCar } from "react-icons/md";
import { RxSlash } from "react-icons/rx";


const StationDetailTab1 = (props) => {


  // 토글 클릭 여부
  const [IsItInOperation, setIsItInOperation] = useState(null);

  // 선택자 클릭 여부
  const [OperatorName, setOperatorName] = useState("");

  const [OwnerName, setOwnerName] = useState("");
  const [openApiChargingStationId, setOpenApiChargingStationId] = useState("");
  const [FacilityClassification, setFacilityClassification] = useState("");
  const [FacilityClassificationVal, setFacilityClassificationVal] = useState("");
  const [FacilityClassificationActive, setFacilityClassificationActive] = useState(null);
  
  const [FacilitySubdivision, setFacilitySubdivision] = useState("");
  const [FacilitySubdivisionVal, setFacilitySubdivisionVal] = useState("");
  const [FacilitySubdivisionActive, setFacilitySubdivisionActive] = useState(null);

  const [parkingFeeType, setParkingFeeType] = useState("");
  const [parkingFeeTypeVal, setParkingFeeTypeVal] = useState("");
  const [parkingFeeTypeActive, setParkingFeeTypeActive] = useState(null);

  const[StartAvailableTime, setStartAvailableTime]=useState("");
  const[EndAvailableTime, setEndAvailableTime]=useState("");
  const[ParkingFee, setParkingFee]=useState("");
  const[NumberOfParkingSpaces, setNumberOfParkingSpaces]=useState("");

  // 토글 클릭 여부 (관리자 상주 여부)
  const [WhetherTheManagerResides, setWhetherTheManagerResides] = useState(null);

  const activeReset = ()=>{
    setFacilityClassificationActive(false);
    setFacilitySubdivisionActive(false);        
    setParkingFeeTypeActive(false);        
  }

  const facilityClassificationList = [
    {id:'-', name:""},
    {id:'A0', name:"공공시설"},
    {id:'B0', name:"주차시설"},
    {id:'C0', name:"휴게시설"},
    {id:'D0', name:"관광시설"},
    {id:'E0', name:"상업시설"},
    {id:'F0', name:"차량정비시설"},
    {id:'G0', name:"기타시설"},
    {id:'H0', name:"공동주택시설"},
    {id:'I0', name:"근린생활시설"},
    {id:'J0', name:"교육문화시설"},
  ];
  const facilitySubdivisionList = {
    'A0':[
      {id:'01', name:"관공서"},
      {id:'02', name:"주민센터"},
      {id:'03', name:"공공기관"},
      {id:'04', name:"지차체시설"}
    ],
    'B0':[
      {id:'01', name: "공영주차장"},
      {id:'02', name: "공원주차장"},
      {id:'03', name: "환승주차장"},
      {id:'04', name: "일반주차장"},
    ],
    'C0':[
      {id:'01', name:"고속도로 휴게소"},
      {id:'02', name:"지방도로 휴게소"},
      {id:'03', name:"쉼터"},
    ],
    'D0':[
      {id:'01', name:"공원"},
      {id:'02', name:"전시관"},
      {id:'03', name:"민속마을"},
      {id:'04', name:"생태공원"},
      {id:'05', name:"홍보관"},
      {id:'06', name:"관광안내소"},
      {id:'07', name:"관광지"},
      {id:'08', name:"박물관"},
      {id:'09', name:"유적지"},
    ],
    'E0':[
      {id:'01', name:'마트(쇼핑몰)'},
      {id:'02', name:'백화점'},
      {id:'03', name:'숙박시설'},
      {id:'04', name:'골프장'},
      {id:'05', name:'카페'},
      {id:'06', name:'음식점'},
      {id:'07', name:'주유소'},
      {id:'08', name:'영화관'},
    ],
    'F0':[
      {id:"01", name:"서비스센터"},
      {id:"02", name:"정비소"},
    ],
    'G0':[
      {id:"01",name:"군부대"},
      {id:"02",name:"야영장"},
      {id:"03",name:"공중전화부스"},
      {id:"04",name:"기타"},
      {id:"05",name:"오피스텔"},
      {id:"06",name:"단독주택"},
    ],
    'H0':[
      {id:"01", name:"아파트"},
      {id:"02", name:"빌라"},
      {id:"03", name:"사업장(사옥)"},
      {id:"04", name:"기숙사"},
      {id:"05", name:"연립주택"},
    ],
    'I0':[
      {id:"01",name:"병원"},
      {id:"02",name:"종교시설"},
      {id:"03",name:"보건소"},
      {id:"04",name:"경찰서"},
      {id:"05",name:"도서관"},
      {id:"06",name:"복지관"},
      {id:"07",name:"수련원"},
      {id:"08",name:"금융기관"},
    ],
    'J0':[
      {id:"01",name:"학교"},
      {id:"02",name:"교육원"},
      {id:"03",name:"학원"},
      {id:"04",name:"공연장"},
      {id:"05",name:"관람장"},
      {id:"06",name:"동식물원"},
      {id:"07",name:"경기장"},
    ],
  };
  const parkingFeeTypeList = [
    {id:"FREE",name:"무료"},
    {id:"PAID",name:"유료"},
    {id:"CONDITIONAL_PAID",name:"조건무료"},
  ];

  function timeInput(time) {
    time.value = time.value.replace(/[^0-9]/g, '');
    time.value = time.value.slice(0,2);
    if(time.value.length >=2) {
      if(time.value >= 24) {
        time.value = "00";
          return false;
      }
    }
  }

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}_StationDetailTab1`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_StationDetailTab1`);
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_StationDetailTab1`);
        return;
      }

      if(temp['OperatorName'])setOperatorName(JSON.parse(temp['OperatorName']));
      if(temp['OwnerName'])setOwnerName(JSON.parse(temp['OwnerName']));
      if(temp['openApiChargingStationId'])setOpenApiChargingStationId(JSON.parse(temp['openApiChargingStationId']));
      if(temp['FacilityClassification']){
        setFacilityClassification(JSON.parse(temp['FacilityClassification']));
        let classTemp = facilityClassificationList.filter(it=>it.name === JSON.parse(temp['FacilityClassification']))[0]
        setFacilityClassificationVal(classTemp);
        if(temp['FacilitySubdivision']){
          setFacilitySubdivision(JSON.parse(temp['FacilitySubdivision']));
          setFacilitySubdivisionVal(classTemp ? facilitySubdivisionList[classTemp.id]?.filter(it=>it.name === JSON.parse(temp['FacilitySubdivision']))[0] : "");
        }
      }
      if(temp['parkingFeeType']){
        setParkingFeeType(JSON.parse(temp['parkingFeeType']));
        setParkingFeeTypeVal(parkingFeeTypeList.filter(it=>it.id ===JSON.parse(temp['parkingFeeType']))[0]);
      }

      if(temp['StartAvailableTime']) setStartAvailableTime(JSON.parse(temp['StartAvailableTime']));
      if(temp['EndAvailableTime']) setEndAvailableTime(JSON.parse(temp['EndAvailableTime']));
      if(temp['ParkingFee']) setParkingFee(JSON.parse(temp['ParkingFee']));
      if(temp['NumberOfParkingSpaces']) setNumberOfParkingSpaces(JSON.parse(temp['NumberOfParkingSpaces']));
      if(temp['WhetherTheManagerResides']) setWhetherTheManagerResides(temp['WhetherTheManagerResides']);
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}_StationDetailTab1`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        console.log(new Date(temp['expiredTime']) < new Date())
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_StationDetailTab1`);
        }
        else{
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_StationDetailTab1`);
      }
    }
    
    setOperatorName(props.data?.OperatorName);

    setOwnerName(props.data?.OwnerName);
    setOpenApiChargingStationId(props.data?.OpenApiChargingStationId);

    setFacilityClassification(props.data?.FacilityClassification);
    let classTemp = facilityClassificationList.filter(it=>it.name === props.data?.FacilityClassification)[0]
    setFacilityClassificationVal(classTemp);
    setFacilitySubdivision(props.data?.FacilitySubdivision);
    setFacilitySubdivisionVal(classTemp ? facilitySubdivisionList[classTemp.id]?.filter(it=>it.name === props.data?.FacilitySubdivision)[0] : "");
    setParkingFeeType(props.data?.ParkingFeeType);
    setParkingFeeTypeVal(parkingFeeTypeList.filter(it=>it.id ===props.data?.ParkingFeeType)[0]);

    setStartAvailableTime(props.data?.StartAvailableTime);
    setEndAvailableTime(props.data?.EndAvailableTime);
    setParkingFee(props.data?.ParkingFee);
    setNumberOfParkingSpaces(props.data?.NumberOfParkingSpaces);
    setWhetherTheManagerResides(props.data?.WhetherTheManagerResides);

  },[])

  const reset = ()=>{
    setOperatorName(props.data?.OperatorName);

    setOwnerName(props.data?.OwnerName);
    setOpenApiChargingStationId(props.data?.OpenApiChargingStationId);

    setFacilityClassification(props.data?.FacilityClassification);
    let classTemp = facilityClassificationList.filter(it=>it.name === props.data?.FacilityClassification)[0]
    setFacilityClassificationVal(classTemp);
    setFacilitySubdivision(props.data?.FacilitySubdivision);
    setFacilitySubdivisionVal(classTemp ? facilitySubdivisionList[classTemp.id]?.filter(it=>it.name === props.data?.FacilitySubdivision)[0] : "");
    setParkingFeeType(props.data?.ParkingFeeType);
    setParkingFeeTypeVal(parkingFeeTypeList.filter(it=>it.id ===props.data?.ParkingFeeType)[0]);

    setStartAvailableTime(props.data?.StartAvailableTime);
    setEndAvailableTime(props.data?.EndAvailableTime);
    setParkingFee(props.data?.ParkingFee);
    setNumberOfParkingSpaces(props.data?.NumberOfParkingSpaces);
    setWhetherTheManagerResides(props.data?.WhetherTheManagerResides);
  }

  
  
  useEffect(()=>{
    if(!FacilityClassificationVal)return;
    setFacilityClassification(FacilityClassificationVal.name);
  },[FacilityClassificationVal])
  
  useEffect(()=>{
    if(!FacilitySubdivisionVal)return;
    setFacilitySubdivision(FacilitySubdivisionVal.name);
  },[FacilitySubdivisionVal])
  
  useEffect(()=>{
    if(!parkingFeeTypeVal)return;
    setParkingFeeType(parkingFeeTypeVal.id);
  },[parkingFeeTypeVal])


  useEffect(()=>{
    if(!props.open)return;
    if(OperatorName === ''&& OwnerName ==='' && openApiChargingStationId ==='' && FacilityClassification==='' && FacilitySubdivision==='' && parkingFeeType==='' && StartAvailableTime==='' && EndAvailableTime==='' && ParkingFee=='' && NumberOfParkingSpaces=='' && IsItInOperation==null  && WhetherTheManagerResides==null)return;
    if(OperatorName == props.data.OperatorName && OwnerName ==props.data.OwnerName && openApiChargingStationId == props.data.OpenApiChargingStationId  && FacilityClassification==props.data.FacilityClassification && FacilitySubdivision==props.data.FacilitySubdivision && parkingFeeType==props.data.parkingFeeType && StartAvailableTime==props.data.StartAvailableTime && EndAvailableTime==props.data.EndAvailableTime && ParkingFee==props.data.ParkingFee && NumberOfParkingSpaces==props.data.NumberOfParkingSpaces && WhetherTheManagerResides==props.data.WhetherTheManagerResides)return;
    localStorage.setItem(`${props.name}`+'_StationDetailTab1', JSON.stringify( {OperatorName:JSON.stringify(!OperatorName?"":OperatorName), OwnerName:JSON.stringify(!OwnerName?"":OwnerName), openApiChargingStationId:JSON.stringify(!openApiChargingStationId?"":openApiChargingStationId), FacilityClassification:JSON.stringify(!FacilityClassification?"":FacilityClassification), FacilitySubdivision:JSON.stringify(!FacilitySubdivision?"":FacilitySubdivision), parkingFeeType:JSON.stringify(!parkingFeeType?"":parkingFeeType), StartAvailableTime:JSON.stringify(!StartAvailableTime?"":StartAvailableTime), EndAvailableTime:JSON.stringify(!EndAvailableTime?"":EndAvailableTime), ParkingFee:JSON.stringify(!ParkingFee?"":ParkingFee), NumberOfParkingSpaces:JSON.stringify(!NumberOfParkingSpaces?"":NumberOfParkingSpaces), WhetherTheManagerResides:WhetherTheManagerResides, expiredTime: new Date(new Date().getTime() + 60 * 60 * 1000) }))
    props.setSaveBtnStatus(true);
  },[OperatorName, OwnerName, openApiChargingStationId, FacilityClassification, FacilitySubdivision, parkingFeeType, StartAvailableTime, EndAvailableTime, ParkingFee, NumberOfParkingSpaces, WhetherTheManagerResides])


  return (
    <div className='station-detail-tab tab1'>

      <div>
        <div className="station-detail-child-grid-column">
          <div>
            <p className='station-detail-input-title'>충전기 운용 현황</p>
            <div className='station-detail-charger-status-container'>
              <p>{props.chargerHubInfo?.filter(it=>it.used === true).length}</p>
              <p>/</p>
              <p>{props.data?.ChargerIds.length}</p>
            </div>
          </div>
          
        </div>
        <div>
          <div className='station-detail-tab-selector-container marginBottom'>
            <p className='station-detail-input-title'>충전소 구분(대분류)</p>
            <Selector grid={true} dataList={facilityClassificationList} select={FacilityClassificationVal} setSelect={setFacilityClassificationVal} active={FacilityClassificationActive} setActive={setFacilityClassificationActive} activeReset={activeReset}/>
          </div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>충전소 구분 (소분류)</p>
            <Selector grid={true} disabled={FacilityClassification?'':true} dataList={FacilityClassificationVal?.id?facilitySubdivisionList[FacilityClassificationVal.id]:[]} select={FacilitySubdivisionVal} setSelect={setFacilitySubdivisionVal} active={FacilitySubdivisionActive} setActive={setFacilitySubdivisionActive} activeReset={activeReset}/>
          </div>
        </div>
        
        <div>
          <div className='station-detail-child-grid time marginBottom'>
            <div className='station-detail-info-input-wrapper time'>
              <p>개방 시간</p>
              <div className="station-detail-info-input-container time">
                <input type="text" placeholder='HH' onInput={(e)=>{timeInput(e.target)}} value={StartAvailableTime} onChange={(e)=>{setStartAvailableTime(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <p>부터</p>
              <div className="station-detail-info-input-container time">
                <input type="text" placeholder='HH' onInput={(e)=>{timeInput(e.target)}} value={EndAvailableTime} onChange={(e)=>{setEndAvailableTime(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <p>까지</p>
              <p>(24시간 개방 시 00~00 표기)</p>
            </div>
          </div>
          <div className='station-detail-info-input-wrapper toggle'>
            <p className='station-detail-input-title'>관리자 상주 여부</p>
            <div className='station-detail-authorization-tab-toggle-container'>
              <Toggle on={'상주'} off={'비상주'} onOff={WhetherTheManagerResides} setOnOff={setWhetherTheManagerResides}/>
            </div>
          </div>
          
        </div>
      </div>
      <div>
        <div className='row3'>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>운영사</p>
            <div className="station-detail-info-input-container">
              <input type="text" placeholder='' value={OperatorName} onChange={(e)=>{setOperatorName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>소유주</p>
            <div className="station-detail-info-input-container">
              <input type="text" placeholder='' value={OwnerName} onChange={(e)=>{setOwnerName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title' onClick={()=>{console.log(props.roamingStationInfo?.moEChargingStationId)}}>환경부 충전소 아이디</p>
            <div className="station-detail-info-input-container">
              {/* <input type="text" placeholder='' value={openApiChargingStationId} onChange={(e)=>{setOpenApiChargingStationId(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/> */}
              <input type="text" placeholder='' value={props.roamingStationInfo?.moEChargingStationId} onClick={(e)=>{e.stopPropagation(); }} readOnly/>
            </div>
          </div>
        </div>
        <div>
          <div className='station-detail-tab-selector-container'>
            <p className='station-detail-input-title'>주차료 유무</p>
            <Selector dataList={parkingFeeTypeList} select={parkingFeeTypeVal} setSelect={setParkingFeeTypeVal} active={parkingFeeTypeActive} setActive={setParkingFeeTypeActive} activeReset={activeReset}/>
          </div>
          <div className='station-detail-child-grid'>
            <div className='station-detail-info-input-wrapper'>
              <p>주차비</p>
              <div className="station-detail-info-input-container parkingFee">
                <input type="text" placeholder='' value={ParkingFee} onChange={(e)=>{setParkingFee(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>
            <div className='station-detail-info-input-wrapper'>
              <p>주차면 개수</p>
              <div className="station-detail-info-input-container parkingSpace">
                <input type="text" placeholder='' value={NumberOfParkingSpaces} onChange={(e)=>{setNumberOfParkingSpaces(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/> 
              </div>
              <p>개</p>
            </div>
          </div>
        </div>
      </div>
      
      
      

    </div>
  )
}
export default StationDetailTab1;
