import React, { useEffect, useState } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";

import { deleteChas } from '../../api/controlhub/controlhubApi.js';

import { Loading } from '../alerts/Loading.js';
import { useNavigate } from 'react-router-dom';
import { GoArrowUpRight } from "react-icons/go";

export const CustomChasDeleteModal = (props) => {
  const navigate = useNavigate();
  const [loadingOn, setLoadingOn] = useState(false);

  const [usedInfo, setUsedInfo] = useState(false);
  const [usedActionSetList, setUsedActionSetList] = useState([]);

  const reqDeleteChas = async()=>{
    setLoadingOn(true);
    let usedTemp = [];
    for await(const target of props.chasList){
      let result = await deleteChas(target.actionSetId);
      if(result.status === 400 && result.data?.ErrorCode === "ALREADY_USED_ENTITY"){
        usedTemp.push(target);
      }
    }
    if(usedTemp.length > 0){
      setUsedActionSetList(usedTemp);
    }
    else{
      setLoadingOn(false);
      props.setOnOff(false);
      navigate('/cpo/custom-chas');
    }
  }
  useEffect(()=>{
    if(usedActionSetList.length > 0){
      setUsedInfo(true);
    }
  },[usedActionSetList])


  return (
    <div className="custom-chas-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="custom-chas-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="custom-chas-delete-modal-header">
          <title>액션셋 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='custom-chas-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          {
            usedInfo ?
            <>
              <p className='custom-chas-delete-modal-msg' onClick={()=>{console.log(usedActionSetList)}}>다음 액션셋은 사용중이므로 삭제가 불가합니다.</p>
              <div className='custom-chas-delete-modal-list-wrapper'>
                {
                  usedActionSetList?.map((it, idx)=>{
                    return(
                      <>
                        <div className='custom-chas-delete-modal-item'>{it?.name}</div>

                      </>
                    )
                  })
                }
              </div>
            </>
            :
            <>
              <p className='custom-chas-delete-modal-msg'>{props.chasList?.length}개의 액션셋을 삭제하시겠습니까?</p>
              <div className='custom-chas-delete-modal-list-wrapper'>
                {
                  props.chasList?.map((it, idx)=>{
                    return(
                      <>
                        <div className='custom-chas-delete-modal-item'>{it?.name}</div>

                      </>
                    )
                  })
                }
              </div>
            </>
          }
        </div>
        <div>
          {
            usedInfo ?
            <div className={'custom-chas-delete-modal-delete-button navigate'} onClick={()=>{navigate("/cpo/charger-control")}}>
              <p>충전기운용 제어</p>
              <GoArrowUpRight/>
            </div>
            :
            <div className={'custom-chas-delete-modal-delete-button '} onClick={()=>{reqDeleteChas()}}>
              {loadingOn ? <Loading/>:<p>삭제</p>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default CustomChasDeleteModal

