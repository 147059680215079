import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'

import CpoInstallListItem from '../../components/list/CpoInstallListItem.js';


const ChargerCardEventTab1 = (props) => {
  let installCompanyData=[
    {
        "name": "설치업체A",
        "category": "윈치설치",
        "adminInfo": [
            {
                "adminName": "오하윤",
                "role": "관리자",
                "phoneNumber": "010-1084-4306",
                "department": "기술팀"
            },
            {
                "adminName": "이재윤",
                "role": "관리자",
                "phoneNumber": "010-1084-4306",
                "department": "기술팀"
            },
        ]
    },
    {
        "name": "설치업체B",
        "category": "충전기설치",
        "adminInfo": [
            {
                "adminName": "장지우",
                "role": "관리자",
                "phoneNumber": "010-9407-4937",
                "department": "재무팀"
            },
            {
                "adminName": "오지훈",
                "role": "관리자",
                "phoneNumber": "010-8304-4937",
                "department": "재무팀"
            },
            {
                "adminName": "이해준",
                "role": "관리자",
                "phoneNumber": "010-9868-7694",
                "department": "재무팀"
            },
        ]
    },
    {
        "name": "설치업체D",
        "category": "캐노피설치",
        "adminInfo": [
            {
                "adminName": "이도윤",
                "role": "관리자",
                "phoneNumber": "010-1584-9058",
                "department": "기술팀"
            },
            {
                "adminName": "이소훈",
                "role": "관리자",
                "phoneNumber": "010-9404-9058",
                "department": "기술팀"
            }
        ]
    },
    {
        "name": "설치업체E",
        "category": "충전기설치",
        "adminInfo": [
            {
                "adminName": "정태양",
                "role": "관리자",
                "phoneNumber": "010-1569-6642",
                "department": "마케팅팀"
            }
        ]
    }
  ]


  const [deleteMode, setDeleteMode] = useState(false);

  // check한 charger list
  const [checkItemList, setCheckedItemList] = useState([]);



  const deleteItem = async()=>{
    // setDeleteMode(!deleteMode);
  }

  return (
    <div>
      <div className='cpo-install-management-container chargerCardEventTab1'>
        <div className="cpo-install-management-inner">
          <div className="cpo-install-list-container">
            {
              installCompanyData.map((it, idx)=>{
                return(<CpoInstallListItem data={it} idx={idx} deleteMode={deleteMode} checkItemList={checkItemList} setCheckedItemList={setCheckedItemList} setAdminEnrollModalOn={props.setAdminEnrollModalOn}/>)
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChargerCardEventTab1;
