import { useEffect } from 'react';
import '../../styles/BillingKeyPage.css';
function BillingKeyPage(){
  // PC/Mobile 구분하여 도메인 변경 필요
	// PC 환경에서 호출시 https://tpay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=현재일자
	// Mobile 환경에서 호출시 https://tmpay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=현재일자
	
	// 운영 전환 시 도메인 변경
	// PC : tpay.smartropay.co.kr -> pay.smartropay.co.kr
	// Mobile : tmpay.smartropay.co.kr -> mpay.smartropay.co.kr
	
	// script 캐시방지를 위해 ?version=today를 추가  ex) SmartroPAY-1.0.min.js?version=20230101
	useEffect(() => {
		const script = document.createElement('script');
		script.src = `https://tpay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=`;
		script.type = 'text/javascript';
		document.head.appendChild(script);
		return () => {
			document.head.removeChild(script);
		}
	}
	, []);

	// 스마트로 페이 응답은 POST로 응답됩니다.
	// POST 응답을 받을 수 있는 서버 url을 넣어주세요.
	const ReturnUrl = "";

	const goPay = () => {
		window.smartropay.init({
			mode : 'STG', // STG: 테스트, REAL: 운영(운영서버 전환 시 변경 필수!)
			actionUri : '/ssb/interface.do'
		});
		// 스마트로페이 빌링키 발급요청
		window.smartropay.payment({
			FormId : 'tranMgr' // 폼ID
		});
	}

	return(
		<>
			<div className='billing-key-page-container'>
        <h1 className='billing-key-title'>스마트로페이 빌링키 발급</h1>
				<form id='tranMgr' name='tranMgr' method='post' className='billing-form'>
					{/* 각 값들을 가맹점에 맞게 설정해 주세요. */}
          <div>
            <label className='billing-label' htmlFor="PayMethod">결제방식</label>
            <input className='billing-input' type="text" name="PayMethod" defaultValue="CARD" placeholder="" />
          </div>
          <div>
            <label className='billing-label' htmlFor="Mid">Mid</label>
            <input className='billing-input' type="text" name="Mid" maxlength="10" placeholder="" />
          </div>
          <div>
            <label className='billing-label' htmlFor="Moid">Moid</label>
            <input className='billing-input' type="text" name="Moid" maxlength="40" placeholder="특수문자 포함 불가" />
          </div>
          <div>
            <label className='billing-label' htmlFor="MallUserId">MallUserId</label>
            <input className='billing-input' type="text" name="MallUserId" maxlength="20" placeholder="" />
          </div>
          <div>
            <label className='billing-label' htmlFor="MallIp">MallIp</label>
            <input className='billing-input' type="text" name="MallIp" maxlength="20" defaultValue="10.0.0.1" placeholder="" />
          </div>
          <div>
            <label className='billing-label' htmlFor="UserIp">UserIp</label>
            <input className='billing-input' type="text" name="UserIp" maxlength="20" defaultValue="10.0.0.1" placeholder="" />
          </div>
          <div>
            <label className='billing-label' htmlFor="ReturnUrl">ReturnUrl</label>
            <input className='billing-input' type="text" name="ReturnUrl" efaultValue={ ReturnUrl }/>
          </div>
          <div>
            <label className='billing-label' htmlFor="VerifyValue">VerifyValue</label>
            <input className='billing-input' type="text" name="VerifyValue" maxlength="100"  placeholder="위/변조방지 HASH 데이터"/>
          </div>
          <div>
            <label className='billing-label' htmlFor="EncodingType">EncodingType</label>
            <input className='billing-input' type="text" name="EncodingType" maxlength="14" defaultValue="" placeholder="utf-8 or euc-kr" />
          </div>
          <div>
            <label className='billing-label' htmlFor="SspMallId">SspMallId</label>
            <input className='billing-input' type="text" name="SspMallId" maxlength="20"  placeholder="테스트용 SspMallId" />
          </div>
          <div>
            <label className='billing-label' htmlFor="EdiDate">EdiDate</label>
            <input className='billing-input' type="text" name="EdiDate" maxlength="14"  placeholder="" />
          </div>
				</form>

				<button className='billing-button' onClick={goPay}>결제하기</button>
			</div>
		</>
	);
}

export default BillingKeyPage;
