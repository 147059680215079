import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import { useNavigate, useLocation } from "react-router-dom";

import { FaRegTrashAlt, FaChevronLeft } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { getChas, getActions, putActionOfMessageList, getActionOfMessageList } from '../../api/controlhub/controlhubApi.js';
import { FaCheck, FaTimes } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";

import { LoadingList } from '../../components/alerts/LoadingList.js';
import CustomChasActionModal from '../../components/modals/CustomChasActionModal.js';
import CustomChasActionDataTransferModal from '../../components/modals/CustomChasActionDataTransferModal.js';
import DataTransferFunctionModal from '../../components/modals/DataTransferFunctionModal.js';
import DataTransferDeleteModal from '../../components/modals/DataTransferDeleteModal.js';

import CustomChasDeleteModal from '../../components/modals/CustomChasDeleteModal.js';
import ChasInfoEditModal from '../../components/modals/ChasInfoEditModal.js';

const CpoCustomChasEdit = () => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  const location = useLocation();
  // 불러오기인 경우 액션셋 데이터
  const data = location.state?.data;
  // new : 새로만들기, dup: 복제, edit: 수정
  const editMode = location.state?.mode;
  // OCPP 1.6
  const chasOcpp = location.state?.ocpp;
  
  const actionSetId = location.state.actionSetId;
  useEffect(()=>{
    dispatch(setNum({id:12, idx:2, detail:true, postFix:" 편집"}));
  },[])

  const messageTypeList = [
    {name: "Authorize", desc: "인증 요청 메시지", isMandatory: true},
    {name: "BootNotification", desc: "부트 알림 메시지", isMandatory: true},
    {name: "DiagnosticsStatusNotification", desc: "진단 상태 알림 메시지", isMandatory: true},
    {name: "FirmwareStatusNotification", desc: "펌웨어 상태 알림 메시지", isMandatory: true},
    {name: "MeterValues", desc: "미터 값 메시지", isMandatory: true},
    {name: "StartTransaction", desc: "시작 메시지", isMandatory: true},
    {name: "StatusNotification", desc: "상태 알림 메시지", isMandatory: true},
    {name: "StopTransaction", desc: "종료 메시지", isMandatory: true},

    {name: "CancelReservation", desc: "예약 취소 메시지", isMandatory: false},
    {name: "ChangeAvailability", desc: "가용 상태 변경 메시지", isMandatory: false},
    {name: "ChangeConfiguration", desc: "설정 변경 메시지", isMandatory: false},
    {name: "ClearCache", desc: "캐시 삭제 메시지", isMandatory: false},
    {name: "ClearChargingProfile", desc: "충전 프로필 삭제 메시지", isMandatory: false},
    {name: "GetCompositeSchedule", desc: "복합 일정 요청 메시지", isMandatory: false},
    {name: "GetConfiguration", desc: "설정 요청 메시지", isMandatory: false},
    {name: "GetDiagnostics", desc: "진단 데이터 요청 메시지", isMandatory: false},
    {name: "GetLocalListVersion", desc: "로컬 목록 버전 요청 메시지", isMandatory: false},
    {name: "Heartbeat", desc: "하트비트 메시지", isMandatory: false},
    {name: "RemoteStartTransaction", desc: "원격 시작 메시지", isMandatory: false},
    {name: "RemoteStopTransaction", desc: "원격 종료 메시지", isMandatory: false},
    {name: "ReserveNow", desc: "즉시 예약 메시지", isMandatory: false},
    {name: "Reset", desc: "리셋 메시지", isMandatory: false},
    {name: "SendLocalList", desc: "로컬 목록 전송 메시지", isMandatory: false},
    {name: "SetChargingProfile", desc: "충전 프로필 설정 메시지", isMandatory: false},
    {name: "TriggerMessage", desc: "트리거 메시지", isMandatory: false},
    {name: "UnlockConnector", desc: "커넥터 잠금 해제 메시지", isMandatory: false},
    {name: "UpdateFirmware", desc: "펌웨어 업데이트 메시지", isMandatory: false}
  ];


  const [chasInfo, setChasInfo] = useState(null);
  const [actions, setActions] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const [actionListByMessage, setActionListByMessage] = useState(null);
  const [messageLoadingOn, setMessageLoadingOn] = useState(false);
  const reqGetChas = async()=>{
    if(!actionSetId)return;
    let result = await getChas(actionSetId);
    if(result.status === 200){
      setChasInfo(result.data);
    }
  }
  const reqGetActions = async()=>{
    if(!actionSetId)return;
    let result = await getActions();
    if(result.status === 200){
      setActions(result.data);
    }
  }
  const reqGetActionListByMessage = async()=>{
    if(!actionSetId)return;
    setMessageLoadingOn(true);
    let tempList=[];
    for await(const it of messageTypeList){
      let result = await getActionOfMessageList(actionSetId, it.name);
      if(result.status === 200){
        let tempData = {name: it.name, actionList: result.data};
        tempList.push(tempData);
      }
    }
    setActionListByMessage(tempList);
    setMessageLoadingOn(false);
  }


  useEffect(()=>{
    reqGetChas();
  },[])
  useEffect(()=>{
    reqGetActions();
  },[])
  useEffect(()=>{
    reqGetActionListByMessage();
    setSelectedMessage("Authorize")
  },[])
  useEffect(()=>{
    if(!chasInfo)return;
    getDataTransfer();
  },[chasInfo])
  
  

  const [dataTransferMsgNameList, setDataTransferMsgNameList] = useState([])
  const [dataTransferMsgList, setDataTransferMsgList] = useState([])
  const [selectedDataTransferMsg, setSelectedDataTransferMsg] = useState(null);
  useEffect(()=>{
    if(selectedDataTransferMsg === null && dataTransferMsgNameList.length !== 0){
      setSelectedDataTransferMsg(dataTransferMsgNameList[0]);
    }
  },[selectedDataTransferMsg])
  const getDataTransfer = async(deleteRefresh=false)=>{
    let temp = [];
    let temp2 = [];
    for await(const it of chasInfo?.messageControlHubActions){
      if(it.messageType == 'DataTransfer'){
        let msg = it.dataTransferMessageId;
        temp.push(msg);
        temp2.push(it)
      }
    }
    setDataTransferMsgNameList(temp);
    setDataTransferMsgList(temp2);
    if(temp.length !== 0 && selectedDataTransferMsg === null){
      setSelectedDataTransferMsg(temp[0])
    }
    else if(deleteRefresh){
      setSelectedDataTransferMsg(temp[0]);
    }
  }

  
  useEffect(()=>{
    if(chasInfo===null)return;
    // reqGetChas();
  },[chasInfo])

  const [actionModalon, setActionModalOn] = useState(false);
  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }

  const [dataTransferFunctionModalOn, setDataTransferFunctionModalOn] = useState(false);
  const [dataTransferFunctionMode, setDataTransferFunctionMode] = useState(null);
  
  const [dataTransferMsgDeleteModalOn, setDataTransferMsgDeleteModalOn] = useState(false);
  const [dataTransferMsgDeleteTarget, setDataTransferMsgDeleteTarget] = useState(null);

  const [chasInfoEditModalOn, setChasInfoEditModalOn] = useState(false);
  const [deleteModalOn, setDeleteModalOn] = useState(false);
  return (
    <div className='cpo-custom-chas-edit-container'>
      {actionModalon && tabIdx ===0 && <CustomChasActionModal setOnOff={setActionModalOn} reqGetActionListByMessage={reqGetActionListByMessage} actionSetId={actionSetId} actions={actions} message={selectedMessage} description={messageTypeList.filter(it=>it.name===selectedMessage)[0].desc} actionList={actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList}/>}
      {actionModalon && tabIdx ===1 && <CustomChasActionDataTransferModal setOnOff={setActionModalOn} reqGetChas={reqGetChas} actionSetId={actionSetId} actions={actions} message={selectedDataTransferMsg} description={""} actionList={dataTransferMsgList?.filter(item=> item.dataTransferMessageId === selectedDataTransferMsg)}/>}
      {dataTransferFunctionModalOn && tabIdx ===1 && <DataTransferFunctionModal setOnOff={setDataTransferFunctionModalOn} mode={dataTransferFunctionMode} reqGetChas={reqGetChas} dataTransferMsgNameList={dataTransferMsgNameList} actionSetId={actionSetId} actions={actions} actionList={dataTransferMsgList?.filter(item=> item.dataTransferMessageId === selectedDataTransferMsg)}/>}
      {dataTransferMsgDeleteModalOn && <DataTransferDeleteModal setOnOff={setDataTransferMsgDeleteModalOn} actionSetId={actionSetId} messageActionMapId={dataTransferMsgList?.filter(item=> item.dataTransferMessageId === dataTransferMsgDeleteTarget)[0]?.messageActionMapId} dataTransferMessageId={dataTransferMsgDeleteTarget} reqGetChas={reqGetChas} reqGetActionListByMessage={reqGetActionListByMessage} setSelectedDataTransferMsg={setSelectedDataTransferMsg}/>}
      {chasInfoEditModalOn && <ChasInfoEditModal setOnOff={setChasInfoEditModalOn} actionSetId={actionSetId} reqGetChas={reqGetChas} chasName={chasInfo?.name} chasDesc={chasInfo?.description}/>}
      {deleteModalOn && <CustomChasDeleteModal setOnOff={setDeleteModalOn} chasList={[chasInfo]} reqGetChas={reqGetChas}/>}
      <div className='cpo-custom-chas-edit-inner'>
        <div className='cpo-custom-chas-edit-back-button' onClick={()=>{navigator(-1)}}>
          <FaChevronLeft/>
          뒤로가기
        </div>
        <div className="cpo-custom-chas-edit-header">
          <div className='cpo-chas-title-container'>
            <p>{chasInfo?.name}</p>
            <div className='cpo-chas-title-function-container'>
              <div>
                <div className="cpo-chas-title-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setChasInfoEditModalOn(true); }}>
                  <div><FiEdit3/></div>
                </div>
              </div>

              <div>
                <div className="cpo-chas-title-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();
                  // setDeleteModalData({action:chasSelect}); setDeleteModalContent("actionset"); setDeleteModalOn(true)
                  setDeleteModalOn(true);
                }}>
                  <div><FaRegTrashAlt/></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='cpo-chas-description'>{chasInfo?.description}</div>
        <div className='cpo-custom-chas-tab-container'>
          <div className={tabIdx == 0 ? "custom-chas-tab-button selected":"custom-chas-tab-button"} onClick={()=>{tabClick(0);}}><p>일반 메세지</p></div>
          <div className={tabIdx == 1 ? "custom-chas-tab-button selected":"custom-chas-tab-button"} onClick={()=>{tabClick(1);}}><p>DataTransfer 메세지</p></div>
          <div className={'custom-chas-tab-bar '+'tab'+tabIdx}></div>
        </div>

        <div className="cpo-custom-chas-edit-content-container">
          <div className='cpo-custom-chas-edit-left-content'>
            <div className='cpo-custom-chas-edit-content-header'>메세지</div>
            {tabIdx===1&&
              <div className='cpo-custom-chas-edit-message-append-button-container'>
                <div className='cpo-custom-chas-edit-message-append-button' onClick={()=>{setDataTransferFunctionModalOn(true); setDataTransferFunctionMode("append");}}>DataTransfer 메세지 추가</div>
              </div>
            }
            <div className="cpo-custom-chas-edit-content-card-list">
              {messageLoadingOn ? <LoadingList/> :
              <>
                {
                  tabIdx === 0 &&
                  messageTypeList.map((it, idx)=>{
                    return(
                      <div className={`cpo-custom-chas-edit-message-card-container ${selectedMessage === it.name ? "selected":""}`} onClick={()=>{setSelectedMessage(it.name);}}>
                        <div className={`cpo-custom-chas-edit-message-card-status ${it.isMandatory === true && actionListByMessage?.filter(item=> item.name === it.name)[0].actionList.length === 0 ? "invalid":"valid"}`}>
                          <div>
                            {
                              it.isMandatory === true && actionListByMessage?.filter(item=> item.name === it.name)[0].actionList.length === 0 ?
                              <FaTimes/>
                              :
                              <FaCheck/>
                            }
                          </div>
                        </div>
                        <div className='cpo-custom-chas-edit-message-card-content'>
                          <div className={`cpo-custom-chas-edit-message-card-badge ${it.isMandatory === true ? "mandatory":""}`}>
                            {it.isMandatory === true ? "필수":"옵션"}
                          </div>
                          <div className='cpo-custom-chas-edit-message-card-title'>{it.name}</div>
                          <div className='cpo-custom-chas-edit-message-card-description'>{it.desc}</div>
                        </div>
                        
                      </div>
                    )
                  })
                }
                {
                  tabIdx === 1 &&
                  dataTransferMsgNameList.map((it, idx)=>{
                    return(
                      <div className={`cpo-custom-chas-edit-message-card-container ${selectedDataTransferMsg === it ? "selected":""}`} onClick={()=>{setSelectedDataTransferMsg(it);}}>
                        <div className={`cpo-custom-chas-edit-message-card-status ${dataTransferMsgList?.filter(item=> item.dataTransferMessageId === it)[0]?.chControlHubAction ? "valid":"invalid"}`}>
                          <div>
                            {
                              dataTransferMsgList?.filter(item=>item.dataTransferMessageId === it)[0]?.chControlHubAction ?
                              <FaCheck/>
                              :
                              <FaTimes/>
                            }
                          </div>
                        </div>
                        <div className='cpo-custom-chas-edit-message-card-content'>
                          <TfiClose className='cpo-custom-chas-dataTransfer-msg-delete-button' onClick={(e)=>{e.stopPropagation(); setDataTransferMsgDeleteModalOn(true); setDataTransferMsgDeleteTarget(it); console.log(dataTransferMsgList?.filter(item=> item.dataTransferMessageId === it)[0]); console.log(actionSetId);}}/>
                          <div className='cpo-custom-chas-edit-message-card-title'>{it}</div>
                          <div className='cpo-custom-chas-edit-message-card-description'>{dataTransferMsgList?.filter(item=>item.dataTransferMessageId === it)[0]?.description}</div>
                        </div>
                        
                      </div>
                    )
                  })
                }
              </>
              }

            </div>
          </div>
          <div className='cpo-custom-chas-edit-right-content'>
            <div className='cpo-custom-chas-edit-content-header'>액션</div>
            {messageLoadingOn ? <LoadingList/> :
            <>
              {
              tabIdx === 1 && 
                <div className='cpo-custom-chas-edit-content-payload-container'>
                  <div className="cpo-custom-chas-edit-content-payload">
                    PAYLOAD
                  </div>
                </div>
              }
              <div className='cpo-custom-chas-edit-content-action-function'>
                <div className='cpo-custom-chas-edit-content-action-index-container'>
                  <div onClick={()=>{console.log(selectedDataTransferMsg);}}>순서</div>
                  <div>액션</div>
                </div>
                <div className='cpo-custom0chas-edit-content-modal-button' onClick={()=>{setActionModalOn(true)}}>액션편집</div>
              </div>
              <div className="cpo-custom-chas-edit-content-card-list">
              {
              tabIdx === 0 &&actionListByMessage && selectedMessage && actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList?.map((it, idx)=>{
                return(
                  <div className='cpo-custom-chas-edit-action-card-container'>
                    <div className='cpo-custom-chas-edit-action-order'>
                      <div>
                        {it?.order + 1}
                      </div>
                      {actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList.length !== it.order+1 &&<div className='cpo-custom-chas-edit-action-flow-bar'></div>}
                    </div>
                    <div className='cpo-custom-chas-edit-action-card'>
                      <div className='cpo-custom-chas-edit-action-card-title'>{it?.chControlHubAction?.name}</div>
                      <div className='cpo-custom-chas-edit-action-card-description'>{it?.chControlHubAction?.description}</div>
                    </div>
                  </div>
                )
              })
              }
              {
                tabIdx === 1 && dataTransferMsgList && selectedDataTransferMsg && dataTransferMsgList?.filter(item=>item.dataTransferMessageId === selectedDataTransferMsg)?.map((it, idx)=>{
                  return(
                    <div className='cpo-custom-chas-edit-action-card-container'>
                      <div className='cpo-custom-chas-edit-action-order'>
                        <div>
                          1
                        </div>
                      </div>
                      
                      <div className='cpo-custom-chas-edit-action-card'>
                        <div className='cpo-custom-chas-edit-action-card-title'>{it?.chControlHubAction?.name}</div>
                        <div className='cpo-custom-chas-edit-action-card-description'>{it?.chControlHubAction?.description}</div>
                      </div>
                    </div>
                  )
                })
              
              }
              </div>
            </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoCustomChasEdit;