import React from 'react'
import { TfiClose } from "react-icons/tfi";
import { useState, useEffect } from 'react';

import { getStationInfraInfoById } from '../../api/infra/infraApi';
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";
import EventStationItem from '../../components/list/EventStationItem.js';
import { LoadingList } from '../alerts/LoadingList.js';

export const CpoChargingEventStationListModal = (props) => {

  const [stationList, setStationList] = useState([]);
  const [stationCount, setStationCount] = useState(0);
  const reqGetStationInfraInfoById = async()=>{
    let temp = [];
    setLoadingOn(true);
    for await(let station of props.data.chargingStations){
      let result = await getStationInfraInfoById(station.chargingStationId);
      if(result.status === 200){
        temp.push(result.data);
      }
    }
    setStationList(temp);
    setStationCount(temp.length);
    setLoadingOn(false);
  }


  useEffect(()=>{
    reqGetStationInfraInfoById();
  },[])



  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(stationList.length/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  const [loadingOn, setLoadingOn] = useState(false);
  
  return (
    <div className="cpo-charging-event-station-list-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-charging-event-station-list-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>이벤트 대상 충전소 목록</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-charging-event-station-list-modal-content-container'>
          {
            loadingOn?
            <LoadingList height={"500px"}/>
            :
            <div className="cpo-charging-event-station-list">
              <div className="cpo-charging-event-station-list-column">
                <div className='cpo-charging-event-station-list-column-item'><p>권역</p></div>
                <div className='cpo-charging-event-station-list-column-item'><p>충전소명</p></div>
                <div className='cpo-charging-event-station-list-column-item'><p>주소</p></div>
                <div className='cpo-charging-event-station-list-column-item'><p></p></div>
              </div>
              <div className='cpo-charging-event-station-list-content'>
                {
                  [...stationList].slice((pageNum-1)*10, (pageNum-1)*10+9)&&([...stationList].slice((pageNum-1)*10, (pageNum-1)*10+9).map((it,idx)=>{
                    if(it.OpStatus === "삭제됨")return<></>;
                    return(
                      <>
                      <EventStationItem data={it}/>
                      </>
                    // <StationItem key={'stationItem-'+idx} totalCount={chargerInfo[idx]?.Total} chargerHubInfo={chargerHubInfo[idx]} checkStationList={checkStationList} setCheckStationList={setCheckStationList} clickedStation={clickedStation} setClickedStation={setClickedStation} stationRef={stationRef} idx={idx}  data={it} />
                  )
                  }))
                }
              </div>
              <div className='page-selector-container'>
                {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
                {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
                {pageSelectorRendering()}
                {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
                {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
                <div className='page-total-info'>{`Total : ${stationCount}`}</div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default CpoChargingEventStationListModal;