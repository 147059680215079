/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-20
 * @file 찾아줌의 시뮬레이션 결과에서 특정 그리드 결과에 POI요약정보를 보여주는 카드 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useState, useEffect } from 'react';
import { ChipListComponent, ChipsDirective, ChipDirective } from '@syncfusion/ej2-react-buttons';
import { FaBuilding, FaCheckCircle,
    FaHotel, 
    FaHouseUser, 
    FaMountain, 
    FaShoppingBag, 
    FaSchool,
    FaChurch,
    FaLayerGroup,
    FaSuitcaseRolling} from "react-icons/fa"; 
import * as ZoomUtil from '../util/zoomUtil.jsx';

const ZoomSimGridReportPoiSummaryCard = (prop) => {

    const poiInfos = [];
    //console.log('[EMOOV] poi summary:', prop);

    let icon;
    if(prop.cardTitle === '상업시설') {
        icon = <FaShoppingBag/>;
    }
    else if(prop.cardTitle === '숙박시설') {
        icon = <FaHotel/>;
    }
    else if(prop.cardTitle === '관광지') {
        icon = <FaSuitcaseRolling/>;
    }
    else if(prop.cardTitle === '교육시설') {
        icon = <FaSchool/>;
    }
    else if(prop.cardTitle === '생활편의') {
        icon = <FaShoppingBag/>;
    }
    else if(prop.cardTitle === '사업') {
        icon = <FaBuilding/>;
    }
    else if(prop.cardTitle === '야외여가시설') {
        icon = <FaMountain/>;
    }
    else if(prop.cardTitle === '종교시설') {
        icon = <FaChurch/>;
    }
    else if(prop.cardTitle === '주거시설') {
        icon = <FaHouseUser/>;
    }
    else if(prop.cardTitle === '기타시설') {
        icon = <FaLayerGroup/>;
    }
    else {
        icon = <FaCheckCircle/>;
    }

    const rendering = (element) => {
        return `${ZoomUtil.getPoiKeyKorLabel(element.poiName)} ${element.value}`;
    }

    const getColor = (element) => {
        if(element.value > 0) {
            return 'e-primary';
        }
        else {
            return  '';
        }
    }

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return (
        <div style={{marginLeft: '15px', marginBottom: '15px', width: '97%'}} className='e-card'>
            <div style={{display:'flex', 
                flexDirection:'row', 
                alignItems: 'center', 
                marginLeft: '20px', 
                width: 'fit-content', 
                gap: '5px'}}>
                <div>{icon}</div>
                <div className='e-card-title'>{prop.cardTitle}</div>
            </div> 
            {/* <div className='e-card-separator' style={{width: '90%', alignSelf: 'center'}}/> */}
            <div className='e-card-content'>
                <ChipListComponent>
                    <ChipsDirective>
                        {prop.poiSummary.map((poi, index)=>(
                            <ChipDirective text={rendering(poi)} cssClass={getColor(poi)}></ChipDirective>
                        ))}
                    </ChipsDirective>
                </ChipListComponent>
            </div>
        </div>        
    );
}

export default ZoomSimGridReportPoiSummaryCard;