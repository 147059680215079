import React, { useState, useEffect } from 'react';

import SearchSelectorNotCreatable from '../../components/buttons/SearchSelectorNotCreatable.js';

export const FirmwareUpdateListItem = (props) => {
  
  const regionList = [
    {value: 'A(서울, 인천, 경기)', label: 'A(서울, 인천, 경기)', idx:0},
    {value: 'B(충청, 전라)', label: 'B(충청, 전라)', idx:1},
    {value: 'C(강원, 경상)', label: 'C(강원, 경상)', idx:2},
    {value: 'D(제주)', label: 'D(제주)', idx:3},
  ];
  const [firmwareVersionLabelList, setFirmwareVersionLabelList] = useState();
  const [firmwareVersionLabel, setFirmwareVersionLabel] = useState();
  
  useEffect(()=>{
    console.log(firmwareVersionLabel);
  },[firmwareVersionLabel])

  const getVersionInfo = ()=>{
    let temp = [];
    // API 정렬 기능 업데이트 전 임시 코드
    let versionList = props.data?.versions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    for(let i = 0; i < versionList?.length; i++){
      temp.push({value: versionList[i]?.version, label: versionList[i]?.version, data:versionList[i], idx: i});
    }
    setFirmwareVersionLabelList(temp);
  }

  useEffect(()=>{
    getVersionInfo();
  },[props.data])

  useEffect(()=>{
    if(firmwareVersionLabelList?.length > 0)
    setFirmwareVersionLabel(firmwareVersionLabelList[0]);
  },[firmwareVersionLabelList])

  const apply = ()=>{
    props.setSelectedFirmwareInfo({firmware: props.data, version:firmwareVersionLabel?.data});
    props.setStep(1);
    props.setUpdateMode("ftp");
  }

  return (
    <div className={`cpo-firmware-update-list-item `} onClick={()=>{}}>
      <div><p>{props.data?.name}</p></div>
      <div>
        <SearchSelectorNotCreatable options={firmwareVersionLabelList} placeholder="버전"  value={firmwareVersionLabel} setValue={setFirmwareVersionLabel}/>
      </div>
      <div><p>{props.data?.description}</p></div>
      <div>
        <div className={`cpo-firmware-update-list-item-apply-button ${!firmwareVersionLabelList?.length || !firmwareVersionLabel  ? "disabled":""}`}
          onClick={()=>{apply()}}>
          선택하기
        </div>
      </div>
    </div>
  )
}
export default FirmwareUpdateListItem;