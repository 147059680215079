import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate } from "react-router-dom";
import BreakdownItem from '../../components/list/BreakdownItem.js';

import { getBreakdownList, putBreakdownPostStatus, putBreakdown } from '../../api/cs/csApi.js';
import { getCpoId } from '../../util/util.js';

export const CpoBreakdown = () => {
  // redux
  const user = useSelector((state) => state.user);
  let dispatch = useDispatch();

  // let cpoMemberId = user.id;
  const [cpoMemberId, setCpoMemberId] = useState(null);
  useEffect(()=>{
    reqGetCpoId();
  },[])
  let reqGetCpoId = async()=>{
    let result = await getCpoId();
    setCpoMemberId(result);
  }

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:11, idx:3}));
  },[])

  let navigator = useNavigate();

  const [BreakdownList, setBreakdownList] = useState([]);
  
  const reqGetBreakdownList = async(cpoMemberId)=>{
    let result = await getBreakdownList(cpoMemberId);
    if(result.status === 200){
      setBreakdownList([...result.data]);
    }
    else{

    }
  }
  useEffect(()=>{
    reqGetBreakdownList(cpoMemberId);
  },[cpoMemberId])
  return (
    <div className='cpo-breakdown-service-container'>
      <div className="cpo-customer-service-inner breakdown">
        <div className="cpo-breakdown-container">
          <div className={"cpo-breakdown-header "}>
            <div className='cpo-breakdown-tab-container'>
              <div className={"breakdown-tab-button selected"}><p>고장신고</p></div>
              <div className={'breakdown-tab-bar '+'tab0'}></div>
            </div>
          </div>
          {BreakdownList.length > 0 ?
            <div className="cpo-breakdown-list-container">
              {
                BreakdownList?.map((it, idx)=>{
                  return(<BreakdownItem key={idx} data={it} reqGetBreakdownList={reqGetBreakdownList}/>);
                })
              }
            </div>
            :
            <div className="cpo-breakdown-empty-container">
              <p>고장신고 내역이 없습니다.</p>
            </div>
          }
          
        </div>
      </div>
    </div>
  )
}
export default CpoBreakdown;