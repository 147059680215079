/* eslint-disable */
import './App.css';
import { Suspense, lazy } from "react";
import React, { useRef, useState, useEffect } from 'react';
import { ReduxProvider } from './store.js';
import Lottie from "react-lottie";

import MainTemplate from './pages/Default/MainTemplate.js';
import Login from './pages/Default/Login.js';
import SimpleLogin from './pages/Default/SimpleLogin.js';
import SignUp from './pages/Default/SingUp.js';
import AccountVerification from './pages/Default/AccountVerification.js';
import CpoService from './pages/Default/CpoService.js';
import OccpService from './pages/Default/OcppService.js';
import ServicePricing from './pages/Default/ServicePricing.js';
import FindPassword from './pages/Default/FindPassword.js';


import CpoDashboard from './pages/CPO/CpoDashboard.js';
import CpoFastEnroll from './pages/CPO/CpoFastEnroll.js';
import CpoLogReport from './pages/CPO/CpoLogReport.js';
import CpoPayReport from './pages/CPO/CpoPayReport.js';
import CpoStationManagement from './pages/CPO/CpoStationManagement.js';
import CpoSubStationManagement from './pages/CPO/CpoSubStationManagement.js';
import CpoChargerManagement from './pages/CPO/CpoChargerManagement.js';
import CpoInfraConstructorManagement from './pages/CPO/CpoInfraConstructorManagement.js';
import CpoManufacturerManagement from './pages/CPO/CpoManufacturerManagement.js';
import CpoChargerModelManagement from './pages/CPO/CpoChargerModelManagement.js';
import CpoChargerControl from './pages/CPO/CpoChargerControl.js';
import CpoSubChargerControl from './pages/CPO/CpoSubChargerControl.js';
import CpoChargingFeeManagement from './pages/CPO/CpoChargingFeeManagement.js';

import CpoFAQ from './pages/CPO/CpoFAQ.js';
import CpoQna from './pages/CPO/CpoQna.js';
import CpoNotice from './pages/CPO/CpoNotice.js';
import CpoBreakdown from './pages/CPO/CpoBreakdown.js';
import CpoSubscription from './pages/CPO/CpoSubscription.js';
import CpoPaymentMethod from './pages/CPO/CpoPaymentMethod.js';
import CpoBusinessAuthorize from './pages/CPO/CpoBusinessAuthorize.js';
import CpoMemberInfoUpdate from './pages/CPO/CpoMemberInfoUpdate.js';
import CpoMemberWithdraw from './pages/CPO/CpoMemberWithdraw.js';
import CpoGroupManagement from './pages/CPO/CpoGroupManagement.js';
import CpoInvitationManagement from './pages/CPO/CpoInvitationManagement.js';
import CpoNoticeDetail from './pages/CPO/CpoNoticeDetail.js';
import CpoNoticeEdit from './pages/CPO/CpoNoticeEdit.js';
import CpoChargingEventManagement from './pages/CPO/CpoChargingEventManagement.js';
import AlertMsgModal from './components/modals/AlertMsgModal.js';
import CpoEvUserManagement from './pages/CPO/CpoEvUserManagement.js';
/// Added by edeward at 2024.11.26
/// Start Code
import StationZoom from './extensions/zoom/pages/StationZoom.jsx';
import StationZoomStatReport from './extensions/zoom/pages/StationZoomStatReport.jsx';
import StationZoomSimReport from './extensions/zoom/pages/StationZoomSimReport.jsx';
import StationZoomSimGridReport from './extensions/zoom/pages/StationZoomSimGridReport.jsx';
/// End Code
import CpoDashBoardTest from './pages/CPO/CpoDashBoardTest.js';

import CpoSystemChas from './pages/CPO/CpoSystemChas.js';
import CpoCustomChas from './pages/CPO/CpoCustomChas.js';
import CpoCustomChasEdit from './pages/CPO/CpoCustomChasEdit.js';

import SysChasProfileManagement from './pages/CPO/SysChasProfileManagement.js';

import CpoCompanyManagement from './pages/CPO/CpoCompanyManagement.js';

import CpoIncomeReport from './pages/CPO/CpoIncomeReport.js';

import CpoFirmwareManagement from './pages/CPO/CpoFirmwareManagement.js';

import CpoStationChargingFeeManagement from './pages/CPO/CpoStationChargingFeeManagement.js';
import CpoFastChargingFeeManagement from './pages/CPO/CpoFastChargingFeeManagement.js';

import QrNavigator from './pages/Mobile/QrNavigator.js';
import CpoMainPage from './pages/CPO/CpoMainPage';
import InvitationVerification from './pages/Default/InvitationVerification.js';

// Labeling
import LabelMainTemplate from './pages/Label/LabelMainTemplate.js';
import LabelInitPage from './pages/Label/LabelInitPage.js';
import LabelLogin from './pages/Label/LabelLogin.js';
import LabelFastEnroll from './pages/Label/LabelFastEnroll.js';
import LabelSignUp from './pages/Label/LabelSignUp.js';
import LabelMemberInfoUpdate from './pages/Label/LabelMemberInfoUpdate.js';
import LabelMemberWithdraw from './pages/Label/LabelMemberWithdraw.js';
import LabelUserMemberWithdraw from './pages/Label/LabelUserMemberWithdraw.js';
import LabelFindPassword from './pages/Label/LabelFindPassword.js';

import BillingKeyPage from './pages/Default/BillingKeyPage.js';


import { Outlet, RouterProvider,createBrowserRouter, useNavigate } from 'react-router-dom'
import { NavermapsProvider } from 'react-naver-maps';

import animationSplash from './lotties/loading.json'
import animationSplash2 from './lotties/404Animation.json'
import axios from 'axios';
import { getCookie, setCookie, refresh, getCpoId } from './util/util.js';
import ProtectedRoute from './ProtectedRoute.js';
import BlockedRoute from './BlockedRoute.js';
import AlternativeProtectedRoute from './AlternativeProtectedRoute.js';
import ProtectedRouteForSuper from './ProtectedRouteForSuper.js';
import { useSelector } from 'react-redux';

// .env에서 환경 변수 읽어오기
const mainTitle = process.env.REACT_APP_MAIN_TITLE || "Emoov";
const iconUrl = process.env.REACT_APP_FAVICON_URL || `${process.env.PUBLIC_URL}/favicon.ico'`;  // 기본 아이콘 경로 설정

// HTML 문서의 title을 변경
document.title = mainTitle;
// 아이콘 변경
const favicon = document.getElementById('favicon');
if (favicon) {
  favicon.href = iconUrl;
}
let isCpoViewer = false;
let isRootCpo = false;
try {
  const user = useSelector(state => {state.user});
  isCpoViewer = user ? (user?.roles?.includes("CpoViewer")): null;
  let cpoId = await getCpoId();
  if(process.env.REACT_APP_SUPER_ID && cpoId === process.env.REACT_APP_SUPER_ID){
    isRootCpo = true;
  }
} catch (error) {
  
}
function App(props) {
  
  // axios 인스턴스 생성
  const axiosInstance = axios.create();

  // axios.interceptors.response.use(
  //   (res)=>{

  //     return res;

  //   },
  //   async (err)=>{
      
  //     if(err.response.status===401){
  //       console.log(err);
  //       await refresh();
  //     }
  //     return Promise.reject(err);
  //   }
  // )
  const[errModalOn, setErrModalOn] = useState();
  const [errData, setErrData] = useState();

  // 응답 인터셉터에서 401 에러 발생 시 처리
  axios.interceptors.response.use(
    response => response, // 요청 성공 시 그대로 반환
    async (error) => {
      const { config, response } = error;

      // 요청에서 401 에러가 발생한 경우
      if (response && response.status === 401 && !config.__isRetryRequest) {
        // 로그인 시 401 발생
        if(response.request.responseURL.includes("signin")){
          setErrData({"Message": "계정정보가 일치하지 않습니다."});
          setErrModalOn(true);
          return Promise.reject(error)
        }
        config.__isRetryRequest = true;  // 무한 재시도 방지를 위해 플래그 설정
        
        try {
          await refresh();  // 토큰 갱신
          const signedToken =  getCookie('signedToken')

          config.headers.Authorization = `Bearer ${signedToken}`;  // 갱신된 토큰으로 요청 헤더 업데이트
          
          return axiosInstance(config);  // 갱신된 토큰으로 원래 요청을 다시 시도
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
      if(response.request.responseURL.includes("signin") && response.status === 404 ){
        setErrData({"Message": "존재하지 않는 이메일입니다."});
        setErrModalOn(true);
        return Promise.reject(error)
      }
      
      // console.log(error.response.data);
      // setErrData(error.response.data);
      // setErrModalOn(true);
      return Promise.reject(error);  // 401 외의 에러는 그대로 처리
    }
    
  );
  
  return (
    <ReduxProvider>
      <NavermapsProvider ncpClientId="f88olicqny">
        <div className="App">
          <RouterProvider router={router} />
          {errModalOn && <AlertMsgModal setOnOff={setErrModalOn} data={errData} />}
        </div>
      </NavermapsProvider>
    </ReduxProvider>
  );
}

export default App;

const excludeRoute = process.env.REACT_APP_EXCLUDED_PAGE_IDS.replaceAll(" ",'').split(',');

const router = createBrowserRouter([
  {
    element: (
      <Outlet />
    ),
    children: [
      {
        path: "cpo",
        element: (
          <Suspense fallback={<Loading/>}><CpoMainPage></CpoMainPage></Suspense>
        ),
        children: [
          {path: "/cpo",element: <CpoDashboard/>},
          {path: "/cpo/dashboard",element: <CpoDashboard/>},

          (excludeRoute.includes("1")===false) ? {path:'/cpo/fast-enroll' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/fast-enroll' ,element:<CpoFastEnroll/>}
            ]
          }:{},
          (excludeRoute.includes("L3")===false) ? {path:'/cpo/fast-enroll' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/fast-enroll' ,element:<LabelFastEnroll/>}
            ]
          }:{},
          
          {path:'/cpo/station-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/station-management' ,element:<CpoStationManagement/>}
            ]
          },
          
          
          
          
          (excludeRoute.includes("L12")===false) ? {path:'/cpo/sub-station-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'' , element:<ProtectedRouteForSuper/>,
                children:[
                  {path:'' ,element:<CpoSubStationManagement/>}
                ]
              },
            ]
          }:{},
          

          {path:'/cpo/charger-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/charger-management' ,element:<CpoChargerManagement/>}
            ]
          },
          
          
          (excludeRoute.includes("4")===false) ? {path:'/cpo/infra-constructor-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/infra-constructor-management' ,element:<CpoInfraConstructorManagement/>}
            ]
          }:{},
          
          (excludeRoute.includes("20")===false) ? {path:'/cpo/manufacturer-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/manufacturer-management' ,element:<CpoManufacturerManagement/>}
            ]
          }:{},

          {path:'/cpo/charger-model-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/charger-model-management' ,element:<CpoChargerModelManagement/>}
            ]
          },

          {path:'/cpo/log-report' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/authorize/:id' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/message/:id' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/status/:id' ,element:<CpoLogReport/>},
          {path:'/cpo/log-report/error/:id' ,element:<CpoLogReport/>},

          {path:'/cpo/pay-report' ,element:<CpoPayReport/>},
          {path:"/cpo/pay-report/:id", element:<CpoPayReport />},
          
          {path:'/cpo/income-report' ,element:<CpoIncomeReport/>},
          {path:"/cpo/income-report/:id", element:<CpoIncomeReport />},

          {path:'/cpo/charger-control' ,element:<CpoChargerControl/>},

          {path:'/cpo/sub-charger-control' , element:<ProtectedRouteForSuper/>,
            children:[
              {path:'/cpo/sub-charger-control' ,element:<CpoSubChargerControl/>}
            ]
          },



          {path:'/cpo/charging-fee-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/charging-fee-management' ,element:<CpoChargingFeeManagement/>}
            ]
          },
          {path:'/cpo/fast-charging-fee-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/fast-charging-fee-management' ,element:<CpoFastChargingFeeManagement/>}
            ]
          },
          {path:'/cpo/station-charging-fee-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/station-charging-fee-management' ,element:<CpoStationChargingFeeManagement/>}
            ]
          },
          {path:'/cpo/charging-event-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/charging-event-management' ,element:<CpoChargingEventManagement/>}
            ]
          },

          !isCpoViewer &&(excludeRoute.includes("11a")===false) ?{path:'/cpo/faq' ,element:<CpoFAQ/>}: {},
          !isCpoViewer &&(excludeRoute.includes("11b")===false) ?{path:'/cpo/qna' ,element:<CpoQna/>}: {},
          !isCpoViewer &&(excludeRoute.includes("11c")===false) ?{path:'/cpo/notice' ,element:<CpoNotice/>}:{},
          !isCpoViewer &&(excludeRoute.includes("11c")===false) ?{path:"/cpo/notice/detail/:id", element:<CpoNoticeDetail />}:{},
          !isCpoViewer &&(excludeRoute.includes("11c")===false) ?{path:"/cpo/notice/edit", element:<CpoNoticeEdit />}:{},
          !isCpoViewer &&{path:'/cpo/breakdown' ,element:<CpoBreakdown/>},

          (excludeRoute.includes("12a")===false) ? {path:'/cpo/subscription' , element:<ProtectedRoute allowedRoles={["CpoAdmin"]}/>,
            children:[
              {path:'/cpo/subscription' ,element:<CpoSubscription/>}
            ]
          }:{},

          {path:'/cpo/system-chas' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/system-chas' ,element:<CpoSystemChas/>}
            ]
          },
          {path:'/cpo/custom-chas' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/custom-chas' ,element:<CpoCustomChas/>}
            ]
          },
          {path:'/cpo/custom-chas/edit' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/custom-chas/edit' ,element:<CpoCustomChasEdit/>}
            ]
          },
          {path:'/cpo/chas-profile-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/chas-profile-management' ,element:<SysChasProfileManagement/>}
            ]
          },
          (process.env.REACT_APP_STAGE === "Development") ? {path:'/cpo/company-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/company-management' ,element:<CpoCompanyManagement/>}
            ]
          }:{},

          {path:'/cpo/firmware-management' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/firmware-management' ,element:<CpoFirmwareManagement/>}
            ]
          },
          {path:'/cpo/evuser-check' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/evuser-check' ,element:<CpoEvUserManagement/>}
            ]
          },
          
          // /cpo/privacy-policy

          (excludeRoute.includes("13a")===false) ? {path:'/cpo/payment-method' , element:<ProtectedRoute allowedRoles={["CpoAdmin"]}/>,
          children:[
            {path:'' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'' ,element:<CpoPaymentMethod/>}
            ]
            },
          ]}:{},
          
          (excludeRoute.includes("13b")===false) ?{path:'/cpo/business-authorize' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
            children:[
              {path:'/cpo/business-authorize' ,element:<CpoBusinessAuthorize/>}
            ]
          }:{},
          (excludeRoute.includes("13c")===false) ?{path:'/cpo/member-info-update' ,element:<CpoMemberInfoUpdate/>}:{},
          (excludeRoute.includes("L5")===false) ?{path:'/cpo/member-info-update' ,element:<LabelMemberInfoUpdate/>}:{},
          // /cpo/payment-company
          // /cpo/screen-setting

          {path:'/cpo/group-management' ,element: <ProtectedRoute allowedRoles={["CpoAdmin"]}/>
            ,children:[
              {path:'' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
                children:[
                  {path:'' ,element:<CpoGroupManagement/>}
                ]
              },
            ]
          },
          (excludeRoute.includes("18")===false) ?{path:'/cpo/invitation-management', element:<ProtectedRoute allowedRoles={["CpoAdmin"]}/>
            ,children:[
              {path:'' , element:<BlockedRoute allowedRoles={["CpoViewer"]}/>,
                children:[
                  {path:'' ,element:<CpoInvitationManagement/>}
                ]
              },
            ]
          }:{},
          
          
          /// Added by edeward at 2024.11.26
          /// Start Code
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom', element:<StationZoom/>} : {},
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom/stat-report/:id', element:<StationZoomStatReport/>} : {},
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom/sim-report/:id', element:<StationZoomSimReport/>} : {},
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/station-zoom/sim-grid-report/:id/:zscode/:gid', element:<StationZoomSimGridReport/>} : {},
          /// End Code
          (process.env.REACT_APP_STAGE == "Development") ? {path:'/cpo/dashboard-test', element:<CpoDashBoardTest/>} : {}
        ],
      },
      (excludeRoute.includes("E1")===false)&&{
        path: "/",
        element:(
          <>
            <MainTemplate></MainTemplate>
          </>
        ),
        children:[
          {path: "",element:<CpoService/>},
          (excludeRoute.includes("E1")===false) ?{path: "login",element:<Login/>}:{},
          (excludeRoute.includes("E2")===false) ?{path: "simple-login",element:<SimpleLogin/>}:{},
          (excludeRoute.includes("E3")===false) ?{path: "sign-up",element:<SignUp/>}:{},
          {path: "email-verification",element:<AccountVerification/>},
          {path: "invitation-verification",element:<InvitationVerification/>},
          (excludeRoute.includes("13d")===false) ?{path:'delete-account' ,element:<CpoMemberWithdraw/>}:{},
          (excludeRoute.includes("E6")===false) ?{path:'cpo-service' ,element:<CpoService/>}:{},
          (excludeRoute.includes("E7")===false) ?{path:'occp-service' ,element:<OccpService/>}:{},
          (excludeRoute.includes("E8")===false) ?{path:'service-pricing' ,element:<ServicePricing/>}:{},
          (excludeRoute.includes("E9")===false) ?{path:'/find-password' ,element:<FindPassword/>}:{},
        ],
      },
      (excludeRoute.includes("L1")===false)&&{
        path: "/",
        element:(
          <LabelMainTemplate></LabelMainTemplate>
        ),
        children:[
          (excludeRoute.includes("L1")===false) ?{path: "",element:<LabelInitPage/>}:{},
          (excludeRoute.includes("L2")===false) ?{path: "login",element:<LabelLogin/>}:{},
          (excludeRoute.includes("L4")===false) ?{path: "sign-up",element:<LabelSignUp/>}:{},
          {path: "email-verification",element:<AccountVerification/>},
          {path: "invitation-verification",element:<InvitationVerification/>},
          (excludeRoute.includes("L6")===false) ?{path:'/delete-account' ,element:<AlternativeProtectedRoute allowedRoles={["CpoAdmin"]} alternativeRoute={"/delete-user-account"}/>,
          children:[
            {path: "/delete-account",element:<LabelMemberWithdraw/>},
          ]
          }:{},
          {path:'/delete-sub-account' ,element:<ProtectedRoute allowedRoles={["CpoAdmin"]}/>,
          children:[
            {path: "/delete-sub-account",element:<LabelUserMemberWithdraw/>}
          ]
          },
          (excludeRoute.includes("L7")===false) ?{path:'/delete-user-account' ,element:<ProtectedRoute allowedRoles={["CpoUser"]}/>,
          children:[
            {path: "/delete-user-account",element:<LabelUserMemberWithdraw/>}
          ]
          }:{},
          (excludeRoute.includes("L8")===false) ?{path:'/find-password' ,element:<LabelFindPassword/>}:{},
        ],
      },
      {
        path: "app-download",
        element:(
          <QrNavigator/>
        ),
      },
      {
        path: "billing-key",
        element:(
          <BillingKeyPage/>
        ),
      },
      {
        path: "*",
        element:(
          <Animation404/>
        ),
      },
    ],
  },
]);

function Loading() {
    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationSplash,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };	
  return (
    <div class="loading-container">
      <div className='loader'>
        <Lottie options={defaultOptions} />
      </div>
      <h1>Loading...</h1>
    </div>
  )
}
function Animation404() {
  let navigator = useNavigate();
    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationSplash2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };	
  return (
    <div class="loading-container">
      <div className='loader'>
        <Lottie options={defaultOptions} />
      </div>
      <h1>페이지를 찾을 수 없습니다.</h1>
      <div className='goToMain' onClick={()=>{navigator(-1)}}><p>뒤로가기</p></div>
    </div>
  )
}