/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-20
 * @file 찾아줌 충전소 최적위치 시뮬레이션 결과페이지의 요인별 비중 전시 카드 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import * as React from "react";
import { ChipListComponent, ChipsDirective, ChipDirective } from '@syncfusion/ej2-react-buttons';

const ZoomSimReportFactorWeightCard = (prop) => {

    console.log('[EMOOV] Factors: ', prop.factorWeight);
    const factorWeight = prop.factorWeight;

    const factor1Label = `번화가 요인 비중 ${factorWeight.factor1Value}`;
    const factor2Label = `생활편의시설 요인 비중 ${factorWeight.factor2Value}`;
    const factor3Label = `상업 및 공공시설 요인 비중 ${factorWeight.factor3Value}`;
    const factor4Label = `거주 및 생활단지 요인 비중 ${factorWeight.factor4Value}`;
    const factor5Label = `연구단지 및 기업 요인 비중 ${factorWeight.factor5Value}`;
    const factor6Label = `주유소 및 휴게소 요인 비중 ${factorWeight.factor6Value}`;
    const factor7Label = `교외 종교 및 복지시설 요인 비중 ${factorWeight.factor7Value}`;
    const factor8Label = `관광지 및 문화시설 요인 비중 ${factorWeight.factor8Value}`;
    const factor9Label = `야외휴양시설 요인 비중 ${factorWeight.factor9Value}`;
    const factor10Label = `대학교 요인 비중 ${factorWeight.factor10Value}`;
    const factor11Label = `골프장 요인 비중 ${factorWeight.factor11Value}`;

    let factor1ClassName = 'e-info';
    let factor2ClassName = 'e-info';
    let factor3ClassName = 'e-info';
    let factor4ClassName = 'e-info';
    let factor5ClassName = 'e-info';
    let factor6ClassName = 'e-info';
    let factor7ClassName = 'e-info';
    let factor8ClassName = 'e-info';
    let factor9ClassName = 'e-info';
    let factor10ClassName = 'e-info';
    let factor11ClassName = 'e-info';

    if(factorWeight.factor1Value > 1) {
        factor1ClassName = 'e-primary';
    }
    if(factorWeight.factor2Value > 1) {
        factor2ClassName = 'e-primary';
    }
    if(factorWeight.factor3Value > 1) {
        factor3ClassName = 'e-primary';
    }
    if(factorWeight.factor4Value > 1) {
        factor4ClassName = 'e-primary';
    }
    if(factorWeight.factor5Value > 1) {
        factor5ClassName = 'e-primary';
    }
    if(factorWeight.factor6Value > 1) {
        factor6ClassName = 'e-primary';
    }
    if(factorWeight.factor7Value > 1) {
        factor7ClassName = 'e-primary';
    }
    if(factorWeight.factor8Value > 1) {
        factor8ClassName = 'e-primary';
    }
    if(factorWeight.factor9Value > 1) {
        factor9ClassName = 'e-primary';
    }
    if(factorWeight.factor10Value > 1) {
        factor10ClassName = 'e-primary';
    }
    if(factorWeight.factor11Value > 1) {
        factor11ClassName = 'e-primary';
    }

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return (
        <div style={{marginLeft: '15px', marginBottom: '15px', width: '97%'}} 
            className='e-card'>
            <div className='e-card-content'>
                <ChipListComponent>
                    <ChipsDirective>
                        <ChipDirective text={factor1Label} cssClass={factor1ClassName}/>
                        <ChipDirective text={factor2Label} cssClass={factor2ClassName}/>
                        <ChipDirective text={factor3Label} cssClass={factor3ClassName}/>
                        <ChipDirective text={factor4Label} cssClass={factor4ClassName}/>
                        <ChipDirective text={factor5Label} cssClass={factor5ClassName}/>
                        <ChipDirective text={factor6Label} cssClass={factor6ClassName}/>
                        <ChipDirective text={factor7Label} cssClass={factor7ClassName}/>
                        <ChipDirective text={factor8Label} cssClass={factor8ClassName}/>
                        <ChipDirective text={factor9Label} cssClass={factor9ClassName}/>
                        <ChipDirective text={factor10Label} cssClass={factor10ClassName}/>
                        <ChipDirective text={factor11Label} cssClass={factor11ClassName}/>
                    </ChipsDirective>
                </ChipListComponent>
            </div>
        </div>        
    );
}

export default ZoomSimReportFactorWeightCard;