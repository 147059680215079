import React, { useState, useEffect } from 'react';
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';

import { postFirmwareCpoUser, putFirmwareCpoUser } from '../../api/controlhub/controlhubApi.js';

export const CpoFirmwareEnrollModal = (props) => {

  const [firmwareName, setFirmwareName] = useState("");
  const [firmwareDesc, setFirmwareDesc] = useState("");

  const [loadingOn, setLoadingOn] = useState(false);

  const reqPostFirmwareCpoUser = async()=>{
    setLoadingOn(true);
    let res = await postFirmwareCpoUser({
      name: firmwareName,
      description: firmwareDesc
    });
    if(res.status === 201 || res.status === 200){
      await props.reqGetFirmwareListCpoUser();
      setLoadingOn(false);
      props.setOnOff(false);
      props.setMode(null);
    }
    else{
      setLoadingOn(false);
    }
  }
  const reqPutFirmwareCpoUser = async()=>{
    setLoadingOn(true);
    let res = await putFirmwareCpoUser(props.selectedFirmwareData.firmwareId, {
      name: firmwareName,
      description: firmwareDesc
    });
    if(res.status === 200){
      await props.reqGetFirmwareListCpoUser();
      setLoadingOn(false);
      props.setOnOff(false);
      props.setMode(null);
    }
    else{
      setLoadingOn(false);
    }
  }

  useEffect(()=>{
    if(props.selectedFirmwareData){
      setFirmwareName(props.selectedFirmwareData?.name);
      setFirmwareDesc(props.selectedFirmwareData?.description);
    }
  },[])

  return (
    <div className='cpo-firmware-enroll-modal-bg'>
      <div className="cpo-firmware-enroll-modal-container">
        <div className="modal-header">
          <title>{props.mode==="enroll" ? "펌웨어 등록" : "펌웨어 수정"}</title>
          <div className='modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false); props.setMode(null);}}><TfiClose/></div>
        </div>
        <div className='cpo-firmware-enroll-modal-content-container'>
          <p>펌웨어 이름</p>
          <div className='cpo-firmware-enroll-modal-input-container'>
            <input type="text" placeholder='펌웨어의 이름을 입력해주세요' value={firmwareName} onChange={(e)=>{setFirmwareName(e.target.value);}}/>
          </div>
          <p>설명</p>
          <div className='cpo-firmware-enroll-modal-input-container textarea'>
            <textarea type="text" placeholder='설명을 입력해주세요' value={firmwareDesc} onChange={(e)=>{setFirmwareDesc(e.target.value);}} />
          </div>
        </div>
        <div className='cpo-firmware-enroll-modal-button-container'>
          <div className={`cpo-firmware-enroll-modal-button ${firmwareName === "" || firmwareDesc === "" ? "disable":""}`} onClick={()=>{
            props.mode==="enroll" ? reqPostFirmwareCpoUser() : reqPutFirmwareCpoUser();
          }}>
            {loadingOn ? <Loading/> :<p>{props.mode==="enroll" ? `등록하기` : "수정하기"}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoFirmwareEnrollModal;