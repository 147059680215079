import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { setCookie, removeCookie, getCookie, getCpoId } from '../../util/util';

import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { FaChevronLeft } from 'react-icons/fa';
import CloudAlertRight from '../../components/alerts/CloudAlertRight';
import { setUser } from '../../store';
import { useDispatch } from 'react-redux';

export const LabelLogin = () => {
  const dispatch = useDispatch();
  let navigator = useNavigate();
  let signedToken = getCookie("signedToken");
  useEffect(()=>{
    if(signedToken){
      navigator('/cpo')
    }
  },[])
  
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const requestLogin = async()=>{
    if(!email||!password){
      if(!email){
        setEmailValidate(false);
      }
      if(!password){
        setPasswordValidate(false);
      }
      return;
    }
    if(!emailPatternValidate){
      return;
    }
    await axios.post("/account/api/NearCpoAuth/signin", 
    {
      "email": email,
      "password": password,
      "rememberMe": true
    },{withCredentials : true})
    .then(async(res)=>{
      setCookie('signedToken', res.data.SignedToken, {
        path: '/',
        expires: new Date(res.data.ExpiryTime)
      });
      let cpoId =  await getCpoId();
      dispatch(setUser({id:res.data.SystemId, cpoId:cpoId, name:res.data.LocalRealName, email:email, roles:[...res.data.Roles]}));
      localStorage.setItem('LocalRealName',res.data.LocalRealName)
      localStorage.setItem('user-email',email)
      localStorage.setItem('user-roles',[...res.data.Roles])
      /// Modified by edeward, 2024-12-12
      /// Start
      localStorage.setItem('user-systemId',res.data.SystemId);
      /// End
    })
    .then((res)=>{
      navigator('/cpo')
      }
    )
    .catch((err)=>{

      // 이메일 미인증
      if(err.response?.data.ErrorCode == "EMAIL_VERIFICATION_REQUIRED"){
        navigator("/email-verification", {state:{email:email}})
      }
      // if(err.response?.status == 401){
        
      // }
      // if(err.response?.status == 404){
        
      // }
    })
  }



  // Validation

  // 이메일 빈칸
  const [emailValidate,setEmailValidate] = useState();
  // 비밀번호 빈칸
  const [passwordValidate,setPasswordValidate] = useState();

  // 이메일 패턴 검사
  const [emailPatternValidate, setEmailPatternValidate] = useState(null); // false : 유효하지않은 이메일 형식, true: 이메일 형식




  // Validation Pattern
  const emailInput = (target)=>{
    const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
    if(pattern.test(target.value) === false) {setEmailPatternValidate(false); }
    else { setEmailPatternValidate(true); }
  }

  // 자음 및 모음 매핑 (키와 값이 바뀜)
  const keyMap = {
    'ㅂ': 'q', 'ㅈ': 'w', 'ㄷ': 'e', 'ㄱ': 'r', 'ㅅ': 't',
    'ㅛ': 'y', 'ㅕ': 'u', 'ㅑ': 'i', 'ㅐ': 'o', 'ㅔ': 'p',
    'ㅁ': 'a', 'ㄴ': 's', 'ㅇ': 'd', 'ㄹ': 'f', 'ㅎ': 'g',
    'ㅗ': 'h', 'ㅓ': 'j', 'ㅏ': 'k', 'ㅣ': 'l', 'ㅋ': 'z',
    'ㅌ': 'x', 'ㅊ': 'c', 'ㅍ': 'v', 'ㅠ': 'b', 'ㅜ': 'n', 'ㅡ': 'm',
  };

  const doubleConsonantsMap = {
    'ㅃ': 'Q', 'ㅉ': 'W', 'ㄸ': 'E', 'ㄲ': 'R', 'ㅆ': 'T', 'ㅒ': 'O', 'ㅖ': 'P',
  };

  function convertKoreanToEnglish(input, isLastCharUpper) {
    let result = '';

    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const mappedChar = keyMap[char] || doubleConsonantsMap[char] || char;

      // 마지막 글자만 대문자로 변환
      if (i === input.length - 1 && isLastCharUpper) {
        result += mappedChar.toUpperCase();
      } else {
        result += mappedChar;
      }
    }

    return result;
  }

  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const handleKeyDown = (event) => {
    if (event.shiftKey) {
      setIsShiftPressed(true);
    }
  };

  const handleKeyUp = () => {
    setIsShiftPressed(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // 한글 초성 또는 중성을 영어로 변환
    const convertedValue = convertKoreanToEnglish(inputValue, isShiftPressed);
    setPassword(convertedValue);
  };

  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])
  return (
    <div className='login-page-container label'>
      <FaChevronLeft className='go-to-initial-button' onClick={()=>{navigator("/");}}/>
      <div className="login-page-wrapper">
        <title>로그인</title>
        <p className="login-input-title">이메일</p>
        <div className='login-input-container email'>
          <input placeholder='이메일을 입력하세요' type="email" 
          autocomplete="email"
          value={email} onInput={(e)=>{setEmailValidate(true); emailInput(e.target);}} onChange={(e)=>{setEmail(e.target.value)}}/>
        </div>
        {emailValidate===false&&<p className='login-input-validation'>이메일을 입력하세요.</p>}
        {emailPatternValidate===false&&<p className='login-input-validation'>이메일 형식을 입력해주세요.</p>}
        <p className="login-input-title">비밀번호</p>
        <div className='login-input-container password'><input placeholder='비밀번호를 입력하세요'
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        type={passwordVisible ? "text" : "password"} 
        // autocomplete="new-password"
        value={password} onChange={(e)=>{handleInputChange(e); }}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div>
        {passwordValidate===false&&<p className='login-input-validation'>비밀번호를 입력해주세요.</p>}
        <div className='login-function-container'>
          <div className='login-checkbox-container'>
            
          </div>
          <div className='login-found-account-container' onClick={(e)=>{navigator("/find-password")}}>
            <p>이메일/비밀번호찾기</p>
          </div>
        </div>
        <div className={'login-button '+`${!email || !password || emailPatternValidate===false ? "none":""}`} onClick={(e)=>{e.stopPropagation();e.preventDefault(); requestLogin();}}><p>로그인</p></div>
      </div>
    </div>
  )
}
export default LabelLogin;