/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2025-01-27
 * @file 선택한 그리드의 군집유형에 대한 지역의 군집별 비교표 제공 카드 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useState, useEffect } from 'react';
import { PivotViewComponent, showGrandTotals, showSubTotals } from '@syncfusion/ej2-react-pivotview';
import * as ZoomApi from '../api/zoomApi.jsx';  

const ClusterComparisionCard = (prop) => {
    
    // 군집비교 데이터 
    const [clusterComparisionData, setClusterComparisionData] = useState([]);


    const settings = {
        dataSource: clusterComparisionData,
        rows: [
            {name: 'groupName'},
            {name: 'itemName'}
        ],
        columns: [
            {name: 'target', caption: '비교대상'}
        ],
        values: [
            {name: 'target1Value', caption: '시군구의 같은특성지역'},
            {name: 'target2Value', caption: '시군구의 모든지역'},
            {name: 'target3Value', caption: '시도'},
        ],
        formatSettings:[
            {name: 'target1Value', format: '###0.0# %'},
            {name: 'target2Value', format: '###0.0# %'},
            {name: 'target3Value', format: '###0.0# %'}
        ],
        filters: [],
        expandAll: true,
        showExpandAll: true,
        showGrandTotals: false,
        showSubTotals: false,
    };

    /********************************************************/
    /* EFFECT SECTION                                       */
    /********************************************************/
    useEffect(() => {
        /// 선택한 지역의 클러스트 유형에 대한 비교표를 가져온다.
        getClusterComparisionTableAsync();
    },[]);    

    const getClusterComparisionTableAsync = async() => {
        let rawData = await ZoomApi.getClusterComparisionList(prop.zscode, prop.clusterType);
        console.log('[EMOOV] 비교데이터:', rawData);
        const data = [];

        const grade1 = {
            'groupName': 'AI추론 등급비율',
            'itemName': '1등급비율',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].grade12,
            'target2Value': rawData.conditionB[0].grade12,
            'target3Value': rawData.conditionC[0].grade12
        };
        const grade2 = {
            'groupName': 'AI추론 등급비율',
            'itemName': '2등급비율',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].grade34,
            'target2Value': rawData.conditionB[0].grade34,
            'target3Value': rawData.conditionC[0].grade34
        };
        const grade3 = {
            'groupName': 'AI추론 등급비율',
            'itemName': '3등급비율',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].grade56,
            'target2Value': rawData.conditionB[0].grade56,
            'target3Value': rawData.conditionC[0].grade56
        };
        const grade4 = {
            'groupName': 'AI추론 등급비율',
            'itemName': '4등급비율',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].grade78,
            'target2Value': rawData.conditionB[0].grade78,
            'target3Value': rawData.conditionC[0].grade78
        };
        const grade5 = {
            'groupName': 'AI추론 등급비율',
            'itemName': '5등급비율',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].grade910,
            'target2Value': rawData.conditionB[0].grade910,
            'target3Value': rawData.conditionC[0].grade910
        };
        const popul1500 = {
            'groupName': '평균 인구비율',
            'itemName': '1500명이상',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].popul1500,
            'target2Value': rawData.conditionB[0].popul1500,
            'target3Value': rawData.conditionC[0].popul1500
        };
        const popul1000 = {
            'groupName': '평균 인구비율',
            'itemName': '1000명이상',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].popul1000,
            'target2Value': rawData.conditionB[0].popul1000,
            'target3Value': rawData.conditionC[0].popul1000
        };
        const popul500 = {
            'groupName': '평균 인구비율',
            'itemName': '500명이상',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].popul500,
            'target2Value': rawData.conditionB[0].popul500,
            'target3Value': rawData.conditionC[0].popul500
        };
        const popul1 = {
            'groupName': '평균 인구비율',
            'itemName': '500명이하',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].popul1,
            'target2Value': rawData.conditionB[0].popul1,
            'target3Value': rawData.conditionC[0].popul1
        };
        const popul0 = {
            'groupName': '평균 인구비율',
            'itemName': '0명',
            'target': '비교군',
            'target1Value': rawData.conditionA[0].popul0,
            'target2Value': rawData.conditionB[0].popul0,
            'target3Value': rawData.conditionC[0].popul0
        };
        data.push(grade1);
        data.push(grade2);
        data.push(grade3);
        data.push(grade4);
        data.push(grade5);
        data.push(popul1500);
        data.push(popul1000);
        data.push(popul500);
        data.push(popul1);
        data.push(popul0);

        setClusterComparisionData(data);
    }    

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return (
        <div style={{marginLeft: '15px', marginBottom: '15px', width: '97%', height: '320px', alignItems: 'top'}} className='e-card'>
            <div className='e-card-content'>
                <PivotViewComponent 
                    dataSourceSettings={settings}
                    height={300}>
                </PivotViewComponent>
            </div>
        </div>   
    );
}

export default ClusterComparisionCard;
