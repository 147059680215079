import axios from 'axios';
import { getCookie, refresh } from '../../util/util';

// SubscriptionsCpoAdmin
// cpoadmin 구독 리스트 조회
export const getSubscriptionList = async()=>{
  let signedToken = getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken = getCookie('signedToken');}
  let temp;
  await axios.get(`/subscription/api/cpoadmin/Subscriptions`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cpoadmin 구독 가능한 리스트 조회
export const getSubscriptionProductList = async()=>{
  let signedToken = getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken = getCookie('signedToken');}
  let temp;
  await axios.get(`/subscription/api/cpoadmin/SubscriptionProducts`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cpoadmin 구독 추가
export const postSubscription = async(data)=>{
  let signedToken = getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken = getCookie('signedToken');}
  let temp;
  let {subscriptionProductId, billingType, paymentCardId} = data;
  await axios.post(`/subscription/api/cpoadmin/Subscriptions`,{
    "subscriptionProductId": subscriptionProductId,
    "billingType": billingType,
    "paymentCardId": paymentCardId,
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cpoadmin 구독 연장(Renew)
export const putSubscriptionRenew = async(subscriptionId)=>{
  let signedToken = getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken = getCookie('signedToken');}
  let temp;
  await axios.put(`/subscription/api/cpoadmin/Subscriptions/${subscriptionId}/Renew`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}
// cpoadmin 구독 취소
export const putSubscriptionCancel = async(subscriptionId)=>{
  let signedToken = getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken = getCookie('signedToken');}
  let temp;
  await axios.put(`/subscription/api/cpoadmin/Subscriptions/${subscriptionId}/Cancel`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}
// cpoadmin 구독 재개(Resume)
export const putSubscriptionResume = async(subscriptionId)=>{
  let signedToken = getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken = getCookie('signedToken');}
  let temp;
  await axios.put(`/subscription/api/cpoadmin/Subscriptions/${subscriptionId}/Resume`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}


// cpo 관리가능 충전기 개수 조회
export const getChargerLimit = async()=>{  
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/subscription/api/cpouser/Subscriptions/ChargerLimit`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}


// 현재 구독 정보 조회
export const getSubscription = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/subscription/api/cpouser/Subscriptions`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch(()=>{})
  return temp;
}