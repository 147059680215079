import React, { useState, useEffect } from 'react';
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';

import { putActionOfMessageList } from '../../api/controlhub/controlhubApi.js';

export const CustomChasActionModal = (props) => {

  const [selectedActionList, setSelectedActionList] = useState(null);

  useEffect(()=>{
    // controlHubActionId
  },[selectedActionList])

  useEffect(()=>{
    initialActionList();
  },[])

  const initialActionList = async()=>{
    let temp = [];
    for await(const item of props.actionList){
      let target = props.actions.filter(it=>it.controlHubActionId === item.chControlHubAction.controlHubActionId)[0]
      temp.push(target);
    }
    setSelectedActionList(temp);
  }

  const clickCard = (id)=>{
    let target = props.actions.filter(it=>it.controlHubActionId === id)[0];
    if(selectedActionList.filter(it=> it.controlHubActionId===target.controlHubActionId).length === 0){
      setSelectedActionList([...selectedActionList, target]);
    }
    else{
      setSelectedActionList([...selectedActionList].filter(it=>it.controlHubActionId !== target.controlHubActionId))
    }
  }
  const deleteCard = (idx)=>{
    let temp = [...selectedActionList];
    temp.splice(idx,1);
    setSelectedActionList(temp);
  }

  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const reqPutActionOfMessageList = async()=>{
    setLoadingOn(true);
    let temp = [];
    for await(const item of selectedActionList){
      temp.push(item.controlHubActionId);
    }
    let reqObj = {
      controlHubActionInfoIds: temp
    }
    let result = await putActionOfMessageList(props.actionSetId, props.message, reqObj);

    if(result.status === 200 || result.status === 201){
      await props.reqGetActionListByMessage();
      props.setOnOff(false);
    }
    else{
      setErrMsg("저장에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }

  return (
    <div className="cpo-custom-chas-action-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-custom-chas-action-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>일반 메세지 액션 편집</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-custom-chas-action-modal-content'>
          <div className='cpo-custom-chas-action-modal-content-header'>
            <div className='cpo-custom-chas-action-modal-info'>
              <div>{props.message}</div>
              <div>{props.description}</div>
            </div>
            <div className='cpo-custom-chas-action-modal-save-button' onClick={()=>{reqPutActionOfMessageList()}}>{loadingOn ? <Loading/> : "저장"}</div>
            {errMsg && <p>{errMsg}</p>}
          </div>

          <div className="cpo-custom-chas-edit-content-container">
          <div className='cpo-custom-chas-edit-left-content'>
            <div className='cpo-custom-chas-edit-content-header'>액션 목록</div>
            <div className='cpo-custom-chas-edit-content-card-list'>
            {
              props.actions && selectedActionList && props.actions.map((it, idx)=>{
                return(
                  <div className={`cpo-custom-chas-action-modal-card ${selectedActionList?.filter(item=>item?.controlHubActionId === it.controlHubActionId).length!==0?"selected":""}`}
                  onClick={()=>{clickCard(it.controlHubActionId)}}>
                    <div className='cpo-custom-chas-action-modal-card-title'>{it?.name}</div>
                    <div className='cpo-custom-chas-action-modal-card-description'>{it?.description}</div>
                  </div>
                )
              })
            }
            </div>
          </div>
          <div className='cpo-custom-chas-edit-right-content'>
            <div className='cpo-custom-chas-edit-content-header'>선택된 액션</div>
            <div className='cpo-custom-chas-edit-content-action-function'>
              <div className='cpo-custom-chas-edit-content-action-index-container'>
                <div>순서</div>
                <div>액션</div>
              </div>
            </div>
            <div className="cpo-custom-chas-edit-content-card-list">
              {
                selectedActionList?.map((it, idx)=>{
                  return(
                    <div className='cpo-custom-chas-edit-action-card-container'>
                      <div className='cpo-custom-chas-edit-action-order'>
                        <div>
                          {idx + 1}
                        </div>
                        {selectedActionList?.length !== idx+1 &&<div className='cpo-custom-chas-edit-action-flow-bar'></div>}
                      </div>
                      <div className='cpo-custom-chas-action-modal-card disable'>
                        <TfiClose onClick={()=>{deleteCard(idx)}}/>
                        <div className='cpo-custom-chas-action-modal-card-title'>{it?.name}</div>
                        <div className='cpo-custom-chas-action-modal-card-description'>{it?.description}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}
export default CustomChasActionModal;