import React, { useState, useEffect } from 'react';

// CpoStationChargingFeeManagement 에서 사용
export const StationChargingFeeListItem = (props) => {

  const getChargingFeeStatus = ()=>{
    // 멤버 요금제와 비멤버 요금제가 모두 null인 경우 
    if(!props.data?.memberPricingPlan && !props.data?.nonMemberPricingPlan) return <div>기본 요금 적용중</div>; // 기본요금 적용중

    // 멤버요금 비교
    if(props.data?.memberPricingPlan){
      // 기본단가와 충전소단가의 기본값 비교
      if(props.defaultPricingPlan?.memberPlan?.defaultUnitPrice === props.data?.memberPricingPlan?.defaultUnitPrice){
        // 기본단가와 충전소단가의 시간대별 요금 비교
        let dMemTemp = [...props.defaultPricingPlan?.memberPlan?.unitPrices].sort((a, b) => a.month - b.month || a.hour - b.hour);
        let memTemp = [...props.data?.memberPricingPlan?.unitPrices].sort((a, b) => a.month - b.month || a.hour - b.hour);
        if(JSON.stringify(dMemTemp) === JSON.stringify(memTemp)){
          // 기본단가와 충정소단가의 멤버요금은 동일
        }
        else{
          return <div className='custom'>맞춤 요금 적용중</div>
        }
      }
      else{
        return <div className='custom'>맞춤 요금 적용중</div>
      }
    }
    // 비멤버요금 비교
    if(props.data?.nonMemberPricingPlan){
      if(props.defaultPricingPlan?.nonMemberPlan?.defaultUnitPrice === props.data?.nonMemberPricingPlan?.defaultUnitPrice){
        let dNonMemTemp = [...props.defaultPricingPlan?.nonMemberPlan?.unitPrices].sort((a, b) => a.month - b.month || a.hour - b.hour);
        let nonMemTemp = [...props.data?.nonMemberPricingPlan?.unitPrices].sort((a, b) => a.month - b.month || a.hour - b.hour);
        if(JSON.stringify(dNonMemTemp) === JSON.stringify(nonMemTemp)){
          // 기본단가와 충전소단가의 비멤버요금까지 동일
        }
        else{
          return <div className='custom'>맞춤 요금 적용중</div>
        }
      }
      else{
        return <div className='custom'>맞춤 요금 적용중</div>
      }
    }
    return <div>기본 요금 적용중</div>
  }

  return (
    <div className={'station-charging-fee-list-item'}>
      <div><p>{props.data?.chargingStationName}</p></div>
      <div className='station-charging-fee-status-container'>{getChargingFeeStatus()}</div>
      <div className='station-charging-fee-status-container'>{getChargingFeeStatus()}</div>
      <div>
        <div className='station-charging-fee-custom-button' onClick={()=>{props.setModalOn(true); props.setSelectedStationName(props.data?.chargingStationName); props.setSelectedStationId(props.data.chargingStationId);}}>완속 요금 설정</div>
      </div>
      <div>
        <div className='station-charging-fee-custom-button fast' onClick={()=>{props.setModalOn(true); props.setSelectedStationName(props.data?.chargingStationName); props.setSelectedStationId(props.data.chargingStationId);}}>급속 요금 설정</div>
      </div>
    </div>
  )
}
export default StationChargingFeeListItem;