import React, {useState, useEffect} from 'react'

export const EventSettingStationItem = (props) => {
  // checked 상태
  const [checked, setChecked] = useState(false);

  const clickEvent = (checked)=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkStationList.indexOf(props.data.ChargingStationId);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      props.setCheckStationList([...props.checkStationList, props.data.ChargingStationId]);
      setChecked(true);
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkStationList];
        temp.splice(itemIdx,1);
        props.setCheckStationList(temp);
        setChecked(false);
      }
    }
  }

  useEffect(()=>{
    let itemIdx = props.checkStationList.indexOf(props.data.ChargingStationId);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkStationList])
  
  useEffect(()=>{
    let itemIdx = props.checkStationList.indexOf(props.data.ChargingStationId);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.data])
  
  


  return (
    <div className={`cpo-charging-event-station-list-content-item checkList ${checked ? "clicked":""}`}>
      <div>
        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
          <input type="checkbox" checked={checked} onChange={()=>{clickEvent(!checked)}} />
          <span className="checkbox_icon"></span>
        </label>
      </div>
      <div><p>{`${props.data.RegionName ? props.data.RegionName:"-"}`}</p></div>
      <div><p>{props.data.ChargingStationName}</p></div>
      <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.Address ?props.data.Address+' '+(props.data.DetailedAddress ? props.data.DetailedAddress:""):"-"}</p></div>
    </div>
  )
}
export default EventSettingStationItem;