import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum, setDetailData} from '../../store.js'
import axios from 'axios';
import StationDetailTab1 from '../tabs/StationDetailTab1.js';
import StationDetailTab2 from '../tabs/StationDetailTab2.js';
import StationDetailTab3 from '../tabs/StationDetailTab3.js';
import StationDetailTab4 from '../tabs/StationDetailTab4.js';

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { getCookie } from '../../util/util.js';


const StationDetailModal = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let detail = useSelector((state)=>state.detail);
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  const [clicked, setClicked] = useState("")
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      // tabBtnRef.current[tabIdx].classList.remove("selected");
      // tabBtnRef.current[idx].classList += " selected";
      setTabIdx(idx);
    }
  }
  function RenderTab({idx}){
    return [ <StationDetailTab1 roamingStationInfo={props.roamingStationInfo} chargerHubInfo={props.chargerHubInfo} data={props.data} setSaveBtnStatus={setSaveBtnStatus} open={props.detailModalOpen} name={props.data?.ChargingStationName}/>, <StationDetailTab2 data={props.data} setSaveBtnStatus={setSaveBtnStatus} open={props.detailModalOpen} name={props.data?.ChargingStationName}/>, 
    <StationDetailTab3 data={props.data} ChargerInstallationCompanyIDList={ChargerInstallationCompanyIDList} ElectricalConstructionCompanyIDList={ElectricalConstructionCompanyIDList} WinchManufacturerIDList={WinchManufacturerIDList}  setSaveBtnStatus={setSaveBtnStatus} open={props.detailModalOpen} name={props.data?.ChargingStationName}/>, <StationDetailTab4 data={props.data} setSaveBtnStatus={setSaveBtnStatus} open={props.detailModalOpen} name={props.data?.ChargingStationName}/> ][idx]
  }

  const slideEvent = (pre)=>{
    if(pre == -1){
      if(props.clickedStation == 0) return;
      else props.setClickedStation(props.clickedStation-1);
    }
    else{
      if(props.clickedStation == props.lastIdx) return;
      else props.setClickedStation(props.clickedStation+1);
    }
  }
  // {
  //   DisplayId:'MOT-1',
  //   ChargingStationName:'충전소1',
  //   Address:'세종특별자치시 조치원읍 군청로 95',
  //   DetailedAddress:'지하 1층',
  //   OpStatus:'운영중',
  //   ChargerIds:[1,2]
  // },

  const [ChargerInstallationCompanyIDList, setChargerInstallationCompanyIDList] = useState(null);
  const [ElectricalConstructionCompanyIDList, setElectricalConstructionCompanyIDList] = useState(null);
  const [WinchManufacturerIDList, setWinchManufacturerIDList] = useState(null);
  
  useEffect(()=>{
    getCompany()
  },[])
  

  const getCompany = async() => {
    await axios.get('/infra/api/Companys',{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setChargerInstallationCompanyIDList(res.data.Results.filter(it=> it.Name.includes("설치업체")))
      setElectricalConstructionCompanyIDList(res.data.Results.filter(it=> it.Name.includes("전력기설치")))
      setWinchManufacturerIDList(res.data.Results.filter(it=> it.Name.includes("윈치제조")))
    })
  }


  // API 연결 시 아래 4가지가 localStorage에 없다?? 수정 사항 X -> 저장 미 활성화
  const [saveBtnStatus, setSaveBtnStatus] = useState(false);
  useEffect(() => {
    const storageKey = props.data?.ChargingStationName;
    if (!storageKey) return; // props.data가 없으면 실행하지 않음
  
    const tabs = [
      localStorage.getItem(`${storageKey}_StationDetailTab1`),
      localStorage.getItem(`${storageKey}_StationDetailTab2`),
      localStorage.getItem(`${storageKey}_StationDetailTab3`),
      localStorage.getItem(`${storageKey}_StationDetailTab4`)
    ];
  
    let hasValidTab = false;
  
    tabs.forEach((t, index) => {
      if (t) {
        let parsedT = JSON.parse(t); // 새로운 변수에 저장
        if (!parsedT['expiredTime'] || new Date(parsedT['expiredTime']) < new Date()) {
          localStorage.removeItem(`${storageKey}_StationDetailTab${index + 1}`);
        } else {
          hasValidTab = true; // 하나라도 유효한 탭이 있으면 true 설정
        }
      }
    });
  
    setSaveBtnStatus(hasValidTab);
  
  }, [props.data]); // props.data가 변경될 때만 실행

  
  let save = async()=>{
    let t1 = JSON.parse(localStorage.getItem(`${props.data?.ChargingStationName}`+'_StationDetailTab1'));
    let t2 = JSON.parse(localStorage.getItem(`${props.data?.ChargingStationName}`+'_StationDetailTab2'));
    let t3 = JSON.parse(localStorage.getItem(`${props.data?.ChargingStationName}`+'_StationDetailTab3'));
    let t4 = JSON.parse(localStorage.getItem(`${props.data?.ChargingStationName}`+'_StationDetailTab4'));

    let data = {
      "chargingStationName" : props.data?.ChargingStationName,
      "serialNumber" :  props.data?.SerialNumber,
      "projectName" : "null",
      "OwnerName": t1 ? (JSON.parse(t1['OwnerName'])?JSON.parse(t1['OwnerName']):null):props.data?.OwnerName,
      "openApiChargingStationId": t1 ? (JSON.parse(t1['openApiChargingStationId'])?JSON.parse(t1['openApiChargingStationId']):null):props.data?.OpenApiChargingStationId,
      "OperatorName": t1 ? (JSON.parse(t1['OperatorName'])?JSON.parse(t1['OperatorName']):null):props.data?.OperatorName,
      "cityCountyAndDistrict":"",
      "facilityClassification" : t1 ? (JSON.parse(t1['FacilityClassification'])?JSON.parse(t1['FacilityClassification']):null):props.data?.FacilityClassification,
      "facilitySubdivision" : t1 ? (JSON.parse(t1['FacilitySubdivision'])?JSON.parse(t1['FacilitySubdivision']):null):props.data?.FacilitySubdivision,
      "parkingFeeType" : t1 ? (JSON.parse(t1['parkingFeeType'])?JSON.parse(t1['parkingFeeType']):null):props.data?.parkingFeeType,
      "startAvailableTime" : t1 ? (JSON.parse(t1['StartAvailableTime'])?JSON.parse(t1['StartAvailableTime']):null):props.data?.StartAvailableTime,
      "endAvailableTime" : t1 ? (JSON.parse(t1['EndAvailableTime'])?JSON.parse(t1['EndAvailableTime']):null):props.data?.EndAvailableTime,
      "parkingFee" : t1 ? (JSON.parse(t1['ParkingFee'])?JSON.parse(t1['ParkingFee']):null) :props.data?.ParkingFee,
      "numberOfParkingSpaces" : t1 ? (JSON.parse(t1['NumberOfParkingSpaces'])?JSON.parse(t1['NumberOfParkingSpaces']):null):props.data?.NumberOfParkingSpaces,
      "whetherTheManagerResides": t1 ? (t1['WhetherTheManagerResides']?t1['WhetherTheManagerResides']:null):props.data?.WhetherTheManagerResides,



      "address" :  t2 ? (JSON.parse(t2['address'])?JSON.parse(t2['address']):null): props.data?.Address,
      "regionName" :  t2 ? (JSON.parse(t2['reg'])?JSON.parse(t2['reg']):null): props.data?.RegionName,
      "detailedAddress" :  t2 ? (JSON.parse(t2['DetailedAddress'])?JSON.parse(t2['DetailedAddress']):null): props.data?.DetailedAddress,
      "latitude" :  t2 ? (JSON.parse(t2['pos']).y?JSON.parse(t2['pos']).y:null): props.data?.Latitude,
      "longitude" :  t2 ? (JSON.parse(t2['pos']).x?JSON.parse(t2['pos']).x:null): props.data?.Longitude,

      "chargerInstallationCompanyID": t3 ? (JSON.parse(t3['ChargerInstallationCompanyID'])?JSON.parse(t3['ChargerInstallationCompanyID']):null):props.data?.ChargerInstallationCompanyID,
      "electricalConstructionCompanyID": t3 ? (JSON.parse(t3['ElectricalConstructionCompanyID'])?JSON.parse(t3['ElectricalConstructionCompanyID']):null):props.data?.ElectricalConstructionCompanyID,
      "canopyInstallationCompanyID": props.data?.CanopyInstallationCompanyID ? props.data?.CanopyInstallationCompanyID:null,
      "winchManufacturerID": t3 ? (JSON.parse(t3['WinchManufacturerID'])?JSON.parse(t3['WinchManufacturerID']):null):props.data?.WinchManufacturerID,
      "faucetMethod": t3 ? (JSON.parse(t3['FaucetMethod'])?JSON.parse(t3['FaucetMethod']):null):props.data?.FaucetMethod,
      "voltageType": t3 ? (JSON.parse(t3['VoltageType'])?JSON.parse(t3['VoltageType']):null):props.data?.VoltageType,
      "contractPower": t3 ? (JSON.parse(t3['ContractPower'])?JSON.parse(t3['ContractPower']):null):props.data?.ContractPower,
      
      "startSiteUseAgreementDate": t4 ? (JSON.parse(t4['SiteUseAgreementDate'])?JSON.parse(t4['SiteUseAgreementDate']):null):props.data?.SiteUseAgreementDate,
      "endSiteUseAgreementDate": t4 ? (JSON.parse(t4['SiteUseAgreementDate'])?JSON.parse(t4['SiteUseAgreementDate']):null):props.data?.SiteUseAgreementDate,
      "startDateOfOccupancyPermission": t4 ? (JSON.parse(t4['DateOfOccupancyPermission'])?JSON.parse(t4['DateOfOccupancyPermission']):null):props.data?.DateOfOccupancyPermission,
      "endDateOfOccupancyPermission": t4 ? (JSON.parse(t4['DateOfOccupancyPermission'])?JSON.parse(t4['DateOfOccupancyPermission']):null):props.data?.DateOfOccupancyPermission,
      "installationDate": t4 ? (JSON.parse(t4['InstallationDate'])?JSON.parse(t4['InstallationDate']):null):props.data?.InstallationDate,
      "operationStartDate": t4 ? (JSON.parse(t4['OperationStartDate'])?JSON.parse(t4['OperationStartDate']):null):props.data?.OperationStartDate,
      
      "siteUseAgreementFileId": props.data?.SiteUseAgreementFileId?props.data?.SiteUseAgreementFileId:null,
      "occupancyPermitFileID": props.data?.OccupancyPermitFileID?props.data?.OccupancyPermitFileID:null,
    }
    await axios.put(`/infra/api/cpouser/v1/ChargingStations/${props.data?.ChargingStationId}`,data,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      localStorage.removeItem(`${props.data?.ChargingStationName}`+'_StationDetailTab1');
      localStorage.removeItem(`${props.data?.ChargingStationName}`+'_StationDetailTab2');
      localStorage.removeItem(`${props.data?.ChargingStationName}`+'_StationDetailTab3');
      localStorage.removeItem(`${props.data?.ChargingStationName}`+'_StationDetailTab4');
      props.getStationList();
      setSaveBtnStatus(false);
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  return (
    <div className={props.detailModalOpen ? 'station-detail-modal-container open': 'station-detail-modal-container'}>
      <div className='station-detail-modal-header'>
        <p className='station-detail-modal-title'>{props.data?.ChargingStationName}</p>
        {/* <p className='station-detail-modal-desc'>Charging Station Details</p> */}
        <div className={"station-detail-modal-button-container "}>
          <div className={'save '+`${saveBtnStatus ? "":"off"}`} onClick={()=>{if(!saveBtnStatus)return; save();}}><p>저장</p></div>
          <div className='close' onClick={()=>{props.setDetailModalOpen(false); props.setClickedStation(null);}}><p>닫기</p></div>
          {/* <div className='excel'><RiFileExcel2Line/><p>엑셀 다운로드</p></div> */}
        </div>
      </div>
      <div className="tab-button-container">
        <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx == 0 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(0)}}><p>기본</p></div>
        <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx == 1 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(1)}}><p>위치</p></div>
        <div ref={el=>tabBtnRef.current[2]=el} className={tabIdx == 2 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(2)}}><p>시설</p></div>
        <div ref={el=>tabBtnRef.current[3]=el} className={tabIdx == 3 ? "tab-button selected":"tab-button"} onClick={()=>{tabClick(3)}}><p>인가</p></div>
        <div className={'tab-bar '+'tab'+tabIdx}></div>
      </div>
      <div className="tab-content-container">
        <div className="tab-content">
          {
            <RenderTab idx={tabIdx}/>
          }
        </div>
      </div>
      <div className='station-detail-modal-left-button-container' onClick={(e)=>{e.stopPropagation(); slideEvent(-1)}}>
        <BsChevronCompactLeft/>
      </div>
      <div className='station-detail-modal-right-button-container' onClick={(e)=>{e.stopPropagation(); slideEvent(1)}}>
        <BsChevronCompactRight/>
      </div>
      {/* <div className={'station-detail-modal-save-button-container ' + `${saveBtnStatus ? "":"off"}`} onClick={(e)=>{e.stopPropagation(); e.preventDefault(); if(!saveBtnStatus)return; save();}}><p>저장하기</p></div> */}
    </div>
  )
}
export default StationDetailModal;