import axios from 'axios';
import { getCookie, refresh } from '../../util/util';

// ******************************************************************************************
// * CpoUserMyRoamingCpo
// ******************************************************************************************
// 로밈등록 여부 확인 `my-roaming-cpo/check`
export const getRoamingCpoUserCheck = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/roaming/api/v1/cpouser/my-roaming-cpo/check`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 로밍 정보 조회
export const getRoamingCpoUser = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/roaming/api/v1/cpouser/my-roaming-cpo`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 로밍 등록
export const postRoamingCpoUser = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {moeBId, name} = data;
  await axios.post(`/roaming/api/v1/cpouser/my-roaming-cpo`, {
    "moeBId": moeBId,
    "name": name,
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}



// ******************************************************************************************
// * ChargingStation Roaming
// ******************************************************************************************

// 로밍 충전소 리스트 조회 GET
export const getRoamingChargingStationList = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/roaming/api/v1/cpouser/charging-stations`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 로밍 충전소 조회 by Id GET
export const getRoamingChargingStationById = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/roaming/api/v1/cpouser/charging-stations/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}
// 로밍 충전소 조회 by emoovChargingStationId GET
export const getRoamingChargingStationByEmoovId = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/roaming/api/v1/allowall/charging-stations/emoov/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
  
}
// 로밍 충전소 생성 POST
export const postRoamingChargingStation = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {moEChargingStationId, emoovChargingStationId} = data;
  await axios.post(`/roaming/api/v1/cpouser/charging-stations`, {
    "moEChargingStationId": moEChargingStationId,
    "emoovChargingStationId": emoovChargingStationId
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// ******************************************************************************************
// * Charger Roaming
// ******************************************************************************************
// 로밍 충전기 리스트 조회 GET
export const getRoamingChargerList = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/roaming/api/v1/cpouser/chargers`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 로밍 충전기 조회 by Id GET
export const getRoamingChargerByEmoovId = async(emoovChargerId, emoovConnectorId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/roaming/api/v1/allowall/chargers/${emoovChargerId}/${emoovConnectorId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 로밍 충전기 생성 POST
export const postRoamingCharger = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {moEChargerId, moEChargingStationId, emoovChargerId, emoovConnectorId} = data;
  await axios.post(`/roaming/api/v1/cpouser/chargers`, {
    "moEChargerId": moEChargerId,
    "moEChargingStationId": moEChargingStationId,
    "emoovChargerId": emoovChargerId,
    "emoovConnectorId": emoovConnectorId,
    "status": "Unknown"
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}