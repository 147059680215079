/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2024-12-16
 * @file 찾아ZooM 통계 보고서 전시 페이지 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setNum } from '../../../store.js';
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ChartComponent, Category, DataLabel, 
    Legend, Tooltip, AxesDirective, AxisDirective,
    LineSeries, ColumnSeries, SplineSeries, 
    ChartAnnotation, SeriesCollectionDirective, 
    AccumulationChartComponent, AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective, AccumulationTooltip, AccumulationDataLabel, AccumulationLegend,
    SeriesDirective, Inject, ParetoSeries,
    PieSeries,BarSeries,
    StackingLineSeries,
    StackingBarSeries} from '@syncfusion/ej2-react-charts';
import * as ZoomApi from '../api/zoomApi.jsx';   
import { FaChevronLeft } from "react-icons/fa"; 


const StationZoomStatReport = () => {
    let dispatch  = useDispatch();
    let userSystemId = localStorage.getItem('user-systemId');

    const params = useParams();
    const navigator = useNavigate();
    const location = useLocation();
    const requestInfo = location.state;

    useEffect(() => {
        dispatch(setNum({id:101, report:true}));
    },[]);

    //////////////////////////////////////////////////////
    /// CHART1 SECTION
    //////////////////////////////////////////////////////
    const dayKorName = new Map([
        ['mon','월요일'],
        ['tue','화요일'],
        ['wed','수요일'],
        ['thu','목요일'],
        ['fri','금요일'],
        ['sat','토요일'],
        ['sun','일요일']
    ]);   
    const [dayChargeChartData, setDayChargeChartData] = useState([]);
    const c1PrimaryX = { valueType: 'Category', title: '요일' };
    const c1PrimaryY = { minimum: 0, title: '충전량' };
    const [c1DescArg1, setC1DescArg1] = useState('');
    const [c1DescArg2, setC1DescArg2] = useState('');

    /// 요일-충전량 관계 차트데이터 생성 비동기 함수 선언 
    const getDayChargeRelationChartDataAsync = async()=>{
        let rawData = await ZoomApi.getDayChargingData(userSystemId, params.id);

        /// 최고/최저 충전량 요일을 찾는다.
        let minValue = 0;
        let maxValue = 0;
        let minDay = '';
        let maxDay = '';
        let index = 0;
        for await(const item of rawData) {
            if(index === 0) {
                minValue = item.value;
                maxValue = item.value;
                minDay = item.day;
                maxDay = item.day;
            }
            else {
                if(item.value >= maxValue) {
                    maxValue = item.value;
                    maxDay = item.day;
                }
                else if(item.value < minValue) {
                    minValue = item.value;
                    minDay = item.day;
                }
            }
            index++;
        }
        /// 요일정보를 한글로 변환한다. 
        setC1DescArg1(dayKorName.get(maxDay));
        setC1DescArg2(dayKorName.get(minDay));
        setDayChargeChartData(rawData);
    };

    /// 차트데이터를 가져온다. 
    useEffect(()=>{
        getDayChargeRelationChartDataAsync();
        
    },[]);

    //////////////////////////////////////////////////////
    /// CHART2 SECTION
    //////////////////////////////////////////////////////
    const [weeklyTemperatureChartData, setWeeklyTemperatureChartData] = useState([]);
    const [weeklyRainChartData, setWeeklyRainChartData] = useState([]);
    const c2PrimaryX = { valueType: 'Category', title: '날짜', labelIntersectAction:'Rotate45' };
    const c2PrimaryY = { title: '온도' };

    /// 주간 평균 온도/강수량 차트데이터 생성 비동기 함수 선언
    const getWeeklyTemperatureRainChartDataAsync = async()=>{
        let rawData = await ZoomApi.getWeeklyTemperatureRainData(userSystemId, params.id);
        let tempData = [];
        let rainData = [];
        
        for await(const item of rawData) {
            const dateStr = (item.firstDay.split(' '))[0];
            const fixedTemp = Number(item.temperature.toFixed(1));
            const fixedRain = Number(item.rainFall.toFixed(1));
            
            const newTempData = {
                x: dateStr,
                y: fixedTemp
            };
            const newRainData = {
                x: dateStr,
                y: fixedRain
            };
            tempData.push(newTempData);
            rainData.push(newRainData);
        }

        setWeeklyTemperatureChartData(tempData);
        setWeeklyRainChartData(rainData);
    }; 

    /// 주간 온도/강수량 차트 데이터를 업데이트 한다.
    useEffect(()=>{
        getWeeklyTemperatureRainChartDataAsync();
    },[]);

    //////////////////////////////////////////////////////
    /// CHART3 SECTION
    //////////////////////////////////////////////////////
    const [evKindRatioChartData, setEvKindRatioChartData] = useState([]);
    const [c3DescArg1, setC3DescArg1] = useState('');
    const getEvKindRatioPieChartDataAsync = async()=>{
        const rawData = await ZoomApi.getEvKindRatioData(userSystemId, params.id);
        //console.log('[EMOOV] 차종별 비율:', rawData);
        let maxValueType = '';
        let maxValue = 0.0;
        let index = 0;
        for await(const item of rawData) {
            if(index === 0) {
                maxValueType = item.type;
                maxValue = item.ratio;
            }
            else {
                if(item.ratio > maxValue) {
                    maxValue = item.ratio;
                    maxValueType = item.type;
                }
            }
            index++;
        }
        setC3DescArg1(maxValueType);
        setEvKindRatioChartData(rawData);
    };

    /// 전기차 차종별 비율 차트 데이터 가져온다. 
    useEffect(()=>{
        getEvKindRatioPieChartDataAsync();
    },[]);    

    //////////////////////////////////////////////////////
    /// CHART4 SECTION
    //////////////////////////////////////////////////////
    const [evMonthlyEvRegChartData, setEvMonthlyEvRegChartData] = useState([]);
    const [c4DescArg1, setC4DescArg1] = useState(0);
    const c4PrimaryX = { valueType: 'Category', title: '날짜', labelIntersectAction:'Rotate45' };
    const c4PrimaryY = { title: '등록대수',minimum: 0 };
    const getEvRegParetoChartDataAsync = async()=>{
        const rawData = await ZoomApi.getMonthlyEvRegistrationData(userSystemId, params.id);
        //console.log('[EMOOV] 전기자동차 월별 등록대수:', rawData);
        let total = 0;
        for await(const item of rawData) {
            const splits = item.month.split('-');
            const dateStr = `${splits[0]}년 ${splits[1]}월`;
            item.month = dateStr;
            total = total + item.count;
        }
        setC4DescArg1(total);
        setEvMonthlyEvRegChartData(rawData);
    };

    /// 전기차 월별 등록대수 파레트 차트 데이터를 가져온다. 
    useEffect(()=>{
        getEvRegParetoChartDataAsync();
    },[]);

    //////////////////////////////////////////////////////
    /// CHART5 SECTION
    //////////////////////////////////////////////////////
    const [vehicleMonthlyCumulativeChartData, setVehicleMonthlyCumulativeChartData] = useState([]);
    const c5PrimaryX = { valueType: 'Category', title: '날짜', labelIntersectAction:'Rotate45'};
    const c5PrimaryY = { minimum: 0, title: '누적등록대수', labelFormat: '{value}대'};
    const getVehicleCumulativeChartDataAsync = async()=> {
        const rawData = await ZoomApi.getMonthlyVehicleKindCumulativeData(userSystemId, params.id);
        for await(const item of rawData) {
            const splits = item.month.split('-');
            const dateStr = `${splits[0]}년 ${splits[1]}월`;
            item.month = dateStr;
        }
        setVehicleMonthlyCumulativeChartData(rawData);
    };

    useEffect(() => {
        getVehicleCumulativeChartDataAsync();
    },[]);

    //////////////////////////////////////////////////////
    /// CHART6 SECTION
    //////////////////////////////////////////////////////
    const [rainChargingRelationChartData, setRainChargingRelationChartData] = useState([]);
    const c6PrimaryX = { valueType: 'Category', title: '날짜', labelIntersectAction:'Rotate45'};
    const c6PrimaryY = { title: '충전회수'};
    const getRainChargingRelationChartDataAsync = async() => {
        const rawData = await ZoomApi.getWeeklyRainChargingRelationData(userSystemId, params.id);
        setRainChargingRelationChartData(rawData);
    };
    useEffect(() => {
        getRainChargingRelationChartDataAsync();
    },[]);

    //////////////////////////////////////////////////////
    /// CHART7 SECTION
    //////////////////////////////////////////////////////
    const [tempChargingRelationChartData, setTempChargingRelationChartData] = useState([]);
    const c7PrimaryX = { valueType: 'Category', title: '날짜', labelIntersectAction:'Rotate45'};
    const c7PrimaryY = { title: '충전회수'};
    const getTempChargingRelationChartDataAsync = async() => {
        const rawData = await ZoomApi.getWeeklyTempChargingRelationData(userSystemId, params.id);
        setTempChargingRelationChartData(rawData);
    };
    useEffect(() => {
        getTempChargingRelationChartDataAsync();
    },[]);      

    //////////////////////////////////////////////////////
    /// CHART8 SECTION
    //////////////////////////////////////////////////////
    const [populationMonthlyChartData, setPopulationMonthlyChartData] = useState([]);
    const c8PrimaryX = { valueType: 'Category', title: '날짜', labelIntersectAction: 'Rotate45'};
    const c8PrimaryY = { minmum: 0, title: '인구 수', labelFormat: '{value}명'};
    const getPopulationChartDataAsync = async() => {
        const rawData = await ZoomApi.getMonthlyPopulationData(userSystemId, params.id);
        for await(const item of rawData) {
            const splits = item.month.split('-');
            const dateStr = `${splits[0]}년 ${splits[1]}월`;
            item.month = dateStr;
        }
        setPopulationMonthlyChartData(rawData);
    };

    useEffect(() => {
        getPopulationChartDataAsync();
    },[]);
  
    //////////////////////////////////////////////////////
    /// CHART9 SECTION
    //////////////////////////////////////////////////////
    // const [chargerCountYearlyChartData, setChargerCountYearlyChartData] = useState([]);
    // const [c9DescArg1, setC9DescArg1] = useState(0);
    // const c9PrimaryX = { valueType: 'Category', title: '연도', labelIntersectAction: 'Rotate45'};
    // const c9PrimaryY = { minmum:0, title: '충전기 대수', labelFormat: '{value}대'};  
    // const getChargerCountChartDataAsync = async() => {
    //     const rawData = await ZoomApi.getYearlyChargerCountData(userSystemId, params.id);
    //     let total = 0;
    //     for await(const item of rawData) {
    //         total = total + item.totalCount;
    //     }
    //     setC9DescArg1(total);
    //     setChargerCountYearlyChartData(rawData);
    // };
    
    // useEffect(() => {
    //     getChargerCountChartDataAsync();
    // }, []);

    //////////////////////////////////////////////////////
    /// CHART11 SECTION
    //////////////////////////////////////////////////////
    const [cpoRankingChartData, setCpoRankingChartData] = useState([]);
    const [cpoRapidRankingChartData, setCpoRapidRankingChartData] = useState([]);
    const [cpoSlowRankingChartData, setCpoSlowRankingChartData] = useState([]);
    const [c11DescArg1, setC11DescArg1] = useState('');
    const [c11DescArg2, setC11DescArg2] = useState('');
    const [c11DescArg3, setC11DescArg3] = useState('');
    const c11PrimaryX = { valueType: 'Category', title: '충전사업자', interval:1, plotOffset: 0};
    const c11PrimaryY = { minmum:0, title: '충전기 대수', labelFormat: '{value}대'};  
    const getCpoRankingBarChartDataAsync = async()=>{
        const rawData = await ZoomApi.getCpoRankingData(userSystemId, params.id);

        if(rawData.length > 0) {
            setC11DescArg1(rawData[0].company);
        }
        /// 그래프를 역순으로 그리기 위해서 데이터를 정렬한다.
        const sortedData = [...rawData].sort((a,b)=>b.rank - a.rank);
        const sortedDataByRapidCount = [...rawData].sort((a,b)=>a.rapidCount - b.rapidCount);
        const sortedDataBySlowCount = [...rawData].sort((a,b)=>a.slowCount - b.slowCount);
        setCpoRankingChartData(sortedData);
        setC11DescArg2(sortedDataByRapidCount[sortedDataByRapidCount.length-1].company);
        setCpoRapidRankingChartData(sortedDataByRapidCount);
        setC11DescArg3(sortedDataBySlowCount[sortedDataByRapidCount.length-1].company);
        setCpoSlowRankingChartData(sortedDataBySlowCount);
    };

    /// 전기충전기 운영 충전사업자 순위차트데이터를 가져온다.
    useEffect(()=>{
        getCpoRankingBarChartDataAsync();
    },[]);          

    //////////////////////////////////////////////////////
    // PAGE VIEW CONSTRUCTION
    //////////////////////////////////////////////////////
    return (
        <div className='cpo-station-zoom-stat-report' style={{height:'100%'}}>
            {/* 목록으로 돌아가기 네비게이션 */}
            <div onClick={
                    ()=>{navigator('/cpo/station-zoom')}
                } 
                style={{
                    marginBottom: '15px', 
                    color:'#2FB4D9',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content'}}>
                <FaChevronLeft/><p>목록으로 이동</p>
            </div>
            {/* 개요 세션 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px'
                }}>
                <h1>'{requestInfo.sigKorNm}' 지역 통계 보고서 </h1>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '15px'
                }}>
                <p>- 요청 일자: {requestInfo.date}</p>
                <p>- 조회기간: {requestInfo.fromDate}부터 {requestInfo.toDate}까지</p>
            </div>
            <hr/>

            {/* SECTION 1 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>1. 요일별 누적 충전량 </h3>
            </div>
            {/* CHART 1: 요일별 누적 충전량 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                일주일 가운데 가장 충전을 많이 하는 요일은 {c1DescArg1}이며, 충전을 가장 적게 하는 요일은 {c1DescArg2}입니다. 
            </div>              
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart1' title='요일별 누적 충전량'
                    palettes={['#2FB4D9', '#33FF57', '#3357FF']} 
                    primaryXAxis={c1PrimaryX} 
                    primaryYAxis={c1PrimaryY} 
                    tooltip={{enable:true}}>
                    <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={dayChargeChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer', format: '{value}kWh'}}}
                            xName='day' 
                            yName='value' 
                            name='요일별 충전량' 
                            type='Column'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>

            {/* CHART 4: 전기자동차 월별 등록대수 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>2. 전기자동차의 월별 등록대수</h3>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px',
                    fontWeight: '600',
                    color: '#FF0000'
                }}>
                알림: 현재 자동차 등록 통계는 조회기간과 무관하게 최대 2024년 3월까지만 제공됩니다.
            </div> 
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 자동차 연료별 월별 누적 등록대수의 동향은 아래의 차트와 같습니다. 
            </div> 
            {/* CHART 5 */}
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart5' title='자동차 연료별 월별 누적등록대수'
                    primaryXAxis={c5PrimaryX}
                    primaryYAxis={c5PrimaryY}
                    tooltip={{enable:true}}>
                    <Inject services={[StackingLineSeries, Legend, Tooltip, DataLabel, Category]}/>    
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='lpg'
                            name='LPG'
                            marker={{visible:true}}
                            dashArray='5,1'
                            type='StackingLine'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='cng'
                            name='CNG'
                            marker={{visible:true}}
                            dashArray='5,1'
                            type='StackingLine'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='diesel'
                            name='디젤'
                            marker={{visible:true}}
                            dashArray='5,1'
                            type='StackingLine'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='electric'
                            name='전기'
                            marker={{
                                visible:true,
                                dataLabel: {
                                    visible: true,
                                    position: 'Top'
                                }
                            }}
                            type='StackingLine'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='gasoline'
                            name='휘발유'
                            marker={{visible:true}}
                            dashArray='5,1'
                            type='StackingLine'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='hydrogen'
                            name='수소'
                            marker={{visible:true}}
                            dashArray='5,1'
                            type='StackingLine'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='hybridDieselElectric'
                            name='하이브리드(디젤-전기)'
                            marker={{visible:true}}
                            dashArray='5,1'
                            type='StackingLine'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={vehicleMonthlyCumulativeChartData}
                            xName='month'
                            yName='hybridGasolineElectric'
                            name='하이브리드(휘발유-전기)'
                            marker={{visible:true}}
                            dashArray='5,1'
                            type='StackingLine'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>        
                </ChartComponent>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 총 {c4DescArg1}대의 전기자동차가 등록되었습니다. 월별 등록대수는 아래의 차트와 같습니다. 
            </div> 
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart4' title='전기자동차 월별 등록대수'
                    palettes={['#2FB4D9', '#000000', '#3357FF']} 
                    primaryXAxis={c4PrimaryX}
                    primaryYAxis={c4PrimaryY}
                    tooltip={{enable:true}}>
                    <Inject services={[ColumnSeries, LineSeries, ParetoSeries, Legend, Tooltip, Category, DataLabel]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={evMonthlyEvRegChartData}
                            marker={{dataLabel:{
                                visible:true,
                                position: 'Outer',
                            }}}
                            xName='month'
                            yName='count'
                            name='월별 전기차등록대수'
                            type='Pareto'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>    
                </ChartComponent>                
            </div>

            
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>3. 전기자동차 종류별 등록비율</h3>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 등록된 전기자동차들 가운데 {c3DescArg1}가(이) 가장 높은 비율을 차지합니다. 
            </div> 
            {/* CHART 3 */}
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <AccumulationChartComponent id='chart3' title='전기자동차 종류별 비율'
                    tooltip={{enable:true}}>
                    <Inject services={[PieSeries, AccumulationLegend, AccumulationTooltip, AccumulationDataLabel]}/>
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective dataSource={evKindRatioChartData}
                            xName='type'
                            yName='ratio'
                            dataLabel={{
                                visible:true,
                                name: 'ratio',
                                position: 'Outside'
                            }}
                            radius='80%'
                            type='Pie'>
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
            </div>

            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>4. 충전기 현황</h3>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 충전기 사용이력을 기준으로 추정된 {requestInfo.sigKorNm}에 설치된 충전기의 개수는 대략  대 이며, 
                년도별 설치대수는 다음과 같습니다. 
            </div> 
            {/* CHART 9: 년도별 충전기 설치 대수 컬럼차트  */}
            {/* <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart9' title='년도별 충전기 설치대수'
                    palettes={['#2FB4D9', '#33FF57', '#3357FF']} 
                    primaryXAxis={c9PrimaryX} 
                    primaryYAxis={c9PrimaryY} 
                    tooltip={{enable:true}}>
                    <Inject services={[LineSeries, ColumnSeries, Legend, Tooltip, DataLabel, Category]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={chargerCountYearlyChartData}
                            xName='year'
                            yName='totalCount'
                            name='총설치대수'
                            marker={{visible:true}}
                            type='Line'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={chargerCountYearlyChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer'}}}
                            xName='year' 
                            yName='slowCount' 
                            name='완속 충전기 설치대수' 
                            type='Column'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={chargerCountYearlyChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer'}}}
                            xName='year' 
                            yName='rapidCount' 
                            name='급속 충전기 설치대수' 
                            type='Column'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={chargerCountYearlyChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer'}}}
                            xName='year' 
                            yName='unknownCount' 
                            name='미분류 충전기 설치대수' 
                            type='Column'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div> */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 {requestInfo.sigKorNm}에 설치된 충전기 대수를 기준으로 충전사업자(CPO)의 순위는 다음과 같습니다. 
                전체사업자들 가운데 10개의 충전사업자만 표시하며, 그외 사업자는 기타사업자에 포함됩니다.  
            </div> 
            {/* CHART 11: 충전사업자 설치 랭킹차트  */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                {requestInfo.sigKorNm}에서 조회기간동안 가장 많은 충전기를 운영하는 충전사업자는 {c11DescArg1}입니다.
            </div>              
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart11' title='충전기대수 기준 충전사업자 순위'
                    palettes={['#2FB4D9', '#33FF57', '#3357FF', '#D8FF46']} 
                    primaryXAxis={c11PrimaryX} 
                    primaryYAxis={c11PrimaryY} 
                    tooltip={{enable:true}}>
                    <Inject services={[BarSeries, Legend, Tooltip, DataLabel, Category]}/>
                    <SeriesCollectionDirective>
                        {/* <SeriesDirective dataSource={cpoRankingChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer'}}}
                            xName='company' 
                            yName='unknownCount' 
                            name='미분류 충전기 설치대수' 
                            type='Bar'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={cpoRankingChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer'}}}
                            xName='company' 
                            yName='rapidCount' 
                            name='급속 충전기 설치대수' 
                            type='Bar'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={cpoRankingChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer'}}}
                            xName='company' 
                            yName='slowCount' 
                            name='완속 충전기 설치대수' 
                            type='Bar'>
                        </SeriesDirective> */}
                        <SeriesDirective dataSource={cpoRankingChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer', format: '{value}대'}}}
                            xName='company' 
                            yName='totalCount' 
                            name='충전기대수' 
                            type='Bar'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            {/* CHART 11-1: 충전사업자 급속충전소 개수 랭킹차트  */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                {requestInfo.sigKorNm}에서 조회기간동안 가장 많은 급속충전기를 운영하는 충전사업자는 {c11DescArg2}입니다.
            </div>              
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart11_1' title='급속충전기대수 기준 충전사업자 순위'
                    palettes={['#2FB4D9', '#33FF57', '#3357FF', '#D8FF46']} 
                    primaryXAxis={c11PrimaryX} 
                    primaryYAxis={c11PrimaryY} 
                    tooltip={{enable:true}}>
                    <Inject services={[BarSeries, Legend, Tooltip, DataLabel, Category]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={cpoRapidRankingChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer', format: '{value}대'}}}
                            xName='company' 
                            yName='rapidCount' 
                            name='급속 충전기 설치대수' 
                            type='Bar'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            {/* CHART 11-2: 충전사업자 완속충전소 개수 랭킹차트  */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                {requestInfo.sigKorNm}에서 조회기간동안 가장 많은 완속충전기를 운영하는 충전사업자는 {c11DescArg3}입니다.
            </div>              
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart11_2' title='완속충전기대수 기준 충전사업자 순위'
                    palettes={['#2FB4D9', '#33FF57', '#3357FF', '#D8FF46']} 
                    primaryXAxis={c11PrimaryX} 
                    primaryYAxis={c11PrimaryY} 
                    tooltip={{enable:true}}>
                    <Inject services={[BarSeries, Legend, Tooltip, DataLabel, Category]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={cpoSlowRankingChartData} 
                            marker={{dataLabel:{visible: true, position: 'Outer', format: '{value}대'}}}
                            xName='company' 
                            yName='slowCount' 
                            name='완속 충전기 설치대수' 
                            type='Bar'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>              

            {/* /// SECTION 5: 관계  */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>5. 재미로보는 날씨와 충전회수 사이의 관계</h3>
            </div>
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px',
                    fontWeight: '600',
                    color: '#FF0000'
                }}>
                알림: 충전횟수는 환경부가 제공하는 충전정보를 기반으로 가공된 데이터이므로 부정확 할 수 있습니다.
            </div>            
            {/* CHART2: 평균 온도 및 총강수량  */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 이 지역의 주간 평균온도와 총강수량은 아래의 차트와 같습니다. 
            </div> 
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart2' title='평균 온도 및 총 강수량'
                    palettes={['#2FB4D9', '#000000', '#3357FF']} 
                    primaryXAxis={c2PrimaryX}
                    primaryYAxis={c2PrimaryY}
                    tooltip={{enable:true}}>
                    <Inject services={[LineSeries, ColumnSeries, Category, Tooltip, Legend, SplineSeries, ChartAnnotation]}/> 
                    <AxesDirective>
                        <AxisDirective name='yAxis1'
                            labelFormat='{value}mm'
                            opposedPosition={true}>
                        </AxisDirective>
                    </AxesDirective>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={weeklyTemperatureChartData}
                            xName='x'
                            yName='y'
                            name='주간 평균온도'
                            type='Column'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={weeklyRainChartData}
                            xName='x'
                            yName='y'
                            name='주간 총강수량'
                            marker={{visible:true}}
                            yAxisName='yAxis1'
                            type='Spline'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            {/* CHART6: 충전회수와 강수량 사이의 관계 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 이 지역의 주간 충전회수와 총강수량 사이의 관계성은 아래의 차트와 같습니다. 
            </div> 
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart6' title='충전횟수와 강수량사이의 관계'
                    palettes={['#2FB4D9', '#000000', '#3357FF']} 
                    primaryXAxis={c6PrimaryX}
                    primaryYAxis={c6PrimaryY}
                    tooltip={{enable:true}}>
                    <Inject services={[LineSeries, ColumnSeries, Category, Tooltip, Legend, SplineSeries, ChartAnnotation]}/> 
                    <AxesDirective>
                        <AxisDirective name='yAxis1'
                            labelFormat='{value}mm'
                            opposedPosition={true}>
                        </AxisDirective>
                    </AxesDirective>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={rainChargingRelationChartData}
                            xName='firstDay'
                            yName='chargeCount'
                            name='충전회수'
                            type='Column'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={rainChargingRelationChartData}
                            xName='firstDay'
                            yName='rainfall'
                            name='주간 총강수량'
                            marker={{visible:true}}
                            yAxisName='yAxis1'
                            type='Spline'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>

            {/* CHART7: 충전회수와 온도 사이의 관계 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 이 지역의 주간 충전회수와 온도 사이의 관계성은 아래의 차트와 같습니다. 
            </div>
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart7' title='충전횟수와 기온사이의 관계'
                    palettes={['#2FB4D9', '#000000', '#3357FF']} 
                    primaryXAxis={c7PrimaryX}
                    primaryYAxis={c7PrimaryY}
                    tooltip={{enable:true}}>
                    <Inject services={[LineSeries, ColumnSeries, Category, Tooltip, Legend, SplineSeries, ChartAnnotation]}/> 
                    <AxesDirective>
                        <AxisDirective name='yAxis1'
                            labelFormat='{value}도'
                            opposedPosition={true}>
                        </AxisDirective>
                    </AxesDirective>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={tempChargingRelationChartData}
                            xName='firstDay'
                            yName='chargeCount'
                            name='충전회수'
                            type='Column'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={tempChargingRelationChartData}
                            xName='firstDay'
                            yName='temperature'
                            name='주간 평균온도'
                            marker={{visible:true}}
                            yAxisName='yAxis1'
                            type='Spline'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div> 
            
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                }}>
                <h3>6. 일반통계 </h3>
            </div>
            {/* CHART 8: 인구증감 차트 */}
            <div style={{
                    marginLeft: '15px',
                    marginBottom: '15px',
                    fontSize: '13px'
                }}>
                조회기간 동안 이 지역의 인구수의 월별 변동은 아래의 차트와 같습니다.  
            </div> 
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <ChartComponent id='chart8' title='월별 인구수'
                    primaryXAxis={c8PrimaryX}
                    primaryYAxis={c8PrimaryY}
                    tooltip={{enable:true}}>
                    <Inject services={[ColumnSeries, LineSeries, Legend, Tooltip, DataLabel, Category]}/>  
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={populationMonthlyChartData}
                            xName='month'
                            yName='total'
                            name='총인구'
                            marker={{visible:true}}
                            type='Line'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={populationMonthlyChartData}
                            xName='month'
                            yName='male'
                            name='남성인구'
                            marker={{visible:false}}
                            type='Column'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={populationMonthlyChartData}
                            xName='month'
                            yName='female'
                            name='여성인구'
                            marker={{visible:false}}
                            type='Column'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
            
        </div>
    );
}

export default StationZoomStatReport;