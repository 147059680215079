import { useState } from "react";

const defaultChargingProfile = {
  chargingProfileId: null, //integer
  transactionId: null, //integer
  stackLevel: null, //integer
  chargingProfilePurpose: null,
  chargingProfileKind: null,
  recurrencyKind: "Daily",
  validFrom: null,
  validTo: null,
  chargingSchedule: {
    duration: null,
    startSchedule: null,
    chargingRateUnit: null,
    tStartPeriod: null,
    tLimit: null,
    tNumberPhases: null,
    chargingSchedulePeriod: [
      
    ],
    minChargingRate: null,
  },
};

// React 커스텀 훅
export function useChargingProfile(initialProfile = defaultChargingProfile) {
  const [chargingProfile, setChargingProfile] = useState(initialProfile);

  // 값 업데이트 함수
  const updateChargingProfile = (key, value) => {
    setChargingProfile((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateChargingSchedule = (key, value) => {
    setChargingProfile((prev) => ({
      ...prev,
      chargingSchedule: {
        ...prev.chargingSchedule,
        [key]: value,
      },
    }));
  };

  return {
    chargingProfile,
    updateChargingProfile,
    updateChargingSchedule,
  };
}
