import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate } from 'react-router-dom';
import { GoArrowUpRight } from "react-icons/go";

import FirmwareUpdateListItem from '../../components/list/FirmwareUpdateListItem.js';

import { getFirmwareList } from '../../api/controlhub/controlhubApi.js';
import { timeInput, timestampFormat5 } from '../../util/util.js';

import {postFirmwareApplyRequestFTP, postFirmwareApplyRequestURL} from '../../api/controlhub/controlhubApi.js';

import { Loading } from '../alerts/Loading.js';

const ChargerCardEventTab3 = (props) => {
  let navigate = useNavigate();
  
  const [tabIdx, setTabIdx] = useState(0);

  const [step, setStep] = useState(0);

  // Firmware List
  const [firmwareList, setFirmwareList] = useState();
  const [selectedFirmwareInfo, setSelectedFirmwareInfo] = useState(null);
  // Firmware List 요청
  const reqGetFirmwareListCpoUser = async()=>{
    let result = await getFirmwareList({});
    if(result.status === 200){
      setFirmwareList([...result.data?.results]);
    }
    else{

    }
  }

  useEffect(()=>{
    reqGetFirmwareListCpoUser();
  },[])


  const [firmwareUrl, setFirmwareUrl] = useState("");
  const [retrieveDay, setRetrieveDay] = useState("");
  const [retrieveTime, setRetrieveTime] = useState("");

  const [updateMode, setUpdateMode] = useState(null);

  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  useEffect(()=>{
    if(errMsg){
      setTimeout(()=>{
        setErrMsg(null);
      },3000);
    }
  },[errMsg])

  const requestUpdate = async()=>{
    setLoadingOn(true);
    if(updateMode === "url"){
      let data = {chargingStationIdentity: props.data.DisplaySerialNumber, location:firmwareUrl};
      let res = await postFirmwareApplyRequestURL(data);
      if(res.status === 200){
        setStep(2);
      }
      else{
        setErrMsg("펌웨어 업데이트 요청에 실패하였습니다.")
      }
      setLoadingOn(false);
    }
    else if(updateMode === "ftp"){
      let data = {
        chargerId: props.data.ChargerId,
        firmwareId: selectedFirmwareInfo?.firmware?.firmwareId,
        firmwareVersionId: selectedFirmwareInfo?.version?.firmwareVersionId,
        retrievedDate: new Date(`${retrieveDay} ${retrieveTime.replaceAll(" ","")}`).toISOString(),
      }
      let res = await postFirmwareApplyRequestFTP(data);
      console.log(res);
      if(res?.status === 200){
        setStep(2);
      }
      else{
        setErrMsg("펌웨어 업데이트 요청에 실패하였습니다.")
      }
      setLoadingOn(false);
    }
  }

  return (
    <>
      <div className='firmware-update-container chargerCardEventTab3'>
        {
          step === 0 &&
          <div className='cpo-firmware-update-step0-container'>
            <div className='cpo-firmware-update-top-container'>
              
              <div className='cpo-firmware-update-tab-container'>
                <div className={tabIdx == 0 ? "firmware-update-tab-button selected":"firmware-update-tab-button"} onClick={()=>{setTabIdx(0);}}><p>펌웨어 목록 조회</p></div>
                <div className={tabIdx == 1 ? "firmware-update-tab-button selected":"firmware-update-tab-button"} onClick={()=>{setTabIdx(1)}}><p>펌웨어 업데이트 이력 조회</p></div>
                <div className={'firmware-update-tab-bar '+'tab'+tabIdx}></div>
              </div>
              <div className='firmware-update-add-button' onClick={()=>{navigate("/cpo/firmware-management")}}>펌웨어 관리 페이지<GoArrowUpRight/></div>
              {
                tabIdx === 0 &&
                <div className='cpo-firmware-update-with-url-msg' onClick={()=>{setStep(1); setUpdateMode("url")}}>
                다른 곳에 업로드한 펌웨어를 적용하고 싶으신가요?
              </div>}
            </div>
            {
              tabIdx === 0 &&
              <>
                <div className="cpo-firmware-update-item-list-container">
                  <div className={`cpo-firmware-update-item-list-column`}>
                    <div>펌웨어명</div>
                    <div>버전</div>
                    <div>설명</div>
                    <div></div>
                  </div>
                  {
                    firmwareList&&firmwareList.map((item, idx)=>{
                      if(item?.isDeleted)return;
                      return (
                        <FirmwareUpdateListItem key={idx} data={item} setStep={setStep} setSelectedFirmwareInfo={setSelectedFirmwareInfo} setUpdateMode={setUpdateMode}/>
                      )
                    })
                  }
                </div>
              </>
            }

            {
              tabIdx === 1 &&
              <div className="firmware-update-update-list-container">
                <div className="firmware-update-update-list-column">
                  <div>번호</div>
                  <div>파일명</div>
                  <div>버전</div>
                  <div>요청일시</div>
                  <div>요청계정</div>
                </div>
                <div className="firmware-update-update-list">
                  {/* <div className="firmware-update-update-list-item">
                    <div><p>101</p></div>
                    <div><p>EVW011SK-SD</p></div>
                    <div><p>0.1.0</p></div>
                    <div><p>2024.09.24</p></div>
                    <div><p>lnamkyung</p></div>
                  </div> */}
                  <p>기능 업데이트 예정입니다.</p>
                </div>
              </div>
            }
          </div>
        }

        {
          step === 1 && tabIdx === 0 &&
          <div className='cpo-firmware-update-step1-container'>
            {
              updateMode === "url" ?
              <>
                <div className='cpo-firmware-update-url-container'>
                  <p className='cpo-firmware-update-sub-title'>적용할 펌웨어 URL</p>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="url" onClick={(e)=>{e.stopPropagation();}} placeholder='https://' value={firmwareUrl} onChange={(e)=>{setFirmwareUrl(e.target.value)}}/>
                  </div>
                </div>
              </>
              :
              <>
              <div>
                <p className='cpo-firmware-update-sub-title'>적용할 펌웨어 정보</p>
                <div className='cpo-firmware-update-info-container'>
                  <div className='cpo-firmware-update-info-row'>
                    <p>버전</p>
                    <p>{`${selectedFirmwareInfo?.firmware?.name} ${selectedFirmwareInfo?.version?.version}`}</p>
                  </div>
                  <div className='cpo-firmware-update-info-row'>
                    <p>설명</p>
                    <p>{selectedFirmwareInfo?.version?.description}</p>
                  </div>
                </div>
              </div>
            
            <div>
              <p className='cpo-firmware-update-sub-title'>업데이트 예약 일시 <span>현재 이후의 일시를 입력해주세요.</span> </p>
              <div>
                <div className='cpo-log-report-modal-content-input-wrapper'>
                  <div className="cpo-log-report-modal-content-input-container ">
                    <input type="date" onClick={(e)=>{e.stopPropagation(); }} min={timestampFormat5(new Date(), "yyyy-MM-dd")} value={retrieveDay} onChange={(e)=>{setRetrieveDay(e.target.value)}}/>
                  </div>
                  <div className="cpo-log-report-modal-content-input-container time">
                    <input type="text" placeholder='00 : 00' value={retrieveTime} onChange={(e)=>{setRetrieveTime(e.target.value)}} onInput={(e)=>{timeInput(e.target)}} />
                  </div>
                </div>
              </div>
            </div>
              </>
            }
            <div className='cpo-firmware-update-step1-button-container'>

              <div className='cpo-firmware-update-request-button cancel' onClick={()=>{setStep(0)}}>
                취소
              </div>
              {errMsg&&<p className='cpo-firmware-update-err-msg'>{errMsg}</p>}
              <div className={`cpo-firmware-update-request-button ${(updateMode==="url" && !firmwareUrl) || (updateMode==="ftp"&&(!retrieveDay || !retrieveTime || retrieveTime?.length!==7 || new Date > new Date(`${retrieveDay} ${retrieveTime.replaceAll(" ","")}`))) ? "disabled":"" }`} onClick={()=>{
                requestUpdate()
                }}>
                {loadingOn ? <Loading/>:"업데이트"}
              </div>
            </div>

          </div>
        }
        {
          step === 2 &&
          <>
            <div className='cpo-firmware-update-step2-container'>
              <div className='cpo-firmware-update-step2-title'>
                <p>펌웨어 업데이트 요청이 완료되었습니다.</p>
              </div>
              <div className='cpo-firmware-update-step2-content'>
                  { updateMode==="ftp" ? 
                    <>
                    <p>
                      {`${retrieveDay} ${retrieveTime}`}
                    </p>
                    <p>{`에 업데이트가 진행됩니다.`}</p>
                    </>
                  :
                    <></>
                  }
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}
export default ChargerCardEventTab3;
