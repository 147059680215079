import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";

export const PaymentNumberKeyPadModal = (props) => {
  const [numberOrder, setNumberOrder] = useState([]);
  const [input, setInput] = useState('');

  // Function to shuffle numbers
  const shuffleNumbers = () => {
    const numbers = [...Array(10).keys()]; // [0, 1, 2, ..., 9]
    for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [numbers[i], numbers[j]] = [numbers[j], numbers[i]]; // Swap elements
    }
    // Insert '삭제' and '전체삭제' buttons at index 9 and 11
    numbers.splice(9, 0, '전체삭제');  // Insert '전체삭제' at index 11
    numbers.splice(11, 0, '삭제');  // Insert '삭제' at index 9

    setNumberOrder(numbers);  // Update state with shuffled numbers and buttons
  };

  // Initialize shuffled numbers on component mount
  useEffect(() => {
    shuffleNumbers();
  }, []);

  // Function to handle number click
  const handleNumberClick = (num) => {
    if(input.length >= 16) return;
    setInput((prevInput) => prevInput + num);
  };

  // Function to handle "delete one" click
  const handleDeleteOne = () => {
    setInput((prevInput) => prevInput.slice(0, -1));
  };

  // Function to handle "delete all" click
  const handleDeleteAll = () => {
    setInput('');
  };

  useEffect(()=>{
    props.setCardNumber(input);
  },[input])

  return (
    <div className="keypad-container">
      <div className='key-pad-header'>
        <p>카드번호 입력</p>
        <IoMdClose onClick={()=>{props.setOnOff(false)}}/>
      </div>
      <div className="keypad">
        {numberOrder.map((key, index) => {
          if (key === '삭제') {
            return (
              <div
                key={index}
                className="key delete-one"
                onClick={handleDeleteOne}
              >
                삭제
              </div>
            );
          } else if (key === '전체삭제') {
            return (
              <div
                key={index}
                className="key delete-all"
                onClick={handleDeleteAll}
              >
                전체삭제
              </div>
            );
          } else {
            return (
              <div
                key={index}
                className="key number"
                onClick={() => handleNumberClick(key)}
              >
                {key}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default PaymentNumberKeyPadModal;
