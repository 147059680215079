import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import Selector2 from '../buttons/Selector2';

import { TfiClose } from "react-icons/tfi";


import { getAllCharger, postCharger } from '../../api/infra/infraApi.js';
import { getChargerLimit } from '../../api/subscription/subscriptionApi.js';

import { getChargerModelList, getChargerDisplaySerialNumberDuplicationCheck } from '../../api/infra/infraApi.js';

import { Loading } from '../alerts/Loading.js';
import {LoadingList} from '../alerts/LoadingList.js';
export const ChargerEnrollModal = (props) => {
  let navigator = useNavigate();
  

  const [modelList, setModelList] = useState([]);

  const reqGetModelList = async()=>{
    let result = await getChargerModelList();
    if(result.status === 200){
      setModelList(result.data.Results);
    }
  }

  useEffect(()=>{
    reqGetModelList();
  },[])

  // 선택자 클릭 여부
  const [ChargerModel, setChargerModel] = useState('');
  const [ChargerModelVal, setChargerModelVal] = useState('');
  const [ChargerModelActive, setChargerModelActive] = useState(null);
  
  
  useEffect(()=>{
    if(!ChargerModelVal)return;
    setChargerModel(ChargerModelVal.Id);
  },[ChargerModelVal])



  const [chargerSerial, setChargerSerial] = useState("");

  useEffect(()=>{
    reqGetChargerLimit();
  },[])
  

  const [loadingListOn, setLoadingListOn] = useState(false);
  const reqGetChargerLimit = async()=>{
    setLoadingListOn(true);
    let chargerResult = await getAllCharger();
    let limitCount = await getChargerLimit();
    setChargerTotal(chargerResult.Total);
    setChargerLimit(limitCount.count);
    setLoadingListOn(false);
  }
  const [chargerTotal, setChargerTotal] = useState();
  const [chargerLimit, setChargerLimit] = useState();

  // 선택자 클릭 여부
  const [occpActive, setOccpActive] = useState('');
  const [occpSelect, setOccpSelect] = useState('none');
  const occpList = [{val:"OCPP_1_6",name:"OCPP 1.6"},
    // {val:"OCPP_2_0_1",name:"OCPP 2.0.1"}
  ];
  const activeReset=()=>{
    setOccpActive(false);
    setChargerModelActive(false);
  }
  const reqAddCharger = async()=>{
    setLoadingOn(true);
    await postCharger(
      {
        displaySerialNumber : nameContent,
        chargerModelId : ChargerModel,
        chargingStationId : props.chargingStationId,
        chargePointSerialNumber: chargerSerial,
        protocol: occpSelect.val,
      }
    );
    // await props.getStationList();
    setTimeout(() => {
      props.setOnOff(false);
      setLoadingOn(false);
    }, 1000);
    await props.getChargerInfo();
  }
  const goToModelManagement = ()=>{
    navigator('/cpo/charger-model-management');
  }



  const [nameContent, setNameContent] = useState(props.displaySerialNumber??null);
  const [nameContentValidation, setNameContentValidation] = useState(null);
  const [nameContentDuplication, setNameContentDuplication] = useState(null);
  
  const reqGetChargerSerialNumberDuplicationCheck = async()=>{
    let result = await getChargerDisplaySerialNumberDuplicationCheck(nameContent);
    if(result.status === 200){

      if(result.data.IsExist === true){
        setNameContentDuplication("disable");
      }
      else{
        setNameContentDuplication(true);
      }
    }
    else{
      setNameContentDuplication("disable");
    }
  }

  const [isError, setIsError] = useState(false);
  const [loadingOn, setLoadingOn] = useState(false);

  return (
    <div className="cpo-charger-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-charger-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-enroll-modal-header">
          <title>충전기 등록</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        {
          loadingListOn ? <LoadingList/>:
          <>
        {
          chargerTotal&&chargerLimit&&chargerTotal>=chargerLimit ?
          <>
          <div className='cpo-charger-enroll-modal-alert-msg'>
            등록 가능한 충전기 수 한계에 도달하였습니다.
          </div>
          <div className='cpo-charger-enroll-modal-grid'>
            <div>
              <p>등록된 충전기 수</p>
              <p>{chargerTotal}</p>
            </div>
            <div>
              <p>최대 충전기 수</p>
              <p>{chargerLimit}</p>
            </div>
          </div>
          <div>
            충전기를 삭제하거나 상위 구독으로 등록 개수를 늘려주세요.
          </div>
          <div>
            <div></div>
            <div className={'cpo-charger-modal-enroll-button subscription'} onClick={()=>{navigator("/cpo/subscription");}}><p>구독상품 보러가기</p></div>
          </div>
          </>
          :
          <>
            <div><p>충전기 식별번호</p></div>
            <div className="fast-enroll-input-container name">
        
              <input type="text" placeholder='식별번호를 입력해주세요' value={nameContent} onChange={(e)=>{ setNameContent(e.target.value); setNameContentDuplication(null);setIsError(false);}} onClick={(e)=>{e.stopPropagation(); }}/>
              <div className={`fast-enroll-station-name-validation ` + `${nameContent===""||!nameContent?"disable":""}`} onClick={()=>{setIsError(false); reqGetChargerSerialNumberDuplicationCheck()}}><p>중복확인</p></div>
              {
                isError === true ? 
                  <p className={'fast-enroll-input-validation-msg alert'}>일시적인 오류가 발생하였습니다.</p>
                :
                  nameContentDuplication === "disable" ? 
                    <p className={'fast-enroll-input-validation-msg alert'}>중복된 식별번호 입니다.</p>
                  :
                    nameContentDuplication ? 
                      <p className={'fast-enroll-input-validation-msg able'}>사용가능한 식별번호 입니다.</p>
                    :
                      nameContent === "" || nameContent == null ?
                        nameContentValidation === "invalid" ?<p className={'fast-enroll-input-validation-msg alert'}>식별번호를 입력해주세요.</p>:<></>
                      :
                      <p className={'fast-enroll-input-validation-msg alert'}>중복확인 버튼을 눌러주세요.</p>
              }
            </div>
            <div><p>충전기 하드웨어 일련번호</p></div>
            <div className="cpo-charger-enroll-modal-input-container">
              <input type="text" placeholder='' value={chargerSerial} onChange={(e)=>{ setChargerSerial(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
            <div><p>OCPP 프로토콜</p></div>
            <Selector2 name={'name'} dataList={occpList} select={occpSelect} setSelect={setOccpSelect} active={occpActive} setActive={setOccpActive} activeReset={activeReset}/>
            <div><p>충전기 모델</p></div>
            <div className='charger-detail-tab-selector-container'>
              <Selector2 append={true} name={"Name"} maxCount={5} dataList={modelList} select={ChargerModelVal} setSelect={setChargerModelVal} active={ChargerModelActive} setActive={setChargerModelActive} activeReset={activeReset} appendFunction={goToModelManagement}/>
            </div>
            <div>
              <div></div>
              <div className={'cpo-charger-modal-enroll-button '+ `${ nameContent!=="" && nameContentDuplication !== "disable" && nameContentDuplication && chargerSerial !== "" && occpSelect !== "none" ? "" :"none"}`} onClick={()=>{reqAddCharger()}}>{loadingOn ? <Loading/> : <p>등록</p>}</div>
            </div>
            
          </>  
        }
        </>}
      </div>
    </div>
  )
}
export default ChargerEnrollModal

// {"ErrorCode":"CHARGER_LIMIT_EXCEEDED","Message":"충전기 한도를 초과했습니다."}