import React, { useRef, useState, useEffect } from 'react';

import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";

import { Loading } from '../alerts/Loading';
import { deleteBoard } from '../../api/board/boardApi';
import { useSelector } from 'react-redux';

export const NoticeDeleteModal = (props) => {
  const user = useSelector((state) =>state.user);
  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  useEffect(()=>{
    if(errMsg){
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  },[errMsg])


  const reqDeleteBoard = async()=>{
    setLoadingOn(true);
    let result = await deleteBoard({id:props.deleteId, cpoId:user.cpoId});
    if(result.status === 200){
      await props.callback();
      props.setOnOff(false);
    }
    else{
      setErrMsg("공지 삭제에 실패하였습니다");
    }
    setLoadingOn(false);
  }


  return (
    <div className="notice-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="notice-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="notice-delete-modal-header">
          <title>공지사항 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='notice-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='notice-delete-modal-msg'>해당 공지를 삭제하시겠습니까?</p>
        </div>
        <div>
          <div className={'notice-delete-modal-delete-button '} onClick={()=>{reqDeleteBoard()}}>
            {loadingOn ? <Loading/>:<p>삭제</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default NoticeDeleteModal
