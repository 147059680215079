import React, { useState, useEffect } from 'react';
import { TbDotsVertical } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
const EventStationItem = (props) => {
  let navigate = useNavigate();
  // station item 클릭 이벤트 콜백 function
  
  const clickEvent = ()=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    if(props.clickedStation !== props.idx){
      props.setClickedStation(props.idx)
    }
    else{
      props.setClickedStation(null)
    } 
  }
  
  // checked 상태
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    if(props.mode === "checked"){
      // 리스트에서 인덱스 찾기
      let itemIdx = props.checkStationList.indexOf(props.idx);
      // check true인 경우
      if(checked){
        if(itemIdx !== -1){
          return;
        }
        props.setCheckStationList([...props.checkStationList, props.idx]);
      }
      else{
        // false 인 경우
        
        // 만약 없는 경우
        if(itemIdx === -1){
          return;
        }
        // 있는 경우
        else{
          let temp = [...props.checkStationList];
          temp.splice(itemIdx,1);
          props.setCheckStationList(temp);
        }
      }
    }
  },[checked])

  useEffect(()=>{
    if(props.mode === "checked"){
      let itemIdx = props.checkStationList.indexOf(props.idx);
      if(itemIdx !== -1 && !checked){
        setChecked(true);
      }
      else if(itemIdx === -1 && checked){
        setChecked(false);
      }
    }
  },[props.checkStationList])


  const[btnOpen, setBtnOpen] = useState(false);

  const clickFunction = async()=>{
    localStorage.setItem("selectedStationCM", props.data.ChargingStationName);
    navigate('/cpo/charger-management');
  }

  return (
    <>
      {
        props.mode === "checked" ?
        <div className={props.clickedStation === props.idx ? 'cpo-charging-event-station-list-content-item checkList clicked':'cpo-charging-event-station-list-content-item checkList'} onClick={()=>{clickEvent()}}>
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
          <div><p>{`${props.data.RegionName ? props.data.RegionName:"-"}`}</p></div>
          <div><p>{props.data.ChargingStationName}</p></div>
          <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.Address ?props.data.Address+' '+(props.data.DetailedAddress ? props.data.DetailedAddress:""):"-"}</p></div>
          <div></div>
          <div className='handle'>
            <div className="cpo-charging-event-station-list-content-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
              <div className={"cpo-charging-event-station-list-content-item-function-button-list " + `${btnOpen ? "open":""}`}>
                <div>
                  <div className="cpo-charging-event-station-list-content-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); clickFunction()}}>
                    <div>충전기관리</div>
                  </div>
                </div>

              </div>
              <TbDotsVertical/>
            </div>
          </div>
        </div>
        :
        <div className={'cpo-charging-event-station-list-content-item'}>
          <div><p>{`${props.data.RegionName ? props.data.RegionName:"-"}`}</p></div>
          <div><p>{props.data.ChargingStationName}</p></div>
          <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.Address ?props.data.Address+' '+(props.data.DetailedAddress ? props.data.DetailedAddress:""):"-"}</p></div>
          <div></div>
          <div className='handle'>
            <div className="cpo-charging-event-station-list-content-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
              <div className={"cpo-charging-event-station-list-content-item-function-button-list " + `${btnOpen ? "open":""}`}>
                <div>
                  <div className="cpo-charging-event-station-list-content-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); clickFunction()}}>
                    <div>충전기관리</div>
                  </div>
                </div>

              </div>
              <TbDotsVertical/>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default EventStationItem;