import React, { useEffect, useState } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";

import { deleteChas } from '../../api/controlhub/controlhubApi.js';
import {deleteCpoMember} from '../../api/account/accountApi.js';
import { Loading } from '../alerts/Loading.js';
import { useNavigate } from 'react-router-dom';
import { GoArrowUpRight } from "react-icons/go";

export const CpoMemberDeleteModal = (props) => {
  const [loadingOn, setLoadingOn] = useState(false);

  const reqDeleteCpoMember = async()=>{
    setLoadingOn(true);
    try {
      for await(const target of props.memberList){
        let result = await deleteCpoMember(target.Email);
      }
      await props.reqGetCpoMembers();
      setLoadingOn(false);
      props.setOnOff(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="cpo-member-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-member-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-member-delete-modal-header">
          <title>CPO멤버 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-member-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          {
            <>
              <p className='cpo-member-delete-modal-msg'>{props.memberList?.length}의 계정을 삭제하시겠습니까?</p>
              <p className='cpo-member-delete-modal-detail-msg'>{'아래 계정들의 접근 권한을 삭제합니다.'}</p>
              <div className='cpo-member-delete-modal-list-wrapper'>
                {
                  props.memberList?.map((it, idx)=>{
                    return(
                      <>
                        <div className='cpo-member-delete-modal-item'>{it?.Email}</div>

                      </>
                    )
                  })
                }
              </div>
            </>
          }
        </div>
        <div>
          {
            <div className={'cpo-member-delete-modal-delete-button '} onClick={()=>{reqDeleteCpoMember()}}>
              {loadingOn ? <Loading/>:<p>삭제</p>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default CpoMemberDeleteModal