import React, { useEffect, useState } from 'react';
import { CiFileOn } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { LuDownload } from "react-icons/lu";
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum, setDetailData} from '../../store.js'
import Toggle from '../buttons/Toggle.js';

import UploadBox from '../buttons/UploadBox.js';

const StationDetailTab4 = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let detail = useSelector((state)=>state.detail);
  let dispatch = useDispatch();
  
  // tabData 형태 정의 필요
  const [tabData, setTabData] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  
  const[SiteUseAgreementDate, setSiteUseAgreementDate]=useState("");
  const[DateOfOccupancyPermission, setDateOfOccupancyPermission]=useState("");
  const[InstallationDate, setInstallationDate]=useState("");
  const[OperationStartDate, setOperationStartDate]=useState("");
  
  // 토글 클릭 여부 (관리자 상주 여부)
  // const [WhetherTheManagerResides, setWhetherTheManagerResides] = useState(null);

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}_StationDetailTab4`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_StationDetailTab4`);
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_StationDetailTab4`);
        return;
      }
      
      if(temp['SiteUseAgreementDate'])setSiteUseAgreementDate(JSON.parse(temp['SiteUseAgreementDate']));
      if(temp['DateOfOccupancyPermission'])setDateOfOccupancyPermission(JSON.parse(temp['DateOfOccupancyPermission']));
      if(temp['InstallationDate'])setInstallationDate(JSON.parse(temp['InstallationDate']));
      if(temp['OperationStartDate'])setOperationStartDate(JSON.parse(temp['OperationStartDate']));
      // setWhetherTheManagerResides(temp['WhetherTheManagerResides']);
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.name}`+'_StationDetailTab4');
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        console.log(new Date(temp['expiredTime']) < new Date())
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_StationDetailTab4`);
        }
        else{
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_StationDetailTab4`);
      }
    }

    setSiteUseAgreementDate(props.data?.StartSiteUseAgreementDate);
    setDateOfOccupancyPermission(props.data?.StartDateOfOccupancyPermission);
    setInstallationDate(props.data?.InstallationDate);
    setOperationStartDate(props.data?.OperationStartDate);
    // setWhetherTheManagerResides(props.data?.WhetherTheManagerResides);

  },[])

  useEffect(()=>{
    if(!props.open)return;
    if(SiteUseAgreementDate == ''&& DateOfOccupancyPermission =='' && InstallationDate=='' && OperationStartDate=='' 
      // && WhetherTheManagerResides==null

    )return;
    if(SiteUseAgreementDate == props.data.StartSiteUseAgreementDate&& DateOfOccupancyPermission ==props.data.StartDateOfOccupancyPermission && InstallationDate==props.data.InstallationDate && OperationStartDate==props.data.OperationStartDate
      //  && WhetherTheManagerResides==props.data.WhetherTheManagerResides
      )return;
    localStorage.setItem(`${props.name}`+'_StationDetailTab4', JSON.stringify( {SiteUseAgreementDate:JSON.stringify(SiteUseAgreementDate), DateOfOccupancyPermission:JSON.stringify(DateOfOccupancyPermission), InstallationDate:JSON.stringify(InstallationDate), OperationStartDate:JSON.stringify(OperationStartDate)
      // , WhetherTheManagerResides:WhetherTheManagerResides
      ,  expiredTime: new Date(new Date().getTime() + 60 * 60 * 1000)
    }))
    props.setSaveBtnStatus(true);
  },[SiteUseAgreementDate, DateOfOccupancyPermission, InstallationDate, OperationStartDate
    // , WhetherTheManagerResides
  ])



  return(
    <div className='station-detail-tab fourth'>
      <div className="station-detail-authorization-container">
        <div className="station-detail-child-grid">
          <div>
            <p className='station-detail-input-title'>부지사용협약일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={SiteUseAgreementDate?.split(' ')[0]} onChange={(e)=>{setSiteUseAgreementDate(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <UploadBox setFile={setFile1}/>
        </div>
        <div className="station-detail-child-grid">
          <div>
            <p className='station-detail-input-title'>점용허가일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={DateOfOccupancyPermission?.split(' ')[0]} onChange={(e)=>{setDateOfOccupancyPermission(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <UploadBox setFile={setFile1}/>
        </div>
        <div className="station-detail-child-grid">
          <div>
            <p className='station-detail-input-title'>설치일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={InstallationDate?.split(' ')[0]} onChange={(e)=>{setInstallationDate(e.target.value)}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
          <div>
            <p className='station-detail-input-title'>운영시작일</p>
            <div className="station-detail-info-input-container mapTab">
            <input type='date' placeholder='' onSubmit={()=>{return false}} value={OperationStartDate?.split(' ')[0]} onChange={(e)=>{setOperationStartDate(e.target.value);}}  onClick={(e)=>{e.stopPropagation(); }}/>
            </div>
          </div>
        </div>
        {/* <div>
          <p className='station-detail-input-title'>관리자 상주 여부</p>
          <div className='station-detail-authorization-tab-toggle-container'>
            <Toggle on={'상주'} off={'비상주'} onOff={WhetherTheManagerResides} setOnOff={setWhetherTheManagerResides}/>
          </div>
        </div> */}
        
      </div>
    </div>
  );
}
export default StationDetailTab4



