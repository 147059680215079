import React, { useRef, useState, useEffect } from 'react';

import { TfiClose } from "react-icons/tfi";

import {putManager} from '../../api/infra/companyApi.js';

import { Loading } from '../alerts/Loading.js';

export const CpoInfraConstructorManagerEditModal = (props) => {

  const [errMsg, setErrMsg] = useState(null);

  const [newManagerData, setNewManagerData] = useState({
    "companyId": props.data?.CompanySysId,
    "managerId": props.data?.ManagerSysId,
    "name": props.data?.Name,
    "position": props.data?.Position,
    "phoneNumber": props.data?.ContantNumber,
    "team": props.data?.Team
  });

  const onMangerDataChange = (e)=>{
    setNewManagerData({...newManagerData, [e.target.name]:e.target.value});
  }

  const reqPutManager = async()=>{
    setLoadingOn(true);
    let result = await putManager(newManagerData);
    if(result.status === 200 || result.status === 201){
      props.setOnOff(false);
      props.reqGetInfraConstructorList(1, {"specialization":"all"});
    }
    else{
      setErrMsg('담당자 수정에 실패하였습니다.');
    }
    setLoadingOn(false);
  }

  const [loadingOn, setLoadingOn] = useState(false);


  useEffect(()=>{
    if(errMsg){
      setTimeout(()=>{
        setErrMsg(null);
      },3000);
    }
  },[errMsg])
  
  return (
    <div className="cpo-infra-constructor-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-infra-constructor-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-infra-constructor-enroll-modal-header">
          <title>담당자 정보 수정</title>
          <div className='cpo-infra-constructor-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);props.reqGetInfraConstructorList(1, {"specialization":"all"});}}><TfiClose/></div>
        </div>
        <div className="cpo-infra-constructor-enroll-modal-content">
          {
                <>
                  {/* <div className="cpo-infra-constructor-enroll-modal-content-header">
                    <p className="cpo-infra-constructor-enroll-modal-content-title">담당자 수정</p>
                  </div> */}

                  <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                    <p>담당자 이름</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text" placeholder='담당자 이름을 입력해주세요' name='name'  value={newManagerData?.name} onChange={onMangerDataChange}/>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>직책</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='직책을 입력해주세요' name='position' value={newManagerData?.position} onChange={onMangerDataChange}/>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>연락처</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='연락처를 입력해주세요' name='phoneNumber' value={newManagerData?.phoneNumber} onChange={onMangerDataChange}/>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>부서</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='부서를 입력해주세요' name='team' value={newManagerData?.team} onChange={onMangerDataChange}/>
                    </div>
                  </div>
                  <div className='cpo-infra-constructor-enroll-modal-button-container'>
                    {/* <div className="cpo-infra-constructor-enroll-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                    <div></div>
                    <div className={`cpo-infra-constructor-enroll-modal-button ${!newManagerData.name || !newManagerData.position || !newManagerData.phoneNumber || !newManagerData.team ? "disabled":""}`} onClick={()=>{reqPutManager()}}>
                      {loadingOn ? <Loading/> :"수정하기"}
                    </div>
                  </div>
                </>
          }
        </div>
        
      </div>
    </div>
  )
}
export default CpoInfraConstructorManagerEditModal
