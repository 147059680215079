import axios from 'axios';
import { getCookie, refresh } from '../../util/util';



export const getSendMagicLinkEmail = async(email)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/account/api/NearCpoAuth/send-magic-link-email?email=${email}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}


// Tag 조회
export const getTag = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/tag/api/v1/Tags?EvUserId=${id}`,
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}