import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const BlockedRoute = ({ allowedRoles }) => {
  const user = useSelector((state) => state.user);

  if(!user?.roles)
  if (!user?.roles || user?.roles?.some(item => allowedRoles.includes(item))) {
    return <Navigate to="/404" replace />; // 접근 불가 시 404 페이지 이동
  }

  return <Outlet />; // 허용된 경우 자식 라우트 렌더링
};

export default BlockedRoute;