import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { setCookie, getCookie, timeInput, dateFormat, getTimeZone, setTimeZone, timestampFormat3, timestampFormat4, timestampFormat5 } from '../../util/util';

import { TfiClose } from "react-icons/tfi";

export const CpoIncomeReportSearchModal = (props) => {
  // 시작 날짜
  const [startDate, setStartDate] = useState(props.MinCreatedDate?timestampFormat3(props.MinCreatedDate).split(' ')[0]:null);
  // 종료 날짜
  const [stopDate, setStopDate] = useState(props.MaxCreatedDate?timestampFormat3(props.MaxCreatedDate).split(' ')[0] :null);

  useEffect(()=>{
    if(startDate && stopDate){
      if(startDate > stopDate){
        let temp = startDate;
        setStartDate(stopDate);
        setStopDate(temp);
      }
    }
  },[startDate, stopDate])



  
  const submit = async()=>{
    if(startDate){
      const tempDate = new Date(startDate); // 입력 받은 날짜
      tempDate.setHours(0, 0, 0); // 0시 0분 0초로 설정
      props.setMinCreatedDate(startDate ? (new Date(startDate+ " " + `${"00:00"}`))?.toISOString():null);
    }
    else{
      props.setMinCreatedDate(null)
    } 
    if(stopDate){
      const tempDate = new Date(stopDate); // 입력 받은 날짜
      tempDate.setHours(23, 59, 59); // 0시 0분 0초로 설정
      props.setMaxCreatedDate(stopDate ? (new Date(tempDate)).toISOString():null)
    }
    else {
      props.setMaxCreatedDate(null)
    }
    
    props.setOnOff(false);
  }

  const reset = ()=>{
    setStartDate(timestampFormat5(new Date(new Date().getMonth() + 1 + "/01/" + new Date().getFullYear()), 'yyyy-MM-dd'));
    setStopDate(timestampFormat5(new Date(new Date(`${new Date().getMonth() + 2}/1/${new Date().getFullYear()}`).getTime() - 1000).toISOString(), 'yyyy-MM-dd'));
  }





  return (
    <div className="cpo-income-report-search-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-income-report-search-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-income-report-search-modal-header">
          <title>검색 기간 설정</title>
          <div className='cpo-income-report-search-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-income-report-search-modal-content-section'>
          <div className="cpo-log-report-modal-content-wrapper marginTop subTitle">
            <p>검색 기간</p>
            <p>최소와 최대 범위를 설정해주세요</p>                
          </div>
          <div className="cpo-income-report-modal-content-wrapper divide">
            <div className="cpo-income-report-modal-content-input-container ">
              <input type="date" onClick={(e)=>{e.stopPropagation(); }} value={startDate} onChange={(e)=>{setStartDate(e.target.value)}}/>
            </div>
            <div>~</div>
            <div className="cpo-income-report-modal-content-input-container ">
              <input type="date"  onClick={(e)=>{e.stopPropagation(); }} value={stopDate} onChange={(e)=>{setStopDate(e.target.value)}}/>
            </div>
          </div>
          
          
        </div>

        <div className='cpo-income-report-search-modal-button-container'>
          <div className="cpo-income-report-search-modal-button reset" onClick={()=>{reset();}}>초기화</div>
          <div className="cpo-income-report-search-modal-button" onClick={()=>{submit()}}>검색</div>
        </div>
      </div>
    </div>
  )
}
export default CpoIncomeReportSearchModal
