import React, { useEffect, useState } from 'react';


export const CpoGroupMemberItem = (props) => {
  const [checked, setChecked] = useState();
  useEffect(()=>{
    let itemIdx = props.checkList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkList])
  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      props.setCheckList([...props.checkList, props.idx]);
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkList];
        temp.splice(itemIdx,1);
        props.setCheckList(temp);
      }
    }
  },[checked])
  
  return (
    <div className={"cpo-group-management-content-item " + `${props.deleteMode?"deleteMode ":""}` +`${checked ? "checked ":""}`}>
      {
        props.deleteMode && 
        <>
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
        </>
      }
      <div>
        <p>{props.data.LocalRealName}</p>
      </div>
      <div>
        <p>{props.data?.Email}</p>
      </div>
    </div>
  )
}
export default CpoGroupMemberItem;