import React, { useRef, useState, useEffect } from 'react';

import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";

import { deletePricingEvent } from '../../api/chargerate/chargerateApi';
import { Loading } from '../alerts/Loading';

export const ChargingEventDeleteModal = (props) => {
  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  useEffect(()=>{
    if(errMsg){
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  },[errMsg])
  const reqDeleteEvent = async()=>{
    setLoadingOn(true);
    let result = await deletePricingEvent(props.data.pricingEventId);
    if(result.status === 200){
      props.reqGetAllPricingEvent();
      props.setOnOff(false);
    }
    else{
      setErrMsg("이벤트요금 삭제에 실패하였습니다");
    }
    setLoadingOn(false);
  }
  return (
    <div className="custom-chas-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="custom-chas-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="custom-chas-delete-modal-header">
          <title>액션셋 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='custom-chas-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='custom-chas-delete-modal-msg'>이벤트요금을 삭제하시겠습니까?</p>
          <div className='custom-chas-delete-modal-list-wrapper'>
            <div className='custom-chas-delete-modal-item'>{props.data.name}</div>
          </div>
        </div>
        <div>
          <div className={'custom-chas-delete-modal-delete-button '} onClick={()=>{reqDeleteEvent()}}>
            {loadingOn ? <Loading/>:<p>삭제</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChargingEventDeleteModal
