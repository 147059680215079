import React from 'react'

import { RiCustomerServiceFill, RiTeamFill, RiBattery2ChargeFill, RiTimerFlashLine, RiHomeGearLine } from "react-icons/ri";
import { FaChargingStation, FaChevronDown, FaSearchLocation } from "react-icons/fa";
import { FaPlugCircleBolt } from "react-icons/fa6";
import { MdSpaceDashboard, MdManageAccounts, MdOutlineEmail, MdOutlinePrecisionManufacturing } from "react-icons/md";
import { BsBuildingFillGear } from "react-icons/bs";
import { TbSettingsBolt, TbReportAnalytics,TbReportMoney, TbAdjustmentsBolt, TbZoomMoney, TbCurrencyDollar, TbHomeDollar, TbHeartDollar } from "react-icons/tb";
import { IoHardwareChip } from "react-icons/io5";
import { GrDocumentUser } from "react-icons/gr";


const NavButton = (props) => {
  // Icon return 함수
  const icon = (idx)=>{
    switch (idx) {
      case 0: // 대시보드
        return <MdSpaceDashboard/>;
      
      case 1: // 빠른등록
        return <RiTimerFlashLine/>;
    
      case 2: // 충전소 관리
        return <RiHomeGearLine/>;
      
      case 3: // 충전기 관리
      return <FaPlugCircleBolt/>;
      
      case 4: // 업체 관리
      return <BsBuildingFillGear/>;
      
      case 5: // 충전기 모델 관리
      return <FaChargingStation/>;

      case 6: // 기본 요금 관리
        return <TbCurrencyDollar/>;

      case 7: // 이벤트 요금 관리
        return <TbHeartDollar/>;
      
      case 8: // 충전기 운용 제어
        return <TbAdjustmentsBolt/>;

      case 9: // 충전기 데이터 기록 조회
        return <TbReportAnalytics/>;
      
      case 10: // 충전 기록 조회
        return <TbReportMoney/>;
      
      case 11: // 고객 서비스 관리
        return <RiCustomerServiceFill/>;
      
      case 12: // 시스템 설정
        return <TbSettingsBolt/>;

      case 13: // 내 정보 관리
        return <MdManageAccounts/>;
      
      case 14: // 조직 관리
        return <RiTeamFill/>;
      
      case 15: // 결제 내역 조회 및 정산
        return <TbZoomMoney/>;
      
      case 16: // 충전소별 요금 관리
        return <TbHomeDollar/>;
      
      case 17: // 충전기 펌웨어 관리
        return <IoHardwareChip/>;
      
      case 18: // 초대 관리
        return <MdOutlineEmail/>;
      
      case 19: // EV사용자 정보 조회
        return <GrDocumentUser/>;

      case 20: // 충전기 제조 업체 관리
        return <MdOutlinePrecisionManufacturing/>;
      

      /// Added by edeward at 2024.11.26
      /// Start Code
      case 101:
        return  <FaSearchLocation/>;
      /// End Code
      case 102:
        return  <MdSpaceDashboard/>;
      
      default:
        return <RiBattery2ChargeFill/>;
    }
  }



  return (
    <>
    <div className={props.selectedIdx === props.id ? "active nav-button-inner " : "nav-button-inner "}>
      <div onClick={props.onClick} className={"nav-button"}>
        {icon(props.data.id)}
        <p>{props.data.name}</p>
        { // subNav가 있는가?
          props.data.items ? 
            <FaChevronDown className={props.selectedIdx === props.id ? 'nav-chevron active':'nav-chevron'}/>
          :null
        }
      </div>
    </div>
    {(props.fast==="fast") &&<div className='first-enroll-guide'>첫 등록이신가요?<br/><color>빠른등록</color>을 이용해보세요!</div>}
    </>
  )
}

export default NavButton;