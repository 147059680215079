import React, { useRef, useState, useEffect } from 'react';

import SubChargerCard from './SubChargerCard.js';

import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";


import { getSubCpoChargerList } from '../../../api/infra/infraApi.js';
import {getControlHubInfoAboutSubCpo} from '../../../api/controlhub/controlhubApi.js';

import { useSelector } from 'react-redux';

import { LoadingList } from '../../../components/alerts/LoadingList.js';

export const SubCpoChargerCardList = (props) => {
  const user = useSelector(state => state.user);
  // chargerHub 데이터 로딩 상태
  const [chargerHubLoading, setChargerHubLoading] = useState(false);

  const [controlHubInfo, setControlHubInfo] = useState(null);
  const [chargerInfo, setChargerInfo] = useState(null);

  const getChargerHubList = async()=>{
    let temp = [];
    for await(const it of chargerInfo){
      let reqObj = {
        chargerSysId: it.ChargerId,
        subCpoSysId: props.selectedCpo.CpoId,
      }
      let result = await getControlHubInfoAboutSubCpo(reqObj);
      if(result.status === 200){
        temp.push(result.data);
      }
    }
    setChargerHubLoading(false);
    setControlHubInfo(temp);
    setLoadingListOn(false);
  }

  // Sub CPO의 charger Infra 정보 API GET
  const getChargerInfo = async()=>{
    setLoadingListOn(true);
    let reqObj = {
      stationId : props.clickedStation.ChargingStationId,
      rootCpoSysId: user.cpoId,
      subCpoSysId: props.selectedCpo.CpoId
    }

    let result = await getSubCpoChargerList(reqObj);
    if(result.status === 200){
      setChargerInfo(result.data);
    }
  }

  // station list Ref[]
  const chargerRef = useRef([]);
  

  // 클릭한 charger list item의 idx state
  const [clickedCharger, setClickedCharger] = useState(null);

  // 상세 페이지 Modal
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  // 클릭 시 상세 모달창 open
  useEffect(()=>{
    if(clickedCharger == -1 || clickedCharger == null || clickedCharger == undefined ){
      setDetailModalOpen(false);
    }
    else{
      setDetailModalOpen(true);
    }
  },[clickedCharger])



  // 선택한 station id
  const [selectedStationId, setSelectedStationId] = useState(null);



  // 보여줄 충전기 리스트
  const [visibleChargerList, setVisibleChargerList] = useState(null);
  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(chargerInfo?.Total/pageSize);
  const totalCount = chargerInfo?.length;
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(chargerInfo&&pageNum){
      setVisibleChargerList(chargerInfo.slice((pageNum-1)*pageSize, pageNum*pageSize));
    }
  },[pageNum])

  useEffect(()=>{
    if(chargerInfo){
      setChargerHubLoading(true);
      getChargerHubList();
    }
  },[chargerInfo])

  // 클릭한 station idx
  const [selected, setSelected] = useState(0);


  useEffect(()=>{
    if(props.clickedStation){
      setSelectedStationId(props.clickedStation.ChargingStationId);
      setPageNum(1);
      if(clickedCharger !== null) setClickedCharger(-1);
    }
  },[props.clickedStation])
  
  useEffect(()=>{
    if(selectedStationId){
      getChargerInfo();
    }
  },[selectedStationId])


  const [loadingListOn, setLoadingListOn] = useState(false);

  return (
    <>
    <div className={detailModalOpen ? 'sub-cpo-charger-management-grid-view-charger-list-container open':'sub-cpo-charger-management-grid-view-charger-list-container' }>
        <div className='sub-cpo-charger-management-grid-view-charger-list-header'>
          <div className={`sub-cpo-charger-management-grid-view-charger-list-wrapper-header`}>
            <p>{`${props.clickedStation.ChargingStationName}의 충전기 목록`}</p>
          </div>
        </div>


        {loadingListOn? <LoadingList height={"500px"}/>:
        <div className='sub-cpo-charger-control-card-container'>
          <>
            {
            chargerInfo &&
            controlHubInfo &&
              (chargerInfo.map((ia,idx)=>{
                if(ia.IsDeleted)return <></>;
                return(
                  <SubChargerCard key={'charger- '+idx}  idx={idx} chargerRef={chargerRef} clickedCharger={clickedCharger} setClickedCharger={setClickedCharger} data={ia} 
                  controlHubData={controlHubInfo?controlHubInfo[idx]:null} 
                  controlhubLoading={chargerHubLoading}/>
              )
              }))
            }
          </>
        </div>
        }
        
        <div className='page-selector-container'>
          {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
          {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
          {pageSelectorRendering()}
          {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
          {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
          <div className='page-total-info'>{`Total : ${totalCount}`}</div>
        </div>
      </div>
    </>
  )
}
export default SubCpoChargerCardList;