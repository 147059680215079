import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";

import { TfiClose } from "react-icons/tfi";
import Selector2 from '../buttons/Selector2.js';
import AsyncSearchSelectorManager from '../buttons/AsyncSearchSelectorManager.js';


import { postCompany, postInfraConstructor, putInfraConstructor } from '../../api/infra/companyApi.js';
import {getMangerList, postManager, putManager, putSetInfraConstructorManager} from '../../api/infra/companyApi';

import { Loading } from '../alerts/Loading.js';

export const CpoInfraConstructorManagerEnrollModal = (props) => {

  const [managerName, setManagerName] = useState(null);
  const [remark, setRemark] = useState(null);
  // 기존 담당자 label
  const [dataLabel, setDataLabel] = useState(null);

  const [errMsg, setErrMsg] = useState(null);
  const [tabIdx, setTabIdx] = useState(0);

  const [category, setCategory] = useState(null);


  const [newCompanyData, setNewCompanyData] = useState(null);
  const [newManagerData, setNewManagerData] = useState({
    "companyId": props.data?.CompanySysId,
    "name": "",
    "position": "",
    "phoneNumber": "",
    "team": ""
  });

  const onMangerDataChange = (e)=>{
    setNewManagerData({...newManagerData, [e.target.name]:e.target.value});
  }

  const reqPostManager = async()=>{
    setLoadingOn(true);
    let result = await postManager(newManagerData);
    if(result.status === 200 || result.status === 201){
      await reqSetInfraConstructorManager({managerSysId:result.data});  
    }
    else{
      setErrMsg('담당자 등록에 실패하였습니다.');
    }
    setLoadingOn(false);
  }

  const reqSetInfraConstructorManager = async(data)=>{
    let {managerSysId} = data;
    let result = await putSetInfraConstructorManager({infraConstructorSysId:props.data?.InfraConstructorSysId, managerSysId:managerSysId});
      if(result.status === 200 || result.status === 201){
        props.setOnOff(false);
        props.reqGetInfraConstructorList(1, {"specialization":"all"});
      }
      else{
        setErrMsg('담당자 등록에 실패하였습니다.');
      }  
  }

  const [loadingOn, setLoadingOn] = useState(false);


  useEffect(()=>{
    if(errMsg){
      setTimeout(()=>{
        setErrMsg(null);
      },3000);
    }
  },[errMsg])
  


  useEffect(()=>{
    if(dataLabel && managerNameRef.current){
      managerNameRef.current.value = null;
    }
  },[dataLabel])

  useEffect(()=>{
    if(managerName){
      setDataLabel(null);
    }
  },[managerName])

  const managerNameRef = useRef(null);

  return (
    <div className="cpo-infra-constructor-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-infra-constructor-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-infra-constructor-enroll-modal-header">
          <title>담당자 등록</title>
          <div className='cpo-infra-constructor-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);props.reqGetInfraConstructorList(1, {"specialization":"all"});}}><TfiClose/></div>
        </div>
        <div className="cpo-infra-constructor-enroll-modal-content">
          {
            tabIdx === 0 ?
              <>
                <div className="cpo-infra-constructor-enroll-modal-content-header">
                  <p className="cpo-infra-constructor-enroll-modal-content-title">신규 담당자 등록</p>
                </div>
                <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                  <p>담당자 이름</p>
                  <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                    <input type="text" placeholder='담당자 이름을 입력해주세요' ref={managerNameRef} value={managerName} onChange={(e)=>{setManagerName(e.target.value)}} />
                  </div>
                </div>
                {/* <div className="cpo-infra-constructor-enroll-modal-content-header">
                  <p className="cpo-infra-constructor-enroll-modal-content-title">기등록 담당자 선택</p>
                </div>
                <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                  <AsyncSearchSelectorManager placeholder="담당자를 선택하세요" value={dataLabel} setValue={setDataLabel} 
                  companyId={props.data?.CompanySysId}
                  managerList={props.data?.Managers}
                  />
                </div> */}
                <div className='cpo-infra-constructor-enroll-modal-button-container'>
                  <div></div>
                  {errMsg && <p className='cpo-infra-constructor-enroll-modal-err-msg'>{errMsg}</p>}
                  <div className={`cpo-infra-constructor-enroll-modal-button ${!managerName && !dataLabel?.label ? "disabled":""}`}  onClick={()=>{if(managerName){setNewManagerData({...newManagerData, name:managerName});  setTabIdx(1);}else if(dataLabel){ reqSetInfraConstructorManager({managerSysId:dataLabel.id}) }}}>
                    {loadingOn ? <Loading/>:<>다음</>}
                  </div>
                </div>
              </>
            :
            tabIdx === 1 ? // 업체 등록
                <>
                  <div className="cpo-infra-constructor-enroll-modal-content-header">
                    <p className="cpo-infra-constructor-enroll-modal-content-title">신규 담당자 등록</p>
                  </div>

                  <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                    <p>담당자 이름</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text" placeholder='담당자 이름을 입력해주세요' value={newManagerData?.name} readOnly/>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>직책</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='직책' name='position' value={newManagerData?.position} onChange={onMangerDataChange}/>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>전화번호</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='전화번호' name='phoneNumber' value={newManagerData?.phoneNumber} onChange={onMangerDataChange}/>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>부서</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='부서' name='team' value={newManagerData?.team} onChange={onMangerDataChange}/>
                    </div>
                  </div>
                  <div className='cpo-infra-constructor-enroll-modal-button-container'>
                    {/* <div className="cpo-infra-constructor-enroll-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                    <div></div>
                    <div className={`cpo-infra-constructor-enroll-modal-button ${!newManagerData.position || !newManagerData.phoneNumber || !newManagerData.team ? "disabled":""}`} onClick={()=>{reqPostManager()}}>등록하기</div>
                  </div>
                </>
            :
            <></>
          }
          
          {/* <div>
            <div className="cpo-infra-constructor-enroll-modal-content-header">
              <p className="cpo-infra-constructor-enroll-modal-content-title">담당자 정보</p>
              <p className='cpo-infra-constructor-enroll-modal-content-desc'>등록 이후에도 담당자 추가/수정이 가능합니다</p>
            </div>
            <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
              <p>이름</p>
              <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                <input type="text"  placeholder='이름' value={name} onChange={(e)=>{setName(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
              <p>휴대전화번호</p>
              <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                <input type="text"  placeholder='휴대전화번호' value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-infra-constructor-enroll-modal-content-wrapper divide">
              <div>
                <p>부서 이름</p>
                <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                  <input type="text"  placeholder='부서 이름' value={division} onChange={(e)=>{setDivision(e.target.value)}}/>
                </div>
              </div>
              <div>
                <p>직책</p>
                <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                  <input type="text"  placeholder='직책' value={role} onChange={(e)=>{setRole(e.target.value)}}/>
                </div>
              </div>
            </div>
          </div> */}

        </div>
        
      </div>
    </div>
  )
}
export default CpoInfraConstructorManagerEnrollModal
