import React, { useState, useEffect } from 'react';
import { chargerTypeConverter, connectorTypeConverter2 } from '../../util/util';
import { FaChevronDown } from "react-icons/fa6";

import { getTag } from '../../api/tag/tagApi';
import {getEvUserCreditCards} from '../../api/payment/paymentApi';


export const CpoEvUserListItem = (props) => {

  const reqGetTags = async()=>{
    let temp = await getTag(props.data?.EvUserSysId);
    if(temp.status === 200){
      setTagList(temp.data);
    }
  }

  const [creditCardList, setCreditCardList] = useState([]);

  const reqGetEvUserCreditCards = async()=>{
    let temp = await getEvUserCreditCards(props.data?.EvUserSysId);
    if(temp.status === 200){
      setCreditCardList(temp.data);
    }

  }

  const [tagList, setTagList] = useState([]);
  
  const [tabOpen, setTabOpen] = useState(false);

  useEffect(()=>{
    if(tabOpen){
      reqGetTags();
      reqGetEvUserCreditCards();
    }
  }
  ,[tabOpen])

  const getCardBrandImage = (brand)=>{
    switch (brand) {

      case '마스터':
        return "mastercard";
      case '비자':
        return 'visa';
      case '아멕스':
        return "amex";
      case '다이너스':
        return "dinersclub";
      case 'JCB':
        return "jcb";
      case '은련':
        return 'unionpay';
      default:
        return 'local';
    }
  }

  return (
    <div className={'cpo-ev-user-management-content-item-container '+`${tabOpen ? "open":""}`}>
      <div className={'cpo-ev-user-management-content-item'} onClick={()=>{setTabOpen(!tabOpen)}}>
        
        <div><p>{props.data?.RealName}</p></div>
        <div><p>{props.data?.EvUserId}</p></div>
        <div><p>{props.data?.PhoneNumber}</p></div>
        <div><p>{props.data?.Address}</p></div>
        
        <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
        
      </div>
      <div className='cpo-ev-user-management-content-item-content'>
        <div><p>idTag 정보</p></div>
        <div className="cpo-ev-user-management-id-tag-list-container">
          {
          tagList&&tagList.length!==0 ?
          null
          :
          <div className="cpo-ev-user-management-id-tag-list-item-column">
            <div></div>
            <div>idTag 없음</div>
            <div></div>
          </div>
          }
          <div className="cpo-ev-user-management-id-tag-list-item-container">
            {
              tagList&&tagList.length!==0&&
              <div className="cpo-ev-user-management-id-tag-list-item-column">
                <div></div>
                <div>idTag</div>
                <div>사용가능여부</div>
                <div>타입</div>
              </div>
            }
            {
              tagList&&tagList.length!==0&&tagList.map((item,itemIdx)=>{
                return(
                  <div className="cpo-ev-user-management-id-tag-list-item">
                    <div>{itemIdx+1}</div>
                    <div>{item.idTag}</div>
                    <div>{item.isBlocked ? "사용불가":"사용가능"}</div>
                    <div>{item.tagType === "EvUser" ? "직접등록":"자동발급"}</div>
                  </div>
                )
              })
            }
          </div>          
        </div>
        <div><p>카드 정보</p></div>
        <div className="cpo-ev-user-management-id-tag-list-container">
          {
          tagList&&tagList.length!==0 ?
          null
          :
          <div className="cpo-ev-user-management-id-tag-list-item-column">
            <div></div>
            <div>등록된 카드 정보 없음</div>
            <div></div>
          </div>
          }
          <div className="cpo-ev-user-management-id-tag-list-item-container">
            {
              creditCardList&&creditCardList.length!==0&&
              <div className="cpo-ev-user-management-card-list-item-column">
                <div></div>
                <div>브랜드</div>
                <div>카드사</div>
                <div>종류</div>
                <div>명의</div>
                <div>별명</div>
                <div>번호</div>
              </div>
            }
            {
              creditCardList&&creditCardList.length!==0&&creditCardList.map((item,itemIdx)=>{
                return(
                  <>
                    <div className="cpo-ev-user-management-card-list-item">
                      <div>{itemIdx+1}</div>
                      <div>
                        <img className={`${getCardBrandImage(item.binInfo?.brand)}`} alt="" />
                      </div>
                      <div>{item.binInfo?.issuer}</div>
                      <div>{item.binInfo?.cardType}</div>
                      <div>{item.binInfo?.entityType}</div>
                      <div>{item.name}</div>
                      <div>{item.number}</div>
                    </div>
                  </>
                )
              })
            }
          </div>          
        </div>
      </div>
    </div>
  )
}
export default CpoEvUserListItem;