import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaCheck } from "react-icons/fa6";
import { getCookie } from '../../util/util.js';

export const SubscriptionEnrolledCard = (props) => {
  let signedToken = getCookie("signedToken");

  function formatDate(isoString) {
    
    // ISO 8601 문자열을 Date 객체로 변환
    const date = new Date(isoString);

    // 연도, 월, 일을 추출
    const year = String(date.getFullYear()).slice(2); // 연도의 마지막 두 자리를 가져옴
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월을 2자리로 맞춤
    const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 맞춤

    // YY-MM-DD 형식으로 변환하여 반환
    return `${year}.${month}.${day}`;
  }

  const [detailedModalOn, setDetailedModalOn] = useState(false);
  useEffect(()=>{
  },[])


  const removeSubscriptionCancel = async()=>{
    await axios.put(`/subscription/api/cpoadmin/Subscriptions/${props.data?.subscriptionId}/Cancel`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }).then((res)=>{
      props.getCurrentSubscriptionList();
    })
  }

  const [itemStatus, setItemStatus] = useState(props.data.renewalStatus ? "subscribe" : new Date()<new Date(props.data.endDate) ? "cancel": "expired");


  return (
    <>
      {
        itemStatus === "subscribe" ?
        <>
        <div className={"cpo-subscription-enrolled-card-container "}>
          <div className='cpo-subscription-enrolled-card-status-container'>
            구독중
          </div>
          <div className="cpo-subscription-enrolled-card-title-container">
            {
            detailedModalOn&&<EnrolledSubscriptionDetailedModal detailedInfo={props.detailedInfo}/>
            }
            <p className='cpo-subscription-title'>{props.data.subscriptionProduct.normalizedName}</p>
            <p onClick={()=>{setDetailedModalOn(!detailedModalOn)}}>혜택 자세히보기</p>
          </div>
          <div className='cpo-subscription-enrolled-card-divider'></div>
          <div className='cpo-subscription-enrolled-card-period-container'>
            <p>{props.data.currentBillingType ==="MONTHLY" ? "월간 ":"연간 "}</p>
          </div>
          <div className='cpo-subscription-enrolled-card-divider'></div>
          <div className="cpo-subscription-enroll-date-container">
            <div>
              <p className={'cpo-subscription-enroll-date-title '}>시작일</p>
              <p className={"cpo-subscription-enroll-date "}>{formatDate(props.data.startDate)}</p>
            </div>
            <p>/</p>
            <div>
              <p className='cpo-subscription-enroll-date-title'>다음 결제 예정일</p>
              <p className={"cpo-subscription-enroll-date "}>{formatDate(props.data.endDate)}</p>
            </div>
            <div className='cpo-subscription-termination-btn' onClick={(e)=>{e.stopPropagation(); props.setSubscriptionItem(props.data); props.setCancelModalOn(true)}}>
              <p>
                해지
              </p>
            </div>
          </div>
        </div>
        </>
        :
        itemStatus === "cancel" ?
        <>
        <div className={"cpo-subscription-enrolled-card-container "}>
          <div className='cpo-subscription-enrolled-card-status-container cancel'>
            만료예정
          </div>
          <div className="cpo-subscription-enrolled-card-title-container">
            {
            detailedModalOn&&<EnrolledSubscriptionDetailedModal detailedInfo={props.detailedInfo}/>
            }
            <p className='cpo-subscription-title'>{props.data.subscriptionProduct.normalizedName}</p>
            <p onClick={()=>{setDetailedModalOn(!detailedModalOn)}}>혜택 자세히보기</p>
          </div>
          <div className='cpo-subscription-enrolled-card-divider'></div>
          <div className='cpo-subscription-enrolled-card-period-container'>
            <p>{props.data.currentBillingType ==="MONTHLY" ? "월간 ":"연간 "}</p>
            <p>구독 만료 예정</p>
          </div>
          <div className='cpo-subscription-enrolled-card-divider'></div>
          <div className="cpo-subscription-enroll-date-container">
            <div>
              <p className={'cpo-subscription-enroll-date-title cancelStart'}>시작일</p>
              <p className={"cpo-subscription-enroll-date cancelStart"}>{formatDate(props.data.startDate)}</p>
            </div>
            <p>/</p>
            <div>
              <p className='cpo-subscription-enroll-date-title'>만료 예정일</p>
              <p className={"cpo-subscription-enroll-date cancel"}>{formatDate(props.data.endDate)}</p>
            </div>
              <div className='cpo-subscription-termination-btn cancel' onClick={(e)=>{e.stopPropagation(); }}>
                <p>
                  해지 취소
                </p>
              </div>
          </div>
        </div>
        </>
        :
        itemStatus === "expired" ?
        <></>
        :
        <>
        <div className={"cpo-subscription-enrolled-card-container "}>
          <div className='cpo-subscription-enrolled-card-status-container expired'>
            만료
          </div>
          <div className="cpo-subscription-enrolled-card-title-container">
            {
            detailedModalOn&&<EnrolledSubscriptionDetailedModal detailedInfo={props.detailedInfo}/>
            }
            <p className='cpo-subscription-title'>{props.data.subscriptionProduct.normalizedName}</p>
            <p onClick={()=>{setDetailedModalOn(!detailedModalOn)}}>혜택 자세히보기</p>
          </div>
          <div className='cpo-subscription-enrolled-card-divider'></div>
          <div className='cpo-subscription-enrolled-card-period-container'>
            <p>{props.data.currentBillingType ==="MONTHLY" ? "월간 ":"연간 "}</p>
          </div>
          <div className='cpo-subscription-enrolled-card-divider'></div>
          <div className="cpo-subscription-enroll-date-container">
            <div>
              <p className={'cpo-subscription-enroll-date-title ' + `${props.data.renewalStatus ?"":"cancelStart"}`}>시작일</p>
              <p className={"cpo-subscription-enroll-date " + `${props.data.renewalStatus ?"":"cancelStart"}` }>{formatDate(props.data.startDate)}</p>
            </div>
            <p>/</p>
            <div>
              <p className='cpo-subscription-enroll-date-title'>{props.data.renewalStatus ? "결제 예정일" : "만료 예정일"}</p>
              <p className={"cpo-subscription-enroll-date " + `${props.data.renewalStatus ?"":"cancel"}`}>{formatDate(props.data.endDate)}</p>
            </div>
            {props.data.renewalStatus ?
              <div className='cpo-subscription-termination-btn' onClick={(e)=>{e.stopPropagation();}}>
                <p>
                  해지
                </p>
              </div>
              :
              <div className='cpo-subscription-termination-btn cancel' onClick={(e)=>{e.stopPropagation(); }}>
                <p>
                  해지 취소
                </p>
              </div>
            }
          </div>
        </div>
        </>
      }
    </>
  )
}
export default SubscriptionEnrolledCard;

const EnrolledSubscriptionDetailedModal = (props)=>{
  
  return(
    <>
      <div className='cpo-subscription-detailed-modal-container'>
        <div className='cpo-subscription-detailed-modal-content-wrapper'>
          <FaCheck/>
          <p>{props.detailedInfo.emoovFeatures.chargerLimit}개 충전기</p>
        </div>
      </div>
    </>
  );
}