import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const ProtectedRouteForSuper = () => {
  const user = useSelector((state) => state.user);

  if(process.env.REACT_APP_SUPER_ID && user.cpoId === process.env.REACT_APP_SUPER_ID){
    return <Outlet />;
  }
  else return <Navigate to="/404" replace />; // 접근 불가 시 404 페이지 이동
};

export default ProtectedRouteForSuper;