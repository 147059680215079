import axios from 'axios';
import { getCookie, refresh } from '../../util/util';

// cpo가 소유한 station GET
export const getStationList = async()=>{  
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/infra/api/cpouser/v1/ChargingStations?size=50`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    },
  })
  .then((res)=>{
    // temp = [...res.data.Results.filter(it=>it.OpStatus!=="삭제됨")];
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// station 정보 GET by chargingStationId
export const getStationInfraInfoById = async(id)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/infra/api/cpouser/v1/ChargingStations/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    },
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cpo가 소유한 station GET 커스텀
export const getStationListCustom = async(pageNum, data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {keyword=null, ownerName=null, operatorName=null, isItInOperation=null, facilityClassification=null, facilitySubdivision=null, numberOfParkingSpaces=null, isThereAParkingFee=null, sort=null, order=null} = data || {};
  let temp;
  await axios.get(`/infra/api/cpouser/v1/ChargingStations?page=${pageNum?pageNum:1}&size=10${keyword?`&Keyword=${keyword}`:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    },
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

export const getStationListForSelect = async(search, page)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const response = await fetch(`/infra/api/cpouser/v1/ChargingStations?size=10&page=${page}&Keyword=${search}`,{headers: {
    Authorization: `Bearer ${signedToken}`
  }});
  return response;
}



// cpo가 소유한 station DELETE
export const deleteStation = async(id)=>{  
  const signedToken =  getCookie('signedToken')
  
  await axios.delete(`/infra/api/cpouser/v1/ChargingStations/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    
  })
  .catch(()=>{})
}

//stationNameDuplicationCheck
export const getStationNameDuplicationCheck = async(name)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/infra/api/cpouser/v1/ChargingStations/isExist?codeName=${name}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    console.log(res.data)
    temp = !res.data;
  })
  .catch(()=>{
    temp = false;
  })
  return temp;
}

// station 추가 POST
export const postStation = async(name)=>{  
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.post(`/infra/api/cpouser/v1/ChargingStations`,
    {
    chargingStationName:name
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Charger 전체 GET
export const getAllCharger = async(data)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Chargers`,
  {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }
  )
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err)
  })
  return temp;
}

// Charger 추가 POST
export const postCharger = async(data)=>{  
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.post(`/infra/api/cpouser/v1/Chargers`,
  { 
    displaySerialNumber : data.displaySerialNumber,
    chargerModelId : data.chargerModelId,
    chargingStationId : data.chargingStationId,
    chargePointSerialNumber: data.chargePointSerialNumber,
    protocol: data.protocol,
  },
  {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }
  )
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// charger 정보 API GET
export const getChargerList = async(stationList)=>{
  const signedToken =  getCookie('signedToken')
  let temp = [];
  for await(const it of stationList){
    await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
    .catch(()=>{})
  }
  return temp;
}

// charger 정보 API GET
export const getChargerInfo = async(id)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Chargers/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}

// charger 삭제
export const deleteCharger = async(id)=>{
  const signedToken =  getCookie('signedToken')
  await axios.delete(`/infra/api/cpouser/v1/Chargers/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
  })
  .catch(()=>{})
}

// /infra/api/cpouser/v1/Chargers?ChargingStationId=9
// charger 정보 API GET -> station 필터링
export const getChargerByStationId = async(id)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

export const getChargerListByStationId = async(id, pageNum)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${id}&size=10&page=${pageNum}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}







// ******************************************************************************************
// * Manufacturers CpoUser
// ******************************************************************************************

// 충전기 제조사 조회 GET
export const getChargerManufacturerList = async()=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Manufacturers`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}


// ******************************************************************************************
// * ChargerModel CpoUser
// ******************************************************************************************

// 충전기 모델 조회 GET - 삭제된 것 제외
export const getChargerModelList = async(page=1, data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {keyword} = data || {};
  await axios.get(`/infra/api/cpouser/v1/ChargerModels?IsDeleted=false&page=${page}&size=10${keyword?`&keyword=${keyword}`:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}


// chargerModel 정보 API POST ->
export const postChargerModel = async(modelInfo)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.post(`/infra/api/cpouser/v1/ChargerModels`,modelInfo,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// chargerModel 정보 API PUT
export const putChargerModel = async(id, modelInfo)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.put(`/infra/api/cpouser/v1/ChargerModels/${id}`,modelInfo,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// chargerModel 정보 API DELETE
export const deleteChargerModel = async(id)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.delete(`/infra/api/cpouser/v1/ChargerModels/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}





// 충전기 DisplaySerialNumber 중복 확인
export const getChargerDisplaySerialNumberDuplicationCheck = async(serialNumber)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Chargers/ValidateDisplaySerialNum?displaySerialNum=${serialNumber}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}


// 하위 CPO 충전소 목록 조회
export const getSubCpoStationList = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {rootCpoSysId, subCpoSysId} = data;
  await axios.get(`/infra/api/cpouser/v1/SubCpoChargingStations?rootCpoSysId=${rootCpoSysId}&subCpoSysId=${subCpoSysId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 하위 CPO 충전기 목록 조회 by stationId
export const getSubCpoChargerList = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {stationId, rootCpoSysId, subCpoSysId, displayId} = data;
  await axios.get(`/infra/api/cpouser/v1/SubCpoChargers/${stationId}?rootCpoSysId=${rootCpoSysId}&subCpoSysId=${subCpoSysId}${displayId ? `&displayId=${displayId}`:""}     `,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}