import React, {useState, useRef, useEffect} from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { setNum } from '../../store.js';
import axios from 'axios';
import Sidebar from "../../components/sidebars/Sidebar.js";
import { FiChevronsLeft } from "react-icons/fi";
import { FaInfo } from "react-icons/fa";

import { getCookie, signedOut, getCpoId } from '../../util/util.js';

// CSS
import '../../styles/MainPage.css'
import '../../styles/CpoControl.css'
import '../../styles/CpoFastEnroll.css'
import '../../styles/CpoManagement.css'
import '../../styles/CpoChargerManagement.css'
import '../../styles/CpoCustomerService.css'
import '../../styles/CpoSystemSetting.css'
import '../../styles/CpoAccountManagement.css'
import '../../styles/CpoChargingFeeManagement.css'
import '../../styles/CpoInstallManagement.css'
import '../../styles/CpoCompany.css'
import '../../styles/CpoLogReport.css'
import '../../styles/CpoPayReport.css'
import '../../styles/CpoMemberInfoUpdate.css'
import '../../styles/CpoChargerModelManagement.css'
import '../../styles/CpoGroupManagement.css'
import '../../styles/CpoInvitationManagement.css'
import '../../styles/CpoDashBoard.css'
import '../../styles/CpoChas.css'
import '../../styles/SysChasProfileManagement.css'
import '../../styles/CpoIncomeReport.css'
import '../../styles/CpoFirmwareManagement.css'
import '../../styles/CpoStationChargingFeeManagement.css'
import '../../styles/CpoChargingEventManagement.css'
import '../../styles/CpoEvUserManagement.css'

const excludeRoute = process.env.REACT_APP_EXCLUDED_PAGE_IDS.replaceAll(" ",'').split(',');

const CpoMainPage = ({ children }) => {
  const user = useSelector((state) => state.user);
  let cpoId = user.cpoId;
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");
  let LocalRealName = user.name;
  const getCHASList = async()=>{
    await axios.get(`/controlhub/api/cpouser/v1/ActionSets`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then(async(res)=>{
      if(res.data?.length === 0){
        await axios.post(`/controlhub/api/cpouser/v1/ActionSets`,
        {
          "name": "default",
          "description": "기본"
        },{
          headers: {
          Authorization: `Bearer ${signedToken}`
        }
        })
        .then((res)=>{
          
        })
        .catch((err)=>{

        })
      }
    })
    .catch((err)=>{
      
    })
  }
  useEffect(()=>{
    if(!signedToken){
      window.location.replace('/login');
      return;
    }
    getCHASList();
  },[])

  let page = useSelector((state) => state.page );
  let navigator = useNavigate();

  // 대분류 (세부 아이템 있는 경우)
  const [category, setCategory] = useState("")
  // 타이틀
  const [title, setTitle] = useState("대시보드")
  
  // 페이지 변경 시 타이틀 설정
  useEffect(()=>{
    if(page.num.id == -1)return;
    if(page.num.id == -2)return;
    const found = menuItems.find((el) => el.id == page.num.id);
    if(found.items){
      setCategory(found.name);
      if(page.num.postFix){
        setTitle(found.items[page.num.idx]+page.num.postFix);
      }
      else setTitle(found.items[page.num.idx]);
    }
    else{
      setCategory("");
      setTitle(found.name);
    }
  },[page])

  let roleList = user.roles;
  useEffect(()=>{
    getRoleInfo();
  },[])
  
  const getRoleInfo = async()=>{
    if(roleList.length === 0){
      dispatch(setNum({id:-2}));
      navigator('/invitation-verification');
    }
    else{
      if(process.env.REACT_APP_SUPER_ID && cpoId === process.env.REACT_APP_SUPER_ID && process.env.REACT_APP_TARGET !== 'emoov'){
        setIsCpoAdmin(true);
        setIsCpoSuperAdmin(true);
      }
      else if(roleList.includes("SysAdmin")!==false){setIsSysAdmin(true)}
      else if(roleList.includes("CpoAdmin")!==false){setIsCpoAdmin(true)}
      else if(roleList.includes("CpoUser")!==false){
        setIsCpoUser(true)
        if(roleList.includes("CpoViewer")!==false){setIsCpoViewer(true)}
      }
      else {
        dispatch(setNum({id:-2}));
        navigator('/invitation-verification');
      }
    }
  }


  const [isSysAdmin, setIsSysAdmin] = useState(false);
  const [isCpoAdmin, setIsCpoAdmin] = useState(false);
  const [isCpoSuperAdmin, setIsCpoSuperAdmin] = useState(false);
  const [isCpoUser, setIsCpoUser] = useState(false);
  const [isCpoViewer, setIsCpoViewer] = useState(false);


  // 페이지 변경 시 라우팅
  useEffect(()=>{
    if(page.num.id == -1)return;
    if(page.num.id == -2)return;
    renderSection()
  }
  ,[page.num])

  // 라우팅 function
  const renderSection = ()=>{
    if(roleList.length === 0){
      dispatch(setNum({id:-2}));
      navigator('/invitation-verification');
      return;
    }
    switch (page.num.id) {
      case 0: //대시보드
        navigator('/cpo');
        break
      
      case 1: //빠른등록
        navigator('/cpo/fast-enroll');
        break

      case 2: //충전소관리
        if(process.env.REACT_APP_SUPER_ID && (cpoId === process.env.REACT_APP_SUPER_ID) && process.env.REACT_APP_TARGET !== 'emoov'){
          switch (page.num.idx) {
            case 0: // 직영지점
              navigator('/cpo/station-management');
              break;
            
            case 1: // 위탁사업자
              navigator('/cpo/sub-station-management');
              break;
            
            default:
              navigator('/cpo/station-management');
              break;
          }
        }
        else navigator('/cpo/station-management');
        break
      
      case 3: //충전기 관리
        navigator('/cpo/charger-management');
        break
      
      case 4: //설치업체 관리
        navigator('/cpo/infra-constructor-management');
        break
      
      case 5: //충전기모델 관리
        navigator('/cpo/charger-model-management');
        break
      
      case 6: //충전요금관리
        switch (page.num.idx) {
          case 0: // 완속
            navigator('/cpo/charging-fee-management');
            break;
        
          case 1: // 급속
            navigator('/cpo/fast-charging-fee-management');
            break;
        
          default:
            navigator('/cpo/charging-fee-management');
            break;
        }
        break
      
      case 7: //충전이벤트관리
        navigator('/cpo/charging-event-management');
        break

      case 8: //충전기 운용제어
        if(process.env.REACT_APP_SUPER_ID && (cpoId === process.env.REACT_APP_SUPER_ID) && process.env.REACT_APP_TARGET !== 'emoov'){
          switch (page.num.idx) {
            case 0: // 직영지점
              navigator('/cpo/charger-control');
              break;
            
            case 1: // 위탁사업자
              navigator('/cpo/sub-charger-control');
              break;
            
            default:
              navigator('/cpo/charger-control');
              break;
          }
        }
        else{
          navigator('/cpo/charger-control');
          break
        }
        break;

      case 9: //충전기로그조회
        if(page.num.detail)break;
        navigator('/cpo/log-report');
        break

      case 10: //충전결제이력
        if(page.num.detail)break;
        navigator('/cpo/pay-report');
        break
            
      case 11: // 고객서비스관리
        switch (page.num.idx) {
          case 0: // FAQ
            navigator('/cpo/faq');
            break
          
          case 1: // 1:1 질문
            navigator('/cpo/qna');
            break
          
          case 2: // 공지사항
            if(page.num.detail)break;
            if(page.num.edit)break;

            navigator('/cpo/notice');
            break
          
          case 3: // 고장신고
            navigator('/cpo/breakdown');
            break
          
          case 4: // 개인정보처리방침 및 기타 동의서
            navigator('/cpo/privacy-policy');
            break
          
          default:
            navigator('/cpo/qna');
            break
        }
      break
      
      case 12: // 시스템설정
        switch (page.num.idx) {
          case 0: // 구독
            navigator('/cpo/subscription');
            break
          
          case 1: // 시스템 제공 CHAS
            if(page.num.detail)break;
            navigator('/cpo/system-chas');
            break
          
          case 2: // 사용자 정의 CHAS
            if(page.num.detail)break;
            navigator('/cpo/custom-chas');
            break
          
          case 3: // 업체관리
            navigator('/cpo/company-management');
            break
          
          default:
            navigator('/cpo/subscription');
            break
        }
      break

      case 13: // 계정 정보 관리
        switch (page.num.idx) {
          case 0: // 결제수단
            navigator('/cpo/payment-method');
            break
          
          case 1: // 법인사업자인증
            navigator('/cpo/business-authorize');
            break
          
          case 2: // 회원정보 변경
            navigator('/cpo/member-info-update');
            break
          
          case 3: // 회원탈퇴
            navigator('/delete-account');
            break
          
          case 4: // 결제사 정보조회
            navigator('/cpo/payment-company');
            break
          
          case 5: // 화면 설정
            navigator('/cpo/screen-setting');
            break
          
          default:
            navigator('/cpo/payment-method');
            break
        }
      break

      case 14: // 조직 관리
        navigator('/cpo/group-management');
        break
      
      case 15: // 결제내역 조회 및 정산
        if(page.num.detail)break;
        navigator('/cpo/income-report'); // 정산
        break
      
      case 16: // 충전소별 요금 관리
        if(page.num.detail)break;
        navigator('/cpo/station-charging-fee-management');
        break
      
      case 17: // 펌웨어 관리
        navigator('/cpo/firmware-management');
        break
      
      case 18: // 초대관리
        navigator('/cpo/invitation-management');
        break
      
      case 19: // EV사용자정보조회
        navigator('/cpo/evuser-check');
        break

      case 20: // 충전기제조업체 관리
        navigator('/cpo/manufacturer-management');
        break
      
      case 1001:
        if(page.num.detail)break;
          navigator('/cpo/chas-profile-management');
        break
      
      /// Added by edeward at 2024.11.26
      /// Start Code
      case 101:
        if(process.env.REACT_APP_STAGE === 'Development') {
          if(page.num.report) break;
          navigator('/cpo/station-zoom');
        }
        break;
      /// End End
      case 102:
        if(process.env.REACT_APP_STAGE === 'Development') {
          navigator('/cpo/dashboard-test');
        }
        break;

      case -2:
        navigator('/invitation-verification');
        break;

      default:
        navigator("/cpo")
        break
    }

  };

  // nav 오픈 여부 state
  const [navOff, setNavOff] = useState("");

  // menu 아이템 리스트
  const menuItems = [
    {
      name:'대시보드',
      id: 0,
    },
    isCpoViewer?{}:{
      name:'빠른 등록',
      id: 1,
    },
    isCpoViewer?{}:{
      title: "충전 인프라 관리"
    },
    isCpoViewer||isCpoSuperAdmin?{}:{
      name:'충전소 관리',
      id: 2,
    },
    !isCpoSuperAdmin?{}:{
      name:'충전소 관리',
      id: 2,
      items:[
        '직영지점',
        '위탁사업자'
      ]
    },

    isCpoViewer?{}:{
      name:'충전기 관리',
      id: 3,
    },
    !isCpoViewer&&(excludeRoute.includes("4")===false) ? {
      name:'설치 업체 관리',
      id: 4,
    }:{},
    !isCpoViewer&&(excludeRoute.includes("20")===false)?{
      name:'충전기 제조 업체 관리',
      id: 20,
    }:{},
    isCpoViewer?{}:{
      name:'충전기 모델 관리',
      id: 5,
    },
    isCpoViewer?{}:{
      name:'충전기 펌웨어 관리',
      id: 17,
    },

    isCpoViewer?{}:{
      title : '요금 관리'
    },
    isCpoViewer?{}:{
      name:'기본 요금 관리',
      id: 6,
      items:[
        (excludeRoute.includes("6a")===false)?'완속 기본 요금 관리':null,
        (excludeRoute.includes("6b")===false)?'급속 기본 요금 관리':null,
      ]
    },
    isCpoViewer?{}:{
      name:'충전소별 요금 관리',
      id: 16,
    },
    isCpoViewer?{}:{
      name:'이벤트 요금 관리',
      id: 7,
    },
    {
      title : '제어'
    },
    !isCpoSuperAdmin?{}:{
      name:'충전기 운용 제어',
      id: 8,
      items:[
        '직영지점',
        '위탁사업자'
      ]
    },
    isCpoSuperAdmin?{}:{
      name:'충전기 운용 제어',
      id: 8,
    },
    {
      name:'충전기 데이터 기록 조회',
      id: 9,
    },
    {
      name:'충전 기록 조회',
      id: 10,
    },
    {
      title : '정산'
    },
    {
      name:'결제 내역 조회 및 정산',
      id: 15,
    },
    isCpoViewer?{}:{
      title : '고객 정보 조회'
    },
    isCpoViewer?{}:{
      name:'EV 사용자 정보 조회',
      id: 19,
    },
    isCpoViewer?{}:{
      title : '시스템 관리'
    },
    isCpoViewer?{}:{
      name:'고객 서비스 관리',
      id: 11,
      items:[
        (excludeRoute.includes("11a")===false)?'자주 묻는 질문(FAQ)':null,
        (excludeRoute.includes("11b")===false)?'1:1 질문':null,
        (excludeRoute.includes("11c")===false)?'공지사항':null,
        '고장신고',
        // (excludeRoute.includes("L7")===false)?'개인정보처리방침 및 기타 동의서':null,
      ]
    },
    isCpoViewer?{}:{
      name:'시스템 설정',
      id: 12,
      items:[
        (excludeRoute.includes("12a")===false)&&isCpoAdmin ? '구독':null,
        "시스템 제공 CHAS",
        "사용자 정의 CHAS",
        (process.env.REACT_APP_STAGE == "Development") ? "업체 관리":null,
      ]
    },
    {
      title : '시스템관리자',
      role: 'SysAdmin'
    },
    {
      name:'Profile 관리',
      id: 1001,
    },
    {
      title : '계정 및 조직'
    },
    {
      name:'내 정보 관리',
      id:13,
      items:[
        !isCpoViewer&&(excludeRoute.includes("13a")===false)?'결제수단':null,
        !isCpoViewer&&(excludeRoute.includes("13b")===false)?'법인사업자 인증':null,
        '회원정보 변경',
        '회원 탈퇴',
        // (excludeRoute.includes("L8")===false)?'결제사 정보조회':null,
        // (excludeRoute.includes("L9")===false)?'화면 설정':null,
      ]
    },
    isCpoAdmin?{
      name:'조직 관리',
      id:14,
    }:{},
    (isCpoAdmin && excludeRoute.includes("18")===false)?{
      name:'초대 관리',
      id:18,
    }:{},
    
    (process.env.REACT_APP_STAGE === 'Development')?{
      title : '실험실'
    }:{},
    (process.env.REACT_APP_STAGE === 'Development')?{
      name : '충전소 입지추천',
      id: 101,
      test: true,
    }:{},
    (process.env.REACT_APP_STAGE === 'Development')?{
      name : '대시보드 테스트',
      id: 102,
      test: true,
    }:{}
  ];

  // nav버튼 Ref array
  let navButtonsRef = useRef([]);
  // navSub버튼 Ref array
  let navSubButtonsRef = useRef({});

  


  return (
    <>
      <div className="main-page-container">
        <div className={'main-nav-controller '+navOff} onClick={()=>{navOff === "" ? setNavOff("off"):setNavOff("")}}>
          <FiChevronsLeft/>
        </div>
        <nav className={'main-nav '+navOff}>
          <div className="nav-logo-container">
            <img className='nav-logo' alt="" src={process.env.PUBLIC_URL+process.env.REACT_APP_LOGO} onClick={()=>{
              navigator("/"); 
            }}/>
          </div>
          <div className="nav-button-list-container">
            <Sidebar navButtonsRef={navButtonsRef} navSubButtonsRef={navSubButtonsRef} menuItems={menuItems}/>
          </div>
        </nav>
        <div className={'main-nav-back '+navOff}></div>
        <div className={navOff !== 'off' ? 'main-view-container' : 'main-view-container navOff'}>
          <header className='main-view-header'>
            <div className="main-view-title-container">
              <h3>{category}</h3>
              {
                title !== "시스템 제공 CHAS"?
                  title !== "사용자 정의 CHAS"?
                    <h2>{title}</h2>
                  :<h2>사용자 정의 CHAS (Control Hub Action Set)</h2>
                :<h2>시스템 제공 CHAS (Control Hub Action Set)</h2>
              }
              { title === "위탁사업자" && page.num.id == 2 && <div className='main-view-more-info'><FaInfo/>위탁사업자의 충전소와 충전기 목록을 볼 수 있으며 로밍 인프라로 등록을 할 수 있는 뷰입니다.</div>}
              { title === "위탁사업자" && page.num.id == 8 && <div className='main-view-more-info'><FaInfo/>위탁사업자의 충전기 운용 상태를 볼 수 있는 뷰입니다.</div>}
            </div>
            <div className='main-nav-user-info-container'>
              <p className='main-nav-go-to-main-button' onClick={()=>{navigator("/");}}>메인 페이지</p>
              <p><color>{isSysAdmin?"시스템 관리자":isCpoAdmin?"CPO 관리자":isCpoUser?"CPO 일반회원":"일반회원"}</color><strong>{` ${LocalRealName}`}</strong>{' 님'}</p>
              <p onClick={()=>{signedOut();}}>로그아웃</p>
            </div>
          </header>
          <section>
            <Outlet />
          </section>
        </div>
      </div>
      <footer>
        <p>Powered by EMOOV Platform.</p>
        <p>Copyright (C) (주)모니트 All right Reserved.</p>
        <p>1.0.13+15</p>
      </footer>
    </>
  )
}

export default CpoMainPage;
