import React, { useRef, useState, useEffect } from 'react';
import StationListForCharger from '../components/StationListForCharger.js';
import ChargerList from '../components/ChargerList.js';
import '../styles/ChargerManagementGridView.css';
import { HiCursorClick } from "react-icons/hi";
import { BsEvStationFill } from "react-icons/bs";
import { useLocation } from 'react-router-dom';

export const ChargerManagementGridView = () => {
  let location = useLocation();
  const [clickedStation, setClickedStation] = useState(null);

  useEffect(()=>{
    if(location.state) setClickedStation(location.state.stationData);
  },[location])

  return (
    <>
      <div className='cpo-charger-management-grid-view-charger-list-header'>
        {`충전소 목록`}
      </div>
      <StationListForCharger initialStationName={location.state?.stationData?.ChargingStationName } setClickedStation={setClickedStation}/>
      <div className='cpo-charger-management-grid-view-charger-list-wrapper'>
        {
          clickedStation 
          ?
          <>
            <ChargerList clickedStation={clickedStation}/>
          </>
          :
          <>
          <div className='cpo-charger-management-grid-view-before-select'>
            <div>
              <BsEvStationFill/>
              <HiCursorClick/>
            </div>
            충전소를 선택해주세요.
          </div>
          </>
        }
      </div>
    </>
  )
}
export default ChargerManagementGridView;