import axios from 'axios';
import { getCookie, refresh } from '../../util/util';


// ******************************************************************************************
// * Companies
// ******************************************************************************************

// company 조회
export const getCompanyList = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {page, size=10} = data;
  let query = "";
  if(page){query += `page=${page?page:1}`;}
  if(size){query += `&size=${size}`;}
  await axios.get(`/infra/api/cpouser/v1/Companies?${query}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

export const getCompanyListForSelect = async(keyword, page)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const response = await fetch(`/infra/api/cpouser/v1/Companies?size=10&page=${page}`,{headers: {
    Authorization: `Bearer ${signedToken}`
  }});
  return response;
}

// company 조회 by id
export const getCompany = async(id) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Companies/${id}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// company 생성
export const postCompany = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {name} = data;
  await axios.post(`/infra/api/cpouser/v1/Companies`, {
    "name": name
  }, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// company 수정
export const putCompany = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {id, name} = data;
  await axios.put(`/infra/api/cpouser/v1/Companies/${id}`, {
    "name": name
  }, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// company 삭제
export const deleteCompany = async(id) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/infra/api/cpouser/v1/Companies/${id}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Manager 검색
export const getMangerList = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {companyId, page, size, keyword} = data;
  let query = "";
  if(page){query += `page=${page?page:1}`;}
  if(size){query += `&size=${size}`;}
  if(keyword){query += `&keyword=${keyword}`}
  await axios.get(`/infra/api/cpouser/v1/Companies/${companyId}/Managers?${query}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

export const getManagerListForSelect = async(keyword, page, companyId)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const response = await fetch(`/infra/api/cpouser/v1/Companies/${companyId}/Managers?size=10&keyword=null&page=${page}`,{headers: {
    Authorization: `Bearer ${signedToken}`
  }});
  return response;
}

// Manager 생성
export const postManager = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {companyId, name, position, phoneNumber, team} = data;
  await axios.post(`/infra/api/cpouser/v1/Companies/${companyId}/Managers`,
    {
      "name": name,
      "position": position,
      "phoneNumber": phoneNumber,
      "team": team
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Manager 수정
export const putManager = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {companyId, managerId, name, position, phoneNumber, team} = data;
  await axios.put(`/infra/api/cpouser/v1/Companies/${companyId}/Managers/${managerId}`,
    {
      "name": name,
      "position": position,
      "phoneNumber": phoneNumber,
      "team": team
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Manager 삭제
export const deleteManager = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {companyId, managerId} = data;
  let temp;
  await axios.delete(`/infra/api/cpouser/v1/Companies/${companyId}/Managers/${managerId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}


// ******************************************************************************************
// * InfraConstructor
// ******************************************************************************************

// InfraConstructor 조회
export const getInfraConstructorList = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {page, size, specialization} = data;
  let query = "";
  // if(page){query += `page=${page?page:1}`;}
  // if(size){query += `&size=${size}`;}
  if(specialization){query += `specialization=${specialization}`}
  await axios.get(`/infra/api/InfraConstructor?${query}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}
export const getInfraConstructorListForSelect = async(keyword, page)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  const response = await fetch(`/infra/api/InfraConstructor?specialization=all&size=10&page=${page}`,{headers: {
    Authorization: `Bearer ${signedToken}`
  }});
  return response;
}

// InfraConstructor 조회 by id
export const getInfraConstructor = async(id) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/infra/api/InfraConstructor/${id}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// InfraConstructor 생성
export const postInfraConstructor = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {companySysId, specializations, remarks} = data;
  await axios.post(`/infra/api/InfraConstructor`, {
    "CompanySysId" : companySysId,
    "Specializations" : specializations,
    "Remark" : remarks
  }, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// InfraConstructor 수정
export const putInfraConstructor = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {infraConstructorSysId, companySysId, specializations, remarks} = data;
  await axios.put(`/infra/api/InfraConstructor/${infraConstructorSysId}`, {
    "Specializations": specializations,
    "Remark": remarks
  }, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// InfraConstructor 삭제
export const deleteInfraConstructor = async(companySysId) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/infra/api/InfraConstructor/${companySysId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// InfraConstructor 관리자 연결
export const putSetInfraConstructorManager = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {infraConstructorSysId, managerSysId} = data;
  let temp;
  await axios.put(`/infra/api/InfraConstructor/${infraConstructorSysId}/SetManager/${managerSysId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}
// InfraConstructor 관리자 연결 해제
export const putUnsetInfraConstructorManager = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {infraConstructorSysId, managerSysId} = data;
  let temp;
  await axios.put(`/infra/api/InfraConstructor/${infraConstructorSysId}/UnsetManager/${managerSysId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}


// ******************************************************************************************
// * Manufacturers CpoUser
// ******************************************************************************************

// Manufacturer 조회
export const getManufacturerList = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {page, size} = data;
  let query = "";
  if(page){query += `page=${page?page:1}`;}
  if(size){query += `&size=${size}`;}
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Manufacturers?${query}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Manufacturer 조회 by id
export const getManufacturer = async(manufacturerId) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Manufacturers/${manufacturerId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Manufacturer 생성
export const postManufacturer = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {companyId, remarks} = data;
  let temp;
  await axios.post(`/infra/api/cpouser/v1/Manufacturers`, {
    "companyId": companyId,
    "remarks": remarks
  }, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Manufacturer 수정
export const putManufacturer = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let {manufacturerId, remarks} = data;
  let temp;
  await axios.put(`/infra/api/cpouser/v1/Manufacturers/${manufacturerId}`, {
    "remarks": remarks
  }, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Manufacturer 삭제
export const deleteManufacturer = async(manufacturerId) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/infra/api/cpouser/v1/Manufacturers/${manufacturerId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

