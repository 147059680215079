import React from 'react'
import { TfiClose } from "react-icons/tfi";
import { useState, useEffect } from 'react';
import { Loading } from '../alerts/Loading.js';
import { useNavigate } from 'react-router-dom';
import { postActionSetProfileVersionCopyCpoUser, getChasValidationName } from '../../api/controlhub/controlhubApi.js';
import { postChas, getChasByName, putChasInfo } from '../../api/controlhub/controlhubApi.js';


export const ChasInfoEditModal = (props) => {
  const [loadingOn, setLoadingOn] = useState(false);
  const [loadingOn2, setLoadingOn2] = useState(false);

  const [errMsg, setErrMsg] = useState(null);

  const reqPutChasInfo = async()=>{
    setLoadingOn2(true);
    let reqObj = {
      "name": chasName,
      "description": chasDesc
    };
    let result = await putChasInfo(props.actionSetId, reqObj);
    if(result.status === 200 || result.status === 201){
      props.setOnOff(false);
      await props.reqGetChas();
    }
    else{
      setErrMsg("수정에 실패하였습니다");
    }
    setLoadingOn2(false);
  }

  useEffect(()=>{
    if(errMsg){
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  },[errMsg])


  const [chasName, setChasName] = useState(props.chasName);
  const [chasDesc, setChasDesc] = useState(props.chasDesc);
  const [chasValid, setChasValid] = useState(null);

  const [buttonValid, setButtonValid] = useState(false);

  const reqGetChasByName = async(chasName)=>{
    
    setLoadingOn(true);
    if(!chasName)return;
    let result = await getChasByName(chasName);
    setChasValid(result.data);
    setLoadingOn(false);
  }

  useEffect(()=>{
    if(chasName === props.chasName && chasDesc === props.chasDesc){
      setButtonValid(false);
    }
    else if( (chasName!=="" && chasDesc !== "") && ((chasName !== props.chasName && chasValid) || chasDesc !== props.chasDesc)){
      setButtonValid(true);
    }
    else{
      setButtonValid(false);
    }
  },[chasName, chasDesc, chasValid])

  return (
    <div className="chas-info-edit-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="chas-info-edit-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="modal-header">
          <title>액션셋 정보 수정</title>
          <div className='modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="chas-info-edit-modal-content">
          <div className='chas-info-edit-modal-content-container'>
            <p>CHAS 이름</p>
            <div className='chas-info-edit-modal-grid-wrapper'>
              <div className='chas-info-edit-modal-input-container'>
                <input type="text" placeholder='CHAS의 이름을 입력해주세요' value={chasName} onChange={(e)=>{setChasName(e.target.value);setChasValid(null)}}/>
              </div>
              <div className={`chas-info-edit-check-button ${chasName === props.chasName ? "disabled":""}`} onClick={()=>{reqGetChasByName(chasName)}}>
                {loadingOn ? 
                <Loading/>
                :"중복확인"}
              </div>
              {(chasName =="" || chasValid === null)&& (chasName !== props.chasName) &&<p className='chas-info-edit-check-info'>중복확인을 눌러 주세요.</p>}
              {
                chasName !== props.chasName &&
                <>
                  {chasValid === false &&<p className='chas-info-edit-check-info'>중복된 이름입니다.</p>}
                  {chasValid === true && <p className='chas-info-edit-check-info valid'>사용가능한 이름입니다.</p>}
                </>
              }
              {errMsg && <p className='chas-info-edit-check-info'>{errMsg}</p>}
            </div>
          </div>
          <div className='chas-info-edit-modal-content-container'>
            <p>설명</p>
            <div className='chas-info-edit-modal-input-container textarea'>
              <textarea type="text" placeholder='설명을 입력해주세요' value={chasDesc} onChange={(e)=>{setChasDesc(e.target.value);}} />
            </div>
          </div>
          <div className='chas-info-edit-modal-request-button-container'>
            
            <div className={`chas-info-edit-modal-request-button ${buttonValid && (chasName === props.chasName || chasValid) ? "":"disabled"}`} onClick={()=>{reqPutChasInfo()}}>
              {loadingOn2 ? 
              <Loading/>
              :<div onClick={()=>{reqPutChasInfo()}}>수정</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChasInfoEditModal;