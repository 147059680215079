import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util.js';

import CpoInstallListItem from '../../components/list/CpoInstallListItem.js';
import CpoInfraConstructorEnrollModal from '../../components/modals/CpoInfraConstructorEnrollModal.js';
import CpoInstallAdminEnrollModal from '../../components/modals/CpoInstallAdminEnrollModal.js';
import CpoInfraConstructorDeleteModal from '../../components/modals/CpoInfraConstructorDeleteModal.js';
import {getInfraConstructorList} from '../../api/infra/companyApi.js';
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

import {LoadingList} from '../../components/alerts/LoadingList.js';
import CpoInfraConstructorListItem from '../../components/list/CpoInfraConstructorListItem.js';

const CpoInfraConstructorManagement = () => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:4}));
  },[])

  const [companyEnrollModalOn, setCompanyEnrollModalOn] = useState(false);
  const [adminEnrollModalOn, setAdminEnrollModalOn] = useState(false);



  const tabName = (idx)=>{
    switch (idx) {
      case 0: return{name : "전체", value: "all"};
      case 1: return{name : "윈치 설치", value: "winch"};
      case 2: return{name : "충전기 설치", value: "charger"};
      case 3: return{name : "캐노피 설치", value: "canopy"};
      // case 4: return"유지보수";
      // case 5: return"";
      // case 6: return"";
      // case 7: return"";
      // case 8: return"";
      default: return {name : "전체", value: "all"};
    }
  }
  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
      setPageNum(1);
      reqGetInfraConstructorList(1,{"keyword": searchKeyword, "specialization": tabName(idx).value});
    }
  }

  const [infraConstructorList, setInfraConstructorList] = useState([]);
  const [infraConstructorCount, setInfraConstructorCount] = useState(0);

  const [checked, setChecked] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState('');

  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [loadingListOn, setLoadingListOn] = useState(false);



  // checked state (모든 아이템 check)
  useEffect(()=>{
    if(checked){
      let temp = new Array(infraConstructorList.length).fill(0).map((_, i) => i)
      setCheckedItemList(temp);
    }
    else{
      setCheckedItemList([]);
    }
  },[checked])

  const reqGetInfraConstructorList = async(page=pageNum, data)=>{
      setLoadingListOn(true);
      let reqObj = {...data, page: page, size: pageSize};
      let result = await getInfraConstructorList(reqObj);
      if(result.status === 200){
        // let temp = [...result.data.Results];
        // setInfraConstructorList(temp);
        let temp = [...result.data];

        setInfraConstructorList(temp);  
        setInfraConstructorCount(result.data.length);
      }
      setLoadingListOn(false);
      
    }

  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(infraConstructorCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetInfraConstructorList(pageNum,{"keyword": searchKeyword, "specialization": tabName(tabIdx).value});
    }
  },[pageNum])


  // const handleKeyPress = async(e) => {
  //   if (e.key === 'Enter') {
  //     reqSearchKeyword();
  //   }
  // };
  // const reqSearchKeyword = async()=>{
  //   setLoadingListOn(true);
  //     if(pageNum !== 1) setPageNum(1);
  //     else await reqGetCompanyList();
  //     setLoadingListOn(false);
  // }

  return (
    <div>
      <div className='cpo-infra-constructor-management-container'>
        {companyEnrollModalOn&&<CpoInfraConstructorEnrollModal setOnOff={setCompanyEnrollModalOn} reqGetInfraConstructorList={reqGetInfraConstructorList} setTabIdx={setTabIdx}/>}
        {adminEnrollModalOn&&<CpoInstallAdminEnrollModal setOnOff={setAdminEnrollModalOn} />}
        {deleteModalOpen&&<CpoInfraConstructorDeleteModal setOnOff={setDeleteModalOpen} infraConstructorList={infraConstructorList} checkedItemList={checkedItemList} setCheckedItemList={setCheckedItemList} setTabIdx={setTabIdx} reqGetInfraConstructorList={reqGetInfraConstructorList}/>}
        <div className="cpo-infra-constructor-management-inner">
          <div className="cpo-infra-constructor-function-button-container">
            {!deleteMode&&<div className='cpo-infra-constructor-function-button enroll' onClick={()=>{
              setCompanyEnrollModalOn(true)
            }}>업체 등록</div>}
            {deleteMode&&<p className='cpo-infra-constructor-delete-guide'>삭제할 설치업체를 선택해주세요</p>}
            <div className={'cpo-infra-constructor-function-button delete ' + `${deleteMode ? "activate" :""}`} onClick={()=>{if(!deleteMode){setDeleteMode(true)}else{setDeleteModalOpen(true)}}}>삭제</div>
            {deleteMode&&<div className='cpo-infra-constructor-function-button cancel' onClick={()=>{setCheckedItemList([]); setDeleteMode(false)}}>취소</div>}
          </div>
          <div className='cpo-infra-constructor-tab-container'>
            <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx === 0 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(0)}}><p>{tabName(0).name}</p></div>
            <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx === 1 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(1)}}><p>{tabName(1).name}</p></div>
            <div ref={el=>tabBtnRef.current[2]=el} className={tabIdx === 2 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(2)}}><p>{tabName(2).name}</p></div>
            <div ref={el=>tabBtnRef.current[3]=el} className={tabIdx === 3 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(3)}}><p>{tabName(3).name}</p></div>
            {/* <div ref={el=>tabBtnRef.current[4]=el} className={tabIdx == 4 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(4)}}><p>{tabName(4)}</p></div>
            <div ref={el=>tabBtnRef.current[5]=el} className={tabIdx == 5 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(5)}}><p>{tabName(5)}</p></div>
            <div ref={el=>tabBtnRef.current[6]=el} className={tabIdx == 6 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(6)}}><p>{tabName(6)}</p></div>
            <div ref={el=>tabBtnRef.current[7]=el} className={tabIdx == 7 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(7)}}><p>{tabName(7)}</p></div>
            <div ref={el=>tabBtnRef.current[8]=el} className={tabIdx == 8 ? "infra-constructor-tab-button selected":"infra-constructor-tab-button"} onClick={()=>{tabClick(8)}}><p>{tabName(8)}</p></div> */}
            <div className={'infra-constructor-tab-bar '+'tab'+tabIdx}></div>
          </div>
          <div className="cpo-infra-constructor-management-content-item-list-container">
            {
              !(deleteMode) ?
              <div className="cpo-infra-constructor-management-content-item-column tab0">
                <div>업체명</div>
                <div>업종</div>
                <div>비고</div>
              </div>
              :
              <div className="cpo-infra-constructor-management-content-item-column tab1">
                <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                  <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                  <span className="checkbox_icon"></span>
                </label>
                <div>업체명</div>
                <div>업종</div>
                <div>비고</div>
              </div>
            }

            {
              loadingListOn ? <LoadingList width={"500px"} height={"500px"}/> :
              <div className="cpo-infra-constructor-management-list-item-wrapper">
                {
                  infraConstructorList && infraConstructorList.length === 0 &&
                  <div className="cpo-infra-constructor-management-list-item-empty">
                    <p>검색 결과가 없습니다.</p>
                  </div>
                }
              {
                infraConstructorList && infraConstructorList.map((it, idx)=>{
                  return(
                    <>
                      <CpoInfraConstructorListItem data={it} checkMode={deleteMode} idx={idx} checkList={checkedItemList} setCheckList={setCheckedItemList} reqGetInfraConstructorList={reqGetInfraConstructorList}/>
                    </>
                  );
                })
              }
              </div>
            }

            <div className='page-selector-container'>
              {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
              {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
              {pageSelectorRendering()}
              {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
              {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
              <div className='page-total-info'>{`Total : ${infraConstructorCount}`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoInfraConstructorManagement;

