import axios from 'axios';
import { getCookie, refresh } from '../../util/util';

// Faq list GET
export const getFaqList = async(cpoMemberId)=>{  
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/cs/api/Faq?cpoMemberId=${cpoMemberId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Faq 추가 요청 POST
export const postFaq = async(data)=>{  
  let signedToken =  getCookie('signedToken');
if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {title, category, contents, visibilityLevel, ownerId} = data;
  await axios.post(`/cs/api/Faq`,{
    "title": title,
    "category": category,
    "contents": contents,
    "visibilityLevel": visibilityLevel,
    "ownerId": ownerId
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Faq 수정
export const putFaq = async(faqPostId, data)=>{  
  let signedToken =  getCookie('signedToken');
if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {title, category, contents, visibilityLevel, ownerId} = data;
  await axios.put(`/cs/api/Faq/${faqPostId}`,{
    "title": title,
    "category": category,
    "contents": contents,
    "visibilityLevel": visibilityLevel,
    "ownerId": ownerId
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}
// Faq 삭제
export const deleteFaq = async(faqPostId)=>{  
  let signedToken =  getCookie('signedToken');
if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.delete(`/cs/api/Faq/${faqPostId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 고장신고 리스트 GET
export const getBreakdownList = async(cpoMemberId)=>{  
  let signedToken =  getCookie('signedToken');
if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/cs/api/Breakdown/cpo?cpoMemberId=${cpoMemberId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 고장신고 등록
export const postBreakdown = async(data)=>{  
  let signedToken =  getCookie('signedToken');
if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargerCodeNo, phoneNumber, contents, cpoMemberId} = data;
  await axios.post(`/cs/api/Breakdown`,{
    "chargerCodeNo": chargerCodeNo,
    "phoneNumber": phoneNumber,
    "contents": contents,
    "cpoMemberId": cpoMemberId
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 고장신고에 대한 처리글 등록
export const putBreakdown = async(data)=>{  
  let signedToken =  getCookie('signedToken');
if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {breakdownPostId, replyContents} = data;
  await axios.put(`/cs/api/Breakdown/reply`,{
    "breakdownPostId": breakdownPostId,
    "replyContents": replyContents
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// 고장신고 상태 접수완료로 변경
export const putBreakdownPostStatus = async(breakdownPostId)=>{  
  let signedToken =  getCookie('signedToken');
if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.put(`/cs/api/Breakdown/reply/${breakdownPostId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}



// Enquiry list GET
export const getEnquiryList = async(cpoMemberId)=>{  
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/cs/api/Enquiry/cpo?cpoMemberId=${cpoMemberId}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Enquiry PUT
export const putEnquiry = async(data)=>{  
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {enquiryPostId, callbackContents} = data;
  await axios.put(`/cs/api/Enquiry/reply`,{
    "enquiryPostId": enquiryPostId,
    "callbackContents": callbackContents,
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}