import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import {setNum} from '../../store.js'
import CustomChasItemList from '../../components/list/CustomChasItemList.js';
import CustomChasAppendModal from '../../components/modals/CustomChasAppendModal.js';
import CustomChasDeleteModal from '../../components/modals/CustomChasDeleteModal.js';
import { getChasList, deleteChas } from '../../api/controlhub/controlhubApi.js';
import { GoArrowUpRight } from "react-icons/go";

export const CpoCustomChas = () => {
  const navigate = useNavigate();
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:12, idx:2}));
  },[])  

  const [CHASList, setCHASList] = useState([]);

  const reqGetChasList = async()=>{
    let result = await getChasList();
    if(result.status === 200){
      setCHASList([...result.data]);
    }
    else{

    }
  }
  useEffect(()=>{
    reqGetChasList();
  },[])
  

  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  
  const [checked, setChecked] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState([]);
  
  // checked state (모든 아이템 check)
  useEffect(()=>{
    if(checked){
      let temp = new Array(CHASList.length).fill(0).map((_, i) => i)
      setCheckedItemList(temp);
    }
    else{
      setCheckedItemList([]);
    }
  },[checked])


  const [chasAppendModalOn, setChasAppendModalOn] = useState(false);
  const [deleteModalOn, setDeleteModalOn] = useState(false);
  return (
    <div className="cpo-custom-chas-container">
      {chasAppendModalOn&&<CustomChasAppendModal setOnOff={setChasAppendModalOn}/>}
      {deleteModalOn&&<CustomChasDeleteModal setOnOff={setDeleteModalOn} chasList={checkedItemList.map(it=>CHASList[it])}/>}
      <div className="cpo-custom-chas-inner">
        <div className='cpo-custom-chas-function-container'>
          {!editMode&&!deleteMode&&<div className='cpo-custom-chas-function-button copy' onClick={()=>{navigate('/cpo/system-chas')}}>시스템제공 CHAS<GoArrowUpRight/></div>}
          {!editMode&&!deleteMode&&<div className='cpo-custom-chas-function-button' onClick={()=>{setChasAppendModalOn(true);}}>CHAS 추가</div>}
          {!deleteMode&&<div className={`cpo-custom-chas-function-button edit ${editMode && checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{if(editMode){
            navigate('./edit',{ state: { mode:"edit", ocpp: "OCPP 1.6", actionSetId:CHASList[checkedItemList[0]]?.actionSetId } })
          }else{setEditMode(true)}}}>수정</div>}
          {!editMode&&<div className={`cpo-custom-chas-function-button delete ${editMode && checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{if(deleteMode){setDeleteModalOn(true);}else{setDeleteMode(true)}}}>삭제</div>}
          {(editMode||deleteMode)&&<div className='cpo-custom-chas-function-button cancel' onClick={()=>{setEditMode(false);setDeleteMode(false);setCheckedItemList([]);setChecked(false);}}>취소</div>}
        </div>
        <div className="cpo-custom-chas-content-container">
          <div className="cpo-custom-chas-content-item-list-container">
            <div className={`cpo-custom-chas-content-item-column ${(editMode||deleteMode)?"edit":""}`}>
              {
                deleteMode &&
                <div>
                  <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                    <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                    <span className="checkbox_icon"></span>
                  </label>
                </div>
              }
              { editMode && <div></div>}
              <div>CHAS명</div>
              <div>버전</div>
              <div>OCPP</div>
              <div>설정 상태</div>
            </div>

            {
              CHASList&&CHASList.map((it, idx)=>{
                return(
                  <CustomChasItemList data={it} settingMode={editMode?"edit":deleteMode?"delete":null} idx={idx} checkList={checkedItemList} setCheckList={setCheckedItemList}/>
                );
              })
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoCustomChas;