import React, { useState, useEffect } from 'react';
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';

import { postFirmwareVersion, putFirmwareVersion } from '../../api/controlhub/controlhubApi.js';

import UploadBox from '../buttons/UploadBox.js';

export const CpoFirmwareVersionEnrollModal = (props) => {

  const [firmwareVersion, setFirmwareVersion] = useState("");
  const [firmwareDesc, setFirmwareDesc] = useState("");
  const [firmwareFile, setFirmwareFile] = useState(null);

  const [enrolledFileData, setEnrolledFileData] = useState(null);

  const [loadingOn, setLoadingOn] = useState(false);

  useEffect(()=>{
    if(props.mode==="edit" && props.data?.versions[props.versionIdx]){
      setFirmwareVersion(props.data?.versions[props.versionIdx].version);
      setFirmwareDesc(props.data?.versions[props.versionIdx].description);
      setEnrolledFileData({
        fileName : props.data?.versions[props.versionIdx].fileName,
        fileSize : props.data?.versions[props.versionIdx].fileSize,
      });
    }
  },[])

  const reqPostFirmwareVersion = async()=>{

    setLoadingOn(true);
    let res = await postFirmwareVersion(props.data?.firmwareId,{
      version: firmwareVersion,
      description: firmwareDesc,
      file: firmwareFile
    });
    if(res.status === 201 || res.status === 200){
      await props.reqGetFirmwareListCpoUser();
      setLoadingOn(false);
      props.setOnOff(false);
    }
    else{
      setLoadingOn(false);
    }
  }
  const reqPutFirmwareVersion = async()=>{
    setLoadingOn(true);
    let res = await putFirmwareVersion(props.data.firmwareId,  props.data?.versions[props.versionIdx].firmwareVersionId, {
      description: firmwareDesc
    });
    if(res.status === 200){
      await props.reqGetFirmwareListCpoUser();
      setLoadingOn(false);
      props.setOnOff(false);
    }
    else{
      setLoadingOn(false);
    }
  }

  return (
    <div className='cpo-firmware-enroll-modal-bg'>
      <div className="cpo-firmware-enroll-modal-container">
        <div className="modal-header">
          <title>{props.mode==="enroll" ? "펌웨어 버전 등록" : "펌웨어 버전 수정"}</title>
          <div className='modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-firmware-enroll-modal-content-container'>
          {
            props.mode==="enroll" &&
            <>
              <p>버전</p>
              <div className='cpo-firmware-enroll-modal-input-container'>
                <input type="text" placeholder='버전을 입력해주세요' value={firmwareVersion} onChange={(e)=>{setFirmwareVersion(e.target.value);}}/>
              </div>
            </>
          }
          <p>설명</p>
          <div className='cpo-firmware-enroll-modal-input-container textarea'>
            <textarea type="text" placeholder='설명을 입력해주세요' value={firmwareDesc} onChange={(e)=>{setFirmwareDesc(e.target.value);}} />
          </div>
          {
            props.mode==="enroll" &&
            <>
              <p>파일</p>
              <div className='cpo-firmware-enroll-modal-uploadbox-container'>
                <UploadBox setFile={setFirmwareFile} downloadDisable={true}/>
              </div>
            </>
          }
          
        </div>
        <div className='cpo-firmware-enroll-modal-button-container'>
          <div className={`cpo-firmware-enroll-modal-button ${ firmwareVersion === "" || (!firmwareDesc || firmwareDesc === "") || !(props.mode === "edit" || firmwareFile) ? "disable":""} `} onClick={()=>{
            props.mode==="enroll" ? reqPostFirmwareVersion() : reqPutFirmwareVersion();
          }}>
            {loadingOn ? <Loading/> :<p>{props.mode==="enroll" ? `등록하기` : "수정하기"}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoFirmwareVersionEnrollModal;