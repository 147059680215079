
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import LabelSignUpTab1 from '../../components/tabs/LabelSignUpTab1';
import LabelSignUpTab2 from '../../components/tabs/LabelSignUpTab2';
import LabelSignUpTab3 from '../../components/tabs/LabelSignUpTab3';

export const LabelSignUp = () => {
  const navigator = useNavigate();
  const RenderTab = ({idx})=>{
    return[<LabelSignUpTab1 setStep={setStep} setTarget={setTarget}/>,<LabelSignUpTab2 setEmail={setEmail} setStep={setStep} target={target}/>,<LabelSignUpTab3 email={email} setStep={setStep}/>][idx];
  }  

  const [email, setEmail] = useState(null);

  const [step, setStep] = useState(0);
  const [target, setTarget] = useState(null);

  return (
    <div className='sign-up-page-container label'>
        <FaChevronLeft className='go-to-initial-button' onClick={()=>{navigator("/");}}/>
      <div className="sign-up-page-wrapper">
        <title>시스템 사용자 가입</title>
        <div className='sign-up-step-container'>
          <div className="sign-up-step-wrapper">
            <div className={"sign-up-step " + `${step===0 ? 'current' : 'done' }`}><p>1</p><p className='sign-up-step-info'>가입 유형 선택</p></div>
            <div className={"sign-up-step " + `${step===1 ? 'current' : step===0?"":"done" }`}><p>2</p><p className='sign-up-step-info'>정보 입력</p></div>
            <div className={"sign-up-step " + `${step===2 ? 'current':""}`}><p>3</p><p className='sign-up-step-info'>가입 완료</p></div>
            <div className='sign-up-dash'></div>
          </div>
        </div>
        <RenderTab idx={step}/>
        {/* <p className="sign-up-input-title">이메일</p>
        <div className='sign-up-input-container email'><input placeholder='이메일을 입력하세요' type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/></div>
        <p className="sign-up-input-title">비밀번호</p>
        <div className='sign-up-input-container password'><input placeholder='비밀번호를 입력하세요' type={passwordVisible ? "text" : "password"} value={password} onChange={(e)=>{setPassword(e.target.value)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div> */}

        
        
      </div>
    </div>
  )
}
export default LabelSignUp;

