import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { FaBoltLightning, FaArrowRotateLeft } from "react-icons/fa6";
import { GrTest, GrUpgrade } from "react-icons/gr";
import { FaStop } from "react-icons/fa";
import { GrCircleInformation } from "react-icons/gr";
import { MdPerson } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa6";
import { FiTrash2 } from "react-icons/fi";
import { PiPlugChargingBold } from "react-icons/pi";
import { HiOutlineCommandLine } from "react-icons/hi2";

import {chargerStatusConverter, chargerTypeConverter, connectorTypeConverter} from "../../util/util.js";
import { getCookie } from '../../util/util.js';

import { getChargeRateOfCharger } from '../../api/chargerate/chargerateApi.js';

import { Tooltip } from 'react-tooltip'
import { useSelector } from 'react-redux';
const ChargerCard = (props) => {
  const user = useSelector(state => state.user);
  const isCpoViewer = user.roles.includes("CpoViewer");
  let navigator = useNavigate();

  const [chargerStatus, setChargerStatus] = useState("");

  const [connectorStatus1, setConnectorStatus1] = useState(null);
  const [connectorStatus2, setConnectorStatus2] = useState(null);

  let signedToken = getCookie("signedToken");
  const [chargeRate, setChargeRate] = useState(null);

  let reqGetChargeRateOfCharger = async()=>{
    let res = await getChargeRateOfCharger(props.data.ChargerId);
    if(res.status === 200){
      setChargeRate(res.data);
    }
    else{

    }
  }

  useEffect(()=>{
    if(props.controlhubData){
      reqGetChargeRateOfCharger();
      getConnectorsStatus();
      getChargerStatus();
    }
  },[props.controlhubData])

  const getChargerStatus = ()=>{
    if(props.controlhubData?.deleted === true){
      setChargerStatus("");
    }
    else if(props.controlhubData?.used === false){
      setChargerStatus("");
    }
    else if(props.controlhubData?.used === true){
      setChargerStatus("ready");
    }
    else if(props.controlhubData?.connected === true){
      setChargerStatus("connected");
    }
  }

  const getConnectorsStatus = ()=>{
    if(props.controlhubData?.connectors){
      if(props.controlhubData?.connectors[0]){
        switch (props.controlhubData?.connectors[0]?.status) {
          case 'NotUsed':setConnectorStatus1(""); break;
          case 'ReadyForConnection':setConnectorStatus1("ready"); break;
          case 'Connected':setConnectorStatus1("connected"); break;
          case 'Available':setConnectorStatus1("available"); break;
          case 'Preparing':setConnectorStatus1("preparing"); break;
          case 'Charging':setConnectorStatus1("charging"); break;
          case 'SuspendedEVSE':setConnectorStatus1("pause"); break;
          case 'SuspendedEV':setConnectorStatus1("pause"); break;
          case 'Finishing':setConnectorStatus1("finishing"); break;
          case 'Reserved':setConnectorStatus1("reserved"); break;
          case 'Unavailable':setConnectorStatus1("unavailable"); break;
          case 'Faulted':setConnectorStatus1("faulted"); break;
          case 'Deleted':setConnectorStatus1(""); break;
          default:setConnectorStatus1("");break;
        }
      }
      if(props.controlhubData?.connectors[1]){
        switch (props.controlhubData?.connectors[1]?.status) {
          case 'NotUsed':setConnectorStatus2(""); break;
          case 'ReadyForConnection':setConnectorStatus2("ready"); break;
          case 'Connected':setConnectorStatus2("connected"); break;
          case 'Available':setConnectorStatus2("available"); break;
          case 'Preparing':setConnectorStatus2("preparing"); break;
          case 'Charging':setConnectorStatus2("charging"); break;
          case 'SuspendedEVSE':setConnectorStatus2("pause"); break;
          case 'SuspendedEV':setConnectorStatus2("pause"); break;
          case 'Finishing':setConnectorStatus2("finishing"); break;
          case 'Reserved':setConnectorStatus2("reserved"); break;
          case 'Unavailable':setConnectorStatus2("unavailable"); break;
          case 'Faulted':setConnectorStatus2("faulted"); break;
          case 'Deleted':setConnectorStatus2(""); break;
          default:setConnectorStatus2("");break;
        }
      }
    }
  }

  // useEffect(()=>{
  //   switch (props.controlhubData?.status) {
  //     case 'NotUsed':setChargerStatus(""); break;
  //     case 'ReadyForConnection':setChargerStatus("ready"); break;
  //     case 'Connected':setChargerStatus("connected"); break;
  //     case 'Available':setChargerStatus("available"); break;
  //     case 'Preparing':setChargerStatus("preparing"); break;
  //     case 'Charging':setChargerStatus("charging"); break;
  //     case 'SuspendedEVSE':setChargerStatus("pause"); break;
  //     case 'SuspendedEV':setChargerStatus("pause"); break;
  //     case 'Finishing':setChargerStatus("finishing"); break;
  //     case 'Reserved':setChargerStatus("reserved"); break;
  //     case 'Unavailable':setChargerStatus("unavailable"); break;
  //     case 'Faulted':setChargerStatus("faulted"); break;
  //     case 'Deleted':setChargerStatus(""); break;
  //     default:setChargerStatus("");break;
  //   }
  // },[props.controlhubData?.status])


  const chargerStartStop = async(idx)=>{
    if(idx === 0){
      await axios.put(`/controlhub/api/cpouser/v1/Chargers/${props.data.ChargerId}/Used`,
      {
        "use": false
      }, 
      {
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      }
      ).then((res)=>{
        props.getChargerList();
      })
    }
    else{
      await axios.put(`/controlhub/api/cpouser/v1/Chargers/${props.data.ChargerId}/Used`,
      {
        "use": true
      }, 
      {
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      }
      ).then((res)=>{
        props.getChargerList();
      })
    }
  }

  useEffect(()=>{
    setTabIdx(0);
  },[props.data])

  const isFirmwareUpdateAvailable = ()=>{
    if(chargerStatus === "connected"){
      if(props.controlhubData?.connectors[0]?.status === "Available" || props.controlhubData?.connectors[0]?.status === "Unavailable"){
      }
      else{
        return false;
      }
      if(props.controlhubData?.connectors?.length > 1){
        return true;
      }
      if(props.controlhubData?.connectors[1]?.status === "Available" || props.controlhubData?.connectors[1]?.status === "Unavailable"){
        return true;
      }
      else{
        return false;
      }
      
    }
    return false;
  }

  const [tabIdx, setTabIdx] = useState(0)
  return (
    <div className={"cpo-charger-card-container "}>
      {
        tabIdx === 0 ?

        <div className='cpo-charger-item-container front'>
          <div className="cpo-charger-status-container">
            
            <p className='cpo-charger-id'>{props.data.DisplaySerialNumber}</p>
            <div className={'cpo-charger-status '+`${chargerStatus}`}>
              <p className={'cpo-charger-status-content '}>{chargerStatusConverter(props.controlhubData?.status)}</p>
            </div>
          </div>
          <div className="cpo-charger-front-container">

            <div className={`cpo-charger-connector-status-container ${props.data?.ChargerModel?.Connectors?.length > 1 ? "two" : "one"}`}>
              <div className={`cpo-charger-connector-status connector1 ${connectorStatus1} ${props.data.DisplaySerialNumber}`} onClick={()=>{console.log(props.data)}}>
                <p>커넥터1</p>
                <PiPlugChargingBold/>
                {/* <p>{chargerStatusConverter(connectorStatus1)}</p> */}
                <Tooltip
                  style={{ backgroundColor: "#fefefe", color: "#222",  boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 10px", zIndex: "2000", borderRadius: "100vh"}}
                  anchorSelect={`.cpo-charger-connector-status.connector1.${props.data.DisplaySerialNumber}`} className='tooltip'>
                  <div className='tooltip-content'>
                    {props.data?.ChargerModel?.Connectors[0]?.ConnectorTypes.map((it, idx)=>{
                      if(!it)return null;
                      return <span>{`${it} `}</span>
                    })}
                  </div>
                </Tooltip>
              </div>
              {
                props.data?.ChargerModel?.Connectors?.length > 1 &&
              <div className={`cpo-charger-connector-status connector2 ${connectorStatus2} ${props.data.DisplaySerialNumber}`}>
                <p>커넥터2</p>
                <PiPlugChargingBold/>
                {/* <p>{chargerStatusConverter(connectorStatus2)}</p> */}
                <Tooltip
                  style={{ backgroundColor: "#fefefe", color: "#222",  boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 10px", zIndex: "2000", borderRadius: "100vh"}}
                  anchorSelect={`.cpo-charger-connector-status.connector2.${props.data.DisplaySerialNumber}`} className='tooltip'>
                  <div className='tooltip-content'>
                    {props.data?.ChargerModel?.Connectors[1]?.ConnectorTypes.map((it, idx)=>{
                      if(!it)return null;
                      return <span>{`${it} `}</span>
                    })}
                  </div>
                </Tooltip>
              </div>
              }
            </div>
            <div className="cpo-charger-front-title">
              <p>현재 요금 (회원 / 비회원)</p>
            </div>
            <p className='cpo-charger-front'>
              {`${chargeRate?.memberUnitPrice?chargeRate?.memberUnitPrice:"-"} / ${chargeRate?.nonMemberUnitPrice?chargeRate?.nonMemberUnitPrice:"-"}`}
            </p>
          </div>
          <div className="cpo-charger-front-container">
            <div className="cpo-charger-front-title">
              <p>액션셋</p>
            </div>
            <p className={'cpo-charger-front ' + `${props.controlhubData?.actionSetName ? "":" alert"}`}>
              {props.controlhubData?.actionSetName ? props.controlhubData?.actionSetName  : '액션셋을 설정해주세요'}
            </p>
          </div>
          <div className="cpo-charger-front-container">
            <div className="cpo-charger-front-title">
              <p>OCPP 버전</p>
            </div>
            <p className='cpo-charger-front'>
              {props.data.Protocol ? props.data?.Protocol  : '정보없음'}
            </p>
          </div>

          {/* <div className="cpo-charger-front-container">
            <div className="cpo-charger-front-title">
              <p>가동일 수</p>
            </div>
            <p className='cpo-charger-front'>
              {'정보없음'}
            </p>
          </div> */}
          {<div className="cpo-charger-btn-list-container">

            <div className='cpo-charger-onOff-btn-container' onClick={(e)=>{e.stopPropagation();
              if(props.controlhubData?.actionSetName){
                props.controlhubData?.used?chargerStartStop(0):chargerStartStop(1)
              }
            }}>
              
              { props.controlhubData?.actionSetName ? null:
                <Tooltip
                  style={{ backgroundColor: "#fefefe", color: "#222",  boxShadow:"rgba(212, 212, 212, 0.16) 0px 1px 10px", zIndex: "2000", borderRadius: "8px"}}
                  anchorSelect={`.cpo-charger-onOff-btn.disabled`} className='tooltip'>
                  <div className='tooltip-content'>
                    <span className='tooltip-highlight-content'>액션셋</span><span>을 설정한 후</span><br/>
                    <span> 사용가능합니다.</span>
                  </div>
                </Tooltip>
              }
              <div className={'cpo-charger-onOff-btn ' +`${props.controlhubData?.used?" used":""}` + `${props.controlhubData?.actionSetName ? "":" disabled"}`}>
                <div className="cpo-charger-onOff-btn-icon">
                  {
                    props.controlhubData?.used?
                    <FaStop/>
                    :
                    <FaBoltLightning/>
                  }
                </div>
                <p>
                  {props.controlhubData?.used?"운용중지":"운용시작"}
                </p>
              </div>
            </div>

            <div className='cpo-charger-reset-btn-container' onClick={(e)=>{e.stopPropagation();}}>
              <div className={'cpo-charger-onOff-btn' + `${props.controlhubData?.actionSetName ? "":" disabled"}`}>
                <div className="cpo-charger-onOff-btn-icon">
                  <FaArrowRotateLeft/>
                </div>
                <p>재시동</p>
              </div>
            </div>

          </div>}
        </div>
        :
        <div className='cpo-charger-item-container back'>

          <div className="cpo-charger-status-container">
            <p className='cpo-charger-id'>{props.data.DisplaySerialNumber}</p>
            <div className={'cpo-charger-status '+`${chargerStatus}`}>
              <p className={'cpo-charger-status-content '}>{chargerStatusConverter(props.controlhubData?.status)}</p>
            </div>
          </div>
          <div className={`cpo-charger-connector-status-container ${props.data?.ChargerModel?.Connectors?.length > 1 ? "two" : "one"}`}>
              <div className={`cpo-charger-connector-status back connector1 ${connectorStatus1} ${props.data.DisplaySerialNumber}`}>
                <p>{chargerStatusConverter(connectorStatus1)}</p>
                <Tooltip
                  style={{ backgroundColor: "#fefefe", color: "#222",  boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 10px", zIndex: "2000", borderRadius: "100vh"}}
                  anchorSelect={`.cpo-charger-connector-status.connector1.${props.data.DisplaySerialNumber}`} className='tooltip'>
                  <div className='tooltip-content'>
                    {props.data?.ChargerModel?.Connectors[0]?.ConnectorTypes.map((it, idx)=>{
                      if(!it)return null;
                      return <span>{`${it} `}</span>
                    })}
                  </div>
                </Tooltip>
              </div>
              {
                props.data?.ChargerModel?.Connectors?.length > 1 &&
              <div className={`cpo-charger-connector-status back connector2 ${connectorStatus2} ${props.data.DisplaySerialNumber}`}>
                <p>{chargerStatusConverter(connectorStatus2)}</p>
                <Tooltip
                  style={{ backgroundColor: "#fefefe", color: "#222",  boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 10px", zIndex: "2000", borderRadius: "100vh"}}
                  anchorSelect={`.cpo-charger-connector-status.connector2.${props.data.DisplaySerialNumber}`} className='tooltip'>
                  <div className='tooltip-content'>
                    {props.data?.ChargerModel?.Connectors[1].ConnectorTypes.map((it, idx)=>{
                      if(!it)return null;
                      return <span>{`${it} `}</span>
                    })}
                  </div>
                </Tooltip>
              </div>
              }
            </div>
          <div className="cpo-charger-four-btn-container" onClick={(e)=>{e.stopPropagation();}}>

            <div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(0); props.setChargerEventModalData(props.data);}}>
              <GrCircleInformation/>
              <p>기기사양 보기</p>
            </div>
            {!isCpoViewer&&<div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(1)}}>
              <MdPerson />
              <p>담당자 보기</p>
            </div>}
            {!isCpoViewer&&<div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(2)}}>
              <GrTest/>
              <p>연결 테스트</p>
            </div>}
            
          {!isCpoViewer&&<div className={`cpo-charger-back-btn-container firmware-update ${
            isFirmwareUpdateAvailable() ?
            "":"disabled"
          }`} onClick={()=>{
              // props.setChargerEventModalOn(true); props.setChargerEventModalIdx(3);  props.setChargerEventModalData(props.data);
                if(isFirmwareUpdateAvailable()){
                  props.setChargerEventModalOn(true); props.setChargerEventModalIdx(3);  props.setChargerEventModalData(props.data);
                }
              }}>
              {isFirmwareUpdateAvailable()?null:
              <Tooltip
                style={{ backgroundColor: "#fefefe", color: "#222",  boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 10px", zIndex: "1000"}}
                anchorSelect={`.cpo-charger-back-btn-container.firmware-update`} className='tooltip'>
                <div className='tooltip-content'>
                  <span>충전기 상태가</span><br/>
                  <span className='tooltip-highlight-content'>연결됨</span><span>이고,</span><br/>
                  <span>커넥터 상태가</span><br/>
                  <span className='tooltip-highlight-content'>연결됨, 사용가능, 사용중지</span><br/>
                  <span>일 때 업데이트 가능합니다.</span><br/>
                </div>
              </Tooltip>}
              <GrUpgrade />
              <p>펌웨어 업데이트</p>
            </div>}
            <div className='cpo-charger-back-btn-container log' onClick={()=>{props.setChargerEventModalOn(true); navigator("/cpo/pay-report",{ state: { DisplaySerialNumber: props.data.DisplaySerialNumber} })}}>
            
                <FaRegChartBar />              
              <p>로그 조회</p>
            </div>
            {!isCpoViewer&&<div className='cpo-charger-back-btn-container command' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(6); props.setChargerEventModalData(props.data);}}>
              <HiOutlineCommandLine />
              <p>원격 명령 전송</p>
            </div>}
            {!isCpoViewer&&<div className='cpo-charger-back-btn-container delete' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(5); props.setChargerEventModalData(props.data);}}>
              <FiTrash2 />
              <p>삭제</p>
            </div>}
          </div>
        </div>
      }
      <div className='cpo-charger-card-idx-control-container'>
        <div className={`${tabIdx === 0 ? "selected":""}`} onClick={()=>{setTabIdx(0)}}></div>
        <div className={`${tabIdx === 1 ? "selected":""}`} onClick={()=>{setTabIdx(1)}}></div>
      </div>
    </div>
  )
}
export default ChargerCard;