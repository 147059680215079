import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'

import { FaSearch } from "react-icons/fa";

import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

import { LoadingList } from '../../components/alerts/LoadingList.js';
import CpoEvUserListItem from '../../components/list/CpoEvUserListItem.js';
import { getEvUsers } from '../../api/account/accountApi.js';

const CpoEvUserManagement = (props) => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:19}));
  },[])

  
  const [searchKeyword, setSearchKeyword] = useState();

  const [evUserList, setEvUserList] = useState([]);
  const [evUserCount, setEvUserCount] = useState(0);
  const reqGetEvUsers = async()=>{
    setLoadingListOn(true);
    let temp = await getEvUsers();
    setEvUserList(temp?.data?.Results);
    setEvUserCount(temp?.data?.Total);
    setLoadingListOn(false);
  }
  useEffect(()=>{
    reqGetEvUsers();
  },[])




  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(evUserCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  // useEffect(()=>{
  //   if(pageNum){
  //     reqGetChargerModelList(pageNum,{"keyword": searchKeyword});
  //   }
  // },[pageNum])


  const [loadingListOn, setLoadingListOn] = useState(false);

  // const handleKeyPress = async(e) => {
  //   if (e.key === 'Enter') {
  //     reqSearchKeyword();
  //   }
  // };
  // const reqSearchKeyword = async()=>{
  //   setLoadingListOn(true);
  //     if(pageNum !== 1) setPageNum(1);
  //     else await reqGetChargerModelList(1,{"keyword": searchKeyword});
  //     setLoadingListOn(false);
  // }

  

  return (
    <div className='cpo-ev-user-management-container'>
      <div className="cpo-ev-user-management-inner">
        <div className={"cpo-ev-user-management-content-container "}>
          <div className="cpo-ev-user-management-content-header">
            {/* <div className='cpo-charger-model-management-content-search-container'>
              <div className="cpo-station-management-search-input-container">
                <input type="text" placeholder={'이메일로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}} onKeyDown={handleKeyPress}/>
                <FaSearch onClick={()=>{reqSearchKeyword()}}/>
              </div>
            </div> */}
            {/* <div className='cpo-charger-model-management-content-function-button-container'>
              {!deleteMode&&!editMode&&<div className='cpo-charger-model-management-content-function-button enroll' onClick={()=>{setModalOpen(true);}}>충전기 모델 등록</div>}
              {editMode&&<div className='cpo-charger-model-function-info-msg'>수정할 충전기 모델을 체크한 후 수정 버튼을 눌러주세요.</div>}
              {deleteMode&&<div className='cpo-charger-model-function-info-msg delete'>삭제할 충전기 모델을 모두 체크한 후 수정 버튼을 눌러주세요.</div>}
              {!deleteMode && <div className={`cpo-charger-model-management-content-function-button edit ${editMode&&checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{editMode ? setModalOpen(true) : setEditMode(true)}}>수정</div>}
              {!editMode &&<div className={`cpo-charger-model-management-content-function-button delete ${deleteMode&&checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{deleteMode ? setDeleteModalOpen(true) : setDeleteMode(true)}}>삭제</div>}
              {(editMode || deleteMode) && <div className='cpo-charger-model-management-content-function-button cancel' onClick={()=>{setCheckedItemList([]); editMode ? setEditMode(false) : setDeleteMode(false)}}>취소</div>}
            </div> */}
          </div>
          <div className={'cpo-ev-user-management-item-list-grid-container '}>
            
              <div className="cpo-ev-user-management-content-item-list-container">

                  <div className="cpo-ev-user-management-content-item-column">
                    <div>이름</div>
                    <div>이메일</div>
                    <div>전화번호</div>
                    <div>주소</div>
                    <div></div>
                  </div>
                
                {
                  loadingListOn ?
                  <LoadingList width={"500px"} height={"500px"}/>
                  :
                  <>
                  {
                    evUserList && evUserList.map((it, idx)=>{
                      return(
                        <>
                          <CpoEvUserListItem data={it}/>
                        </>
                      );
                    })
                  }
                  </>
                }
                {/* <div className='page-selector-container'>
                  {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
                  {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
                  {pageSelectorRendering()}
                  {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
                  {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
                  <div className='page-total-info'>{`Total : ${chargerModelCount}`}</div>
                </div> */}
              </div>
              
            </div>
          </div>
      </div>
    </div>
  )
}
export default CpoEvUserManagement;
