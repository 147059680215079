import axios from 'axios';
import { getCookie, refresh } from '../../util/util';

// board 조회
export const getBoardList = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {page, size} = data;
  let query = "";
  if(!page){query += `page=${page?page:1}`;}
  if(!size){query += `&size=${size}`;}
  await axios.get(`/board/api/Post?${query}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// board 조회 by id
export const getBoard = async(id) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  await axios.get(`/board/api/Post/${id}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }
  )
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// board 생성
export const postBoard = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {title, contents, cpoId, isPinned, pinOrder, isPopup, popupStartDate, popupEndDate, postType} = data;
  await axios.post(`/board/api/Post`, {
    "title": title,
    "contents": contents,
    "cpoId": cpoId,
    "isPinned": isPinned,
    "pinOrder": pinOrder,
    "isPopup": isPopup,
    "popupStartDate": popupStartDate,
    "popupEndDate": popupEndDate,
    "postType":postType,
  },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// board 수정
export const putBoard = async(data) => {
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {id, title, contents, cpoId, isPinned, pinOrder, isPopup, popupStartDate, popupEndDate, postType} = data;
  await axios.put(`/board/api/Post/${id}`, {
    "title": title,
    "contents": contents,
    "cpoId": cpoId,
    "isPinned": isPinned,
    "pinOrder": pinOrder,
    "isPopup": isPopup,
    "popupStartDate": popupStartDate,
    "popupEndDate": popupEndDate,
    "postType":postType,
  },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
  .then((res)=>{
    temp = res;
  }).catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// board 삭제
export const deleteBoard = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { id, cpoId } = data;
  await axios.delete(`/board/api/Post/${id}`, {
    headers: {
      Authorization: `Bearer ${signedToken}`,
      'Content-Type': 'application/json'
    },
    data: {
      cpoId: cpoId
    }
  })
  .then((res) => {
    temp = res;
  }).catch((err) => {
    temp = err.response;
  });
  return temp;
}