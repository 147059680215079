import { useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {chargerTypeConverter, connectorTypeConverter} from "../../util/util.js"

import Selector2 from '../buttons/Selector2.js';


const ChargerDetailTab2 = (props) => {
  let navigate = useNavigate();
  // 선택자 클릭 여부
  const [ChargerModel, setChargerModel] = useState('');
  const [ChargerModelVal, setChargerModelVal] = useState('');
  const [ChargerModelActive, setChargerModelActive] = useState(null);
  
  // 선택자 active reset
  const activeReset = ()=>{
    setChargerModelActive(false);
  }

  const [modelInfo, setModelInfo] = useState(null);
  
  useEffect(()=>{
    if(!ChargerModel)return;
    setModelInfo(props.modelList.filter(it=>it.Id == ChargerModel)[0]);
  },[ChargerModel])

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.number}_ChargerDetailTab2`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
        return;
      }
      if(temp['ChargerModel']){
        setChargerModel(JSON.parse(temp['ChargerModel']));
        setChargerModelVal(props.modelList.filter(it=>it.Id == JSON.parse(temp['ChargerModel']))[0]);
      }
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    if(!props.data?.ChargerModel)return;
    let temp = localStorage.getItem(`${props.number}_ChargerDetailTab2`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        console.log(new Date(temp['expiredTime']) < new Date())
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
        }
        else{
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
      }
    }
    setChargerModel(props.data?.ChargerModel?.Id);
    setChargerModelVal(props.modelList.filter(it=>it?.Id == props.data?.ChargerModel?.Id)[0]);
    
  },[])

  useEffect(()=>{
    if(!props.open)return;
    if(ChargerModel=='')return;
    if(ChargerModel==props.data?.ChargerModel?.Id){
      localStorage.removeItem(`${props.number}`+'_ChargerDetailTab2');
      return;
    }
    localStorage.setItem(`${props.number}`+'_ChargerDetailTab2', JSON.stringify( {ChargerModel:JSON.stringify(ChargerModel) , expiredTime: new Date(new Date().getTime() + 60 * 60 * 1000)}))
    props.setSaveBtnStatus(true);
  },[ChargerModel])

  useEffect(()=>{
    if(!ChargerModelVal)return;
    setChargerModel(ChargerModelVal.Id);
  },[ChargerModelVal])
  

  const goToModelManagement = ()=>{
    navigate('/cpo/charger-model-management');
  }

  return (
    <div className='charger-detail-tab tab2'>
      <div>
        <div>
          <p className='charger-input-title'>충전기 모델</p>
          <div className="charger-model-info-grid-oneColumn">
            <div className='charger-detail-tab-selector-container'>
              <Selector2 append={true} name={"Name"} maxCount={5} dataList={props.modelList} select={ChargerModelVal} setSelect={setChargerModelVal} active={ChargerModelActive} setActive={setChargerModelActive} activeReset={activeReset} appendFunction={goToModelManagement}/>
            </div>
            <div className="charger-detail-info-flex-wrapper">
              <div className="charger-detail-info-container chargerTab2 longer">
                <p className='charger-detail-tab-sub-title no-margin'>충전기 종류</p>
                <div>
                  <p className='cpo-charger-detail-info-chargerType'>{chargerTypeConverter(modelInfo?.ChargerType)}</p>
                </div>
              </div>
              <div className="charger-detail-info-container chargerTab2 longer">
                <p className='charger-detail-tab-sub-title'>동시충전 지원</p>
                <div className='charger-detail-info-connectorType'>
                {
                  ChargerModelVal&&ChargerModelVal.Connectors.length>1 ?
                  <p>지원</p>
                  :
                  <p>미지원</p>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className='charger-input-title'>커넥터 정보</p>
          <div className="charger-model-info-grid-oneColumn">

            <div className="charger-detail-info-container chargerTab2 longer">
              <p className='charger-detail-tab-sub-title'>커넥터 1</p>
              <div>
                {/* <p className='cpo-charger-detail-info-chargerType'>{chargerTypeConverter(modelInfo?.ChargerType)}</p> */}
                {
                  modelInfo?.Connectors[0] &&
                  modelInfo?.Connectors[0]?.ConnectorTypes.map((it, idx)=>{
                    if(!it)return;
                    return(
                    <>
                      <div className="cpo-charger-model-connector-badge">
                        <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
                      </div>
                    </>
                  )
                  })
                }
              </div>
            </div>
            {
              modelInfo?.Connectors.length>1&&
              <div className="charger-detail-info-container chargerTab2 longer">
                <p className='charger-detail-tab-sub-title'>커넥터 2</p>
                <div>
                  {
                    modelInfo?.Connectors[1]?.ConnectorTypes.map((it, idx)=>{
                      if(!it)return;
                      return(
                      <>
                        <div className="cpo-charger-model-connector-badge">
                          <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
                        </div>
                      </>
                    )
                    })
                  }
                </div>
              </div>
            }
          </div>


        </div>
      </div>
      <div>
        <p className='charger-input-title'>기기 정보</p>
        <div className='charger-model-info-grid'>
          <div className="charger-detail-info-container chargerTab2">
            <p className='charger-detail-tab-sub-title'>속도</p>
            <div>
              <p>{modelInfo?.ChargingSpeed}</p>
              <p className='postFix'>kWh</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p className='charger-detail-tab-sub-title'>출력</p>
            <div>
              <p>{modelInfo?.ChargingPower}</p>
              <p className='postFix'>kW</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p className='charger-detail-tab-sub-title'>전압</p>
            <div>
              <p>{modelInfo?.Voltage}</p>
              <p className='postFix'>V</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p className='charger-detail-tab-sub-title'>전류</p>
            <div>
              <p>{modelInfo?.ElectricCurrent}</p>
              <p className='postFix'>A</p>
            </div>
          </div>

          
        </div>
      </div>
      <div>
        <p className='charger-input-title'>상세 정보</p>
        <div className='charger-model-info-grid-oneColumn'>
          <div className="charger-detail-info-container chargerTab2 longer">
            <p className='charger-detail-tab-sub-title'>등록년월</p>
            <div>
              <p>{modelInfo?.ActualRegistrationDate}</p>
            </div>
          </div>
          
          <div className="charger-detail-info-container chargerTab2 longer">
            <p className='charger-detail-tab-sub-title'>설명</p>
            <p>{modelInfo?.Remarks ? modelInfo?.Remarks:"-"}</p>
          </div>
        </div>
        
      </div>
      <div>
        <div className="charger-detail-info-textarea-container">
          <p className='charger-input-title no-margin'>상세스펙</p>
          <textarea value={modelInfo?.DetailedSpec} readOnly/>
        </div>
      </div>
    </div>
  )
}
export default ChargerDetailTab2;
