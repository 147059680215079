import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import FaqItem from "../../components/list/FaqItem.js";
import { IoCloseCircle } from "react-icons/io5";
import Selector from '../../components/buttons/Selector.js';
import { getFaqList, postFaq, putFaq} from '../../api/cs/csApi.js';

import { getCpoId } from '../../util/util.js';
import FaqDeleteModal from '../../components/modals/FaqDeleteModal.js';
const CpoFAQ = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  const [cpoMemberId, setCpoMemberId] = useState(null);
  useEffect(()=>{
    reqGetCpoId();
  },[])

  let reqGetCpoId = async()=>{
    let result = await getCpoId();
    setCpoMemberId(result);
  }

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:11, idx:0}));
  },[])


  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }

  const [modalOpen, setModalOpen] = useState(false);

  const[faqList, setFaqList] = useState([]);

  const reqGetFaqList = async(cpoMemberId)=>{

    let result = await getFaqList(cpoMemberId);
    if(result.status === 200){
      let temp = [...result.data];
      setFaqList(temp);
    }
  }
  
  const reqPostFaq = async(data)=>{
    let result = await postFaq(data);
    if(result.status === 200){
      
    }
    else{

    }
  }
  
  const reqPutFaq = async(postId, data)=>{
    let result = await putFaq(postId, data);
    if(result.status === 200){
      
    }
    else{

    }
  }

  useEffect(()=>{
    reqGetFaqList(cpoMemberId);
  },[cpoMemberId])

  const tabName = (idx)=>{
    switch (idx) {
      case 0: return"전체";
      case 1: return"충전기 설치";
      case 2: return"충전기 이용";
      case 3: return"충전기 A/S";
      case 4: return"회원계정";
      case 5: return"회원카드";
      case 6: return"요금/결제";
      case 7: return"앱 이용방법";
      case 8: return"기타";
      default: return "전체";
    }
  }
  let tabList = [
    {id:"",name:"충전기 설치"},
    {id:"",name:"충전기 이용"},
    {id:"",name:"충전기 A/S"},
    {id:"",name:"회원계정"},
    {id:"",name:"회원카드"},
    {id:"",name:"요금/결제"},
    {id:"",name:"앱 이용방법"},
    {id:"",name:"기타"},
  ];

  const [tabSelection, setTabSelection] = useState("");
  const [tabSelectionActive, setTabSelectionActive] = useState(null);
  const [title, setTitle] = useState("");
  const [answer, setAnswer] = useState("");

  const activeReset = ()=>{
    setTabSelectionActive(false)
  }

  const [enrollBtnStatus, setEnrollBtnStatus] = useState(false);

  useEffect(()=>{
    if(title.trim() == "" || answer.trim() == "" || tabSelection == "") setEnrollBtnStatus(false);
    else{
      setEnrollBtnStatus(true);
    }
  },[title, answer, tabSelection])


  const enroll = async(postId)=>{
    if(title.trim() == "" || answer.trim() == "" || tabSelection == "")return;
    let reqObj = {
      "title": title.trim(),
      "category": tabSelection.name,
      "contents": answer.trim(),
      "visibilityLevel": "회원종속",
      "ownerId": cpoMemberId
    };
    if(!isPatch){
      let result = await reqPostFaq(reqObj);
      //!TODO 성공/실패 시 이벤트
      await reqGetFaqList(cpoMemberId);
    }
    else{
      let result = await reqPutFaq(postId, reqObj);
      await reqGetFaqList(cpoMemberId);
      setIsPatch(false);
    }
    setModalOpen(false);
    setPostId(null);
  }

  // 수정 요청인가?
  const[isPatch, setIsPatch] = useState(false);
  const[postId, setPostId] = useState(null);


  const [deleteModalOn, setDeleteModalOn] = useState(false);

  return (
    <div className='cpo-customer-service-container'>
      {deleteModalOn && <FaqDeleteModal setOnOff={setDeleteModalOn} postId={postId} cpoMemberId={cpoMemberId} reqGetFaqList={reqGetFaqList}/>}
      <div className="cpo-customer-service-inner faq">
        <div className="cpo-faq-container">

          <div className={'cpo-faq-enroll-modal-container ' + `${modalOpen ? "modalOpen":""}`}>
            <div className="cpo-faq-enroll-modal-inner">
              <div className='faq-enroll-title'><p>카테고리</p></div>
              <div className='cpo-faq-enroll-modal-header'>
                <div className='faq-selector-container'>
                  <Selector grid={true} dataList={tabList} select={tabSelection} setSelect={setTabSelection} active={tabSelectionActive} setActive={setTabSelectionActive} activeReset={activeReset}/>
                </div>
                <div className='cpo-faq-enroll-modal-button-container'>
                  <div className='cpo-faq-enroll-modal-button cancel' onClick={()=>{setModalOpen(false)}}>취소</div>
                  <div className={'cpo-faq-enroll-modal-button enroll'  + `${enrollBtnStatus ? "":" disabled"}`} onClick={()=>{isPatch ? enroll(postId):enroll()}}>{isPatch ? "수정하기":"등록하기"}</div>
                </div>
              </div>
              <div className='cpo-faq-enroll-modal-content-container'>
                <div className='faq-enroll-title'><p>제목 란</p></div>
                <div className="faq-title-input-container">
                  <form onSubmit={(e)=>{}}>
                    <input className='hidden' hidden="hidden" />
                    <input type="text" placeholder='' value={title} onChange={(e)=>{setTitle(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </form>
                </div>
                <div className='faq-enroll-title answer'><p>답변 란</p></div>
                <div className='cpo-faq-enroll-modal-answer-container'>
                  <div className='cpo-faq-enroll-modal-answer'>
                    <textarea type="text" placeholder='' value={answer} onChange={(e)=>{setAnswer(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
                  </div>
                </div>
              </div>
              {/* <div className={'cpo-faq-enroll-button ' + `${enrollBtnStatus ? "":"disabled"}`} onClick={()=>{isPatch ? enroll(postId):enroll()}}><p>{isPatch ? "수정하기":"등록하기"}</p></div> */}
            </div>
          </div>


          <div className={"cpo-faq-header " + `${modalOpen ? "modalOpen":""}`}>
            <div className='cpo-faq-append-button' onClick={()=>{setIsPatch(false); setTitle(""); setAnswer(""); setTabSelection(""); setModalOpen(!modalOpen);}}>
              <p>새 질문 등록</p>
            </div>
            <div className='cpo-faq-tab-container'>
              <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx == 0 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(0)}}><p>{tabName(0)}</p></div>
              <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx == 1 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(1)}}><p>{tabName(1)}</p></div>
              <div ref={el=>tabBtnRef.current[2]=el} className={tabIdx == 2 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(2)}}><p>{tabName(2)}</p></div>
              <div ref={el=>tabBtnRef.current[3]=el} className={tabIdx == 3 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(3)}}><p>{tabName(3)}</p></div>
              <div ref={el=>tabBtnRef.current[4]=el} className={tabIdx == 4 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(4)}}><p>{tabName(4)}</p></div>
              <div ref={el=>tabBtnRef.current[5]=el} className={tabIdx == 5 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(5)}}><p>{tabName(5)}</p></div>
              <div ref={el=>tabBtnRef.current[6]=el} className={tabIdx == 6 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(6)}}><p>{tabName(6)}</p></div>
              <div ref={el=>tabBtnRef.current[7]=el} className={tabIdx == 7 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(7)}}><p>{tabName(7)}</p></div>
              <div ref={el=>tabBtnRef.current[8]=el} className={tabIdx == 8 ? "faq-tab-button selected":"faq-tab-button"} onClick={()=>{tabClick(8)}}><p>{tabName(8)}</p></div>
              <div className={'faq-tab-bar '+'tab'+tabIdx}></div>
            </div>
          </div>
          <div className={"cpo-faq-list-container "+ `${modalOpen ? "modalOpen":""}`}>
            {
              faqList.map((it, idx)=>{
                return(<FaqItem key={idx} data={it} category={tabName(tabIdx)} setModalOpen={setModalOpen} setTitle={setTitle} setAnswer={setAnswer} setTabSelection={setTabSelection} setIsPatch={setIsPatch} setPostId={setPostId} setDeleteModalOn={setDeleteModalOn}/>)
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoFAQ;