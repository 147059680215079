import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import {setNum} from '../../store.js'
import { getCookie, adjustColor, signedOutToLogin } from '../../util/util.js';

import {IoMdEye, IoMdEyeOff} from 'react-icons/io';

import { Loading } from '../../components/alerts/Loading';
import {postForgetPassword,postResetPassword} from '../../api/account/accountApi';
import { literallyNumberInput } from '../../util/util';

const LabelMemberInfoUpdate = (props) => {
  const user = useSelector(state => state.user);
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:13, idx:2}));
  },[])
  let navigator = useNavigate();
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordCheck, setPasswordCheck] = useState(null);


  // 이메일 패턴 검사
  
  const [sendValidate, setSendValidate] = useState(null);
  const [sendMsg, setSendMsg] = useState(null);
  const [tokenValidate, setTokenValidate] = useState(null);

  const [passwordValidate, setPasswordValidate] = useState(null);
  const [passwordCheckValidate, setPasswordCheckValidate] = useState(null);
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(false);
  
  const [sendLoading, setSendLoading] = useState(false);
  const [loadingOn, setLoadingOn] = useState(false);

  const [isDone, setIsDone] = useState(false);

  useEffect(()=>{
    if(sendMsg){
      setTimeout(() => {
        setSendMsg(null);
      }, 2000);
    }
  },[sendMsg])

  
  // 자음 및 모음 매핑 (키와 값이 바뀜)
  const keyMap = {
    'ㅂ': 'q', 'ㅈ': 'w', 'ㄷ': 'e', 'ㄱ': 'r', 'ㅅ': 't',
    'ㅛ': 'y', 'ㅕ': 'u', 'ㅑ': 'i', 'ㅐ': 'o', 'ㅔ': 'p',
    'ㅁ': 'a', 'ㄴ': 's', 'ㅇ': 'd', 'ㄹ': 'f', 'ㅎ': 'g',
    'ㅗ': 'h', 'ㅓ': 'j', 'ㅏ': 'k', 'ㅣ': 'l', 'ㅋ': 'z',
    'ㅌ': 'x', 'ㅊ': 'c', 'ㅍ': 'v', 'ㅠ': 'b', 'ㅜ': 'n', 'ㅡ': 'm',
  };

  const doubleConsonantsMap = {
    'ㅃ': 'Q', 'ㅉ': 'W', 'ㄸ': 'E', 'ㄲ': 'R', 'ㅆ': 'T', 'ㅒ': 'O', 'ㅖ': 'P',
  };

  function convertKoreanToEnglish(input, isLastCharUpper) {
    let result = '';

    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const mappedChar = keyMap[char] || doubleConsonantsMap[char] || char;

      // 마지막 글자만 대문자로 변환
      if (i === input.length - 1 && isLastCharUpper) {
        result += mappedChar.toUpperCase();
      } else {
        result += mappedChar;
      }
    }

    return result;
  }

  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const handleKeyDown = (event) => {
    if (event.shiftKey) {
      setIsShiftPressed(true);
    }
  };

  const handleKeyUp = () => {
    setIsShiftPressed(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // 한글 초성 또는 중성을 영어로 변환
    const convertedValue = convertKoreanToEnglish(inputValue, isShiftPressed);
    setPassword(convertedValue);
  };
  const handleInputChangeDoubleCheck = (event) => {
    const inputValue = event.target.value;
    // 한글 초성 또는 중성을 영어로 변환
    const convertedValue = convertKoreanToEnglish(inputValue, isShiftPressed);
    setPasswordCheck(convertedValue);
  };

  // const passwordInput = (target) => {
  //   target.value = target.value.replace(' ', '');
  //   const pattern = /^(?=.*[!@#$%^&*])((?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[!@#$%^&*])|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)).*$/;
  //   const value = target.value;
  //   if (value.length >= 6 && value.length <= 20 && pattern.test(value)) {
  //       setPasswordValidate(true);
  //   } else {
  //       setPasswordValidate(false);
  //   }
  // };
  const passwordInput = (target) => {
    target.value = target.value.replace(' ', '');
    const pattern = /^(?=.*[!@#$%^&*])(?=.*[A-Za-z])(?=.*\d).{6,20}$/;
    const value = target.value;
    if (pattern.test(value)) {
      setPasswordValidate(true);
    } else {
      setPasswordValidate(false);
    }
  };



  useEffect(()=>{
    if(passwordCheck == null || password == null)return;
    if (passwordCheck == password){
      if(passwordCheck == "" || password == "")return;
      setPasswordCheckValidate(true);
    }
    else{
      setPasswordCheckValidate(false);
    }
  },[passwordCheck, password])

  
  const [signUpMsg, setSignUpMsg] = useState(null);


  // 인증토큰 요청
  const reqPostForgetPassword = async()=>{
    setSendLoading(true);
    setSendValidate(null);
    let res = await postForgetPassword({email:user.email});
    if(res.status === 200){
      setSendValidate(true);
      setSendMsg("인증메일 전송에 성공하였습니다.");
    }
    else{
      setSendMsg("인증메일 전송에 실패하였습니다.");
    }
    setSendLoading(false);
  }

  // 비밀번호 재설정
  const reqPostResetPassword = async()=>{
    if(!sendValidate || !token || !passwordValidate || !passwordCheckValidate || !password || !passwordCheck){
      return;
    }
    setLoadingOn(true);
    let res = await postResetPassword({email:user.email, token:token, password:password});
    if(res.status === 200){
      setIsDone(true);
    }
    else{
      setSignUpMsg("비밀번호 재설정에 실패하였습니다.");
    }
    setLoadingOn(false);
  }
  
  return (
    <div className='cpo-member-info-update-container'>
        <div className='cpo-member-info-update-grid-container'>
          <div className='cpo-member-info-update-front' style={{
            background: `linear-gradient(133deg, ${process.env.PUBLIC_URL + process.env.REACT_APP_MAIN_COLOR} 30%, ${adjustColor(process.env.PUBLIC_URL + process.env.REACT_APP_MAIN_COLOR, 0.8, 1.1)} 100%)`,
            backgroundColor: process.env.PUBLIC_URL + process.env.REACT_APP_MAIN_COLOR
            }}>

            <img className='label-fast-enroll-logo' src={process.env.PUBLIC_URL+process.env.REACT_APP_WHITE_LOGO}/>

          </div>
          <div className='member-info-update-back-wrapper'>
              {
                isDone ?
                <div className="member-info-update-tab-container done">
                  <p className='member-info-update-done-msg'>비밀번호 재설정이 완료되었습니다.</p>
                  <div className="member-info-update-done-button" onClick={()=>{signedOutToLogin();}}>재로그인</div>
                </div>
              :
                <div className="member-info-update-tab-container">
                  <>
                    <div className='member-info-update-input-row'>
                      <p className="member-info-update-input-title">인증번호</p>
                      <div className={'member-info-update-input-container email' + `${tokenValidate == 'invalid' ? ' invalid':""}`}><input placeholder='인증번호' type="text" value={token} onChange={(e)=>{setToken(e.target.value);}} maxLength={6} onInput={(e)=>{literallyNumberInput(e.target)}} readOnly={!sendValidate}/></div>
                      <div className={`member-info-update-token-send-button`} onClick={(e)=>{e.preventDefault(); reqPostForgetPassword(); }}>
                        {sendLoading ? <Loading/>:
                          <>
                            {sendValidate ?<p>인증번호 재전송</p> : <p>인증번호 전송</p>}
                          </>
                        }
                      </div>
                      {
                        sendMsg && <p className={`member-info-update-validation-msg ${sendMsg}`}>{sendMsg}</p>
                      }
                      {
                        sendValidate && !sendMsg &&
                        <>
                          {<p className={'member-info-update-validation-msg able'}>메일로 전송된 6자리 숫자를 입력해주세요.</p>}
                        </>
                      }
                    </div>
                    
                    <div className='member-info-update-input-row'>
                      <p className="member-info-update-input-title">비밀번호</p>
                      <div className={'member-info-update-input-container password ' + `${passwordValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호' 
                      onKeyDown={handleKeyDown}
                      onKeyUp={handleKeyUp}
                      type={passwordVisible ? "text" : "password"} autocomplete="new-password" value={password} onChange={(e)=>{handleInputChange(e);}} onInput={(e)=>{passwordInput(e.target)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div>
                      {passwordValidate && passwordValidate!=="invalid" ? <p className={'member-info-update-validation-msg able' }>유효한 비밀번호입니다.</p> : password!==null || passwordValidate=="invalid" ? <p className={'member-info-update-validation-msg alert' }>영문 대문자와 소문자, 숫자, 특수문자 중 2가지 이상을 조합하여 6~20자로 입력해주세요.</p>:<></>}
                    </div>
                    <div className='member-info-update-input-row'>
                      <p className="member-info-update-input-title">비밀번호 재확인</p>
                      <div className={'member-info-update-input-container password-check '+ `${passwordCheckValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호 확인'
                      onKeyDown={handleKeyDown}
                      onKeyUp={handleKeyUp}
                      type={passwordCheckVisible ? "text" : "password"} autocomplete="new-password" value={passwordCheck} onChange={(e)=>{handleInputChangeDoubleCheck(e)}} onInput={(e)=>{e.target.value = e.target.value.replace(' ', '');}}/> {passwordCheckVisible ? <IoMdEyeOff onClick={()=>setPasswordCheckVisible(false)}/> : <IoMdEye onClick={()=>setPasswordCheckVisible(true)}/>}</div>
                      {passwordCheckValidate !== "invalid" &&(passwordCheckValidate || passwordCheck == null) ? <></> : <p className={'member-info-update-validation-msg alert'}>비밀번호가 일치하지 않습니다.</p>}
                    </div>
                    
      
                    <div className='member-info-update-go-to-container'>
                      <p>{signUpMsg}</p>
                      <div className={`member-info-update-go-to-button request ${ !sendValidate || !token || !passwordValidate || !passwordCheckValidate || !password || !passwordCheck ? "disabled":""}`} onClick={()=>{reqPostResetPassword()}}>              
                        {loadingOn ? <Loading/> : <p>비밀번호 재설정</p>}
                      </div>
                    </div>
                  </>
                </div>
              }
            
          </div>
        </div>
        
    </div>
  )
}
export default LabelMemberInfoUpdate;