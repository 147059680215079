import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {setNum} from '../../store.js'

import {getFirmwareList, postFirmwareCpoUser, } from '../../api/controlhub/controlhubApi.js';
import CpoFirmwareListItem from '../../components/list/CpoFirmwareListItem.js';
import CpoFirmwareEnrollModal from '../../components/modals/CpoFirmwareEnrollModal.js';
import CpoFirmwareVersionEnrollModal from '../../components/modals/CpoFirmwareVersionEnrollModal.js';

import FirmwareDeleteModal from '../../components/modals/FirmwareDeleteModal.js';
import FirmwareVersionDeleteModal from '../../components/modals/FirmwareVersionDeleteModal.js';

export const CpoFirmwareManagement = () => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:17}));
  },[])  
  
  // page 인덱스
  // const [pageNum, setPageNum] = useState(1);
  // page 사이즈
  // const [pageSize, setPageSize] = useState(10);


  // Firmware List
  const [firmwareList, setFirmwareList] = useState();

  // Firmware List 요청
  const reqGetFirmwareListCpoUser = async()=>{
    let result = await getFirmwareList({});
    if(result.status === 200){
      setFirmwareList([...result.data?.results]);
    }
    else{

    }
  }

  useEffect(()=>{
    reqGetFirmwareListCpoUser();
  },[])

  const [selectedFirmwareIdx, setSelectedFirmwareIdx] = useState(null);
  const [selectedVersionIdx, setSelectedVersionIdx] = useState(null);

  const [firmwareEnrollModalOpen, setFirmwareEnrollModalOpen] = useState(false);
  const [firmwareDeleteModalOpen, setFirmwareDeleteModalOpen] = useState(false);
  const [firmwareVersionEditModalOpen, setFirmwareVersionEditModalOpen] = useState(false);
  const [firmwareVersionDeleteModalOpen, setFirmwareVersionDeleteModalOpen] = useState(false);

  // 펌웨어 등록-수정 모달 state (enroll, edit)
  const [firmwareEditMode, setFirmwareEditMode] = useState(null);
  // 펌웨어 버전 등록-수정 모달 state (enroll, edit)
  const [versionEditMode, setVersionEditMode] = useState(null);

  // 리스트 수정 모드 state
  const [editMode, setEditMode] = useState(false);
  // 리스트 삭제 모드 state
  const [deleteMode, setDeleteMode] = useState(false);

  const [checked, setChecked] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState([]);
  
  // checked state (모든 아이템 check)
  useEffect(()=>{
    if(checked){
      let temp = new Array(firmwareList.length).fill(0).map((_, i) => i)
      setCheckedItemList(temp);
    }
    else{
      setCheckedItemList([]);
    }
  },[checked])

  useEffect(()=>{
    if(firmwareEditMode ){
      if(firmwareEditMode === "edit")setFirmwareEnrollModalOpen(true);
    }
  },[firmwareEditMode])

  return (
    <div className="cpo-firmware-management-container">
      {firmwareEnrollModalOpen && <CpoFirmwareEnrollModal setOnOff={setFirmwareEnrollModalOpen} selectedFirmwareData={firmwareList[checkedItemList[0]]} mode={firmwareEditMode} setMode={setFirmwareEditMode} reqGetFirmwareListCpoUser={reqGetFirmwareListCpoUser}/>}
      {firmwareVersionEditModalOpen && <CpoFirmwareVersionEnrollModal setOnOff={setFirmwareVersionEditModalOpen} mode={versionEditMode} reqGetFirmwareListCpoUser={reqGetFirmwareListCpoUser} data={firmwareList[selectedFirmwareIdx]} versionIdx={selectedVersionIdx}/>}
      {firmwareDeleteModalOpen && <FirmwareDeleteModal setOnOff={setFirmwareDeleteModalOpen} firmwareList={firmwareList} checkedItemList={checkedItemList} reqGetFirmwareListCpoUser={reqGetFirmwareListCpoUser} setDeleteMode={setDeleteMode}/>}
      {firmwareVersionDeleteModalOpen && <FirmwareVersionDeleteModal setOnOff={setFirmwareVersionDeleteModalOpen} firmwareList={firmwareList} selectedFirmwareIdx={selectedFirmwareIdx} selectedVersionIdx={selectedVersionIdx} reqGetFirmwareListCpoUser={reqGetFirmwareListCpoUser} setVersionEditMode={setVersionEditMode}/>}
      <div className="cpo-firmware-management-inner">
        <div className="cpo-firmware-management-header">
          {editMode&&
            <p className='cpo-firmware-management-info-msg edit'>수정할 펌웨어를 체크해주세요</p>
          }
          {deleteMode&&
            <p className='cpo-firmware-management-info-msg delete'>삭제할 펌웨어를 체크해주세요</p>
          }

          {!editMode&&!deleteMode&&
          <div className="cpo-firmware-management-header-button" onClick={()=>{
            setFirmwareEditMode("enroll");
            setFirmwareEnrollModalOpen(true);
          }}>
            펌웨어 추가
          </div>
          }

          {!deleteMode&&
          <div className={`cpo-firmware-management-header-button update ${editMode && checkedItemList.length === 0 ?"disabled":""}`} onClick={()=>{
            !editMode ? setEditMode(true) : setFirmwareEditMode("edit"); 
          }}>
            수정
          </div>
          }

          {!editMode&&
          <div className={`cpo-firmware-management-header-button delete ${deleteMode && checkedItemList.length === 0 ?"disabled":""}`} onClick={()=>{
            !deleteMode ? setDeleteMode(true) : setFirmwareDeleteModalOpen(true)
          }}>
            삭제
          </div>
          }

          {(editMode||deleteMode)&&
          <div className='cpo-firmware-management-header-button cancel' onClick={()=>{setEditMode(false);setDeleteMode(false);setCheckedItemList([]);setChecked(false);}}>
            취소
          </div>
          }
        </div>

        <div className="cpo-firmware-management-content-container">
          <div className="cpo-firmware-management-content-item-list-container">
            <div className={`cpo-firmware-management-content-item-column ${editMode || deleteMode ? "edit":""}`}>
              {
                deleteMode &&
                <div>
                  <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                    <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                    <span className="checkbox_icon"></span>
                  </label>
                </div>
              }
              { editMode && <div></div>}
              <div>펌웨어명</div>
              <div>설명</div>
              <div></div>
              <div></div>
            </div>

            {
              firmwareList&&firmwareList.map((it, idx)=>{
                return(
                  it.isDeleted === false &&
                  <CpoFirmwareListItem data={it} idx={idx} checkList={checkedItemList} setCheckList={setCheckedItemList} settingMode={editMode?"edit":deleteMode?"delete":null} setVersionEditMode={setVersionEditMode} setSelectedFirmwareIdx={setSelectedFirmwareIdx} setSelectedVersionIdx={setSelectedVersionIdx} setModalOpen={setFirmwareVersionEditModalOpen} setDeleteModalOpen={setFirmwareVersionDeleteModalOpen} />
                );
              })
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoFirmwareManagement;
