import { useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';

const ChargerDetailTab3 = (props) => {
  const [ChargerModelVal, setChargerModelVal] = useState('');
  const navigate = useNavigate();
  const onCapture = () => {
    const elements = document.getElementsByClassName('charger-qrcode');
  
    Array.from(elements).forEach((element, index) => {
      html2canvas(element).then((canvas) => {
        onSaveAs(canvas.toDataURL('image/png'), `${props.number}+0${index + 1}_QRcode.png`);
      });
    });
  };
  
  const onSaveAs = (uri, filename) => {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = uri;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
  };
  

  useEffect(()=>{
    if(!props.open)return;
    let temp = localStorage.getItem(`${props.number}_ChargerDetailTab2`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
        return;
      }
      if(temp['ChargerModel']){
        setChargerModelVal(props.modelList.filter(it=>it.Id == JSON.parse(temp['ChargerModel']))[0]);
      }
    }
  },[])
  useEffect(()=>{
    if(!props.open)return;
    if(!props.data?.ChargerModel)return;
    let temp = localStorage.getItem(`${props.number}_ChargerDetailTab2`);
    if(temp){
      temp = JSON.parse(temp);
      if(temp['expiredTime']){
        console.log(new Date(temp['expiredTime']) < new Date())
        if(new Date(temp['expiredTime']) < new Date()){
          localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
        }
        else{
          return;
        }
      }
      else{
        localStorage.removeItem(`${props.name}_ChargerDetailTab2`);
      }
    }

    setChargerModelVal(props.modelList.filter(it=>it?.Id == props.data?.ChargerModel?.Id)[0]);
    
  },[])

  return (
    <div className='charger-detail-tab tab3'>
      <div>
        <div className='charger-qrcode-container'>
          {
            ChargerModelVal?.Connectors?.map((it, idx)=>{
              return(
                <>
                  <div className="charger-qrcode-wrapper">
                    <div className='charger-qrcode-wrapper-info'>
                      {it.ConnectorTypes&&it.ConnectorTypes.toString()}
                    </div>
                    <div className="charger-qrcode">
                      {/* <p>QRCODE</p> */}
                      {props.number?
                      <QRCode value={`https://dev.emoov.co.kr/app-download?${props.number}+0${idx+1}`} />
                      :<p>일련번호가 지정되어 있지 않습니다.</p>
                      }
                    </div>
                  </div>
                </>
              )
            })
          }

        </div>
        <div className='qrcode-download-button-container'>
          <div className='qrcode-download-button' onClick={(e)=>{onCapture()}}><p>QR코드 이미지 다운로드</p></div>
        </div>
        {/* <button onClick={()=>{console.log(ChargerModelVal)}}></button> */}
      </div>
    </div>
  )
}
export default ChargerDetailTab3;
