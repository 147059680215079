import React, { useEffect, useState } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";

import { deleteChas } from '../../api/controlhub/controlhubApi.js';

import {deleteChargerModel} from '../../api/infra/infraApi.js';
import { Loading } from '../alerts/Loading.js';
import { useNavigate } from 'react-router-dom';
import { GoArrowUpRight } from "react-icons/go";

export const ChargerModelDeleteModal = (props) => {
  const [loadingOn, setLoadingOn] = useState(false);
  const [usedActionSetList, setUsedActionSetList] = useState([]);
  const reqDeleteChargerModel = async()=>{
    setLoadingOn(true);
    let usedTemp = [];
    for await(const target of props.checkedItemList){
      let result = await deleteChargerModel(props.chargerModelList[target].Id);
      if(result.status !== 200){
        usedTemp.push(target);
      }
    }
    if(usedTemp.length > 0){
      setUsedActionSetList(usedTemp);
    }
    else{
      props.setOnOff(false);
      props.reqGetChargerModelList();
      props.setCheckedItemList([]);
      props.setDeleteMode(false);
    }
    setLoadingOn(false);
  }

  return (
    <div className="charger-model-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="charger-model-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="charger-model-delete-modal-header">
          <title>충전기 모델 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='charger-model-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          {
            usedActionSetList.length > 0 ?
            <>
              <p className='charger-model-delete-modal-msg'>다음 모델은 삭제에 실패하였습니다.</p>
              <div className='charger-model-delete-modal-list-wrapper'>
                {
                  usedActionSetList?.map((it, idx)=>{
                    return(
                      <>
                        <div className='charger-model-delete-modal-item'>{props.chargerModelList[it]?.Name}</div>
                      </>
                    )
                  })
                }
              </div>
            </>:
            <>
              <p className='charger-model-delete-modal-msg'>{props.checkedItemList?.length}개의 모델을 삭제하시겠습니까?</p>
              <div className='charger-model-delete-modal-list-wrapper'>
                {
                  props.checkedItemList?.map((it, idx)=>{
                    return(
                      <>
                        <div className='charger-model-delete-modal-item'>{props.chargerModelList[it]?.Name}</div>

                      </>
                    )
                  })
                }
              </div>
            </>
          }
        </div>
        <div>
          {
            usedActionSetList.length > 0 ?
            <div className='charger-model-delete-modal-delete-button close' onClick={()=>{
              props.reqGetChargerModelList();
              props.setCheckedItemList([]);
              props.setDeleteMode(false);
              props.setOnOff(false);}}>
              <p>확인</p>
            </div>
            :
            <div className={'charger-model-delete-modal-delete-button '} onClick={()=>{reqDeleteChargerModel()}}>
              {loadingOn ? <Loading/>:<p>삭제</p>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default ChargerModelDeleteModal

