import { useEffect, useState } from 'react';
import React from 'react'
import {FaChevronLeft} from 'react-icons/fa';
import {IoMdEye, IoMdEyeOff} from 'react-icons/io';
import {useNavigate} from 'react-router-dom';
import { Loading } from '../../components/alerts/Loading';
import {postForgetPassword,postResetPassword} from '../../api/account/accountApi';
import { literallyNumberInput } from '../../util/util';
export const LabelFindPassword = () => {
  let navigator = useNavigate();
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordCheck, setPasswordCheck] = useState(null);


  // 이메일 패턴 검사
  const [emailValidate, setEmailValidate] = useState(null); // false : 유효하지않은 이메일 형식, true: 유효
  const [emailPatternValidate, setEmailPatternValidate] = useState(null); // false : 유효하지않은 이메일 형식, true: 이메일 형식
  
  const [sendValidate, setSendValidate] = useState(null);
  const [sendMsg, setSendMsg] = useState(null);
  const [tokenValidate, setTokenValidate] = useState(null);

  const [passwordValidate, setPasswordValidate] = useState(null);
  const [passwordCheckValidate, setPasswordCheckValidate] = useState(null);
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(false);
  
  const [sendLoading, setSendLoading] = useState(false);
  const [loadingOn, setLoadingOn] = useState(false);

  const [isDone, setIsDone] = useState(false);

  useEffect(()=>{
    if(emailPatternValidate ){
      setEmailValidate(true);
    }
    else{
      setEmailValidate(false);
    }
  },[emailPatternValidate])

  useEffect(()=>{
    if(sendMsg){
      setTimeout(() => {
        setSendMsg(null);
      }, 2000);
    }
  },[sendMsg])



  // Validation Pattern
  const emailInput = (target)=>{
    target.value = target.value.replace(' ', '');
    const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
    if(pattern.test(target.value) === false) {setEmailPatternValidate(false); }
    else { setEmailPatternValidate(true); }
  }

  
  // 자음 및 모음 매핑 (키와 값이 바뀜)
  const keyMap = {
    'ㅂ': 'q', 'ㅈ': 'w', 'ㄷ': 'e', 'ㄱ': 'r', 'ㅅ': 't',
    'ㅛ': 'y', 'ㅕ': 'u', 'ㅑ': 'i', 'ㅐ': 'o', 'ㅔ': 'p',
    'ㅁ': 'a', 'ㄴ': 's', 'ㅇ': 'd', 'ㄹ': 'f', 'ㅎ': 'g',
    'ㅗ': 'h', 'ㅓ': 'j', 'ㅏ': 'k', 'ㅣ': 'l', 'ㅋ': 'z',
    'ㅌ': 'x', 'ㅊ': 'c', 'ㅍ': 'v', 'ㅠ': 'b', 'ㅜ': 'n', 'ㅡ': 'm',
  };

  const doubleConsonantsMap = {
    'ㅃ': 'Q', 'ㅉ': 'W', 'ㄸ': 'E', 'ㄲ': 'R', 'ㅆ': 'T', 'ㅒ': 'O', 'ㅖ': 'P',
  };

  function convertKoreanToEnglish(input, isLastCharUpper) {
    let result = '';

    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const mappedChar = keyMap[char] || doubleConsonantsMap[char] || char;

      // 마지막 글자만 대문자로 변환
      if (i === input.length - 1 && isLastCharUpper) {
        result += mappedChar.toUpperCase();
      } else {
        result += mappedChar;
      }
    }

    return result;
  }

  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const handleKeyDown = (event) => {
    if (event.shiftKey) {
      setIsShiftPressed(true);
    }
  };

  const handleKeyUp = () => {
    setIsShiftPressed(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // 한글 초성 또는 중성을 영어로 변환
    const convertedValue = convertKoreanToEnglish(inputValue, isShiftPressed);
    setPassword(convertedValue);
  };
  const handleInputChangeDoubleCheck = (event) => {
    const inputValue = event.target.value;
    // 한글 초성 또는 중성을 영어로 변환
    const convertedValue = convertKoreanToEnglish(inputValue, isShiftPressed);
    setPasswordCheck(convertedValue);
  };

  // const passwordInput = (target) => {
  //   target.value = target.value.replace(' ', '');
  //   const pattern = /^(?=.*[!@#$%^&*])((?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[!@#$%^&*])|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)).*$/;
  //   const value = target.value;
  //   if (value.length >= 6 && value.length <= 20 && pattern.test(value)) {
  //       setPasswordValidate(true);
  //   } else {
  //       setPasswordValidate(false);
  //   }
  // };
  const passwordInput = (target) => {
    target.value = target.value.replace(' ', '');
    const pattern = /^(?=.*[!@#$%^&*])(?=.*[A-Za-z])(?=.*\d).{6,20}$/;
    const value = target.value;
    if (pattern.test(value)) {
      setPasswordValidate(true);
    } else {
      setPasswordValidate(false);
    }
  };



  useEffect(()=>{
    if(passwordCheck == null || password == null)return;
    if (passwordCheck == password){
      if(passwordCheck == "" || password == "")return;
      setPasswordCheckValidate(true);
    }
    else{
      setPasswordCheckValidate(false);
    }
  },[passwordCheck, password])

  
  const [signUpMsg, setSignUpMsg] = useState(null);


  // 인증토큰 요청
  const reqPostForgetPassword = async()=>{
    setSendLoading(true);
    setSendValidate(null);
    let res = await postForgetPassword({email:email});
    if(res.status === 200){
      setSendValidate(true);
      setSendMsg("인증메일 전송에 성공하였습니다.");
    }
    else{
      setSendMsg("인증메일 전송에 실패하였습니다.");
    }
    setSendLoading(false);
  }

  // 비밀번호 재설정
  const reqPostResetPassword = async()=>{
    if(!email || !emailValidate || !sendValidate || !token || !passwordValidate || !passwordCheckValidate || !password || !passwordCheck){
      return;
    }
    setLoadingOn(true);
    let res = await postResetPassword({email:email, token:token, password:password});
    if(res.status === 200){
      setIsDone(true);
    }
    else{
      setSignUpMsg("비밀번호 재설정에 실패하였습니다.");
    }
    setLoadingOn(false);
  }
  return (
    <div className='find-password-page-container label'>
        <FaChevronLeft className='go-to-initial-button' onClick={()=>{navigator("/");}}/>
      <div className="find-password-page-wrapper">
        <title>비밀번호 재설정</title>
        {
          isDone ?
          <div className="find-password-tab-container done">
            <p className='find-password-done-msg'>비밀번호 재설정이 완료되었습니다.</p>
            <div className="find-password-done-button" onClick={()=>{navigator("/login");}}>로그인</div>
          </div>
        :
          <div className="find-password-tab-container">
            <>
              <p className="find-password-tab-title">
                계정 정보
              </p>
              <div className='find-password-input-row'>
                <p className="find-password-input-title">이메일</p>
                <div className={'find-password-input-container email' + `${emailValidate == 'invalid' ? ' invalid':""}`}><input placeholder='이메일' type="email" value={email} onChange={(e)=>{setEmail(e.target.value);}} onInput={(e)=>{emailInput(e.target)}} readOnly={sendValidate}/></div>
                {
                  emailValidate !=="invalid" && emailValidate ?
                    <p className={'find-password-validation-msg able'}></p>
                  :
                  !email && emailValidate !=="invalid" ? <></>:<p className={'find-password-validation-msg alert'}>유효하지 않은 이메일 형식입니다.</p>
                }
              </div>
              
              <div className='find-password-input-row'>
                <p className="find-password-input-title">인증번호</p>
                <div className={'find-password-input-container email' + `${tokenValidate == 'invalid' ? ' invalid':""}`}><input placeholder='인증번호' type="text" value={token} onChange={(e)=>{setToken(e.target.value);}} maxLength={6} onInput={(e)=>{literallyNumberInput(e.target)}} readOnly={!sendValidate}/></div>
                <div className={`find-password-token-send-button ${emailValidate ? "":"none"}`} onClick={(e)=>{e.preventDefault(); reqPostForgetPassword(); }}>
                  {sendLoading ? <Loading/>:
                    <>
                      {sendValidate ?<p>인증번호 재전송</p> : <p>인증번호 전송</p>}
                    </>
                  }
                </div>
                {
                  sendMsg && <p className={`find-password-validation-msg ${sendMsg}`}>{sendMsg}</p>
                }
                {
                  sendValidate && !sendMsg &&
                  <>
                    {<p className={'find-password-validation-msg able'}>메일로 전송된 6자리 숫자를 입력해주세요.</p>}
                  </>
                }
              </div>
              
              <div className='find-password-input-row'>
                <p className="find-password-input-title">비밀번호</p>
                <div className={'find-password-input-container password ' + `${passwordValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호' 
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                type={passwordVisible ? "text" : "password"} autocomplete="new-password" value={password} onChange={(e)=>{handleInputChange(e);}} onInput={(e)=>{passwordInput(e.target)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div>
                {passwordValidate && passwordValidate!=="invalid" ? <p className={'find-password-validation-msg able' }>유효한 비밀번호입니다.</p> : password!==null || passwordValidate=="invalid" ? <p className={'find-password-validation-msg alert' }>영문 대문자와 소문자, 숫자, 특수문자 중 2가지 이상을 조합하여 6~20자로 입력해주세요.</p>:<></>}
              </div>
              <div className='find-password-input-row'>
                <p className="find-password-input-title">비밀번호 재확인</p>
                <div className={'find-password-input-container password-check '+ `${passwordCheckValidate == 'invalid' ? ' invalid':""}`}><input placeholder='비밀번호 확인'
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                type={passwordCheckVisible ? "text" : "password"} autocomplete="new-password" value={passwordCheck} onChange={(e)=>{handleInputChangeDoubleCheck(e)}} onInput={(e)=>{e.target.value = e.target.value.replace(' ', '');}}/> {passwordCheckVisible ? <IoMdEyeOff onClick={()=>setPasswordCheckVisible(false)}/> : <IoMdEye onClick={()=>setPasswordCheckVisible(true)}/>}</div>
                {passwordCheckValidate !== "invalid" &&(passwordCheckValidate || passwordCheck == null) ? <></> : <p className={'find-password-validation-msg alert'}>비밀번호가 일치하지 않습니다.</p>}
              </div>
              

              <div className='find-password-go-to-container'>
                <p>{signUpMsg}</p>
                <div className={`find-password-go-to-button request ${!email || !emailValidate || !sendValidate || !token || !passwordValidate || !passwordCheckValidate || !password || !passwordCheck ? "disabled":""}`} onClick={()=>{reqPostResetPassword()}}>              
                  {loadingOn ? <Loading/> : <p>비밀번호 재설정</p>}
                </div>
              </div>
            </>
          </div>
        }
        {/* <p className="find-password-input-title">이메일</p>
        <div className='find-password-input-container email'><input placeholder='이메일을 입력하세요' type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/></div>
        <p className="find-password-input-title">비밀번호</p>
        <div className='find-password-input-container password'><input placeholder='비밀번호를 입력하세요' type={passwordVisible ? "text" : "password"} value={password} onChange={(e)=>{setPassword(e.target.value)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div> */}

        
        
      </div>
    </div>
  )
}
export default LabelFindPassword;