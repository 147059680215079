import React from 'react'
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';

import { useState, useEffect } from 'react';

import { getChas, getActions, getActionSetOfProfileVersion, getActionOfProfileVersion, postActionSetProfileVersionCopyCpoUser } from '../../api/controlhub/controlhubApi.js';


import { FaCheck, FaTimes } from "react-icons/fa";

import ChasCopyModal from './ChasCopyModal.js';
import { LoadingList } from '../alerts/LoadingList.js';

export const ChasPreviewModal = (props) => {

  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [profileId, setProfileId] = useState(props.profileData?.actionSetProfileId);
  const [versionId, setVersionId] = useState(props.versionData?.actionSetProfileVersionId);

  const messageTypeList = [
    // {name:"AUTHORIZE_REQ", desc:"인증 요청 메세지"},
    // {name:"START_TRANSACTION_REQ", desc:"시작 메세지"},
    // {name:"STOP_TRANSACTION_REQ", desc:"종료 메세지"},
    // {name:"REMOTE_START_TRANSACTION_REQ", desc:"원격 시작 메세지"},
    // {name:"REMOTE_STOP_TRANSACTION_REQ", desc:"원격 종료 메세지"},
    {name: "Authorize", desc: "인증 요청 메시지", isMandatory: true},
    {name: "BootNotification", desc: "부트 알림 메시지", isMandatory: true},
    {name: "DiagnosticsStatusNotification", desc: "진단 상태 알림 메시지", isMandatory: true},
    {name: "FirmwareStatusNotification", desc: "펌웨어 상태 알림 메시지", isMandatory: true},
    {name: "MeterValues", desc: "미터 값 메시지", isMandatory: true},
    {name: "StartTransaction", desc: "시작 메시지", isMandatory: true},
    {name: "StatusNotification", desc: "상태 알림 메시지", isMandatory: true},
    {name: "StopTransaction", desc: "종료 메시지", isMandatory: true},

    {name: "CancelReservation", desc: "예약 취소 메시지", isMandatory: false},
    {name: "ChangeAvailability", desc: "가용 상태 변경 메시지", isMandatory: false},
    {name: "ChangeConfiguration", desc: "설정 변경 메시지", isMandatory: false},
    {name: "ClearCache", desc: "캐시 삭제 메시지", isMandatory: false},
    {name: "ClearChargingProfile", desc: "충전 프로필 삭제 메시지", isMandatory: false},
    {name: "GetCompositeSchedule", desc: "복합 일정 요청 메시지", isMandatory: false},
    {name: "GetConfiguration", desc: "설정 요청 메시지", isMandatory: false},
    {name: "GetDiagnostics", desc: "진단 데이터 요청 메시지", isMandatory: false},
    {name: "GetLocalListVersion", desc: "로컬 목록 버전 요청 메시지", isMandatory: false},
    {name: "Heartbeat", desc: "하트비트 메시지", isMandatory: false},
    {name: "RemoteStartTransaction", desc: "원격 시작 메시지", isMandatory: false},
    {name: "RemoteStopTransaction", desc: "원격 종료 메시지", isMandatory: false},
    {name: "ReserveNow", desc: "즉시 예약 메시지", isMandatory: false},
    {name: "Reset", desc: "리셋 메시지", isMandatory: false},
    {name: "SendLocalList", desc: "로컬 목록 전송 메시지", isMandatory: false},
    {name: "SetChargingProfile", desc: "충전 프로필 설정 메시지", isMandatory: false},
    {name: "TriggerMessage", desc: "트리거 메시지", isMandatory: false},
    {name: "UnlockConnector", desc: "커넥터 잠금 해제 메시지", isMandatory: false},
    {name: "UpdateFirmware", desc: "펌웨어 업데이트 메시지", isMandatory: false}
  ];

  const [chasInfo, setChasInfo] = useState(null);
  const [actions, setActions] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const [actionListByMessage, setActionListByMessage] = useState(null);

  const [messageLoadingOn, setMessageLoadingOn] = useState(false);

  const reqGetActionSetOfProfileVersion = async()=>{
    if(!versionId || !profileId)return;
    let result = await getActionSetOfProfileVersion(profileId, versionId);
    if(result.status === 200){
      setChasInfo(result.data);
    }
  }

  const reqGetActionOfProfileVersion = async()=>{
    if(!versionId || !profileId)return;
    setMessageLoadingOn(true);
    let tempData = [];
    for await(const it of messageTypeList){
      let result = await getActionOfProfileVersion(profileId, versionId, it.name, null);
      if(result.status === 200){
        tempData.push({name: it.name, actionList: result.data});
      }
    }
    setActionListByMessage(tempData);
    setMessageLoadingOn(false);
  }
  
  const reqGetActions = async()=>{
    let result = await getActions();
    if(result.status === 200){
      setActions(result.data);
    }
  }

  const [dataTransferMsgNameList, setDataTransferMsgNameList] = useState([])
  const [dataTransferMsgList, setDataTransferMsgList] = useState([])
  const [selectedDataTransferMsg, setSelectedDataTransferMsg] = useState(null);
  const getDataTransfer = async()=>{
    let temp = [];
    let temp2 = [];
    for await(const it of chasInfo?.messageControlHubActions){
      if(it.messageType == 'DataTransfer'){
        let msg = it.dataTransferMessageId;
        temp.push(msg);
        temp2.push(it)
      }
    }
    setDataTransferMsgNameList(temp);
    setDataTransferMsgList(temp2);
    if(temp.length !== 0 && selectedDataTransferMsg === null)setSelectedDataTransferMsg(temp[0]);
  }


  useEffect(()=>{
    reqGetActionSetOfProfileVersion();
  },[])
  useEffect(()=>{
    reqGetActions();
  },[])
  useEffect(()=>{
    reqGetActionOfProfileVersion();
    setSelectedMessage("Authorize");
  },[])
  useEffect(()=>{
    if(!chasInfo)return;
    getDataTransfer();
  },[chasInfo])

  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }


  const [copyModalOpen, setCopyModalOpen] = useState(false);

  return (
    <div className="cpo-chas-preview-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-chas-preview-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        {copyModalOpen && <ChasCopyModal profileName={props.profileData?.name} version={props.versionData?.version}  profileId={profileId} versionId={versionId} setOnOff={setCopyModalOpen}/>}
        <div className="modal-header">
          <title>액션셋 미리보기</title>
          <div className='modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-chas-preview-modal-content">
          <div className='cpo-custom-chas-action-modal-content-header'>
            <div className='cpo-custom-chas-action-modal-info'>
              <div>{props.profileData?.name}</div>
              <div>{props.versionData?.version}</div>
            </div>
            <div className='cpo-custom-chas-action-modal-save-button' onClick={()=>{setCopyModalOpen(true)}}>{loadingOn ? <Loading/> : "복사하기"}</div>
            {errMsg && <p>{errMsg}</p>}
          </div>
          <div className='cpo-chas-preview-modal-action-set-content-container'>

            <div className='cpo-custom-chas-tab-container'>
              <div className={tabIdx == 0 ? "custom-chas-tab-button selected":"custom-chas-tab-button"} onClick={()=>{tabClick(0);}}><p>일반 메세지</p></div>
              <div className={tabIdx == 1 ? "custom-chas-tab-button selected":"custom-chas-tab-button"} onClick={()=>{tabClick(1)}}><p>DataTransfer 메세지</p></div>
              <div className={'custom-chas-tab-bar '+'tab'+tabIdx}></div>
            </div>



            <div className="cpo-custom-chas-edit-content-container">
              <div className='cpo-custom-chas-edit-left-content'>
                <div className='cpo-custom-chas-edit-content-header'>메세지</div>
                <div className="cpo-custom-chas-edit-content-card-list">
                {
                  messageLoadingOn ? <LoadingList/>
                  :
                  <>
                    {
                      tabIdx === 0 &&
                      messageTypeList.map((it, idx)=>{
                        return(
                          <div className={`cpo-custom-chas-edit-message-card-container ${selectedMessage === it.name ? "selected":""}`} onClick={()=>{setSelectedMessage(it.name);}}>
                            <div className={`cpo-custom-chas-edit-message-card-status ${it.isMandatory === true && actionListByMessage?.filter(item=> item.name === it.name)[0]?.actionList?.length === 0 ? "invalid":"valid"}`}>
                              <div>
                                {
                                  (it.isMandatory === true && actionListByMessage?.filter(item=> item.name === it.name)[0].actionList.length === 0) ?
                                  <FaTimes/>
                                  :
                                  <FaCheck/>
                                }
                              </div>
                            </div>
                            <div className='cpo-custom-chas-edit-message-card-content'>
                              <div className={`cpo-custom-chas-edit-message-card-badge ${it.isMandatory === true ? "mandatory":""}`}>
                                {it.isMandatory === true ? "필수":"옵션"}
                              </div>
                              <div className='cpo-custom-chas-edit-message-card-title'>{it.name}</div>
                              <div className='cpo-custom-chas-edit-message-card-description'>{it.desc}</div>
                            </div>
                            
                          </div>
                        )
                      })
                    }
                    {
                      tabIdx === 1 &&
                      dataTransferMsgNameList.map((it, idx)=>{
                        return(
                          <div className={`cpo-custom-chas-edit-message-card-container ${selectedDataTransferMsg === it ? "selected":""}`} onClick={()=>{setSelectedDataTransferMsg(it);}}>
                            <div className={`cpo-custom-chas-edit-message-card-status ${dataTransferMsgList?.filter(item=> item.dataTransferMessageId === it)[0]?.chControlHubAction ? "valid":"invalid"}`}>
                              <div>
                                {
                                  dataTransferMsgList?.filter(item=> item.dataTransferMessageId === it)[0]?.chControlHubAction ?
                                  <FaCheck/>
                                  :
                                  <FaTimes/>
                                }
                              </div>
                            </div>
                            <div className='cpo-custom-chas-edit-message-card-content'>
                              <div className='cpo-custom-chas-edit-message-card-title'>{it}</div>
                              <div className='cpo-custom-chas-edit-message-card-description'>{dataTransferMsgList?.filter(item=> item.dataTransferMessageId === it)[0]?.description}</div>
                            </div>
                            
                          </div>
                        )
                      })
                    }
                  </>
                }

                </div>
              </div>
              <div className='cpo-custom-chas-edit-right-content'>
                <div className='cpo-custom-chas-edit-content-header'>액션</div>
                {messageLoadingOn ? <LoadingList/>
                :
                <>
                  {
                  tabIdx === 1 && 
                    <div className='cpo-custom-chas-edit-content-payload-container'>
                      <div className="cpo-custom-chas-edit-content-payload">
                        PAYLOAD
                      </div>
                    </div>
                  }
                  <div className='cpo-custom-chas-edit-content-action-function'>
                    <div className='cpo-custom-chas-edit-content-action-index-container'>
                      <div>순서</div>
                      <div>액션</div>
                    </div>
                  </div>
                  <div className="cpo-custom-chas-edit-content-card-list">
                  {
                  tabIdx === 0 &&actionListByMessage && selectedMessage && actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList?.map((it, idx)=>{
                    return(
                      <div className='cpo-custom-chas-edit-action-card-container'>
                        <div className='cpo-custom-chas-edit-action-order'>
                          <div>
                            {it?.order + 1}
                          </div>
                          {actionListByMessage?.filter(item=> item.name === selectedMessage)[0].actionList.length !== it.order+1 &&<div className='cpo-custom-chas-edit-action-flow-bar'></div>}
                        </div>
                        <div className='cpo-custom-chas-edit-action-card'>
                          <div className='cpo-custom-chas-edit-action-card-title'>{it?.chControlHubAction?.name}</div>
                          <div className='cpo-custom-chas-edit-action-card-description'>{it?.chControlHubAction?.description}</div>
                        </div>
                      </div>
                    )
                  })
                  }
                  {
                    tabIdx === 1 && dataTransferMsgList && selectedDataTransferMsg && dataTransferMsgList?.filter(item=> item.dataTransferMessageId === selectedDataTransferMsg)?.map((it, idx)=>{
                      return(
                        <div className='cpo-custom-chas-edit-action-card-container'>
                          <div className='cpo-custom-chas-edit-action-order'>
                            <div>
                              1
                            </div>
                          </div>
                          
                          <div className='cpo-custom-chas-edit-action-card'>
                            <div className='cpo-custom-chas-edit-action-card-title'>{it?.chControlHubAction?.name}</div>
                            <div className='cpo-custom-chas-edit-action-card-description'>{it?.chControlHubAction?.description}</div>
                          </div>
                        </div>
                      )
                    })
                  
                  }
                  </div>
                </>
                }
              </div>
            </div>
          </div>





        </div>
      </div>
    </div>
  )
}
export default ChasPreviewModal;