import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';

import {FaSearch} from 'react-icons/fa';
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

import {getCompanyList} from '../../api/infra/companyApi.js';
import {LoadingList} from '../../components/alerts/LoadingList.js';
import CpoCompanyListItem from '../../components/list/CpoCompanyListItem.js';
export const CpoCompanyManagement = () => {
  let dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(setNum({id:12, idx: 3}));
  },[])


  const [searchKeyword, setSearchKeyword] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [loadingListOn, setLoadingListOn] = useState(false);

  const [companyList, setCompanyList] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);

  const reqGetCompanyList = async(page=pageNum, data)=>{
    setLoadingListOn(true);
    let reqObj = {...data, page: page, size: pageSize};
    let result = await getCompanyList(reqObj);
    if(result.status === 200){
      let temp = [...result.data.Results];
      setCompanyList(temp);
      setCompanyCount(result.data.Total);
    }
    setLoadingListOn(false);
    
  }




  const [checked, setChecked] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState([]);
  
  // checked state (모든 아이템 check)
  useEffect(()=>{
    if(checked){
      let temp = new Array(companyList.length).fill(0).map((_, i) => i)
      setCheckedItemList(temp);
    }
    else{
      setCheckedItemList([]);
    }
  },[checked])


  // useEffect(()=>{
  //   reqGetCompanyList();
  // },[])



  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(companyCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetCompanyList(pageNum,{"keyword": searchKeyword});
    }
  },[pageNum])

  const handleKeyPress = async(e) => {
    if (e.key === 'Enter') {
      reqSearchKeyword();
    }
  };
  const reqSearchKeyword = async()=>{
    setLoadingListOn(true);
      if(pageNum !== 1) setPageNum(1);
      else await reqGetCompanyList();
      setLoadingListOn(false);
  }

  return (
    <div className="cpo-company-management-container">
      <div className="cpo-company-management-inner">
        <div className="cpo-charger-model-management-content-header">
          <div className='cpo-charger-model-management-content-search-container'>

            {/* <div className="cpo-charger-model-management-content-search-filter-button">검색필터<PiSlidersHorizontal/></div> */}
            <div className="cpo-company-management-search-input-container">
              <input type="text" placeholder={'업체명으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}} onKeyDown={handleKeyPress}/>
              <FaSearch onClick={()=>{reqSearchKeyword()}}/>
            </div>
          </div>
          <div className='cpo-company-management-content-function-button-container'>
            {!deleteMode&&<div className='cpo-company-management-content-function-button enroll' onClick={()=>{setModalOpen(true);}}>업체 등록</div>}
            {deleteMode&&<div className='cpo-company-function-info-msg delete'>삭제할 충전기 모델을 모두 체크한 후 수정 버튼을 눌러주세요.</div>}
            {<div className={`cpo-company-management-content-function-button delete ${deleteMode&&checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{deleteMode ? setDeleteModalOpen(true) : setDeleteMode(true)}}>삭제</div>}
            {(deleteMode) && <div className='cpo-company-management-content-function-button cancel' onClick={()=>{setCheckedItemList([]); editMode ? setEditMode(false) : setDeleteMode(false)}}>취소</div>}
          </div>
        </div>
        <div className={'cpo-company-management-item-list-grid-container '}>
            
            <div className="cpo-company-management-content-item-list-container">
              {
                !(deleteMode) ?
                <div className="cpo-company-management-content-item-column tab0">
                  <div>업체명</div>
                  <div></div>
                </div>
                :
                <div className="cpo-company-management-content-item-column tab1">
                  <div>
                    {
                      deleteMode ?
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      :
                      <div></div>
                    }
                  </div>
                  <div>업체명</div>
                  <div></div>
                </div>
              }
              
              {
                loadingListOn ?
                <LoadingList width={"500px"} height={"500px"}/>
                :
                <>
                {
                  companyList && companyList.map((it, idx)=>{
                    return(
                      <>
                        <CpoCompanyListItem data={it} checkMode={deleteMode} idx={idx} checkList={checkedItemList} setCheckList={setCheckedItemList}/>
                      </>
                    );
                  })
                }
                </>
              }
              <div className='page-selector-container'>
                {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
                {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
                {pageSelectorRendering()}
                {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
                {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
                <div className='page-total-info'>{`Total : ${companyCount}`}</div>
              </div>
            </div>
            
          </div>
        </div>
    </div>
  )
}
export default CpoCompanyManagement;