import axios from 'axios';
import { getCookie, refresh } from '../../util/util';

// reset OCPP 1.6 메세지 전송 1
export const postOcpp16reset = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, type} = data;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/reset?chargingStationIdentity=${chargingStationIdentity}${type?`&type=${type}`:""}`,data,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// getConfiguration OCPP 1.6 메세지 전송 2
export const postOcpp16getConfiguration = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, key} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (key) query += `&key=${key}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/getConfiguration?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// changeConfiguration OCPP 1.6 메세지 전송 3
export const postOcpp16changeConfiguration = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, key, value} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (key) query += `&key=${key}`;
  if (value) query += `&value=${value}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/changeConfiguration?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// updatePassword OCPP 1.6 메세지 전송 4
export const postOcpp16updatePassword = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity} = data;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/updatePassword?chargingStationIdentity=${chargingStationIdentity}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// clearCache OCPP 1.6 메세지 전송 5
export const postOcpp16clearCache = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity} = data;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/clearCache?chargingStationIdentity=${chargingStationIdentity}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// getLocalListVersion OCPP 1.6 메세지 전송 6
export const postOcpp16getLocalListVersion = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity} = data;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/getLocalListVersion?chargingStationIdentity=${chargingStationIdentity}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// sendLocalList OCPP 1.6 메세지 전송 7
export const postOcpp16sendLocalList = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, updateType, versionNumber, idTags} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (updateType) query += `&updateType=${updateType}`;
  if (versionNumber) query += `&versionNumber=${versionNumber}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/sendLocalList?${query}`,{
    idTags: idTags
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// requestStartTransaction OCPP 1.6 메세지 전송 8
export const postOcpp16requestStartTransaction = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, body} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/requestStartTransaction?${query}`,body,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// requestStopTransaction OCPP 1.6 메세지 전송 9
export const postOcpp16requestStopTransaction = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, transactionId} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (transactionId) query += `&transactionId=${transactionId}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/requestStopTransaction?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// unlockConnector OCPP 1.6 메세지 전송 10
export const postOcpp16unlockConnector = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, connectorId} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (connectorId) query += `&connectorId=${connectorId}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/unlockConnector?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// triggerMessage OCPP 1.6 메세지 전송 11
export const postOcpp16triggerMessage = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, requestMessage, connectorId} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (requestMessage) query += `&requestMessage=${requestMessage}`;
  if (connectorId) query += `&connectorId=${connectorId}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/triggerMessage?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// extendedTriggerMessage OCPP 1.6 메세지 전송 12
export const postOcpp16extendedTriggerMessage = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, requestMessage, connectorId} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (requestMessage) query += `&requestMessage=${requestMessage}`;
  if (connectorId) query += `&connectorId=${connectorId}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/extendedTriggerMessage?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// changeAvailability OCPP 1.6 메세지 전송 13
export const postOcpp16changeAvailability = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, connectorId, operationalStatus} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (connectorId) query += `&connectorId=${connectorId}`;
  if (operationalStatus) query += `&operationalStatus=${operationalStatus}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/changeAvailability?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// setChargingProfile OCPP 1.6 메세지 전송 14
export const postOcpp16setChargingProfile = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, 
    // connectorId, chargingProfile
    body
  } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  // if (connectorId) query += `&connectorId=${connectorId}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/setChargingProfile?${query}`,body,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// setChargingProfiles OCPP 1.6 메세지 전송 15
export const postOcpp16setChargingProfiles = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity,
      // chargingProfiles
      body
    } = data;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/setChargingProfiles?chargingStationIdentity=${chargingStationIdentity}`,body,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// clearChargingProfile OCPP 1.6 메세지 전송 16
export const postOcpp16clearChargingProfile = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, chargingProfileId, connectorId, chargingProfilePurpose, stackLevel} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (chargingProfileId) query += `&chargingProfileId=${chargingProfileId}`;
  if (connectorId) query += `&connectorId=${connectorId}`;
  if (chargingProfilePurpose) query += `&chargingProfilePurpose=${chargingProfilePurpose}`;
  if (stackLevel) query += `&stackLevel=${stackLevel}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/clearChargingProfile?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// getCompositeSchedule OCPP 1.6 메세지 전송 17
export const postOcpp16getCompositeSchedule = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, connectorId, chargingRateUnit, duration} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (connectorId) query += `&connectorId=${connectorId}`;
  if (chargingRateUnit) query += `&chargingRateUnit=${chargingRateUnit}`;
  if (duration) query += `&duration=${duration}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/getCompositeSchedule?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// Reserve Now OCPP 1.6 메세지 전송 18
export const postOcpp16reserveNow = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, expiryDate, idTag, connectorId, parentIdTag} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (expiryDate) query += `&expiryDate=${expiryDate}`;
  if (idTag) query += `&idTag=${idTag}`;
  if (connectorId) query += `&connectorId=${connectorId}`;
  if (parentIdTag) query += `&parentIdTag=${parentIdTag}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/reserveNow?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// cancelReservation OCPP 1.6 메세지 전송 19
export const postOcpp16cancelReservation = async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, reservationId} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (reservationId) query += `&reservationId=${reservationId}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/cancelReservation?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// updateFirmware OCPP 1.6 메세지 전송 20
export const postOcpp16updateFirmware= async(data)=>{
  let signedToken =  getCookie('signedToken');
  if(!signedToken){ await refresh(); signedToken =  getCookie('signedToken');}
  let temp;
  let {chargingStationIdentity, location} = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (location) query += `&location=${location}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/updateFirmware?${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res;
  })
  .catch((err)=>{
    temp = err.response;
  })
  return temp;
}

// signedUpdateFirmware OCPP 1.6 메세지 전송 21
export const postOcpp16signedUpdateFirmware = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { chargingStationIdentity, location, retrieveDateTime, signingCertificate, signature } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/signedUpdateFirmware?${query}`,{
    firmware : {
      location: location?location:null,
      retrieveDateTime: retrieveDateTime?retrieveDateTime:null,
      signingCertificate: signingCertificate?signingCertificate:null,
      signature: signature?signature:null,
    }
  }, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res) => {
    temp = res;
  })
  .catch((err) => {
    temp = err.response;
  });
  return temp;
}

// getInstalledCertificateIds OCPP 1.6 메세지 전송 22
export const postOcpp16getInstalledCertificateIds = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { chargingStationIdentity, certificateType } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (certificateType) query += `&certificateType=${certificateType}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/getInstalledCertificateIds?${query}`, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res) => {
    temp = res;
  })
  .catch((err) => {
    temp = err.response;
  });
  return temp;
}

// installCertificate OCPP 1.6 메세지 전송 23
export const postOcpp16installCertificate = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { chargingStationIdentity,
    // certificateType, certificate
    body
  } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  // if (certificateType) query += `&certificateType=${certificateType}`;
  // if (certificate) query += `&certificate=${certificate}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/installCertificate?${query}`,body, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res) => {
    temp = res;
  })
  .catch((err) => {
    temp = err.response;
  });
  return temp;
}

// deleteCertificate OCPP 1.6 메세지 전송 24
export const postOcpp16deleteCertificate = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { chargingStationIdentity, hashAlgorithm, certificate } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/deleteCertificate?${query}`, {
    hashAlgorithm: hashAlgorithm?hashAlgorithm:null,
    certificate: certificate?certificate:null
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res) => {
    temp = res;
  })
  .catch((err) => {
    temp = err.response;
  });
  return temp;
}

// deleteCertificateByManual OCPP 1.6 메세지 전송
export const postOcpp16deleteCertificateByManual = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { chargingStationIdentity, hashAlgorithm, issuerNameHash, issuerKeyHash, serialNumber } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/deleteCertificateByManual?${query}`, {
    "hashAlgorithm":hashAlgorithm?hashAlgorithm:null,
    "issuerNameHash":issuerNameHash?issuerNameHash:null,
    "issuerKeyHash":issuerKeyHash?issuerKeyHash:null,
    "serialNumber":serialNumber?serialNumber:null
  },{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res) => {
    temp = res;
  })
  .catch((err) => {
    temp = err.response;
  });
  return temp;
}

// getDiagnostics OCPP 1.6 메세지 전송 25
export const postOcpp16getDiagnostics = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { chargingStationIdentity, location, startTime, stopTime, retries, retryInterval } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (location) query += `&location=${location}`;
  if (startTime) query += `&startTime=${startTime}`;
  if (stopTime) query += `&stopTime=${stopTime}`;
  if (retries) query += `&retries=${retries}`;
  if (retryInterval) query += `&retryInterval=${retryInterval}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/getDiagnostics?${query}`, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res) => {
    temp = res;
  })
  .catch((err) => {
    temp = err.response;
  });
  return temp;
}

// getLog OCPP 1.6 메세지 전송 26
export const postOcpp16getLog = async(data) => {
  let signedToken = getCookie('signedToken');
  if (!signedToken) { await refresh(); signedToken = getCookie('signedToken'); }
  let temp;
  let { chargingStationIdentity, logType, remoteLocation, oldestTimestamp, latestTimestamp, retries, retryInterval } = data;
  let query = `chargingStationIdentity=${chargingStationIdentity}`;
  if (logType) query += `&logType=${logType}`;
  if (remoteLocation) query += `&remoteLocation=${remoteLocation}`;
  if (oldestTimestamp) query += `&oldestTimestamp=${oldestTimestamp}`;
  if (latestTimestamp) query += `&latestTimestamp=${latestTimestamp}`;
  if (retries) query += `&retries=${retries}`;
  if (retryInterval) query += `&retryInterval=${retryInterval}`;
  await axios.post(`/controlhub/api/v1/octt/ocpp16/getLog?${query}`, {
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res) => {
    temp = res;
  })
  .catch((err) => {
    temp = err.response;
  });
  return temp;
}