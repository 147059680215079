import React,{useEffect, useState} from 'react'
import { AsyncPaginate } from "react-select-async-paginate";

import { getCompanyListForSelect, getInfraConstructorList } from '../../api/infra/companyApi';

export const AsyncSearchSelectorCompany = (props) => {
  const [value, setValue] = useState(null);
  const [removeTarget, setRemoveTarget] = useState(null);

  useEffect(()=>{
    setValue(props.value);
  },[props.value]);

  const onMenuOpen = () => {
    setTimeout(()=>{
      const selectedEl = document.getElementsByClassName("react-select__option--is-selected")[0];
      if(selectedEl){
        selectedEl.scrollIntoView({behavior:'instant', block:'nearest', inline: 'start'});
      }
    },15);
  };
  
  const reqGetInfraConstructorList = async () => {
    const response = await getInfraConstructorList({specialization:"all"});
    setRemoveTarget(response.data);
    return response.data;
  }
  
  const loadOptions = async (search, prevOptions, { page }) => {
    const response = await getCompanyListForSelect(search, page);
    const responseJSON = await response.json();
    let temp = [];
    let idx = 0;
    let tRemoveTargetList = null;
    if(removeTarget === null){
      tRemoveTargetList = await reqGetInfraConstructorList();
    }
    else{
      tRemoveTargetList = removeTarget;
    }
    for await(const it of responseJSON.Results){
      if(tRemoveTargetList.find((el)=>el.CompanySysId === it.Id)){
        continue;
      }
      temp.push({id:it.Id,  label:it.Name, value:it.Name, idx:(page-1)*10+idx});
    }
    return {
      options: temp,
      hasMore: responseJSON.Total > page * 10,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(()=>{
    reqGetInfraConstructorList();
  },[])

  return (
    <>
    {
      <AsyncPaginate options={props.options}
      {...props}
      className="react-select-container"
      classNamePrefix="react-select"
      value={value}
      onChange={(newValue) => {props.setValue(newValue); setValue(newValue);}}
      loadOptions={loadOptions}
      additional={{
        page: 1,
      }}
      onMenuOpen={onMenuOpen}
      />
    }
    </>
  )
}
export default AsyncSearchSelectorCompany;