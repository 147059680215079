import React, { useState, useEffect } from 'react';
import { chargerTypeConverter, connectorTypeConverter2 } from '../../util/util';
import { FaChevronDown } from "react-icons/fa6";


export const CpoChargerModelListItem = (props) => {
  
  const [tabOpen, setTabOpen] = useState(false);
  const [checked, setChecked] = useState(null);

  useEffect(()=>{
    if(props.checkMode){
      setTabOpen(false);
    }
    else{
      setTabOpen(false);

      setChecked(false);
    }
  },[props.checkMode])



    
    useEffect(()=>{
      // 리스트에서 인덱스 찾기
      let itemIdx = props.checkList.indexOf(props.idx);
      // check true인 경우
      if(checked){
        if(itemIdx !== -1){
          return;
        }
        if(props.settingMode === "edit"){
          props.setCheckList([props.idx]);
        }
        else{
          props.setCheckList([...props.checkList, props.idx]);
        }
      }
      else{
        // false 인 경우
        
        // 만약 없는 경우
        if(itemIdx === -1){
          return;
        }
        // 있는 경우
        else{
          let temp = [...props.checkList];
          temp.splice(itemIdx,1);
          props.setCheckList(temp);
        }
      }
    },[checked])
  
    useEffect(()=>{
      let itemIdx = props.checkList.indexOf(props.idx);
      if(itemIdx !== -1 && !checked){
        setChecked(true);
      }
      else if(itemIdx === -1 && checked){
        setChecked(false);
      }
    },[props.checkList])





  return (
    <div className={'cpo-charger-model-management-content-item-container '+`${tabOpen ? "open":""}`}>
      <div className={'cpo-charger-model-management-content-item '+`${props.checkMode?"checkMode ":""}` +`${checked?"checked":""}`} onClick={()=>{setTabOpen(!tabOpen)}}>
        {
          !props.checkMode ?
          <>
            <div><p>{props.data.Manufacturer.Company.Name}</p></div>
            <div><p>{props.data.Name}</p></div>
            <div><p>{chargerTypeConverter(props.data.ChargerType)}</p></div>
            <div><p>{props.data.ChargingSpeed}</p></div>
            <div><p>{props.data.ChargingPower}</p></div>
            <div><p>{props.data.Voltage}</p></div>
            <div><p>{props.data.ElectricCurrent}</p></div>
            <div>{props.data?.Connectors?.length > 1 ? "지원":"미지원"}</div>
            <div>{props.data?.IsUsed ? "중고":"해당없음"}</div>
            <div><p>{new Date(props.data?.ActualRegistrationDate).getUTCFullYear() +"/"+ `${parseInt(new Date(props.data?.ActualRegistrationDate).getMonth())+1}`}</p></div>

            <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
          </>
          :
          <>
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
          <div><p>{props.data?.Manufacturer?.Company?.Name}</p></div>
          <div><p>{props.data?.Name}</p></div>
          <div><p>{chargerTypeConverter(props.data?.ChargerType)}</p></div>
          <div><p>{props.data?.ChargingSpeed}</p></div>
          <div><p>{props.data?.ChargingPower}</p></div>
          <div><p>{props.data?.Voltage}</p></div>
          <div><p>{props.data?.ElectricCurrent}</p></div>
          <div>{props.data?.Connectors?.length > 1 ? "지원":"미지원"}</div>
          <div>{props.data?.IsUsed ? "중고":"해당없음"}</div>
          <div><p>{new Date(props.data?.ActualRegistrationDate).getUTCFullYear() +"/"+ `${parseInt(new Date(props.data?.ActualRegistrationDate).getMonth())+1}`}</p></div>
          <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
        </>
        }
        
      </div>
      <div className='cpo-charger-model-management-content-item-content'>
        <div><p>커넥터 정보</p></div>
        <div className="cpo-charger-model-management-connector-list-container">
          {
          props.data?.Connectors&&props.data?.Connectors.length!==0 ?
          // <div className="cpo-charger-model-management-connector-list-item-column">
          //   <div></div>
          //   <div>커넥터 타입</div>  
          //   <div></div>
          // </div>
          null
          :
          <div className="cpo-charger-model-management-connector-list-item-column">
            <div></div>
            <div>커넥터 정보 없음</div>
            <div></div>
          </div>
          }
          <div className="cpo-charger-model-management-connector-list-item-container">
            {
              props.data?.Connectors&&props.data?.Connectors.length!==0&&props.data?.Connectors.map((item,itemIdx)=>{
                return(
                  <div className="cpo-charger-model-management-connector-list-item">
                    <div>{itemIdx+1}</div>
                    <div>
                    {
                      item?.ConnectorTypes.map((it, idx)=>{
                        if(!it)return;
                        return(
                        <>
                          <div className="cpo-charger-model-connector-badge">
                            <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
                          </div>
                        </>
                      )
                      })
                    }
                    </div>
                  </div>
                )
              })
            }
          </div>          
        </div>

        <div><p>설명</p>{props.data.Remarks}</div>
        <div><p>상세사양</p>{props.data.DetailedSpec}</div>
      </div>
    </div>
  )
}
export default CpoChargerModelListItem;