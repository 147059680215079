import React, { useEffect, useState } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";



import { deleteFirmwareVersion } from '../../api/controlhub/controlhubApi.js';

import { Loading } from '../alerts/Loading.js';

export const FirmwareVersionDeleteModal = (props) => {

  const [loadingOn, setLoadingOn] = useState(false);

  const reqDeleteFirmwareVersion = async()=>{
    setLoadingOn(true);
    await deleteFirmwareVersion(props.firmwareList[props.selectedFirmwareIdx]?.firmwareId, props.firmwareList[props.selectedFirmwareIdx]?.versions[props.selectedVersionIdx]?.firmwareVersionId);
    await props.reqGetFirmwareListCpoUser();
    setLoadingOn(false);
    props.setOnOff(false);
  }



  return (
    <div className="cpo-charger-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charger-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-delete-modal-header">
          <title>펌웨어 버전 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-charger-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='cpo-charger-delete-modal-msg'>펌웨어 버전을 삭제하시겠습니까?</p>
          <div className='cpo-charger-delete-modal-list-wrapper'>
            <div className='cpo-charger-delete-modal-item'>{`${props.firmwareList[props.selectedFirmwareIdx].name} ${props.firmwareList[props.selectedFirmwareIdx]?.versions[props.selectedVersionIdx]?.version}`}</div>
          </div>
        </div>
        <div>
          <div className={'cpo-charger-modal-delete-button '} onClick={()=>{reqDeleteFirmwareVersion()}}>
            {loadingOn ? <Loading/> : <p>삭제</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default FirmwareVersionDeleteModal;

