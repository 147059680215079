import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';

export const CpoManufacturerManagement = () => {
  let dispatch = useDispatch();
  
    useEffect(()=>{
      dispatch(setNum({id:20}));
    },[])

  return (
    <div>CpoManufacturerManagement</div>
  )
}
export default CpoManufacturerManagement;