import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AlternativeProtectedRoute = ({ allowedRoles, alternativeRoute }) => {
  const user = useSelector((state) => state.user);
  

  if (!user.roles.some(item => allowedRoles.includes(item))) {
    return <Navigate to={alternativeRoute} replace />; // 접근 불가 시 대안 페이지 이동
  }
  if (user.cpoId !== process.env.REACT_APP_SUPER_ID){
    return <Navigate to={'/delete-sub-account'} replace />; // 접근 불가 시 대안 페이지 이동
  }
  return <Outlet />; // 허용된 경우 자식 라우트 렌더링
};

export default AlternativeProtectedRoute;