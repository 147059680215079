import React, { useState, useEffect } from 'react';
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';

import { putActionOfDataTransferMessage } from '../../api/controlhub/controlhubApi.js';

export const DataTransferFunctionModal = (props) => {

  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const [chasName, setChasName] = useState();
  const [chasDesc, setChasDesc] = useState();
  const [chasValid, setChasValid] = useState(null);

  const dupCheck = async(chasName)=>{
    if(props.dataTransferMsgNameList.filter(it=>it === chasName).length === 0){
      setChasValid(true);
    }
    else setChasValid(false);
  }


  const reqAddMessage = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "controlHubActionInfoIds": [selectedActionList[0]?.controlHubActionId],
      "dataTransferMessageId": chasName,
    }
    let result = await putActionOfDataTransferMessage(props.actionSetId, "DataTransfer", reqObj);
    if(result.status === 200 || result.status === 201){
      await props.reqGetChas();
      props.setOnOff(false);
    }
    else{
      setErrMsg("추가에 실패하였습니다.");
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }

  const [tabIdx, setTabIdx] = useState(0);
  const [selectedActionList, setSelectedActionList] = useState([]);


  const clickCard = (id)=>{
    let target = props.actions.filter(it=>it.controlHubActionId === id)[0];
    setSelectedActionList([target]);
    // if(selectedActionList.filter(it=> it.controlHubActionId===target.controlHubActionId).length === 0){
    //   setSelectedActionList([target]);
    // }
    // else{
    //   setSelectedActionList([])
    // }
  }
  return (
    <div className="datatransfer-function-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="datatransfer-function-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>{props.mode==="append" ? "DataTransfer 메세지 추가":"DataTransfer 메세지 편집"}</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='datatransfer-function-modal-content'>
          {
            props.mode==="append" &&
            <>
              {tabIdx === 0 ?
              <>
                <div className='cpo-custom-chas-append-modal-content-container'>
                  <p>데이터 명</p>
                  <div className='cpo-custom-chas-append-modal-grid-wrapper'>
                    <div className='cpo-custom-chas-append-modal-input-container'>
                      <input type="text" placeholder='데이터 명을 입력해주세요' value={chasName} onChange={(e)=>{setChasName(e.target.value);setChasValid(null)}}/>
                    </div>
                    <div className='cpo-custom-chas-append-check-button ' onClick={()=>{dupCheck(chasName)}}>
                      {loadingOn ? 
                      <Loading/>
                      :"중복확인"}
                    </div>
                    {(chasName =="" || chasValid === null) &&<p className='cpo-custom-chas-append-check-info'>중복확인을 눌러 주세요.</p>}
                    {chasValid === false &&<p className='cpo-custom-chas-append-check-info'>중복된 이름입니다.</p>}
                    {chasValid === true && <p className='cpo-custom-chas-append-check-info valid'>사용가능한 이름입니다.</p>}
                  </div>
                </div>
                <div className='cpo-custom-chas-append-modal-content-container'>
                  <p>설명</p>
                  <div className='cpo-custom-chas-append-modal-input-container textarea'>
                    <textarea type="text" placeholder='설명을 입력해주세요' value={chasDesc} onChange={(e)=>{setChasDesc(e.target.value);}} />
                  </div>
                </div>
                {errMsg && <p className='datatransfer-function-modal-error-msg'>{errMsg}</p>}
                <div className={`datatransfer-function-modal-button ${chasValid && !(chasDesc==undefined || chasDesc ==null || chasDesc == "") ? "":"disabled"}`} onClick={()=>{
                  setTabIdx(1);
                  }}>
                  
                  {
                  loadingOn ? 
                    <Loading/>
                  :
                  <>
                    <p>다음</p>
                  </>
                  }
                </div>
              </>
              :
              <>
                <div className='cpo-custom-chas-action-modal-content'>
                  <div className='cpo-custom-chas-action-modal-content-header'>
                    <div className='cpo-custom-chas-action-modal-info'>
                      <div>{chasName}</div>
                      <div>{chasDesc}</div>
                    </div>
                    <div className={`cpo-custom-chas-action-modal-save-button ${selectedActionList.length === 0 ? "disabled":""}`} onClick={()=>{reqAddMessage()}}>{loadingOn ? <Loading/> : "저장"}</div>
                    {errMsg && <p>{errMsg}</p>}
                  </div>
        
                  <div className="cpo-custom-chas-edit-content-container">
                  <div className='cpo-custom-chas-edit-left-content'>
                    <div className='cpo-custom-chas-edit-content-header'>액션 목록</div>
                    <div className='cpo-custom-chas-edit-content-card-list'>
                    {
                      props.actions && selectedActionList && props.actions.map((it, idx)=>{
                        return(
                          <div className={`cpo-custom-chas-action-modal-card ${selectedActionList?.filter(item=>item?.controlHubActionId === it.controlHubActionId).length!==0?"selected":""}`}
                          onClick={()=>{clickCard(it.controlHubActionId)}}>
                            <div className='cpo-custom-chas-action-modal-card-title'>{it?.name}</div>
                            <div className='cpo-custom-chas-action-modal-card-description'>{it?.description}</div>
                          </div>
                        )
                      })
                    }
                    </div>
                  </div>
                  <div className='cpo-custom-chas-edit-right-content'>
                    <div className='cpo-custom-chas-edit-content-header'>선택된 액션</div>
                    <div className='cpo-custom-chas-edit-content-action-function'>
                      <div className='cpo-custom-chas-edit-content-action-index-container'>
                        <div>순서</div>
                        <div>액션</div>
                      </div>
                    </div>
                    <div className="cpo-custom-chas-edit-content-card-list">
                      {
                        selectedActionList?.map((it, idx)=>{
                          return(
                            <div className='cpo-custom-chas-edit-action-card-container'>
                              <div className='cpo-custom-chas-edit-action-order'>
                                <div>
                                  {idx + 1}
                                </div>
                                {selectedActionList?.length !== idx+1 &&<div className='cpo-custom-chas-edit-action-flow-bar'></div>}
                              </div>
                              <div className='cpo-custom-chas-action-modal-card disable'>
                                {/* <TfiClose onClick={()=>{deleteCard(idx)}}/> */}
                                <div className='cpo-custom-chas-action-modal-card-title'>{it?.name}</div>
                                <div className='cpo-custom-chas-action-modal-card-description'>{it?.description}</div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                </div>
              </>
              }
            </>
          }

        </div>

      </div>
    </div>
  )
}
export default DataTransferFunctionModal;