import React, { useState, useEffect } from 'react';
import { TfiClose } from "react-icons/tfi";
import { Loading } from '../alerts/Loading.js';
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


import { postChas, getChasByName } from '../../api/controlhub/controlhubApi.js';

import SearchSelectorNotCreatable from '../buttons/SearchSelectorNotCreatable.js';

export const CustomChasAppendModal = (props) => {
  let navigate = useNavigate();
  const [category, setCategory] = useState(0);
  const [tabIdx, setTabIdx] = useState(1);


  // 선택자 클릭 여부
  const [occpSelect, setOccpSelect] = useState(null);

  const [checked, setChecked] = useState(null);

  const [chasName, setChasName] = useState();
  const [chasDesc, setChasDesc] = useState();
  const [chasValid, setChasValid] = useState(null);

  const reqGetChasByName = async(chasName)=>{
    setLoadingOn(true);
    if(!chasName)return;
    let result = await getChasByName(chasName);
    setChasValid(result.data);
    setLoadingOn(false);
  }

  const reqPostChas = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "name": chasName,
      "description": chasDesc
    };
    let result = await postChas(reqObj);
    if(result.status === 200 || result.status === 201){
      navigate('./edit',{ state: { mode:category===0?"new":"dup", ocpp: "OCPP 1.6", actionSetId:result.data.actionSetId } })
    }
    else{
      setErrMsg("CHAS 추가에 실패하였습니다");
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
    setLoadingOn(false);

  }
  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const ocppList = [
    {label:"OCPP 1.6", value:"OCPP 1.6", idx:0},
  ]

  return (
    <div className="cpo-custom-chas-append-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
      <div className="cpo-custom-chas-append-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-station-enroll-modal-header">
          <title>사용자 정의 CHAS 추가</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-custom-append-content-container'>
          {/* {
            tabIdx === 0 &&
            <div className='cpo-custom-append-content tab-0'>
              <div className={'cpo-fast-enroll-category-button '+`${category == 0 ? "selected":""}`} onClick={()=>{setCategory(0)}}>
                <div className='cpo-fast-enroll-category-select-outer'>
                  <div className='cpo-fast-enroll-category-select-inner'></div>
                </div>
                <div className='cpo-fast-enroll-category-content-container'>
                  <p>새로 만들기</p>
                  <p>비어있는 상태에서 편집을 시작해요.</p>
                </div>
              </div>
              <div className={'cpo-fast-enroll-category-button '+`${category == 1 ? "selected":""}`} onClick={()=>{setCategory(1)}}>
                <div className='cpo-fast-enroll-category-select-outer'>
                  <div className='cpo-fast-enroll-category-select-inner'></div>
                </div>
                <div className='cpo-fast-enroll-category-content-container'>
                  <p>액션셋 불러오기</p>
                  <p>Profile을 불러온 후 편집을 시작해요.</p>
                </div>
              </div>
            </div>
          } */}
          {
            tabIdx === 1 && category === 0 &&
            <div className='cpo-custom-append-content tab-1'>
              <p>OCPP</p>
              <div className="fast-enroll-input-container occp">
                <SearchSelectorNotCreatable placeholder="OCPP 버전을 선택하세요" value={occpSelect} setValue={setOccpSelect} options={ocppList}/>
              </div>
            </div>
          }
          {/* {
            tabIdx === 1 && category === 1 &&
            <>
              <div className='cpo-custom-append-content tab-1 list-container'>
                <div className="cpo-system-chas-content-item-list-container">
                  <div className="cpo-system-chas-content-item-column modal">
                    <div></div>
                    <div>CHAS명</div>
                    <div>버전</div>
                    <div>OCPP</div>
                  </div>

                  {
                    systemChasList&&systemChasList.map((it, idx)=>{
                      return(
                        <>
                          <div className={`cpo-system-chas-content-item modal ${checked===idx ? "selected":""}`} onClick={()=>{setChecked(idx)}}>
                            <div>
                              <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                                <input type="checkbox" checked={checked===idx} onChange={()=>{setChecked(idx)}} />
                                <span className="checkbox_icon"></span>
                              </label>
                            </div>
                            <div><p>{it.name}</p></div>
                            <div><p>{it.version}</p></div>
                            <div><p>{it.ocpp}</p></div>
                          </div>
                        </>
                      );
                    })
                  }
                  
                </div>
              </div>
            </>
          } */}
          {
            tabIdx === 2 &&
            <>
              <div className='cpo-custom-chas-append-modal-content-container'>
                <p>CHAS 이름</p>
                <div className='cpo-custom-chas-append-modal-grid-wrapper'>
                  <div className='cpo-custom-chas-append-modal-input-container'>
                    <input type="text" placeholder='CHAS의 이름을 입력해주세요' value={chasName} onChange={(e)=>{setChasName(e.target.value);setChasValid(null)}}/>
                  </div>
                  <div className='cpo-custom-chas-append-check-button ' onClick={()=>{reqGetChasByName(chasName)}}>
                    {loadingOn ? 
                    <Loading/>
                    :"중복확인"}
                  </div>
                  {(chasName =="" || chasValid === null) &&<p className='cpo-custom-chas-append-check-info'>중복확인을 눌러 주세요.</p>}
                  {chasValid === false &&<p className='cpo-custom-chas-append-check-info'>중복된 이름입니다.</p>}
                  {chasValid === true && <p className='cpo-custom-chas-append-check-info valid'>사용가능한 이름입니다.</p>}
                </div>
              </div>
              <div className='cpo-custom-chas-append-modal-content-container'>
                <p>설명</p>
                <div className='cpo-custom-chas-append-modal-input-container textarea'>
                  <textarea type="text" placeholder='설명을 입력해주세요' value={chasDesc} onChange={(e)=>{setChasDesc(e.target.value);}} />
                </div>
              </div>
            </>
          }
          {
            tabIdx ===0 ?
            <div className={'cpo-custom-append-modal-button '+`${category==null?"none":""}`} onClick={()=>{
              setTabIdx(1);
              }}>
              <p>다음</p>
              <FaChevronRight/>
            </div>
            :
            tabIdx ===1 ?
              category === 0 ?
              <div className={'cpo-custom-append-modal-button '+`${occpSelect===null?"none":""}`} onClick={()=>{
                setTabIdx(2);
                }}>
                <p>다음</p>
                <FaChevronRight/>
              </div>
              :
              <></>
            :
            <div className={`cpo-custom-append-modal-button ${chasValid && !(chasDesc==undefined || chasDesc ==null || chasDesc == "") ? "":"disabled"}`} onClick={()=>{
              // setTabIdx(1);
              reqPostChas();
              
              }}>
              
              {
              loadingOn ? 
                <Loading/>
              :
              <>
                <p>추가하기</p>
                <FaChevronRight/>
              </>
              }
            </div>
          }
          {
            tabIdx ===0 ?
            <></>
            :
            tabIdx === 1 ?
            // <div className='cpo-custom-append-modal-button left' onClick={()=>{
            //   setTabIdx(0);
            //   }}>
            //   <FaChevronLeft/>
            //   <p>이전</p>
            // </div>
            <></>
            :
            <div className='cpo-custom-append-modal-button left' onClick={()=>{
              setTabIdx(1);
              setChasName(null);
              setChasDesc(null);
              }}>
              <FaChevronLeft/>
              <p>이전</p>
            </div>
          }
          {
            errMsg&&<p className='cpo-custom-chas-msg'>{errMsg}</p>
          }
        </div>
      </div>
    </div>
  )
}
export default CustomChasAppendModal;