/**
 * @author edeward <camellia.g@evmonit.com>
 * @create 2024-12-02
 * @file 찾아줌 서비스 요청 페이지 
 * @copyright Copyright MoniT Inc. all rights reserved.
 */
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import {setNum} from '../../../store.js'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DataManager, Query, UrlAdaptor, WebApiAdaptor } from '@syncfusion/ej2-data';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Selection } from '@syncfusion/ej2-react-grids';
import { getZoomStatRequest, getZoomSimRequest } from '../api/zoomApi.jsx';
import { useNavigate } from 'react-router-dom';
import ZoomSimRequestModal from '../modals/ZoomSimRequestModal.jsx';
import ZoomStatRequestModal from '../modals/ZoomStatRequestModal.jsx';

const StationZoom = () => {

    /********************************************************/
    /* VAR SECTION                                          */
    /********************************************************/
    let page = useSelector((state) => state.page);
    let dispatch  = useDispatch();
    let userSystemId = localStorage.getItem('user-systemId');
    const navigator = useNavigate();

    /********************************************************/
    /* STATE SECTION                                        */
    /********************************************************/    
    const [visibleSimRequestDialog, setVisibleSimRequestDialog] = useState(false);
    const [refreshSimRequestList, setRefreshSimRequestList] = useState(false);
    const [visibleStatRequestDialog, setVisibleStatRequestDialog] = useState(false);
    const [refreshStatRequestList, setRefreshStatRequestList] = useState(false);
    const [statReqData, setStatReqData] = useState([]);
    const [simReqData, setSimReqData] = useState([]);
    const [visibleInfoDialog, setVisibleInfoDialog] = useState(false);

    /// 시뮬레이션 요청에 대한 CRUD를 관리하는 데이터매니저 
    // const url = `https://dev.emoov.co.kr/zoomsimulation/api/userRequests2/${userSystemId}?skip=0&take=1000`;
    // const dataManager = new DataManager({
    //     url: url,
    //     adaptor: new UrlAdaptor(),
    //     crossDomain: true
    // });

    /********************************************************/
    /* EFFECT SECTION                                       */
    /********************************************************/
    useEffect(()=> {
        dispatch(setNum({id:101}))
    },[dispatch]);

    useEffect(()=>{
        fetchZoomStatRequest();
    },[]);

    useEffect(()=>{
        fetchZoomSimRequest();
    },[]);
  
    /// 신청 대화상자가 없어지면 재갱신을 수행한다.
    useEffect(()=>{
        if(refreshStatRequestList === true) {
            fetchZoomStatRequest();
            setRefreshStatRequestList(false);
        }
    },[visibleStatRequestDialog]);

    /// 목록을 재갱신 요청한다.
    useEffect(()=>{
        if(refreshSimRequestList === true) {
            fetchZoomSimRequest();
            setRefreshSimRequestList(false);
        }
    },[visibleSimRequestDialog]);    

    // useEffect(()=>{
    //     if(visibleSimRequestDialog === false) {
    //         fetchZoomSimRequest();
    //     }

    // }, [visibleSimRequestDialog]);

    // const query = new Query().take(10).skip(0);

    // dataManager.executeQuery(query)
    // .then((response) => {
    //     console.log('데이터 로드 성공:', response);
    // })
    // .catch((error) => {
    //     console.error('데이터 로드 실패:', error);
    // });

    /********************************************************/
    /* HANDLER SECTION                                      */
    /********************************************************/
    /// 통계신청 목록을 요청한 
    const fetchZoomStatRequest = async()=>{
        let data = await getZoomStatRequest(userSystemId);
        //console.log('로드된 데이터: ',data);
        setStatReqData(data);
    };

    /// 시뮬레이션 신청 목록을 패치 
    const fetchZoomSimRequest = async() => {
        let data = await getZoomSimRequest(userSystemId);
        setSimReqData(data);
    };
    
    /// 신규 통계신청 버튼클릭 이벤트 핸들러 
    function requestStatButtonClickHandler() {
        setVisibleStatRequestDialog(true);
    };

    /// 통계 요청목록에서 아이템 선택 이벤트 핸들러 
    const onSelectStatRequest = (args) => {
        const selectedRow = args.data;
        navigator(`/cpo/station-zoom/stat-report/${selectedRow.statId}`, {state: selectedRow});
    };

    /// 시뮬레이션 요청목록에서 아이템 선택 이벤트 핸들러 
    const onSelectSimRequest = (args) => {
        const selectedRow = args.data;

        if(selectedRow.status === '완료') {
            navigator(`/cpo/station-zoom/sim-report/${selectedRow.id}`, {state: selectedRow});
        }
        else if(selectedRow.sttus === '준비중') {
            /// 완료되지 않은 요청인 경우, 알림 대화상자를 보이게 한다. 
            setVisibleInfoDialog(true);
        }
        else {
            /// 완료되지 않은 요청인 경우, 알림 대화상자를 보이게 한다. 
            setVisibleInfoDialog(true);
        }
    };

    /// 신규 시뮬레이션신청 버튼 선택이벤트 핸들러 
    function requestSimulButtonClickHandler() {
        setVisibleSimRequestDialog(true);
    };

    /// 시뮬레이션 신청 모달대화상자에 전달할 콜백 함수 
    const simRequestDialogCallback = (result) => {
        if(result === true) {
            setRefreshSimRequestList(true);
        }
        setVisibleSimRequestDialog(false);
    };

    /// 통계 신청 모달대화상자에 전달할 콜백 함수
    const statRequestDialogCallback = (result) => {
        if(result === true) {
            setRefreshStatRequestList(true);
        }
        setVisibleStatRequestDialog(false);
    };

    /// 시뮬레이션 신청 목록의 컬럼값 레이블을 변경하는 핸들러 
    const simRequestGridqueryCellInfoHandler = (args) => {

        if(args.column.field === 'status') {
            /// 상태 정보를 한글로 변경 
            let statusKr = '준비중';
            if(args.data.status === 'complete') {
                statusKr = '완료'
            }
            else if(args.data.status === 'running') {
                statusKr = '시뮬레이션중'
            }
            args.cell.innerHTML = statusKr;
        }

        if(args.column.field === 'date') {
            /// 날짜 형식을 YYYY년 MM월 DD일로 변경 
            const date = new Date(args.data.date);
            args.cell.innerHTML = date.toLocaleDateString('ko-KR',{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    }

    /********************************************************/
    /* VIEW SECTION                                         */
    /********************************************************/
    return (
        <div className='cpo-station-zoom-page-container' style={{height:'100%'}} id='dialog-target'>
 
            <div style={{marginBottom:'10px'}}>
                대한민국 시군구 단위 지역별로 전기차 충전과 관련된 통계데이터와 충전량과 POI를 기반으로 학습된 AI가 시뮬레이션한 충전소 설치 위치 정보를 제공받을 수 있습니다.
            </div>
             
            {/* 통계자료 신청 및 신청목록 세션 */}
            <div style={{marginBottom:'15px', marginTop:'15px'}}>
                <h1 style={{fontSize: '20px'}}> 1. 지역 통계정보</h1>
            </div>
            <div style={{
                    marginBottom: '15px',
                    fontSize: '12px'
                }}>
                통계자료신청에 대해 건당 처리시간은 최대 5분정도 소요됩니다. 통계자료요청 대기열에 따라서 종료시간은 유동적일 수 있습니다. 
            </div>  
            <div style={{marginBottom:'10px'}}>
                <ButtonComponent onClick={requestStatButtonClickHandler}>통계자료 신청</ButtonComponent>
            </div>
            <div style={{marginBottom:'20px'}}>
                <GridComponent 
                    height={380}
                    allowPaging={true}
                    // allowSorting={true}
                    loadingIndicator={{indicatorType: 'Spinner'}}
                    pageSettings = {{pageSize:10, pageCount:10}}
                    rowSelected = {onSelectStatRequest}
                    dataSource={statReqData}>
                    <ColumnsDirective>
                        <ColumnDirective field='statId' headerText='신청번호' width='50' textAlign='Center'/>
                        <ColumnDirective field='date' headerText='신청일' width='100' textAlign='Left'/>
                        <ColumnDirective field='sigKorNm' headerText='신청지역' width='150' textAlign='Left'/>
                        <ColumnDirective field='fromDate' headerText='자료 시작일' width='100' textAlign='Left'/>
                        <ColumnDirective field='toDate' headerText='자료 종료일' width='100' textAlign='Left'/>
                        <ColumnDirective field='status' headerText='신청처리상태' width='100' textAlign='Left'/>
                    </ColumnsDirective>
                    <Inject services={[Page, Selection]}/>
                </GridComponent>
            </div>
            {/* 시뮬레이션 신청 및 신청목록 세션 */}
            <div style={{marginBottom:'15px', marginTop:'25px'}}>
                <h1 style={{fontSize: '20px'}}> 2. 지역 충전소 입지 추천 시뮬레이션</h1>
            </div>
            <div style={{
                    marginBottom: '15px',
                    fontSize: '12px'
                }}>
                시뮬레이션은 자체적으로 수집한 통계데이터와 필요충전량 예상 AI의 결과 및 다양한 관점의 입지 선정 알고리즘을 이용하여 진행됩니다. 
                시뮬레이션은 요청에 따라서 순차적으로 처리되며 건당 최대 15분정도 소요됩니다. 대기열에 포함된 요청에 따라서 처리종료시간이 유동적일 수 있습니다.
            </div>  
            <div style={{marginBottom:'10px'}}>
                <ButtonComponent onClick={requestSimulButtonClickHandler}>시뮬레이션 신청</ButtonComponent>
            </div>
            <div style={{marginBottom:'20px'}}> 
                <GridComponent 
                    height={380}
                    allowPaging={true}
                    loadingIndicator={{indicatorType: 'Spinner'}}
                    pageSettings = {{pageSize:10, pageCount:10}}
                    rowSelected={onSelectSimRequest}
                    //queryCellInfo={simRequestGridqueryCellInfoHandler}
                    dataSource={simReqData}>
                    <ColumnsDirective>
                        <ColumnDirective field='id' headerText='신청번호' width='50' textAlign='Center'/>    
                        <ColumnDirective field='date' headerText='신청일' width='100' textAlign='Left'/>
                        <ColumnDirective field='korName' headerText='신청지역' width='150' textAlign='Left'/>  
                        <ColumnDirective field='status' headerText='신청처리상태' width='100' textAlign='Left'/>   
                    </ColumnsDirective> 
                    <Inject services={[Page, Selection]}/>   
                </GridComponent>
            </div>
            {/* 통계요청 팝업상자 */}
            <ZoomStatRequestModal visible={visibleStatRequestDialog} closeDialogCallback={statRequestDialogCallback}/>
            {/* 시뮬레이션요청 팝업상자  */}
            <ZoomSimRequestModal visible={visibleSimRequestDialog} closeDialogCallback={simRequestDialogCallback}/>
            {/* 알림 팝업상자  */}
            <DialogComponent width='300px'
                isModal={true}
                visible={visibleInfoDialog}
                close={()=>{setVisibleInfoDialog(false)}}
                showCloseIcon={true}
                header='알림'>
                시뮬레이션이 완료되지 않았습니다.
            </DialogComponent>
        </div>
    );
}

export default StationZoom;