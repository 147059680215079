import React, { useRef, useState, useEffect } from 'react';
import { TfiClose } from "react-icons/tfi";
import Selector2 from '../buttons/Selector2';
import { numberInput, timestampFormat5 } from '../../util/util';
import { Loading } from '../alerts/Loading.js';

import { postChargerModel, getChargerManufacturerList, putChargerModel } from '../../api/infra/infraApi';

export const CpoChargerModelEnrollModal = (props) => {
  
  const [companyCategory, setCompanyCategory] = useState(null);
  const [name, setName] = useState(null);
  const [chargerType, setChargerType] = useState(null);
  const [chargingSpeed, setChargingSpeed] = useState(null);
  const [chargingPower, setChargingPower] = useState(null);
  const [voltage, setVoltage] = useState(null);
  const [electricCurrent, setElectricCurrent] = useState(null);
  
  const [actualRegistrationDate, setActualRegistrationDate] = useState(null);
  
  const [remarks, setRemarks] = useState(null);
  const [detailedSpec, setDetailedSpec] = useState(null);

  // 동시 충전 지원 여부 (임시 변수 명)
  const [isSimultaneous, setIsSimultaneous] = useState(null);

  const [connectorType, setConnectorType] = useState(null);
  const [connector2Type, setConnector2Type] = useState(null);

  const [companyCategoryActive, setCompanyCategoryActive] = useState();

  const [chargerTypeActive, setChargerTypeActive] = useState();

  const activeReset = ()=>{
    setCompanyCategoryActive(false);
    setChargerTypeActive(false);
  }


  const [chargerManufacturerList, setChargerManufacturerList] = useState(null);
  const [chargerManufacturerLabelList, setChargerManufacturerLabelList] = useState(null);

  const reqGetChargerManufacturerList = async()=>{
    let result = await getChargerManufacturerList();


    if(result.status === 200){
      setChargerManufacturerList(result.data.Results);
    }
    else{
      
    }
  }

  useEffect(()=>{
    reqGetChargerManufacturerList();
  },[])

  const getLabelList = async (dataList) => {

    let labelList = [];
    for await (const it of dataList) {
      labelList.push({name : it.Company?.Name, id: it.ManufacturerId});
    }

    setChargerManufacturerLabelList(labelList);
  }

  useEffect(()=>{
    if(chargerManufacturerList){
      getLabelList(chargerManufacturerList);
    }
  },[chargerManufacturerList])

  let chargerTypeList=[
    {name:"고속", value:"FAST_CHARGER"},
    // {name:"중속"}, //TODO 현재 중속에 대한 정의가 없음
    {name:"저속", value:"SLOW_CHARGER"},
  ];

  const [checkedDC_Chademo, setCheckedDC_Chademo] = useState(false);
  const [checkedAC_ThreePase, setCheckedAC_ThreePase] = useState(false);
  const [checkedDC_ComboA, setCheckedDC_ComboA] = useState(false);
  const [checkedDC_ComboB, setCheckedDC_ComboB] = useState(false);
  const [checkedAC_Slow, setCheckedAC_Slow] = useState(false);
  const [checkedAC_SlowC, setCheckedAC_SlowC] = useState(false);
  const [checkedNacs, setCheckedNacs] = useState(false);
  
  const [checkedNacs2, setCheckedNacs2] = useState(false);
  const [checkedDC_Chademo2, setCheckedDC_Chademo2] = useState(false);
  const [checkedAC_ThreePase2, setCheckedAC_ThreePase2] = useState(false);
  const [checkedDC_ComboA2, setCheckedDC_ComboA2] = useState(false);
  const [checkedDC_ComboB2, setCheckedDC_ComboB2] = useState(false);
  const [checkedAC_Slow2, setCheckedAC_Slow2] = useState(false);
  const [checkedAC_SlowC2, setCheckedAC_SlowC2] = useState(false);

  const [IsUsed, setIsUsed] = useState(null);

  useEffect(()=>{
    switch (chargerType?.name) {
      case "고속":
        setCheckedAC_Slow(false);
        setCheckedAC_Slow2(false);
        setCheckedAC_SlowC(false);
        setCheckedAC_SlowC2(false);
        break;
      case "중속":
        setCheckedAC_Slow(false);
        setCheckedAC_Slow2(false);
        setCheckedAC_SlowC(false);
        setCheckedAC_SlowC2(false);
        break;
      case "저속":
        setCheckedDC_Chademo(false);
        setCheckedDC_Chademo2(false);
        setCheckedDC_ComboA(false);
        setCheckedDC_ComboA2(false);
        setCheckedDC_ComboB(false);
        setCheckedDC_ComboB2(false);
        break;
      default:
        break;
    }
  },[chargerType])

  const [enrollState, setEnrollState] = useState(false);
  
  useEffect(()=>{
    setConnectorType(getChargerType2(checkedDC_Chademo,checkedAC_ThreePase,checkedDC_ComboA,checkedAC_Slow,checkedAC_SlowC,checkedDC_ComboB,checkedNacs))
  },[checkedDC_Chademo,checkedAC_ThreePase,checkedDC_ComboA,checkedAC_Slow,checkedAC_SlowC,checkedDC_ComboB,checkedNacs])

  useEffect(()=>{
    setConnector2Type(getChargerType2(checkedDC_Chademo2,checkedAC_ThreePase2,checkedDC_ComboA2,checkedAC_Slow2,checkedAC_SlowC2, checkedDC_ComboB2, checkedNacs2))
  },[checkedDC_Chademo2,checkedAC_ThreePase2,checkedDC_ComboA2,checkedAC_Slow2,checkedAC_SlowC2, checkedDC_ComboB2, checkedNacs2])

  useEffect(()=>{
    if(companyCategory&&name&&chargerType&&chargingSpeed&&chargingPower&&voltage&&electricCurrent&&actualRegistrationDate&&(isSimultaneous!==null)&&connectorType&&(isSimultaneous===0?connector2Type:true)&&(IsUsed!==null) ){
      setEnrollState(true);
    }
    else{
      setEnrollState(false);
    }
  })
  function getChargerType(checkedDC_Chademo, checkedAC_ThreePase, checkedDC_Combo, checkedAC_Slow) {
    const DC_Chademo = checkedDC_Chademo;
    const AC_ThreePhase = checkedAC_ThreePase;
    const DC_Combo = checkedDC_Combo;
    const AC_Slow = checkedAC_Slow;

    if (DC_Chademo && !AC_ThreePhase && !DC_Combo && !AC_Slow) {
        return 'DC_Chademo';
    } else if (!DC_Chademo && !AC_ThreePhase && !DC_Combo && AC_Slow) {
        return 'AC_Slow';
    } else if (DC_Chademo && AC_ThreePhase && !DC_Combo && !AC_Slow) {
        return 'DC_Chademo_AC_ThreePhase';
    } else if (!DC_Chademo && !AC_ThreePhase && DC_Combo && !AC_Slow) {
        return 'DC_Combo';
    } else if (DC_Chademo && !AC_ThreePhase && DC_Combo && !AC_Slow) {
        return 'DC_Chademo_DC_Combo';
    } else if (DC_Chademo && AC_ThreePhase && DC_Combo && !AC_Slow) {
        return 'DC_Chademo_AC_ThreePhase_DC_Combo';
    } else if (!DC_Chademo && AC_ThreePhase && !DC_Combo && !AC_Slow) {
        return 'AC_ThreePhase';
    } else {
        return '';
    }
  }

  function getChargerType2(checkedDC_Chademo, checkedAC_ThreePase, checkedDC_ComboA, checkedAC_Slow, checkedAC_SlowC, checkedDC_ComboB, checkedNacs) {
    return [checkedDC_Chademo?"DC차데모":null,checkedAC_ThreePase?"AC3상":null,checkedDC_ComboA?"DC콤보1":null,checkedAC_Slow?"AC완속B":null,checkedAC_SlowC?"AC완속C":null,checkedDC_ComboB?"DC콤보2":null,checkedNacs?"NACS":null].filter(item => item !== null);
  }

  // 
  // "DC_Chademo", -DC차데모
  // "AC_Slow", - AC완속
  // "DC_Chademo_AC_ThreePhase", DC차데모+AC3상
  // "DC_Chademo_DC_Combo", - DC차데모+DC콤보
  // "DC_Chademo_AC_ThreePhase_DC_Combo", DC차데모+AC3상+DC콤보
  // "AC_ThreePhase", AC3상
  // "DC_Combo1", - DC콤보1
  // "DC_Combo2", - DC콤보2
  // "DC_Combo_Slow" - DC콤보완속?

  useEffect(()=>{
    if(props.editMode && chargerManufacturerLabelList){
      initialEditData();
    }
  },[chargerManufacturerLabelList])

  const initialEditData = ()=>{
    setCompanyCategory(chargerManufacturerLabelList.find(it=>it.id===props.editData?.Manufacturer?.ManufacturerId));
    setName(props.editData?.Name);
    setChargingSpeed(props.editData?.ChargingSpeed);
    setChargerType(chargerTypeList.find(it=>it.value===props.editData?.ChargerType));
    setChargingPower(props.editData?.ChargingPower);
    setVoltage(props.editData?.Voltage);
    setElectricCurrent(props.editData?.ElectricCurrent);
    setActualRegistrationDate(timestampFormat5(new Date(props.editData?.ActualRegistrationDate), 'yyyy-MM-dd'));
    setIsUsed(props.editData?.IsUsed);
    setRemarks(props.editData?.Remarks);
    setDetailedSpec(props.editData?.DetailedSpec);
    setIsSimultaneous(props.editData?.Connectors.length===1?1:0);
    if(props.editData?.Connectors.length>1){
      props.editData?.Connectors[0]?.ConnectorTypes?.map((it,idx)=>{
        if(it){
          switch (it) {
            case "DC차데모":
              setCheckedDC_Chademo(true);
              break;
            case "AC3상":
              setCheckedAC_ThreePase(true);
              break;
            case "DC콤보1":
              setCheckedDC_ComboA(true);
              break;
            case "AC완속B":
              setCheckedAC_Slow(true);
              break;
            case "AC완속C":
              setCheckedAC_SlowC(true);
              break;
            case "DC콤보2":
              setCheckedDC_ComboB(true);
              break;
            case "NACS":
              setCheckedNacs(true);
              break;
            default:
              break;
          }
        }
      })
      props.editData?.Connectors[1]?.ConnectorTypes?.map((it,idx)=>{
        if(it){
          switch (it) {
            case "DC차데모":
              setCheckedDC_Chademo2(true);
              break;
            case "AC3상":
              setCheckedAC_ThreePase2(true);
              break;
            case "DC콤보1":
              setCheckedDC_ComboA2(true);
              break;
            case "AC완속B":
              setCheckedAC_Slow2(true);
              break;
            case "AC완속C":
              setCheckedAC_SlowC2(true);
              break;
            case "DC콤보2":
              setCheckedDC_ComboB2(true);
              break;
            case "NACS":
              setCheckedNacs2(true);
              break;
            default:
              break;
          }
        }
      })
    }
    else{
      props.editData?.Connectors[0]?.ConnectorTypes?.map((it,idx)=>{
        if(it){
          switch (it) {
            case "DC차데모":
              setCheckedDC_Chademo(true);
              break;
            case "AC3상":
              setCheckedAC_ThreePase(true);
              break;
            case "DC콤보1":
              setCheckedDC_ComboA(true);
              break;
            case "AC완속B":
              setCheckedAC_Slow(true);
              break;
            case "AC완속C":
              setCheckedAC_SlowC(true);
              break;
            case "DC콤보2":
              setCheckedDC_ComboB(true);
              break;
            case "NACS":
              setCheckedNacs(true);
              break;
            default:
              break;
          }
        }
      })
    }


  }

  const reqPostChargerModel = async()=>{
    setLoadingOn(true);
    let modelInfo = {
    "manufacturerId": companyCategory.id,
    "chargingSpeed": chargingSpeed,
    "name": name,
    "chargerType": chargerType?.value,
    "connectors":isSimultaneous===0 ? [
      {
        "connectorTypes":
          connector2Type
      }
      ,{
        "connectorTypes":
          connectorType
      }
    ]
    :
    [
      {
        "connectorTypes":
          connectorType
      }
    ]
    ,
    "chargingPower": chargingPower,
    "voltage": voltage,
    "electricCurrent": electricCurrent,
    "actualRegistrationDate": new Date(actualRegistrationDate),
    "isUsed": IsUsed,
    "imageIds": [],
    "remarks": remarks,
    "detailedSpec": detailedSpec
    }

    
    let result = await postChargerModel(modelInfo);
    if(result.status === 201){
      props.setOnOff(false);
      props.getChargerModelList();
    }
    else{
      setErrMsg("등록에 실패했습니다.");
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }
  const reqPutChargerModel = async()=>{
    setLoadingOn(true);
    let modelInfo = {
    "manufacturerId": companyCategory.id,
    "chargingSpeed": chargingSpeed,
    "name": name,
    "chargerType": chargerType?.value,
    "connectors":isSimultaneous===0 ? [
      {
        "connectorTypes":
          connector2Type
      }
      ,{
        "connectorTypes":
          connectorType
      }
    ]
    :
    [
      {
        "connectorTypes":
          connectorType
      }
    ]
    ,
    "chargingPower": chargingPower,
    "voltage": voltage,
    "electricCurrent": electricCurrent,
    "actualRegistrationDate": new Date(actualRegistrationDate),
    "isUsed": IsUsed,
    "imageIds": [],
    "remarks": remarks,
    "detailedSpec": detailedSpec
    }

    
    let result = await putChargerModel(props.editData.Id,modelInfo);
    if(result.status === 200){
      props.setOnOff(false);
      props.getChargerModelList();
      props.setCheckedItemList([]);
      props.setEditMode(false);
    }
    else{
      setErrMsg("수정에 실패했습니다.");
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
    setLoadingOn(false);
  }
  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])

  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  return (
    <div className="cpo-charger-model-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-charger-model-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-model-enroll-modal-header">
          <title>{`충전기모델 ${props.editMode ? "수정":"신규등록"}`}</title>
          <div className='cpo-charger-model-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-charger-model-enroll-modal-content">
          <div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper'>
              <p className='cpo-charger-model-content-title required'>충전기 제조사 업체명 </p>
              {/* ********************************** */}
              {/* 제조사 관련 API 제작 완료 후 변경예정 */}
              <div className="cpo-charger-model-enroll-modal-content-selector-container">
                <Selector2 name={'name'} dataList={chargerManufacturerLabelList} select={companyCategory} setSelect={setCompanyCategory} active={companyCategoryActive} setActive={setCompanyCategoryActive} activeReset={activeReset}/>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title required'>모델이름</p>
              <div className="cpo-charger-model-enroll-modal-content-input-container">
                <input type="text" placeholder='모델명을 입력해주세요' value={name} onChange={(e)=>{setName(e.target.value)}} />
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper triple marginTop'>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>출력</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={chargingPower} onChange={(e)=>{setChargingPower(e.target.value)}} />
                  <p>kWh</p>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>전압</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={voltage} onChange={(e)=>{setVoltage(e.target.value)}} />
                  <p>V</p>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>전류</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={electricCurrent} onChange={(e)=>{setElectricCurrent(e.target.value)}} />
                  <p>A</p>
                </div>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title required'>등록년월</p>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper triple date'>
              <div className="cpo-charger-model-enroll-modal-content-input-container date">
                <input type="date" placeholder='날짜를 선택해주세요' onSubmit={()=>{return false}} value={actualRegistrationDate} onChange={(e)=>{setActualRegistrationDate(e.target.value)}} onClick={(e)=>{e.stopPropagation();}} />
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title'>설명</p>
              <div className="cpo-charger-model-enroll-modal-content-textarea-container">
                <textarea type="text" placeholder='설명을 입력해주세요' value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} />
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p>상세사양</p>
              <div className="cpo-charger-model-enroll-modal-content-textarea-container">
                <textarea type="text" placeholder='상세사양을 입력해주세요' value={detailedSpec} onChange={(e)=>{setDetailedSpec(e.target.value)}} />
              </div>
            </div>
          </div>
          <div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper'>
              <p className='cpo-charger-model-content-title required'>충전속도</p>
              <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={chargingSpeed} onChange={(e)=>{setChargingSpeed(e.target.value)}} />
                <p>kW</p>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p className='cpo-charger-model-content-title required'>충전기 종류</p>
              <div className="cpo-charger-model-enroll-modal-content-selector-container">
                <Selector2 name={'name'} dataList={chargerTypeList} select={chargerType} setSelect={setChargerType} active={chargerTypeActive} setActive={setChargerTypeActive} activeReset={activeReset}/>
              </div>
            </div>





            <div className='cpo-charger-model-enroll-modal-content-wrapper divide-no-margin marginTop'>
              <div className="cpo-charger-model-enroll-modal-content-wrapper">
                <p className='cpo-charger-model-content-title required'>동시충전 지원여부</p>
                <div className="cpo-charger-model-enroll-modal-radio-container">
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${isSimultaneous == 0 ? "clicked":""}`} onClick={()=>{setIsSimultaneous(0)}}></div>
                      <p>지원</p>
                    </div>
                  </div>
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${isSimultaneous == 1 ? "clicked":""}`} onClick={()=>{setIsSimultaneous(1)}}></div>
                      <p>미지원</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p className='cpo-charger-model-content-title required'>커넥터 1 타입</p>
                <div className="cpo-charger-model-enroll-modal-checkbox-container">
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checkedDC_Chademo} onChange={()=>{setCheckedDC_Chademo(!checkedDC_Chademo)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>DC차데모</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check `} type="checkbox" checked={checkedAC_ThreePase} onChange={()=>{setCheckedAC_ThreePase(!checkedAC_ThreePase)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>AC3상</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checkedDC_ComboA} onChange={()=>{setCheckedDC_ComboA(!checkedDC_ComboA)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>DC콤보1</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checkedDC_ComboB} onChange={()=>{setCheckedDC_ComboB(!checkedDC_ComboB)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>DC콤보2</p>
                  </div>
                </div>
                <div className="cpo-charger-model-enroll-modal-checkbox-container">
                  
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check `} type="checkbox" checked={checkedNacs} onChange={()=>{setCheckedNacs(!checkedNacs)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>NACS</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="고속"||chargerType?.name==="중속" ? "none":""}`} type="checkbox" checked={checkedAC_Slow} onChange={()=>{setCheckedAC_Slow(!checkedAC_Slow)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>AC완속B</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input className={`cpo-charger-model-check ${chargerType?.name==="고속"||chargerType?.name==="중속" ? "none":""}`} type="checkbox" checked={checkedAC_SlowC} onChange={()=>{setCheckedAC_SlowC(!checkedAC_SlowC)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>AC완속C</p>
                  </div>




                </div>
              </div>
            </div>
            {
              isSimultaneous === 0 &&
              <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
                <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                  <p className='cpo-charger-model-content-title required'>커넥터 2 타입</p>
                  <div className="cpo-charger-model-enroll-modal-checkbox-container">
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checkedDC_Chademo2} onChange={()=>{setCheckedDC_Chademo2(!checkedDC_Chademo2)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      <p>DC차데모</p>
                    </div>
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input className={`cpo-charger-model-check `} type="checkbox" checked={checkedAC_ThreePase2} onChange={()=>{setCheckedAC_ThreePase2(!checkedAC_ThreePase2)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      <p>AC3상</p>
                    </div>
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checkedDC_ComboA2} onChange={()=>{setCheckedDC_ComboA2(!checkedDC_ComboA2)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      <p>DC콤보1</p>
                    </div>
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input className={`cpo-charger-model-check ${chargerType?.name==="저속" ? "none":""}`} type="checkbox" checked={checkedDC_ComboB2} onChange={()=>{setCheckedDC_ComboB2(!checkedDC_ComboB2)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      <p>DC콤보2</p>
                    </div>
                    
                  </div>
                  <div className="cpo-charger-model-enroll-modal-checkbox-container">
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input className={`cpo-charger-model-check `} type="checkbox" checked={checkedNacs2} onChange={()=>{setCheckedNacs2(!checkedNacs2)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      <p>NACS</p>
                    </div>
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input className={`cpo-charger-model-check ${chargerType?.name==="고속"||chargerType?.name==="중속" ? "none":""}`} type="checkbox" checked={checkedAC_Slow2} onChange={()=>{setCheckedAC_Slow2(!checkedAC_Slow2)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      <p>AC완속B</p>
                    </div>
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input className={`cpo-charger-model-check ${chargerType?.name==="고속"||chargerType?.name==="중속" ? "none":""}`} type="checkbox" checked={checkedAC_SlowC2} onChange={()=>{setCheckedAC_SlowC2(!checkedAC_SlowC2)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                      <p>AC완속C</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className='cpo-charger-model-enroll-modal-content-wrapper divide-no-margin marginTop'>
              <div className="cpo-charger-model-enroll-modal-content-wrapper">
                <p className='cpo-charger-model-content-title required'>중고여부</p>
                <div className="cpo-charger-model-enroll-modal-radio-container">
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${IsUsed == 1 ? "clicked":""}`} onClick={()=>{setIsUsed(1)}}></div>
                      <p>중고</p>
                    </div>
                  </div>
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${IsUsed == 0 ? "clicked":""}`} onClick={()=>{setIsUsed(0)}}></div>
                      <p>해당없음</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
          
          

        </div>
        <div className='cpo-charger-model-enroll-modal-button-container'>
          {errMsg && <p className='error-msg'>{errMsg}</p>}
          <div className={`cpo-charger-model-enroll-modal-button ${enrollState?"":"none"}`} onClick={()=>{props.editMode ? reqPutChargerModel() : reqPostChargerModel()}}>
            {loadingOn ? <Loading/> : 
              <>
                {props.editMode ? "수정하기":"등록하기"}
              </>
            }
          {/* {cloudAlertOn&&<CloudAlertRight data={"업데이트 예정인 기능입니다."}/>} */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoChargerModelEnrollModal
