import React, { useState, useEffect } from 'react';
import { TbDotsVertical } from "react-icons/tb";
import { useSelector } from "react-redux";

import RoamingChargingStationModal from '../../../components/modals/RoamingChargingStationModal';
import { getRoamingChargingStationByEmoovId } from '../../../api/roaming/roamingApi';



const SubCpoStationItem = (props) => {
  const user = useSelector(state => state.user);
  // station item 클릭 이벤트 콜백 function
  const clickEvent = ()=>{
    // 현재 클릭된 것이 해당 객체가 아닌 경우
    props.setClickedStation(props.idx)
  }

  const[btnOpen, setBtnOpen] = useState(false);
  const clickRoaming = async()=>{
    setRoamingTarget(props.data?.ChargingStationId);
    setRoamingModalOpen(true);
  }


  // 로밍 Modal
  const [roamingModalOpen, setRoamingModalOpen] = useState(false);
  const [roamingTarget, setRoamingTarget] = useState(null);

  // 충전소 로밍 정보
  const [roamingStationInfo, setRoamingStationInfo]=useState(null);

  const reqGetRoamingChargingStationList = async()=>{
    let res = await getRoamingChargingStationByEmoovId(props.data?.ChargingStationId);
    if(res.status === 200){
      setRoamingStationInfo(res.data);
    }
    else{

    }
  }

  useEffect(()=>{
    reqGetRoamingChargingStationList();
  },[])

  return (
    <>
    {roamingModalOpen && <RoamingChargingStationModal setOnOff={setRoamingModalOpen} roamingTarget={roamingTarget} callback={reqGetRoamingChargingStationList}/>}
    <div className={props.clickedStation === props.idx ? 'sub-cpo-station-management-list-content-item clicked':'sub-cpo-station-management-list-content-item'}
      ref={(el)=>{props.stationRef.current[props.idx]=el}}
      onClick={()=>{clickEvent()}}
      >
        <div><p>{`${props.data.RegionName ? props.data.RegionName:"-"}`}</p></div>
        <div><p>{props.data.ChargingStationName}</p></div>
        <div><p onDrag={(e)=>{e.stopPropagation();}}>{props.data.Address ?props.data.Address+' '+(props.data.DetailedAddress ? props.data.DetailedAddress:""):"-"}</p></div>
        <div>
          <div className={`sub-cpo-roaming-check-badge ${roamingStationInfo ? "":"none"}`}><p>{roamingStationInfo ? "등록":"미등록"}</p></div>
        </div>
        <div><p>{roamingStationInfo?.moEChargingStationId}</p></div>
        <div className='centerAlign'><p style={{color:props.chargerHubInfo&&props.chargerHubInfo?.filter(it=>it.used === true).length !== 0?'var(--emoovBlue)':'#ddd',fontWeight:"800"}}>
          {props.chargerHubInfo?.filter(it=>it.used === true).length}</p></div>
        <div className='centerAlign'><p>{props.totalCount}</p></div>
        <div className='handle'>
          <div className="sub-cpo-station-management-list-content-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
            <div className={"sub-cpo-station-management-list-content-item-function-button-list " + `${btnOpen ? "open":""}`}>
              {
                user.cpoId === process.env.REACT_APP_SUPER_ID && !roamingStationInfo && <div>
                <div className="sub-cpo-station-management-list-content-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(false); clickRoaming();}}>
                  <div className='roaming'>로밍 등록</div>
                </div>
              </div>}
              <div>
              </div>
            </div>
            <TbDotsVertical/>
          </div>
        </div>
      </div>
    </>
  )
}
export default SubCpoStationItem;