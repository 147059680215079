import React, { useRef, useState, useEffect } from 'react';

import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";

import { Loading } from '../alerts/Loading';

import {deleteManager} from '../../api/infra/companyApi';

export const CpoInfraConstructorManagerDeleteModal = (props) => {
  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  useEffect(()=>{
    if(errMsg){
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  },[errMsg])
  
  const reqDeleteManager = async()=>{
    setLoadingOn(true);
    let result = await deleteManager(props.CompanySysId, props.ManagerSysId);
    if(result.status === 200){
      props.reqGetInfraConstructorList(1, {"specialization":"all"});
      props.setOnOff(false);
    }
    else{
      setErrMsg("담당자 삭제에 실패하였습니다");
    }
    setLoadingOn(false);
  }

  return (
    <div className="cpo-infra-constructor-manager-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-infra-constructor-manager-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-infra-constructor-manager-delete-modal-header">
          <title>담당자 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-infra-constructor-manager-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='cpo-infra-constructor-manager-delete-modal-msg'>해당 담당자를 삭제하시겠습니까?</p>
          <div className='cpo-infra-constructor-manager-delete-modal-info'>
            <p>{props.data?.Name}</p>
            <p>{props.data?.Team}</p>
            <p>{props.data?.Position}</p>
            <p>{props.data?.ContantNumber}</p>
          </div>
        </div>
        <div>
          <div className={'cpo-infra-constructor-manager-delete-modal-delete-button '} onClick={()=>{reqDeleteManager()}}>
            {loadingOn ? <Loading/>:<p>삭제</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CpoInfraConstructorManagerDeleteModal
