import { TfiClose } from "react-icons/tfi";

import React, { useRef, useState, useEffect } from 'react';

import { numberInput } from '../../util/util';

import { MdInfoOutline } from "react-icons/md";

import SettingDefaultChargingRateModal from '../../components/modals/SettingDefaultChargingRateModal.js';
import { Loading } from '../../components/alerts/Loading.js';

import { getPricingPlanOfStation, patchChargeRateOfStation, putChargeRateOfStation, getDefaultPricingPlan, getAllPricingEvent } from '../../api/chargerate/chargerateApi.js';

import { Tooltip } from 'react-tooltip'
import { RiDeleteBin2Line } from "react-icons/ri";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { ChargingFeeMoreInfo } from "../../pages/CPO/CpoChargingFeeManagement.js";

import ChargingFeeInitialAlertModal from "./ChargingFeeInitialAlertModal.js";

export const StationChargingFeeSettingModal = (props) => {

  const [membershipRate, setMembershipRate] = useState();
  const [nonMembershipRate, setNonMembershipRate] = useState();
  
  const [initMembershipRate, setInitMembershipRate] = useState();
  const [initNonMembershipRate, setInitNonMembershipRate] = useState();

  const [membershipRateUnitList, setMembershipRateUnitList] = useState();
  const [nonMembershipRateUnitList, setNonMembershipRateUnitList] = useState();
  
  const [membershipRateDefault, setMembershipRateDefault] = useState();
  const [nonMembershipRateDefault, setNonMembershipRateDefault] = useState();

  const [membershipRateUnitListDefault, setMembershipRateUnitListDefault] = useState();
  const [nonMembershipRateUnitListDefault, setNonMembershipRateUnitListDefault] = useState();
  
  const [defaultModalOn, setDefaultModalOn] = useState(false);
  const [initialModalOn, setInitialModalOn] = useState(false);

  const [moreInfoOn, setMoreInfoOn] = useState(false);


  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }

  const month = [1,2,3,4,5,6,7,8,9,10,11,12];
  const hour = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
  // 24x12 테이블을 구성
  const cols = 12;
  const rows = 24;

  // 각 셀을 관리할 ref 배열 생성 (배열로 초기화)
  const cellRefs = useRef(Array(rows * cols).fill(null));

  // 셀 클릭 시 호출되는 함수

  useEffect(()=>{
    if(tabIdx === 0 && (membershipRateUnitList ? membershipRateUnitList : membershipRateUnitListDefault)){
      fillCell();
    }
    else if(tabIdx === 1 && (nonMembershipRateUnitList ? nonMembershipRateUnitList : nonMembershipRateUnitListDefault)){
      fillCell();
    }
  },[tabIdx, membershipRateUnitList, nonMembershipRateUnitList, membershipRateUnitListDefault, nonMembershipRateUnitListDefault])
  

  const fillCell = async () => {
    let target;
    let pivot;

    if (tabIdx === 0) {
      target = membershipRateUnitList ? membershipRateUnitList : membershipRateUnitListDefault;
      pivot = initMembershipRate ? initMembershipRate : membershipRateDefault;
    } else {
      target = nonMembershipRateUnitList ? nonMembershipRateUnitList : nonMembershipRateUnitListDefault;
      pivot = initNonMembershipRate ? initNonMembershipRate : nonMembershipRateDefault;
    }

    const newCellState = Array(rows * cols).fill(null); // 상태 초기화

    for (let item of target) {
      const index = parseInt((item.hour) * 12 + (item.month - 1));
      newCellState[index] = {
        unitPrice: item.unitPrice,
        month: item.month,
        hour: item.hour,
        className: parseFloat(item.unitPrice) > parseFloat(pivot) ? `high month-${item.month} hour-${item.hour}` : `low month-${item.month} hour-${item.hour}`,
        hasTooltip: parseFloat(item.unitPrice) !== parseFloat(pivot), // 조건에 따라 Toggle 추가
      };
    }

    setCellState(newCellState);
  };
  
  const [cellState, setCellState] = useState(Array(rows * cols).fill(null)); // 각 셀의 상태 저장

  
  
  // 기본요금 세팅
  const [loadingOn, setLoadingOn] = useState(false);

  const [initialTrigger, setInitialTrigger] = useState(false);

  useEffect(()=>{
    reqGetDefaultPricingPlan();
  },[])
  
  useEffect(()=>{
    reqGetPricingPlanOfStation();
  },[initialTrigger])
  

  // 충전소 기본요금 조회
  const reqGetPricingPlanOfStation = async()=>{
    let result = await getPricingPlanOfStation(props.selectedStationId);
    if(result.status === 200){
      setMembershipRate(result.data.memberPlan?.defaultUnitPrice ? result.data.memberPlan?.defaultUnitPrice : membershipRateDefault);
      setNonMembershipRate(result.data.nonMemberPlan?.defaultUnitPrice ? result.data.nonMemberPlan?.defaultUnitPrice : nonMembershipRateDefault);
      setInitMembershipRate(result.data.memberPlan?.defaultUnitPrice ? result.data.memberPlan?.defaultUnitPrice : membershipRateDefault);
      setInitNonMembershipRate(result.data.nonMemberPlan?.defaultUnitPrice ? result.data.nonMemberPlan?.defaultUnitPrice : nonMembershipRateDefault);
      setMembershipRateUnitList(result.data.memberPlan?.unitPrices ? result.data.memberPlan?.unitPrices : membershipRateUnitListDefault);
      setNonMembershipRateUnitList(result.data.nonMemberPlan?.unitPrices ? result.data.nonMemberPlan?.unitPrices : nonMembershipRateUnitListDefault);
    }
  }

  // Cpo의 기본요금 조회
  const reqGetDefaultPricingPlan = async()=>{
    let result = await getDefaultPricingPlan();
    if(result.status === 200){
      setMembershipRateDefault(result.data.memberPlan?.defaultUnitPrice);
      setNonMembershipRateDefault(result.data.nonMemberPlan?.defaultUnitPrice);
      setMembershipRateUnitListDefault(result.data.memberPlan?.unitPrices);
      setNonMembershipRateUnitListDefault(result.data.nonMemberPlan?.unitPrices);
    }
    else {
    }
    setInitialTrigger(true);
  }
  

  // 
  const reqPatchStationPricingPlan = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "chargingStationId": props.selectedStationId,
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate
    }

    let result = await patchChargeRateOfStation(reqObj);
    if(result.status === 200){
      setSaveResultMsg("저장되었습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    else if(result.status === 400){
      if(result.data.ErrorCode ==="PLAN_NOT_EXIST"){
        await reqPutStationPricingPlan();
      }
    }
    await reqGetPricingPlanOfStation();
    await props.reqGetChargeRateListOfStation();
    setLoadingOn(false);
  }
  const reqPutStationPricingPlan = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "chargingStationId": props.selectedStationId,
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate,
      "unitPrices":tabIdx===0?membershipRateUnitListDefault:nonMembershipRateUnitListDefault,
    }

    let result = await putChargeRateOfStation(reqObj);
    if(result.status === 200){
      setSaveResultMsg("저장되었습니다.");
      setTimeout(() => {
        setSaveResultMsg("");
      }, 3000);
    }
    else if(result.status === 400){
      
    }
    await reqGetPricingPlanOfStation();
    await props.reqGetChargeRateListOfStation();
    setLoadingOn(false);
  }

  const [saveResultMsg, setSaveResultMsg] = useState("");



  // 충전기 selector label 리스트
  const [labelList, setLabelList] = useState();



  // selector에서 클릭한 label (Station)
  const [dataLabel, setDataLabel] = useState();

  
  const [selected, setSelected] = useState(0);
  useEffect(()=>{
    if(labelList){
      setDataLabel(labelList[0])
    }
  },[labelList])
  useEffect(()=>{
    if(dataLabel){
      setSelected(dataLabel.idx)
    }
  },[dataLabel]) 


  // 초기화 
  const reqPutStationPricingPlanForInitial = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "chargingStationId": props.selectedStationId,
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate,
      "unitPrices":[]
    }

    let result = await putChargeRateOfStation(reqObj);
    if(result.status === 200){
      // setSaveResultMsg("저장되었습니다.");
      // setTimeout(() => {
      //   setSaveResultMsg("");
      // }, 3000);
    }
    else if(result.status === 400){
      // console.log(result);
      
    }
    await reqGetPricingPlanOfStation();
    await props.reqGetChargeRateListOfStation();
    setLoadingOn(false);
  }
  
  // 초기화 
  const reqPutStationPricingPlanForDelete = async(month, hour)=>{
    setLoadingOn(true);
    let unitList = [];
    if(tabIdx===0){
      if(membershipRateUnitList) unitList = membershipRateUnitList.filter(it=>(it.month!==month || it.hour!== hour))
      else unitList = membershipRateUnitListDefault.filter(it=>(it.month!==month || it.hour!== hour))
    }
    else{
      if(nonMembershipRateUnitList) unitList = nonMembershipRateUnitList.filter(it=>it.month!==month || it.hour!== hour)
      else unitList = nonMembershipRateUnitListDefault.filter(it=>it.month!==month || it.hour!== hour)
    }
    let reqObj = {
      "chargingStationId": props.selectedStationId,
      "pricingType": tabIdx===0?"Member":"NonMember",
      "currency": "KRW",
      "defaultUnitPrice": tabIdx===0?membershipRate:nonMembershipRate,
      "unitPrices":unitList
    }
    

    let result = await putChargeRateOfStation(reqObj);
    if(result.status === 200){
      // setSaveResultMsg("저장되었습니다.");
      // setTimeout(() => {
      //   setSaveResultMsg("");
      // }, 3000);
    }
    else if(result.status === 400){
      // console.log(result);
      
    }
    await reqGetPricingPlanOfStation();
    await props.reqGetChargeRateListOfStation();
    setLoadingOn(false);
  }





  return (
    <div className='station-charging-fee-setting-modal-bg'>
      <div className="station-charging-fee-setting-modal-container">
        <div className="station-charging-fee-setting-modal-header">
          <title>{`${props.selectedStationName}충전소 요금 설정`}</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-charging-fee-management-page-container">
            {defaultModalOn&&<SettingDefaultChargingRateModal setOnOff={setDefaultModalOn} stationSetting={true} chargingStationId={props.selectedStationId} reqGetPricingPlanOfStation={reqGetPricingPlanOfStation} pricingType={tabIdx===0 ? "Member":"NonMember"} unitPrices={tabIdx === 0 ? membershipRateUnitList : nonMembershipRateUnitList} defaultUnitPrice={tabIdx===0 ? membershipRateDefault:nonMembershipRateDefault } reqGetDefaultPricingPlan={reqGetDefaultPricingPlan} reqGetChargeRateListOfStation={props.reqGetChargeRateListOfStation}/>}
            {initialModalOn&&<ChargingFeeInitialAlertModal setOnOff={setInitialModalOn} reset={reqPutStationPricingPlanForInitial}/>}
              <div className="cpo-charging-fee-management-inner">
                <div className="cpo-charging-fee-management-container">
                  <div>
                    {/* <div className="cpo-charging-fee-management-header">
                      <div className='cpo-charging-fee-management-more-info'>more info<MdInfoOutline onClick={()=>{setMoreInfoOn(!moreInfoOn)}}/></div>
                      {moreInfoOn&&<ChargingFeeMoreInfo/>}
                      <div className='cpo-charging-fee-management-title-container'>
                        <title>충전소 기본 충전요금 관리</title>
                        <p>(원 kWh, 부가세 포함)</p>
                      </div>
        
                    </div> */}
                    <div className='cpo-charging-fee-management-rate-header'>
                      <div className='cpo-charging-fee-management-tab-container'>
                        <div className={tabIdx === 0 ? "charging-fee-management-tab-button selected":"charging-fee-management-tab-button"} onClick={()=>{tabClick(0)}}><p>회원</p></div>
                        <div className={tabIdx === 1 ? "charging-fee-management-tab-button selected":"charging-fee-management-tab-button"} onClick={()=>{tabClick(1)}}><p>비회원</p></div>
                        <div className={'charging-fee-management-tab-bar '+'tab'+tabIdx}></div>
                      </div>
                      
                    </div>
                    <div>
                      <p className='cpo-charging-fee-management-content-title default'>기본요금 설정 
                        <color>{saveResultMsg}</color>
                      </p>
                      
                      <div className='cpo-charging-fee-management-input-wrapper'>
                        {
                          tabIdx === 0 ?
                            <>
                              <div className="cpo-setting-default-charging-rate-input-container">
                                <input type="text" placeholder='요금을 입력해 주세요' onInput={(e)=>{numberInput(e.target)}} value={membershipRate} onChange={(e)=>{setMembershipRate(e.target.value)}} />
                              </div>
                              <div className={`cpo-setting-default-charging-rate-input-save-button ${parseFloat(initMembershipRate) !== parseFloat(membershipRate) ?"":"none"}`} onClick={()=>{reqPatchStationPricingPlan();}}>
                                {loadingOn ? <Loading/>:"저장"}
                              </div>
                            </>
                          :
                            
                            <>
                              <div className="cpo-setting-default-charging-rate-input-container">
                                <input type="text" placeholder='요금을 입력해 주세요' onInput={(e)=>{numberInput(e.target)}} value={nonMembershipRate} onChange={(e)=>{setNonMembershipRate(e.target.value)}} />
                              </div>
                              <div className={`cpo-setting-default-charging-rate-input-save-button ${parseFloat(initNonMembershipRate) !== parseFloat(nonMembershipRate) ?"":"none"}`} onClick={()=>{reqPatchStationPricingPlan();}}>
                                {loadingOn ? <Loading/>:"저장"}
                              </div>
                            </>
                        }
                        
        
                      </div>
                    </div>
                    <div className='cpo-charging-fee-management-content-header'>
                      <p className='cpo-charging-fee-management-content-title'>시간별 설정 <color>설정되지 않은 셀은 기본요금이 적용됩니다.</color></p>
                      <div className='cpo-charging-fee-management-rate-setting-button-wrapper'> 
                        <div className='cpo-charging-fee-management-rate-initial-button' onClick={()=>{setInitialModalOn(true)}}><FaArrowRotateLeft/>초기화</div>
                        <div className='cpo-charging-fee-management-rate-setting-button' onClick={()=>{setDefaultModalOn(true)}}><p>시간별 요금 설정</p></div>
                      </div>
                    </div>
                    <div className='cpo-charging-fee-management-calendar-container'>
                      <div className='cpo-charging-fee-management-calendar-row'>
                        <div></div>
                        {hour.map((it, idx)=>{
                          return(<div>{`${it} - ${it+1}시`}</div>)
                        })}
                      </div>
                      <div>
                        <div className="cpo-charging-fee-management-calendar-column">
                          {month.map((it, idx)=>{
                            return(<div>{`${it}월`}</div>)
                          })}
                        </div>
                        <div className="cpo-charging-fee-management-calendar-table-container">
                          {/* {renderCells()} */}
                          {Array.from({ length: rows * cols }).map((_, index) => {
                            const cellData = cellState[index]; // 상태에서 셀 데이터를 가져옴
        
                            return (
                              <div
                                key={index}
                                ref={(el) => (cellRefs.current[index] = el)} // 각 셀의 참조 저장
                                className={`cell ${cellData?.className || ""}`} // 상태 기반 클래스 추가
                              >
                                {cellData?.unitPrice}
                                {cellData?.hasTooltip && <Tooltip border="2px solid var(--textRed1)" anchorSelect={`.cell.month-${cellData?.month}.hour-${cellData?.hour}`} className='tooltip' clickable>
                                  <div className='tooltip-content' onClick={()=>{reqPutStationPricingPlanForDelete(cellData?.month, cellData?.hour);}}>
                                    <div>
                                      <RiDeleteBin2Line/>
                                    </div>
                                    삭제하기
                                  </div>
                                </Tooltip>} {/* 조건에 따라 Toggle 컴포넌트 추가 */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
        
                  </div>
                </div>
                
              </div>
            </div>
      </div>

    </div>
  )
}
export default StationChargingFeeSettingModal;