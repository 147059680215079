import React, { useEffect, useState } from 'react';

import { Loading } from '../alerts/Loading';
import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";

export const ChargingFeeInitialAlertModal = (props) => {
  const [loadingOn, setLoadingOn] = useState(false);
  const reqReset = async()=>{
    setLoadingOn(true);
    await props.reset();
    setLoadingOn(false);
    props.setOnOff(false);
  }

  return (
    <div className="cpo-charger-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charger-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-delete-modal-header">
          <title>시간별 충전요금 초기화</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-charger-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='cpo-charger-delete-modal-msg'>시간별 설정을 초기화하시겠습니까?</p>
          <div className='cpo-charger-delete-modal-item'>설정된 모든 셀이 삭제됩니다.</div>
        </div>
        <div>
          <div className={'cpo-charger-modal-delete-button '} onClick={()=>{reqReset()}}>
            {loadingOn ? <Loading/> : <p>초기화</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChargingFeeInitialAlertModal;