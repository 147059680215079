import { configureStore, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// 🔹 Persist 설정
const persistConfig = {
  key: "user",
  storage,
};

// 🔹 유저 상태 관리 Slice 추가
const userSlice = createSlice({
  name: "user",
  initialState: { id: null, name: "", email: "", roles: "" },
  reducers: {
    setUser: (state, action) => {
      return action.payload; // 유저 정보 저장
    },
    clearUser: () => {
      return { id: null, name: "", email: "", roles: "" }; // 로그아웃 시 초기화
    },
  },
});

const persistedUserReducer = persistReducer(persistConfig, userSlice.reducer);

// 🔹 기존 페이지 상태 유지
const pageSlice = createSlice({
  name: "page",
  initialState: { num: { id: -1 } },
  reducers: {
    setNum(state, action) {
      state.num = action.payload;
    },
  },
});

// 🔹 Redux 스토어 설정
const store = configureStore({
  reducer: {
    page: pageSlice.reducer,
    user: persistedUserReducer, // 유저 상태 추가 (persist 적용)
  },
});

const persistor = persistStore(store);

// 🔹 Provider 포함된 Redux 설정 컴포넌트 추가
const ReduxProvider = ({ children }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
);

// 🔹 액션 내보내기
export const { setUser, clearUser } = userSlice.actions;
export const { setNum } = pageSlice.actions;

export { store, persistor, ReduxProvider };
export default store;
