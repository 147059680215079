import React, {useState} from 'react'
import { TfiClose } from "react-icons/tfi";
import { timestampFormat4 } from '../../util/util.js';
export const ChargingEventCard = (props) => {
  return (
    <div className='cpo-charging-event-management-event-item'>
      <TfiClose className='cpo-charging-event-management-event-item-delete-button' onClick={()=>{props.setDeleteModalOn(true); props.setEventEditTarget(props.data)}}/>
      <div className='cpo-charging-event-management-event-item-title'>{props.data.name}</div>
      <div className='cpo-charging-event-management-event-item-period'>{`${timestampFormat4(props.data.startDateTime)} - ${timestampFormat4(props.data.endDateTime)}`}</div>
      <div className='cpo-charging-event-management-event-item-rate-wrapper'>
        <div className="cpo-charging-event-management-event-item-rate">
          <div className='cpo-charging-event-management-event-item-rate-category'>급속</div>
          <div className='cpo-charging-event-management-event-item-rate-info'>
            <p>회원</p>
            <p>{`${props.data.memberUnitPrice}`}</p>
          </div>
          <div className='cpo-charging-event-management-event-item-rate-info'>
            <p>비회원</p>
            <p>{`${props.data.nonMemberUnitPrice}`}</p>
          </div>
        </div>
        <div className="cpo-charging-event-management-event-item-rate">
          <div className='cpo-charging-event-management-event-item-rate-category'>완속</div>
          <div className='cpo-charging-event-management-event-item-rate-info'>
            <p>회원</p>
            <p>{`${props.data.memberUnitPrice}`}</p>
          </div>
          <div className='cpo-charging-event-management-event-item-rate-info'>
            <p>비회원</p>
            <p>{`${props.data.nonMemberUnitPrice}`}</p>
          </div>
        </div>
      </div>
      <div className='cpo-charging-event-management-event-item-function-wrapper'>
        <div className='cpo-charging-event-management-event-item-function-button' onClick={()=>{props.setEventEditModalOn(true); props.setEventEditTarget(props.data)}}>
          이벤트 수정
        </div>
        <div className='cpo-charging-event-management-event-item-function-button' onClick={()=>{props.setChargerListModalOn(true); props.setCardIdx(props.idx)}}>
          충전소 목록 보기
        </div>
      </div>
    </div>
  )
}
export default ChargingEventCard;