import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";

import { TfiClose } from "react-icons/tfi";
import Selector2 from '../buttons/Selector2.js';
import AsyncSearchSelectorCompany from '../buttons/AsyncSearchSelectorCompany.js';


import { postCompany, postInfraConstructor, putInfraConstructor } from '../../api/infra/companyApi.js';

import { Loading } from '../alerts/Loading.js';

export const CpoInfraConstructorEnrollModal = (props) => {

  const [companyName, setCompanyName] = useState(null);
  const [remark, setRemark] = useState(null);
  // 기존 업체 label
  const [dataLabel, setDataLabel] = useState(null);

  const [errMsg, setErrMsg] = useState(null);

  const [tabIdx, setTabIdx] = useState(0);
  const [category, setCategory] = useState(null);


  const [newCompanyData, setNewCompanyData] = useState(null);

  const reqPostCompany = async()=>{
    setLoadingOn(true);
    let result = await postCompany({name:companyName});
    if(result.status === 200 || result.status === 201){
      setNewCompanyData({Id: result.data, Name:companyName});
      setTabIdx(1);
    }
    else if(result.data.ErrorCode === 'ALREADY_COMPANY_NAME_EXIST'){
      setErrMsg('이미 존재하는 업체입니다.');
    }
    else{
      
      console.log('등록 실패');
    }
    setLoadingOn(false);
  }

  const reqPostInfraConstructor = async()=>{
    setLoadingOn(true);
    let tempCompanyCategory = "";
    if(checkCompanyCategory.winch){
      tempCompanyCategory += "winch;";
    }
    if(checkCompanyCategory.charger){
      tempCompanyCategory += "charger;";
    }
    if(checkCompanyCategory.canopy){
      tempCompanyCategory += "canopy;";
    }
    let result = await postInfraConstructor({companySysId:newCompanyData.Id, specializations:tempCompanyCategory, remarks:remark});
    if(result.status === 200 || result.status === 201){
      props.setOnOff(false);
      props.reqGetInfraConstructorList(1, {"specialization":"all"});
      props.setTabIdx(0);
    }
    else{
      console.log('등록 실패');
    }
    setLoadingOn(false);    
  }

  const reqPutInfraConstructor = async()=>{
    setLoadingOn(true);
    let tempCompanyCategory = "";
    if(checkCompanyCategory.winch){
      tempCompanyCategory += "winch;";
    }
    if(checkCompanyCategory.charger){
      tempCompanyCategory += "charger;";
    }
    if(checkCompanyCategory.canopy){
      tempCompanyCategory += "canopy;";
    }
    let result = await putInfraConstructor({infraConstructorSysId:dataLabel.infraConstructorSysId, companySysId:dataLabel.companySysId, specializations:tempCompanyCategory, remarks:remark});
    if(result.status === 200 || result.status === 201){
      props.setOnOff(false);
      props.reqGetInfraConstructorList(1, {"specialization":"all"});
      props.setTabIdx(0);
    }
    else{
      console.log('등록 실패');
    }
    setLoadingOn(false);
  }  

  const [loadingOn, setLoadingOn] = useState(false);


  const [checkCompanyCategory, setCheckCompanyCategory] = useState({
    winch: false,
    charger: false,
    canopy: false,
  });
  const onCheckCompanyCategory = (e)=>{
    setCheckCompanyCategory({...checkCompanyCategory, [e.target.name]:!checkCompanyCategory[e.target.name]});
  }

  useEffect(()=>{
    if(tabIdx === 2 && category===1){
      setCheckCompanyCategory({
        winch: false,
        charger: false,
        canopy: false,
      });
      setRemark(null);
    }
  },[tabIdx])

  useEffect(()=>{
    if(errMsg){
      setTimeout(()=>{
        setErrMsg(null);
      },3000);
    }
  },[errMsg])
  


  useEffect(()=>{
    if(dataLabel && companyNameRef.current){
      companyNameRef.current.value = null;
    }
  },[dataLabel])

  useEffect(()=>{
    if(companyName){
      setDataLabel(null);
    }
  },[companyName])

  const companyNameRef = useRef(null);

  return (
    <div className="cpo-infra-constructor-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-infra-constructor-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-infra-constructor-enroll-modal-header">
          <title>업체 등록</title>
          <div className='cpo-infra-constructor-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);props.reqGetInfraConstructorList(1, {"specialization":"all"});}}><TfiClose/></div>
        </div>
        <div className="cpo-infra-constructor-enroll-modal-content">
          {
            tabIdx === 0 ?
              <>
                <div className="cpo-infra-constructor-enroll-modal-content-header">
                  <p className="cpo-infra-constructor-enroll-modal-content-title">신규 업체 등록</p>
                </div>
                <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                  <p>업체 이름</p>
                  <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                    <input type="text" placeholder='업체 이름을 입력해주세요' ref={companyNameRef} value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}} />
                  </div>
                </div>
                <div className="cpo-infra-constructor-enroll-modal-content-header">
                  <p className="cpo-infra-constructor-enroll-modal-content-title">기등록 업체 선택</p>
                </div>
                <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                  <AsyncSearchSelectorCompany placeholder="업체를 선택하세요" value={dataLabel} setValue={setDataLabel}/>
                </div>
                <div className='cpo-infra-constructor-enroll-modal-button-container'>
                  <div className="cpo-infra-constructor-enroll-modal-button"  onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div>
                  {errMsg && <p className='cpo-infra-constructor-enroll-modal-err-msg'>{errMsg}</p>}
                  <div className={`cpo-infra-constructor-enroll-modal-button ${!companyName && !dataLabel?.label ? "disabled":""}`}  onClick={()=>{if(companyName){reqPostCompany();}else if(dataLabel){setNewCompanyData({Id:dataLabel.id, Name:dataLabel.label}); setTabIdx(1);} }}>
                    {loadingOn ? <Loading/>:<>다음</>}
                  </div>
                </div>
              </>
            :
            tabIdx === 1 ? // 업체 등록
                <>
                  <div className="cpo-infra-constructor-enroll-modal-content-header">
                    <p className="cpo-infra-constructor-enroll-modal-content-title">신규 업체 등록</p>
                  </div>

                  <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                    <p onClick={()=>{console.log(newCompanyData);}}>업체 이름</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text" placeholder='업체 이름을 입력해주세요' value={newCompanyData?.Name} readOnly/>
                    </div>
                  </div>
                  <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                    <p>업종</p>
                    <div className="cpo-infra-constructor-enroll-modal-checkbox-container">
                      {/* <Selector2 name={'name'} dataList={companyCategoryList} select={companyCategory} setSelect={setCompanyCategory} active={companyCategoryActive} setActive={setCompanyCategoryActive} activeReset={activeReset}/> */}
                      <div>
                        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                          <input className={`cpo-infra-constructor-check `} type="checkbox" name='winch' checked={checkCompanyCategory.winch} onChange={onCheckCompanyCategory} />
                          <span className="checkbox_icon"></span>
                        </label>
                        <p>윈치 설치</p>
                      </div>
                      <div>
                        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                          <input className={`cpo-infra-constructor-check `} type="checkbox" name='charger'  checked={checkCompanyCategory.charger} onChange={onCheckCompanyCategory} />
                          <span className="checkbox_icon"></span>
                        </label>
                        <p>충전기 설치</p>
                      </div>
                      <div>
                        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                          <input className={`cpo-infra-constructor-check `} type="checkbox" name='canopy' checked={checkCompanyCategory.canopy} onChange={onCheckCompanyCategory} />
                          <span className="checkbox_icon"></span>
                        </label>
                        <p>캐노피 설치</p>
                      </div>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>비고</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='비고' value={remark} onChange={(e)=>{setRemark(e.target.value)}}/>
                    </div>
                  </div>
                  <div className='cpo-infra-constructor-enroll-modal-button-container'>
                    {/* <div className="cpo-infra-constructor-enroll-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                    <div></div>
                    <div className={`cpo-infra-constructor-enroll-modal-button ${!remark || !(checkCompanyCategory.winch||checkCompanyCategory.charger||checkCompanyCategory.canopy)? "disabled":""}`} onClick={()=>{reqPostInfraConstructor()}}>등록하기</div>
                  </div>
                </>
            :
            tabIdx === 2 ? // 업체 수정
                <>
                  <div className="cpo-infra-constructor-enroll-modal-content-header">
                    <p className="cpo-infra-constructor-enroll-modal-content-title">기존 업체 수정</p>
                  </div>
                  <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                    <p onClick={()=>{console.log(checkCompanyCategory)}}>업체</p>
                    <AsyncSearchSelectorCompany placeholder="업체를 선택하세요" value={dataLabel} setValue={setDataLabel}/>
                  </div>
                  <div className='cpo-infra-constructor-enroll-modal-content-wrapper'>
                    <p onClick={()=>{console.log(dataLabel);}}>업종</p>
                    <div className="cpo-infra-constructor-enroll-modal-checkbox-container">
                      {/* <Selector2 name={'name'} dataList={companyCategoryList} select={companyCategory} setSelect={setCompanyCategory} active={companyCategoryActive} setActive={setCompanyCategoryActive} activeReset={activeReset}/> */}
                      <div>
                        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                          <input className={`cpo-infra-constructor-check `} type="checkbox" name='winch' checked={checkCompanyCategory.winch} onChange={onCheckCompanyCategory} />
                          <span className="checkbox_icon"></span>
                        </label>
                        <p>윈치 설치</p>
                      </div>
                      <div>
                        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                          <input className={`cpo-infra-constructor-check `} type="checkbox" name='charger'  checked={checkCompanyCategory.charger} onChange={onCheckCompanyCategory} />
                          <span className="checkbox_icon"></span>
                        </label>
                        <p>충전기 설치</p>
                      </div>
                      <div>
                        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                          <input className={`cpo-infra-constructor-check `} type="checkbox" name='canopy' checked={checkCompanyCategory.canopy} onChange={onCheckCompanyCategory} />
                          <span className="checkbox_icon"></span>
                        </label>
                        <p>캐노피 설치</p>
                      </div>
                    </div>
                  </div>
                  <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
                    <p>비고</p>
                    <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                      <input type="text"  placeholder='비고' value={remark} onChange={(e)=>{setRemark(e.target.value)}}/>
                    </div>
                  </div>
                  <div className='cpo-infra-constructor-enroll-modal-button-container'>
                    <div className="cpo-infra-constructor-enroll-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div>
                    <div className={`cpo-infra-constructor-enroll-modal-button ${!dataLabel || !remark || !(checkCompanyCategory.winch||checkCompanyCategory.charger||checkCompanyCategory.canopy)? "disabled":""} `} onClick={()=>{reqPutInfraConstructor()}}>수정하기</div>
                  </div>
                </>
              : 
            <></>
          }
          
          {/* <div>
            <div className="cpo-infra-constructor-enroll-modal-content-header">
              <p className="cpo-infra-constructor-enroll-modal-content-title">담당자 정보</p>
              <p className='cpo-infra-constructor-enroll-modal-content-desc'>등록 이후에도 담당자 추가/수정이 가능합니다</p>
            </div>
            <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
              <p>이름</p>
              <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                <input type="text"  placeholder='이름' value={name} onChange={(e)=>{setName(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-infra-constructor-enroll-modal-content-wrapper">
              <p>휴대전화번호</p>
              <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                <input type="text"  placeholder='휴대전화번호' value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-infra-constructor-enroll-modal-content-wrapper divide">
              <div>
                <p>부서 이름</p>
                <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                  <input type="text"  placeholder='부서 이름' value={division} onChange={(e)=>{setDivision(e.target.value)}}/>
                </div>
              </div>
              <div>
                <p>직책</p>
                <div className="cpo-infra-constructor-enroll-modal-content-input-container">
                  <input type="text"  placeholder='직책' value={role} onChange={(e)=>{setRole(e.target.value)}}/>
                </div>
              </div>
            </div>
          </div> */}

        </div>
        
      </div>
    </div>
  )
}
export default CpoInfraConstructorEnrollModal
