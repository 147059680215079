import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"

import {setNum} from '../../store.js'
import { IoBusinessSharp } from "react-icons/io5";
import { HiCursorClick } from "react-icons/hi";

import {getSubCpoList} from '../../api/account/accountApi.js'

import '../../styles/label/LabelSubCpoStationManagement.css'

import SubCpoInfraGridView from '../../views/SubCpoInfraManagement/pages/SubCpoInfraGridView.js'

export const CpoSubStationManagement = () => {
  // redux
    let dispatch = useDispatch();
    const user = useSelector(state => state.user);
    // 진입 시 redux에 page 적용
    useEffect(()=>{
      dispatch(setNum({id:2, idx:1}));
    },[])

    // 선택된 하위 CPO
    const [selectedCpo, setSelectedCpo] = useState(null);
    // 하위 CPO 리스트
    const [subCpoList, setSubCpoList] = useState(null);

    // 하위 CPO 리스트 요청
    const requestSubCpoList = async()=>{
      let res = await getSubCpoList(user.cpoId);
      if(res.status===200){
        setSubCpoList(res.data);
      }
    }

    useEffect(()=>{
      requestSubCpoList();
    },[])

  return (
    <div className="sub-cpo-station-management-container">
      <div className="sub-cpo-station-management-inner">
      <div className="sub-cpo-station-management-left-container">
        <div className="sub-cpo-station-management-left-inner">
          <div className="sub-cpo-station-management-header">
            <p>위탁사업자 목록</p>
          </div>
          <div className="sub-cpo-station-management-left-list">
            {
              subCpoList&&subCpoList.map((item, idx)=>{
                return(
                  <div className={`sub-cpo-station-management-sub-cpo-item ${selectedCpo?.CpoId === item.CpoId ? "selected" : ""}`} onClick={()=>{selectedCpo?.CpoId === item.CpoId ? setSelectedCpo(null) : setSelectedCpo(item)}}>
                    <p>{item.Name}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className={`sub-cpo-station-management-right-container ${selectedCpo?"":"none"}`}>
        <>
          {
            !selectedCpo?
            <div className='sub-cpo-station-management-before-select'>
              <div>
                <IoBusinessSharp/>
                <HiCursorClick/>
              </div>
              위탁사업자를 선택해주세요.
            </div>
            :
            <>
              <SubCpoInfraGridView selectedCpo={selectedCpo}/>
            </>
          }
        </>
      </div>
      </div>
    </div>
  )
}
export default CpoSubStationManagement;