import React, { useState, useEffect } from 'react';

import { FaChevronDown } from "react-icons/fa6";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

export const CpoFirmwareListItem = (props) => {
  
  const [tabOpen, setTabOpen] = useState(false);

  const [checked, setChecked] = useState(false);
    
    useEffect(()=>{
      // 리스트에서 인덱스 찾기
      let itemIdx = props.checkList.indexOf(props.idx);
      // check true인 경우
      if(checked){
        if(itemIdx !== -1){
          return;
        }
        if(props.settingMode === "edit"){
          props.setCheckList([props.idx]);
        }
        else{
          props.setCheckList([...props.checkList, props.idx]);
        }
      }
      else{
        // false 인 경우
        
        // 만약 없는 경우
        if(itemIdx === -1){
          return;
        }
        // 있는 경우
        else{
          let temp = [...props.checkList];
          temp.splice(itemIdx,1);
          props.setCheckList(temp);
        }
      }
    },[checked])
  
    useEffect(()=>{
      let itemIdx = props.checkList.indexOf(props.idx);
      if(itemIdx !== -1 && !checked){
        setChecked(true);
      }
      else if(itemIdx === -1 && checked){
        setChecked(false);
      }
    },[props.checkList])


  return (
    <div className={'cpo-firmware-list-item-container '+`${tabOpen ? "open":""}`}>
      <div className={`cpo-firmware-list-item ${props.settingMode?"edit":""} ${checked?"selected":""}`} onClick={()=>{setTabOpen(!tabOpen)}}>
      {
          props.settingMode!==null &&
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
        }
        <div><p>{props.data?.name}</p></div>
        <div><p>{props.data?.description}</p></div>
        <div></div>
        <div></div>
        {/* <div><p className='cpo-system-chas-detail-button'>자세히보기</p></div> */}
        <div><p>{tabOpen ? "닫기 ":"펼치기 "}</p><FaChevronDown/></div>
      </div>
      <div className='cpo-firmware-list-item-content'>
        <div className="cpo-firmware-version-list-container">
          <div className="cpo-firmware-version-list-item-column">
            <div></div>
            <div>버전</div>
            <div>파일명</div>
            <div>설명</div>
            
            <div className='version-count'>Total : {props.data?.versions?.length}</div>
            <div>
              <div className='cpo-firmware-version-append-button' onClick={()=>{props.setSelectedFirmwareIdx(props.idx); props.setVersionEditMode("enroll"); props.setModalOpen(true);}}>
                버전 추가
              </div>
            </div>
            
          </div>
          <div className="cpo-firmware-version-list-item-container">
            {
              props.data?.versions?.map((it, idx)=>{
                return(
                  <>
                    <div className="cpo-firmware-version-list-item">
                      <div>{idx+1}</div>
                      <div>{it.version}</div>
                      <div>{it.fileName}</div>
                      <div>{it.description}</div>
                      <div className='cpo-firmware-version-list-function edit' onClick={()=>{props.setSelectedFirmwareIdx(props.idx);props.setSelectedVersionIdx(idx); props.setVersionEditMode("edit"); props.setModalOpen(true)}}>
                        <FiEdit3/>
                      </div>
                      <div className='cpo-firmware-version-list-function delete' onClick={()=>{props.setSelectedFirmwareIdx(props.idx);props.setSelectedVersionIdx(idx); props.setDeleteModalOpen(true)}}>
                        <FaRegTrashAlt/>
                      </div>
                    </div>
                    
                  </>
                )
              })
            }
          </div>          
        </div>

      </div>
    </div>
  )
}
export default CpoFirmwareListItem;