import React from 'react';

export const Blocker = (props) => {
  return (
    <>
      <div className="cpo-blocker-alert-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.blocker.reset()}}>
        <div className="cpo-blocker-alert-container">
          <div className="cpo-blocker-alert-logo-container">
            <img className='nav-logo' alt="" src={process.env.REACT_APP_LOGO}/>
          </div>
          <div className="cpo-blocker-alert-content-container">
            <p>{'글쓰기를 그만두시겠습니까?'}</p>
            <pre>{`나가기 버튼을 누르면 저장되지 않습니다.\n저장하고 싶다면 취소를 누른뒤 양식을 모두 채우고\n등록버튼을 눌러주세요.`}</pre>
          </div>
          <div className="cpo-blocker-alert-button-container">
            <div onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.blocker.proceed()}}>나가기</div>
            <div onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.blocker.reset()}}>취소</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Blocker;