import React, { useEffect } from 'react';


import { TfiClose } from "react-icons/tfi";
import { CiWarning } from "react-icons/ci";

import { deleteInfraConstructor } from '../../api/infra/companyApi.js';
export const CpoInfraConstructorDeleteModal = (props) => {


  

  const reqDeleteInfraConstructor = async()=>{
    for await(const infraConstructorIdx of props.checkedItemList){
      let tempInfraConstructor = props.infraConstructorList[infraConstructorIdx];
      await deleteInfraConstructor(tempInfraConstructor.InfraConstructorSysId);
    }
    props.setOnOff(false);
    props.setTabIdx(0);
    await props.reqGetInfraConstructorList(1,{"specialization": "all"});
    props.setCheckedItemList([]);
  }

  return (
    <div className="cpo-charger-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-charger-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-delete-modal-header">
          <title>업체 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='cpo-charger-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='cpo-charger-delete-modal-msg'>{props.checkedItemList?.length}개의 업체를 삭제하시겠습니까?</p>
          <div className='cpo-charger-delete-modal-list-wrapper'>
            {
              props.checkedItemList && props.infraConstructorList && props.checkedItemList?.map((it, idx)=>{
                return(
                  <>
                    <div className='cpo-charger-delete-modal-item'>{props.infraConstructorList[it].CompanyName}</div>

                  </>
                )
              })
            }
          </div>
        </div>
        <div>
          <div className={'cpo-charger-modal-delete-button '} onClick={()=>{reqDeleteInfraConstructor()}}><p>삭제</p></div>
        </div>
      </div>
    </div>
  )
}
export default CpoInfraConstructorDeleteModal

