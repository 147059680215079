import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'

// import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";

import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

import { LoadingList } from '../../components/alerts/LoadingList.js';
import CpoChargerModelListItem from '../../components/list/CpoChargerModelListItem.js';
import CpoChargerModelEnrollModal from '../../components/modals/CpoChargerModelEnrollModal.js';
import ChargerModelDeleteModal from '../../components/modals/ChargerModelDeleteModal.js';
import { getChargerModelList  } from '../../api/infra/infraApi.js';

const CpoChargerModelManagement = (props) => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:5}));
  },[])

  
  const [searchKeyword, setSearchKeyword] = useState();

  const [chargerModelList, setChargerModelList] = useState();
  const [chargerModelCount, setChargerModelCount] = useState(0);
  useEffect(()=>{
    reqGetChargerModelList();
  },[])

  const reqGetChargerModelList = async(page=pageNum, data)=>{
    setLoadingListOn(true);
    let result = await getChargerModelList(page, data);
    if(result.status === 200){
      let temp = [...result.data.Results];
      setChargerModelList(temp);
      setChargerModelCount(result.data.Total);
    }
    setLoadingListOn(false);
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])


  const [checked, setChecked] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState([]);
  
  // checked state (모든 아이템 check)
  useEffect(()=>{
    if(checked){
      let temp = new Array(chargerModelList.length).fill(0).map((_, i) => i)
      setCheckedItemList(temp);
    }
    else{
      setCheckedItemList([]);
    }
  },[checked])








  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(chargerModelCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetChargerModelList(pageNum,{"keyword": searchKeyword});
    }
  },[pageNum])


  const [loadingListOn, setLoadingListOn] = useState(false);

  const handleKeyPress = async(e) => {
    if (e.key === 'Enter') {
      reqSearchKeyword();
    }
  };
  const reqSearchKeyword = async()=>{
    setLoadingListOn(true);
      if(pageNum !== 1) setPageNum(1);
      else await reqGetChargerModelList(1,{"keyword": searchKeyword});
      setLoadingListOn(false);
  }


  return (
    <div className='cpo-charger-model-management-container'>
      {modalOpen&&<CpoChargerModelEnrollModal getChargerModelList={reqGetChargerModelList} setOnOff={setModalOpen} editMode={editMode} editData={chargerModelList[checkedItemList[0]]} setCheckedItemList={setCheckedItemList} setEditMode={setEditMode}/>}
      {deleteModalOpen&&<ChargerModelDeleteModal chargerModelList={chargerModelList} reqGetChargerModelList={reqGetChargerModelList} setOnOff={setDeleteModalOpen} checkedItemList={checkedItemList} setCheckedItemList={setCheckedItemList} setDeleteMode={setDeleteMode}/>}
      <div className="cpo-charger-model-management-inner">
        <div className={"cpo-charger-model-management-content-container "}>
          <div className="cpo-charger-model-management-content-header">
            <div className='cpo-charger-model-management-content-search-container'>

              {/* <div className="cpo-charger-model-management-content-search-filter-button">검색필터<PiSlidersHorizontal/></div> */}
              <div className="cpo-station-management-search-input-container">
                <input type="text" placeholder={'모델명으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}} onKeyDown={handleKeyPress}/>
                <FaSearch onClick={()=>{reqSearchKeyword()}}/>
              </div>
            </div>
            <div className='cpo-charger-model-management-content-function-button-container'>
              {!deleteMode&&!editMode&&<div className='cpo-charger-model-management-content-function-button enroll' onClick={()=>{setModalOpen(true);}}>충전기 모델 등록</div>}
              {editMode&&<div className='cpo-charger-model-function-info-msg'>수정할 충전기 모델을 체크한 후 수정 버튼을 눌러주세요.</div>}
              {deleteMode&&<div className='cpo-charger-model-function-info-msg delete'>삭제할 충전기 모델을 모두 체크한 후 수정 버튼을 눌러주세요.</div>}
              {!deleteMode && <div className={`cpo-charger-model-management-content-function-button edit ${editMode&&checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{editMode ? setModalOpen(true) : setEditMode(true)}}>수정</div>}
              {!editMode &&<div className={`cpo-charger-model-management-content-function-button delete ${deleteMode&&checkedItemList.length===0 ? "disabled":""}`} onClick={()=>{deleteMode ? setDeleteModalOpen(true) : setDeleteMode(true)}}>삭제</div>}
              {(editMode || deleteMode) && <div className='cpo-charger-model-management-content-function-button cancel' onClick={()=>{setCheckedItemList([]); editMode ? setEditMode(false) : setDeleteMode(false)}}>취소</div>}
            </div>
          </div>
          <div className={'cpo-charger-model-management-item-list-grid-container '}>
            
              <div className="cpo-charger-model-management-content-item-list-container">
                {
                  !(editMode || deleteMode) ?
                  <div className="cpo-charger-model-management-content-item-column tab0">
                    <div>제조사</div>
                    <div>모델명</div>
                    <div>종류</div>
                    <div>충전속도</div>
                    <div>출력</div>
                    <div>전압</div>
                    <div>전류</div>
                    <div>동시충전 지원</div>
                    <div>중고여부</div>
                    <div>등록년월</div>
                    <div></div>
                  </div>
                  :
                  <div className="cpo-charger-model-management-content-item-column tab1">
                    <div>
                      {
                        deleteMode ?
                        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                          <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                          <span className="checkbox_icon"></span>
                        </label>
                        :
                        <div></div>
                      }
                    </div>
                    <div>제조사</div>
                    <div>모델명</div>
                    <div>종류</div>
                    <div>충전속도</div>
                    <div>출력</div>
                    <div>전압</div>
                    <div>전류</div>
                    <div>동시충전 지원</div>
                    <div>중고여부</div>
                    <div>등록년월</div>
                    <div></div>
                  </div>
                }
                
                {
                  loadingListOn ?
                  <LoadingList width={"500px"} height={"500px"}/>
                  :
                  <>
                  {
                    chargerModelList && chargerModelList.map((it, idx)=>{
                      return(
                        <>
                          <CpoChargerModelListItem data={it} checkMode={deleteMode || editMode} idx={idx} checkList={checkedItemList} setCheckList={setCheckedItemList} settingMode={editMode ? "edit": ""}/>
                        </>
                      );
                    })
                  }
                  </>
                }
                <div className='page-selector-container'>
                  {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
                  {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
                  {pageSelectorRendering()}
                  {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
                  {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
                  <div className='page-total-info'>{`Total : ${chargerModelCount}`}</div>
                </div>
              </div>
              
            </div>
          </div>
      </div>
    </div>
  )
}
export default CpoChargerModelManagement;
