import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import GridViewStationItem from './GridViewStationItem.js';
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";
import { FaSearch } from 'react-icons/fa';

import { getChargerByStationId, getStationListCustom } from '../../../api/infra/infraApi.js';
import {getChargerHubListByChargerId} from '../../../api/controlhub/controlhubApi.js';
import { getRoamingChargingStationByEmoovId } from '../../../api/roaming/roamingApi.js';
import { LoadingList } from '../../../components/alerts/LoadingList.js';

import { useLocation } from 'react-router-dom';

export const StationListForCharger = (props) => {
  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  const [stationCount, setStationCount]=useState(null);

  // 충전기 infra 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);
  // 로밍 충전소 데이터 Array<Object>
  const [roamingStationInfo, setRoamingStationInfo]=useState(null);
  
  useEffect(()=>{
    // if(localStorage.getItem('selectedStationCM')){
    //   setSearchKeyword(localStorage.getItem('selectedStationCM'));
    //   reqGetStationList(1, localStorage.getItem('selectedStationCM') ? {keyword :localStorage.getItem('selectedStationCM')} : null);
    // }
    // else{
    // }
    reqGetStationList();
  },[])
  

  const reqGetStationList = async(page=pageNum, data)=>{
    setLoadingListOn(true);
    let result = await getStationListCustom(page, data);
    if(result.status === 200){
      let temp = [...result.data.Results].filter(it=>it.OpStatus !== "삭제됨");
      setStationInfo(temp);
      setStationCount(result.data.Total);
    }
  }

  // station에 종속된 charger
  const reqChargerList = async()=>{
    let result =[];
    for await(const station of stationInfo){
      let tempChargerList = await getChargerByStationId(station.ChargingStationId);
      result.push(tempChargerList.data);
    }
    setChargerInfo(result);
  }
  
  const reqChargerHubList = async()=>{
    let result = [];
    for await(const chargerList of chargerInfo){
      let tempList = [];
      for await(const charger of chargerList.Results){
        let result = await getChargerHubListByChargerId(charger.ChargerId)
        if(result.status === 200){
          let data = result.data;
          tempList.push(data);
        }
      }
      result.push(tempList);
    }
    setChargerHubInfo(result);
    setLoadingListOn(false);
  }

  const reqGetRoamingChargingStationList = async()=>{
      let result = [];
      for await(const station of stationInfo){
        let res = await getRoamingChargingStationByEmoovId(station.ChargingStationId);
        if(res.status === 200){
          result.push(res.data);
        }
        else{
          result.push(null);
        }
      }
      setRoamingStationInfo(result);
    }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    reqChargerList();
    reqGetRoamingChargingStationList();
  }, [stationInfo])

  useEffect(()=>{
    if(!chargerInfo)return;
    reqChargerHubList();
  },[chargerInfo])

  // station list Ref[]
  const stationRef = useRef([]);
  
  // 클릭한 station의 idx state
  const [clickedStation, setClickedStation] = useState(null);


  useEffect(()=>{
    if(clickedStation !== null){
      console.log(clickedStation);
      props.setClickedStation(stationInfo[clickedStation]);
    }
  },[clickedStation])



  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(stationCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    // if(localStorage.getItem('selectedStationCM'))return;
    if(pageNum){
      reqGetStationList(pageNum, {"keyword": searchKeyword});
    }
  },[pageNum])



  const handleKeyPress = async(e) => {
    if (e.key === 'Enter') {
      reqSearchKeyword();
    }
  };
  const reqSearchKeyword = async()=>{
    setLoadingListOn(true);
      if(pageNum !== 1) setPageNum(1);
      else await reqGetStationList(1, {"keyword": searchKeyword});
      setLoadingListOn(false);
  }

  const [searchKeyword, setSearchKeyword] = useState('');
  const [loadingListOn, setLoadingListOn] = useState(false);
  
  return (
    <>
      <div className='charger-management-grid-view-search-container'>
        <div className="charger-management-grid-view-search-input-container">
          <input type="text" placeholder={'충전소 이름으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}} onKeyDown={handleKeyPress}/>
          <FaSearch onClick={()=>{reqSearchKeyword()}}/>
        </div>  
      </div>

      <div className={'charger-management-grid-view-station-list-container' }>
        <div className="charger-management-grid-view-station-list">
          <div className="charger-management-grid-view-station-list-column">
            <div className='charger-management-grid-view-station-list-column-item'><p>권역</p></div>
            <div className='charger-management-grid-view-station-list-column-item'><p>충전소명</p></div>
            <div className='charger-management-grid-view-station-list-column-item'><p>주소</p></div>
            <div className='charger-management-grid-view-station-list-column-item'><p>로밍</p></div>
            <div className='charger-management-grid-view-station-list-column-item'><p>환경부 ID</p></div>
            <div className='charger-management-grid-view-station-list-column-item centerAlign'><p>운용대수</p></div>
            <div className='charger-management-grid-view-station-list-column-item centerAlign'><p>충전기 총 대수</p></div>
          </div>
          <div className='charger-management-grid-view-list-content'>
            {
            loadingListOn? <LoadingList height={"500px"}/>:
            <>
            {
              stationInfo&&chargerHubInfo&&roamingStationInfo&&(stationInfo.map((it,idx)=>{
                if(it.OpStatus === "삭제됨")return<></>;
                return(<GridViewStationItem key={'stationItem-'+idx} totalCount={chargerInfo[idx]?.Total} roamingStationInfo={roamingStationInfo[idx]} chargerHubInfo={chargerHubInfo[idx]} clickedStation={clickedStation} setClickedStation={setClickedStation} stationRef={stationRef} idx={idx}  data={it} />)
              }))
            }
            </>
            }
          </div>
          <div className='page-selector-container'>
            {<CgPushChevronLeft onClick={()=>{movePage(1);}} className={`skip ${pageNum===1?"disabled":""}`}/>}
            {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1);}} className={`${pageNum>5?"":"disabled"}`}/>}
            {pageSelectorRendering()}
            {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2);}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
            {<CgPushChevronRight onClick={()=>{movePage(pageCount);}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
            <div className='page-total-info'>{`Total : ${stationCount}`}</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default StationListForCharger;