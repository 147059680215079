import React, { useRef, useState, useEffect } from 'react';

import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";

import { deleteFaq } from '../../api/cs/csApi';
import { Loading } from '../alerts/Loading';

export const FaqDeleteModal = (props) => {
  const [loadingOn, setLoadingOn] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  useEffect(()=>{
    if(errMsg){
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  },[errMsg])


  const reqDeleteFaq = async()=>{
    setLoadingOn(true);
    let result = await deleteFaq(props.postId);
    if(result.status === 200){
      props.reqGetFaqList(props.cpoMemberId);
      props.setOnOff(false);
    }
    else{
      setErrMsg("FAQ 삭제에 실패하였습니다");
    }
    setLoadingOn(false);
  }

  return (
    <div className="faq-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="faq-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="faq-delete-modal-header">
          <title>FAQ 삭제</title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className='faq-delete-modal-list-container'>
          <div className='cpo-alert-msg-modal-delete-alert'>
            <CiWarning/>
          </div>
          <p className='faq-delete-modal-msg'>해당 FAQ를 삭제하시겠습니까?</p>
        </div>
        <div>
          <div className={'faq-delete-modal-delete-button '} onClick={()=>{reqDeleteFaq()}}>
            {loadingOn ? <Loading/>:<p>삭제</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default FaqDeleteModal
