import React, { useRef, useState, useEffect } from 'react';
import { FaCheck } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";
import {PiSlidersHorizontal} from "react-icons/pi";
import ChargingFeeSearchModal from './ChargingFeeSearchModal';

import { numberInput } from '../../util/util';
import { postPricingEvent, putPricingEvent } from '../../api/chargerate/chargerateApi';

import { getStationListCustom } from '../../api/infra/infraApi.js';

import EventSettingStationItem from '../list/EventSettingStationItem.js';
import { timestampFormat5 } from '../../util/util.js';
import { Loading } from '../alerts/Loading.js';
import { LoadingList } from '../alerts/LoadingList.js';
export const ChargingFeeSettingModal = (props) => {
  const [searchKeyword, setSearchKeyword] = useState();
  const [alertOn, setAlertOn] = useState(false);

  useEffect(()=>{
    if(alertOn){
      setTimeout(() => {
        setAlertOn(false)
      }, 1300);
    }
  },[alertOn])

  const [eventName, setEventName] = useState(props.edit ? props.eventData.name : null);
  const [membershipRate, setMembershipRate] = useState(props.edit ? props.eventData.memberUnitPrice : null);
  const [nonMembershipRate, setNonMembershipRate] = useState(props.edit ? props.eventData.nonMemberUnitPrice : null);
  const [searchModalOn, setSearchModalOn] = useState(false);
  const [startDateTime, setStartDateTime] = useState(props.edit ? timestampFormat5(new Date(props.eventData.startDateTime), 'yyyy-MM-dd') : null);
  const [endDateTime, setEndDateTime] = useState(props.edit ? timestampFormat5(new Date(props.eventData.endDateTime), 'yyyy-MM-dd') : null);

  

  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  const [stationCount, setStationCount]=useState(null);


  useEffect(()=>{
    reqGetStationList();
  },[])
  

  const reqGetStationList = async(page=pageNum, data)=>{
    setLoadingListOn(true);
    let result = await getStationListCustom(page, data);
    if(result.status === 200){
      let temp = [...result.data.Results].filter(it=>it.OpStatus !== "삭제됨");
      setStationInfo(temp);
      setStationCount(result.data.Total);
    }
    setLoadingListOn(false);
  }

  const reqPostPricingEvent = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "eventName": eventName,
      "memberPrice": membershipRate,
      "nonMemberPrice": nonMembershipRate,
      "startDateTime": new Date(startDateTime).toISOString(),
      "endDateTime": new Date(endDateTime).toISOString(),
      "stationIds": checkStationList
    };
    let result = await postPricingEvent(reqObj);
    if(result.status === 200){
      await props.reqGetAllPricingEvent();
      props.setOnOff(false);
    }
    else{
      setErrMsg("이벤트요금 추가에 실패하였습니다.")
    }
    setLoadingOn(false);
  }
  
  const reqPutPricingEvent = async()=>{
    setLoadingOn(true);
    let reqObj = {
      "eventName": eventName,
      "memberPrice": membershipRate,
      "nonMemberPrice": nonMembershipRate,
      "startDateTime": new Date(startDateTime).toISOString(),
      "endDateTime": new Date(endDateTime).toISOString(),
      "stationIds": checkStationList
    };
    let result = await putPricingEvent(props.eventData.pricingEventId, reqObj);
    if(result.status === 200){
      await props.reqGetAllPricingEvent();
      props.setOnOff(false);
    }
    else{
      setErrMsg("이벤트요금 수정에 실패하였습니다.")
    }
    setLoadingOn(false);
  }

  // check한 station list
  const [checkStationList, setCheckStationList] = useState(props.edit ? [...props.eventData.chargingStations.map(it=>it.chargingStationId)] :[]);
  
  // 현재 보이는 리스트 전체 선택
  const checkAll = () => {
    let temp = [...stationInfo.map((it, idx) => it.ChargingStationId)];
    setCheckStationList(prevList => {
      const combinedList = [...new Set([...prevList, ...temp])]; // 기존 리스트와 temp를 합치고 중복 제거
      return combinedList;
    });
  }

  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(stationCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetStationList(pageNum, {"keyword": searchKeyword});
    }
  },[pageNum])


  const handleKeyPress = async(e) => {
    if (e.key === 'Enter') {
      reqSearchKeyword();
    }
  };
  const reqSearchKeyword = async()=>{
    setLoadingListOn(true);
      if(pageNum !== 1) setPageNum(1);
      else await reqGetStationList(1,{"keyword": searchKeyword});
      setLoadingListOn(false);
  }

  const [errMsg, setErrMsg] = useState(null);
  const [loadingOn, setLoadingOn] = useState(false);
  const [loadingListOn, setLoadingListOn] = useState(false);
  useEffect(()=>{
    if(errMsg){
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  },[errMsg])


  return (
    <div className="cpo-charging-fee-setting-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="cpo-charging-fee-setting-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setSearchModalOn(false)}}>
        {alertOn && <div className='cpo-charging-fee-setting-modal-alert'><p>정상적으로 적용이 완료되었습니다.</p></div>}
        <div className="cpo-charging-fee-setting-modal-header">
          <title>
            {
              props.edit ? "이벤트요금 수정" :"이벤트요금 추가"
            }
          </title>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
          {/* <p>이벤트를 추가할 충전소를 선택 후, 기간을 입력하세요</p> */}
        </div>
        <div className='cpo-charging-fee-setting-modal-period-selection-container'>
          <div>
            <p>이벤트명</p>
            <div className="cpo-setting-default-charging-rate-input-container">
              <input type="text" placeholder='이벤트명을 입력하세요' value={eventName} onChange={(e)=>{setEventName(e.target.value)}} />
            </div>
          </div>
          <div>
            <p onClick={()=>{console.log(startDateTime)}}>운영기간</p>
            <div className='cpo-charging-fee-setting-modal-period-input-wrapper'>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={endDateTime ? endDateTime : "9999-12-31"} placeholder='' value={startDateTime} onChange={(e)=>{setStartDateTime(e.target.value)}} onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div>~</div>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date' min={startDateTime ? startDateTime:null}  max={"9999-12-31"} placeholder='' value={endDateTime} onChange={(e)=>{setEndDateTime(e.target.value)}} onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
            </div>
          </div>
        </div>
        <div className='cpo-charging-fee-setting-modal-period-selection-container'>
          <div>
            <p>회원요금</p>
            <div className="cpo-setting-default-charging-rate-input-container">
              <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={membershipRate} onChange={(e)=>{setMembershipRate(e.target.value)}} />
            </div>
          </div>
          <div>
            <p>비회원요금</p>
            <div className="cpo-setting-default-charging-rate-input-container">
              <input type="text" placeholder='0' onInput={(e)=>{numberInput(e.target)}} value={nonMembershipRate} onChange={(e)=>{setNonMembershipRate(e.target.value)}} />
            </div>
          </div>
        </div>
        
        <div className="cpo-charging-fee-setting-modal-search-container">
          {searchModalOn && <ChargingFeeSearchModal/>}
          <div className="cpo-charging-fee-setting-modal-search-filter-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault();setSearchModalOn(!searchModalOn)}}>검색필터<PiSlidersHorizontal/></div>
          <div className="cpo-charging-fee-setting-modal-search-input-container">
            <input type="text" placeholder={'충전소 이름으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}} onKeyDown={handleKeyPress}/>
            <FaSearch onClick={()=>{reqSearchKeyword()}}/>
          </div>
        </div>

        <div className='cpo-charging-event-station-list settingModal'>
          <div className='cpo-charging-event-station-list-column checkList'>
            <div className='cpo-charging-event-station-list-column-item checkbox' onClick={()=>{checkAll()}}>
              <FaCheck/>
              <p>전체 선택</p>
            </div>
            {
              checkStationList?.length > 0 &&
              <>
                <div className='cpo-charging-event-station-list-column-item checkedInfo' onClick={()=>{console.log(checkStationList)}}>
                  <p>선택한 충전소</p>
                  <p>{`${checkStationList?.length}`}</p>
                  <p onClick={()=>{setCheckStationList([])}}>선택해제</p>
                </div>
              </>
            }
            <div></div>
            <div className='cpo-charging-event-station-list-column-item'><p>권역</p></div>
            <div className='cpo-charging-event-station-list-column-item'><p>충전소명</p></div>
            <div className='cpo-charging-event-station-list-column-item'><p>주소</p></div>
          </div>
          <div className='cpo-charging-fee-setting-modal-item-list'>
            {
              loadingListOn ? <LoadingList /> :
            <>
              {
                (stationInfo&&stationInfo?.map((it, idx)=>{
                  return(
                    <EventSettingStationItem data={it} idx={idx} checkStationList={checkStationList} setCheckStationList={setCheckStationList}/>
                )
                }))
              }
            </>
            }
          </div>
          <div className='page-selector-container'>
            {<CgPushChevronLeft onClick={()=>{movePage(1); setCheckStationList([]);}} className={`skip ${pageNum===1?"disabled":""}`}/>}
            {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1); setCheckStationList([]);}} className={`${pageNum>5?"":"disabled"}`}/>}
            {pageSelectorRendering()}
            {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2); setCheckStationList([]);}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
            {<CgPushChevronRight onClick={()=>{movePage(pageCount); setCheckStationList([]);}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
            <div className='page-total-info'>{`Total : ${stationCount}`}</div>
          </div>
        </div>
        <div className='cpo-charging-fee-setting-modal-save-button-container'>

          <div onClick={()=>{props.edit ? reqPutPricingEvent() : reqPostPricingEvent()}} className={`cpo-charging-fee-setting-modal-save-button ${!eventName||!startDateTime||!endDateTime||!membershipRate||!nonMembershipRate||checkStationList.length === 0 ? "none":""}`}>
            {loadingOn ? <Loading/> : 
              <>
                {props.edit ? "수정하기":"저장하기"}
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChargingFeeSettingModal;

