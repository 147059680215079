import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'

import ChargerManagementGridView from '../../views/ChargerManagement/pages/ChargerManagementGridView.js';
import ChargerManagementSimpleView from '../../views/ChargerManagement/pages/ChargerManagementSimpleView.js';
import { CgToolbarTop, CgViewGrid } from "react-icons/cg";



const CpoChargerManagement = () => {
  // redux
  let dispatch = useDispatch();

  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:3}));
  },[dispatch])
  const initialViewMode = localStorage.getItem("CBM");
  const [viewMode, setViewMode] = useState(initialViewMode?initialViewMode:"simple");

  return (
    <div className='cpo-charger-management-container'>
      <div className="cpo-charger-management-inner">
        <div className='cpo-charger-management-view-mode-button-container'>
          <CgToolbarTop className={`cpo-charger-management-view-mode-button ${viewMode === "simple" ? "clicked": ""}`} onClick={()=>{localStorage.setItem("CBM","simple");setViewMode("simple")}}/>
          <CgViewGrid   className={`cpo-charger-management-view-mode-button ${viewMode === "grid" ? "clicked": ""}`} onClick={()=>{localStorage.setItem("CBM","grid");setViewMode("grid")}}/>
        </div>
        {
          viewMode === 'simple'?
          <ChargerManagementSimpleView/>
          :
          <ChargerManagementGridView/>
        }
      </div>
    </div>
  )
}
export default CpoChargerManagement;






{/* <div className='cpo-charger-management-category-container'>
  <p>충전기 타입</p>
  <div className="cpo-charger-management-category-type-container">
    <div className="cpo-charger-management-category-type">
      <div className="cpo-charger-management-category-type-title">
        <p>고속</p>
      </div>
      <div className="cpo-charger-management-category-type-content">
        <p>{chargerTypeStatus.FAST_CHARGER}</p>
      </div>
    </div>
    <div className="cpo-charger-management-category-type">
      <div className="cpo-charger-management-category-type-title">
        <p>중속</p>
      </div>
      <div className="cpo-charger-management-category-type-content">
        <p>0</p>
      </div>
    </div>
    <div className="cpo-charger-management-category-type">
      <div className="cpo-charger-management-category-type-title">
        <p>완속</p>
      </div>
      <div className="cpo-charger-management-category-type-content">
        <p>{chargerTypeStatus.SLOW_CHARGER}</p>
      </div>
    </div>
    <div className="cpo-charger-management-category-type">
      <div className="cpo-charger-management-category-type-title">
        <p>미설정</p>
      </div>
      <div className="cpo-charger-management-category-type-content">
        <p>{chargerTypeStatus.NONE}</p>
      </div>
    </div>
  </div>
</div>
<div className='cpo-charger-management-category-container'>
  <p>충전기 분류</p>
  <div className="cpo-charger-management-category-class-container">

    <div className="cpo-charger-management-category-class">
      <div className="cpo-charger-management-category-class-title">완전공용</div>
      <p>{chargerCategoryStatus.완전공용}</p>
    </div>

    <div className="cpo-charger-management-category-class">
      <div className="cpo-charger-management-category-class-title">부분공용</div>
      <p>{chargerCategoryStatus.부분공용}</p>
    </div>
    <div className="cpo-charger-management-category-class">
      <div className="cpo-charger-management-category-class-title">비공용</div>
      <p>{chargerCategoryStatus.비공용}</p>
    </div>
    <div className="cpo-charger-management-category-class">
      <div className="cpo-charger-management-category-class-title">미설정</div>
      <p>{chargerCategoryStatus.미설정}</p>
    </div>
  </div>
</div> */}