import React from 'react'
import Select from 'react-select'

export const SearchSelectorNotCreatable = (props) => {
  const onMenuOpen = () => {
    setTimeout(()=>{
      const selectedEl = document.getElementsByClassName("react-select__option--is-selected")[0];
      if(selectedEl){
        selectedEl.scrollIntoView({behavior:'instant', block:'nearest', inline: 'start'});
      }
    },15);
  };
  return (
    <>
    {
      props.customSave === true ?
      <Select options={props.options}
      {...props}
      className="react-select-container"
      classNamePrefix="react-select"
      value={props.value}
      onChange={(newValue)=>{props.setValue(props.keyValue, newValue)}}
      />
      :
      <Select options={props.options}
      {...props}
      className="react-select-container"
      classNamePrefix="react-select"
      value={props.value}
      onChange={(newValue) => props.setValue(newValue)}
      onMenuOpen={onMenuOpen}
      />
    }
    </>
  )
}
export default SearchSelectorNotCreatable;