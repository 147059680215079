import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";

import { TfiClose } from "react-icons/tfi";

import {getRoamingChargingStationByEmoovId, getRoamingCpoUserCheck, postRoamingCpoUser, postRoamingChargingStation, postRoamingCharger} from '../../api/roaming/roamingApi.js';
import {getMoeCpoByCpoId, postMoeCpo} from '../../api/moe/moeApi.js';
import { Loading } from '../alerts/Loading.js';
import { useSelector } from 'react-redux';

import { handleNumericInput } from '../../util/util.js';


export const RoamingChargerModal = (props) => {
  const user = useSelector(state => state.user);
  const [companyName, setCompanyName] = useState(null);
  const [remark, setRemark] = useState(null);

  const [tabIdx, setTabIdx] = useState(0);
  
  const [errMsg, setErrMsg] = useState(null);

  const [loadingOn, setLoadingOn] = useState(false);
  const [roamingCpoUserLoading, setRoamingCpoUserLoading] = useState(true);
  useEffect(()=>{
    if(errMsg){
      setTimeout(()=>{
        setErrMsg(null);
      },3000);
    }
  },[errMsg])
  

  // 1.로밍CPO
  const reqGetRoamingCpoUserCheck = async()=>{
    setRoamingCpoUserLoading(true);
    let res = await getRoamingCpoUserCheck();
    setTimeout(() => {
      if(res.status === 200){
        if(res.data.exist){
          setTabIdx(1);
        }
      }
      else{
        
      }
      setRoamingCpoUserLoading(false);
    }, 1500);
  }

  useEffect(()=>{
    reqGetRoamingCpoUserCheck();
  },[])

  const [roamingCpoUser, setRoamingCpoUser] = useState({
    "moeBId": "",
    "name": user.name
  });

  const reqPostRoamingUser = async()=>{
    setLoadingOn(true);
    let res = await postRoamingCpoUser(roamingCpoUser);
    if(res.status === 201){
      setTabIdx(1);
    }
    else{
      setErrMsg("로밍 CPO 등록에 실패하였습니다.");
    }
    setLoadingOn(false);
  }

  // 2.Moe
  const reqGetMoeCpoByCpoId = async()=>{
    setRoamingCpoUserLoading(true);
    let res = await getMoeCpoByCpoId(user.cpoId);
    setTimeout(() => {
      if(res.status === 200){
        setTabIdx(2);
      }
      else{

      }
      setRoamingCpoUserLoading(false);
    }, 1500);
  }
  const [moeCpoUser, setMoeCpoUser] = useState({
    "cpoId": user.cpoId,
    "cpoName": user.name,
    "bid": "",
    "bkey": "",
  });

  const reqPostMoeCpo = async()=>{
    setLoadingOn(true);
    let res = await postMoeCpo({
      "cpoId": user.cpoId,
      "cpoName": user.name,
      "bid": moeCpoUser.bid,
      "bkey": moeCpoUser.bkey,
    });
    if(res.status === 201){
      setTabIdx(2);
    }
    else{
      setErrMsg("환경부 CPO 등록에 실패하였습니다.");
    }
    setLoadingOn(false);
  }

  useEffect(()=>{
    if(tabIdx === 1){
      reqGetMoeCpoByCpoId();
    }
  },[tabIdx])

  // 3, 로밍 충전소 등록
  const [roamingChargingStation, setRoamingChargingStation] = useState({
    "moEChargingStationId": "",
    "emoovChargingStationId": props.roamingStationId,
  });

  const reqPostRoamingChargingStation = async()=>{
    setLoadingOn(true);
    let res = await postRoamingChargingStation(roamingChargingStation);
    if(res.status === 201){
      setRoamingCharger({...roamingCharger, "moEChargingStationId": roamingChargingStation.moEChargingStationId});
      setRoamingCharger2({...roamingCharger2, "moEChargingStationId": roamingChargingStation.moEChargingStationId});
      setTabIdx(3);
    }
    else{
      setErrMsg("충전소 로밍 등록에 실패하였습니다.");
    }
    setLoadingOn(false);
  }


  // 로밍 충전소 조회
  const reqGetRoamingChargingStation = async()=>{
    setRoamingCpoUserLoading(true);
    let res = await getRoamingChargingStationByEmoovId(props.roamingStationId);
    setTimeout(() => {
      if(res.status === 200){
        setRoamingChargingStation({"moEChargingStationId" : res.data?.moEChargingStationId, "emoovChargingStationId": props.roamingStationId});
        setRoamingCharger({...roamingCharger, "moEChargingStationId": res.data?.moEChargingStationId});
        setRoamingCharger2({...roamingCharger2, "moEChargingStationId": res.data?.moEChargingStationId});
        setTabIdx(3);
      }
      else{
      }
      setRoamingCpoUserLoading(false);
    }, 1500);
  }

  useEffect(()=>{
    if(tabIdx === 2){
      reqGetRoamingChargingStation();
    }
  },[tabIdx])

  // 4. 로밍 충전기 등록
  const [roamingCharger, setRoamingCharger] = useState({
    "moEChargerId": "",
    "moEChargingStationId": "",
    "emoovChargerId": props.controlHubData?.chargerId,
    "emoovConnectorId": 1,
  });
  const [roamingCharger2, setRoamingCharger2] = useState({
    "moEChargerId": "",
    "moEChargingStationId": "",
    "emoovChargerId": props.controlHubData?.chargerId,
    "emoovConnectorId": 2,
  });

  const reqPostRoamingCharger = async()=>{
    setLoadingOn(true);
    let res = await postRoamingCharger(roamingCharger);
    let res2;
    if(res.status === 201){
      if(props.controlHubData?.connectors.length>1){
        res2 = await postRoamingCharger(roamingCharger2);
        if(res2.status === 201){
          await props.reqGetRoamingChargerByEmoovId();
          props.setOnOff(false);
        }
        else{
          setErrMsg("커넥터2 로밍 등록에 실패하였습니다.");
        }
      }
      else{
        await props.reqGetRoamingChargerByEmoovId();
        props.setOnOff(false);
      }
    }
    else{
      setErrMsg("커넥터1 로밍 등록에 실패하였습니다.");
    }
    setLoadingOn(false);
  }

  // roamingChargerId roamingStationId

  

  

  return (
    <div className="roaming-charging-station-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      <div className="roaming-charging-station-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="roaming-charging-station-modal-header">
          <title>충전기 로밍</title>
          <div className='roaming-charging-station-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="roaming-charging-station-modal-content">
          {
            tabIdx === 0 ? // 로밍 확인
              <>
                <div className="roaming-charging-station-modal-content-header">
                  <p className="roaming-charging-station-modal-content-title">{roamingCpoUserLoading ? "로밍 CPO 확인":"로밍 CPO 등록"}</p>
                </div>
                {
                  roamingCpoUserLoading ?
                  <div className='roaming-charging-station-modal-loading-wrapper'>
                    <div>
                      <Loading/> 
                    </div> 
                  </div>
                  :
                  <>
                    <div className="roaming-charging-station-modal-content-wrapper">
                      <p>moeBId</p>
                      <div className="roaming-charging-station-modal-content-input-container">
                        <input type="text"  placeholder='moeBId' value={roamingCpoUser.moeBId} onChange={(e)=>{setRoamingCpoUser({...roamingCpoUser,"moeBId":e.target.value})}}/>
                      </div>
                      <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                    </div>
                    <div className='roaming-charging-station-modal-button-container'>
                      {/* <div className="roaming-charging-station-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                      <div></div>
                      <div className={`roaming-charging-station-modal-button ${!roamingCpoUser.moeBId ? "disabled":""}`} onClick={()=>{reqPostRoamingUser()}}>
                        등록하기
                      </div>
                    </div>
                  </>
                }

              </>
            :
            tabIdx === 1 ? // 환경부 CPO 등록
              <>
                <div className="roaming-charging-station-modal-content-header">
                  <p className="roaming-charging-station-modal-content-title">{roamingCpoUserLoading ? "환경부 CPO 등록 확인":"환경부 CPO 등록"}</p>
                </div>
                {
                  roamingCpoUserLoading ?
                  <div className='roaming-charging-station-modal-loading-wrapper'>
                    <div>
                      <Loading/> 
                    </div> 
                  </div>
                  :
                  <>
                    <div className="roaming-charging-station-modal-content-wrapper">
                      <p>bid</p>
                      <div className="roaming-charging-station-modal-content-input-container">
                        <input type="text"  placeholder='bid' value={moeCpoUser.bid} onChange={(e)=>{setMoeCpoUser({...moeCpoUser, "bid": e.target.value})}}/>
                      </div>
                      <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                    </div>
                    <div className="roaming-charging-station-modal-content-wrapper">
                      <p>bkey</p>
                      <div className="roaming-charging-station-modal-content-input-container">
                        <input type="text"  placeholder='bkey' value={moeCpoUser.bkey} onChange={(e)=>{setMoeCpoUser({...moeCpoUser, "bkey": e.target.value})}}/>
                      </div>
                      <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                    </div>
                    <div className='roaming-charging-station-modal-button-container'>
                      {/* <div className="roaming-charging-station-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                      <div>
                        {errMsg && <p className='roaming-charging-station-modal-errMsg'>{errMsg}</p>}
                      </div>
                      <div className={`roaming-charging-station-modal-button ${!moeCpoUser.bid && !moeCpoUser.bkey ? "disabled":""}`} onClick={()=>{reqPostMoeCpo()}}>
                        {loadingOn ? <Loading/> : "등록하기"}
                      </div>
                    </div>
                  </>
                }
              </>
            :
            tabIdx === 2 ? // 충전소 로밍 등록
              <>
                <div className="roaming-charging-station-modal-content-header">
                  <p className="roaming-charging-station-modal-content-title">{roamingCpoUserLoading ? "충전소 로밍 확인":"충전소 로밍 등록"}</p>
                </div>
                {
                  roamingCpoUserLoading ?
                  <div className='roaming-charging-station-modal-loading-wrapper'>
                    <div>
                      <Loading/> 
                    </div> 
                  </div>
                  :
                  <>
                    <div className="roaming-charging-station-modal-content-wrapper">
                      <p>환경부 충전소 ID</p>
                      <div className="roaming-charging-station-modal-content-input-container">
                        <input type="text"  placeholder='환경부 충전소 ID - 6자리 숫자열을 입력해주세요.' value={roamingChargingStation.moEChargingStationId} maxLength={6} onInput={handleNumericInput} onChange={(e)=>{setRoamingChargingStation({...roamingChargingStation, "moEChargingStationId": e.target.value})}}/>
                      </div>
                      <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                    </div>
                    <div className='roaming-charging-station-modal-button-container'>
                      {/* <div className="roaming-charging-station-modal-button" onClick={()=>{setTabIdx(0); setCategory(null)}}>이전</div> */}
                      <div>
                        {errMsg && <p className='roaming-charging-station-modal-errMsg'>{errMsg}</p>}
                      </div>
                      <div className={`roaming-charging-station-modal-button ${!roamingChargingStation.moEChargingStationId || roamingChargingStation.moEChargingStationId.length!==6 ? "disabled":""}`} onClick={()=>{reqPostRoamingChargingStation()}}>
                        {loadingOn ? <Loading/> : "등록하기"}
                      </div>
                    </div>
                  </>
                }
              </>
              
            :
            tabIdx === 3 ? // 로밍 충전기 선택
              <>
                {/* <div className="roaming-charging-station-modal-content-header">
                  <p className="roaming-charging-station-modal-content-title">커넥터 선택</p>
                </div> */}
                {
                  props.controlHubData?.connectors && props.controlHubData?.connectors.sort((a, b) => a.connectorId - b.connectorId).map((it)=>{
                    return(
                      <>
                        <div className="roaming-charging-station-modal-content-header">
                          <p className="roaming-charging-station-modal-content-title">{`충전기 로밍 등록 - 커넥터 ${it.connectorId}`}</p>
                        </div>
                        <div className="roaming-charging-station-modal-content-wrapper">
                          <p>환경부 충전기 ID</p>
                          <div className="roaming-charging-station-modal-content-input-container">
                            <input type="text"  placeholder='환경부 충전기 ID - 2자리 숫자열을 입력해주세요' value={it.connectorId === 1 ? roamingCharger.moEChargerId : roamingCharger2.moEChargerId} maxLength={2} onInput={handleNumericInput} onChange={(e)=>{it.connectorId === 1 ? setRoamingCharger({...roamingCharger, "moEChargerId": e.target.value}):setRoamingCharger2({...roamingCharger2, "moEChargerId": e.target.value})}}/>
                          </div>
                          <p className='infoMsg'>무공해차 통합관리시스템에서 확인 후 입력해주세요.</p>
                          <p className='infoMsg'>충전기 ID는 커넥터 당 1개씩 발급이 필요합니다.</p>
                        </div>
                        

                        {/* <div className={`roaming-charging-station-modal-connector-button ${
                          it.connectorId === 1 && props.roamingCharger1 ? "disabled": it.connectorId === 2 && props.roamingCharger2 ? "disabled":""
                        }`} onClick={()=>{setRoamingCharger({...roamingCharger, "emoovConnectorId": it.connectorId}); setTabIdx(4);}}>
                          <p>{`커넥터 ${it.connectorId}`}</p>
                          <div>
                            <>
                              {
                                it.connectorTypes?.map((it)=>{
                                if(!it)return<></>;
                                return(
                                  <div className="cpo-charger-management-connector-badge">
                                    <p onDrag={(e)=>{e.stopPropagation();}}>{it}</p>
                                  </div>
                                )})
                              }
                            </>
                          </div>
                          
                        </div> */}
                      </>
                    )
                  })
                }
                {errMsg && <p className='roaming-charging-station-modal-errMsg'>{errMsg}</p>}
                <div className={`roaming-charging-station-modal-button ${!roamingCharger.moEChargerId || roamingCharger.moEChargerId.length !== 2 || (props.controlHubData?.connectors.length>1 ? (roamingCharger2.moEChargerId === roamingCharger.moEChargerId || !roamingCharger2.moEChargerId || roamingCharger2.moEChargerId.length !== 2):false) ? "disabled":""}`} onClick={()=>{reqPostRoamingCharger()}}>
                  {loadingOn ? <Loading/> : "등록하기"}
                </div>
              </>
            :
            <></>
          }
          
          {/* <div>
            <div className="roaming-charging-station-modal-content-header">
              <p className="roaming-charging-station-modal-content-title">담당자 정보</p>
              <p className='roaming-charging-station-modal-content-desc'>등록 이후에도 담당자 추가/수정이 가능합니다</p>
            </div>
            <div className="roaming-charging-station-modal-content-wrapper">
              <p>이름</p>
              <div className="roaming-charging-station-modal-content-input-container">
                <input type="text"  placeholder='이름' value={name} onChange={(e)=>{setName(e.target.value)}}/>
              </div>
            </div>
            <div className="roaming-charging-station-modal-content-wrapper">
              <p>휴대전화번호</p>
              <div className="roaming-charging-station-modal-content-input-container">
                <input type="text"  placeholder='휴대전화번호' value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
              </div>
            </div>
            <div className="roaming-charging-station-modal-content-wrapper divide">
              <div>
                <p>부서 이름</p>
                <div className="roaming-charging-station-modal-content-input-container">
                  <input type="text"  placeholder='부서 이름' value={division} onChange={(e)=>{setDivision(e.target.value)}}/>
                </div>
              </div>
              <div>
                <p>직책</p>
                <div className="roaming-charging-station-modal-content-input-container">
                  <input type="text"  placeholder='직책' value={role} onChange={(e)=>{setRole(e.target.value)}}/>
                </div>
              </div>
            </div>
          </div> */}

        </div>
        
      </div>
    </div>
  )
}
export default RoamingChargerModal
