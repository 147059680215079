import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import { CgChevronLeft, CgPushChevronLeft, CgChevronRight, CgPushChevronRight } from "react-icons/cg";

import { getChargeRateListOfStation, getDefaultPricingPlan } from '../../api/chargerate/chargerateApi.js';
import { FaSearch } from 'react-icons/fa';
import StationChargingFeeListItem from '../../components/list/StationChargingFeeListItem.js';
import StationChargingFeeSettingModal from '../../components/modals/StationChargingFeeSettingModal.js';
import { LoadingList } from '../../components/alerts/LoadingList.js';

export const CpoStationChargingFeeManagement = () => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:16}));
  },[dispatch])

  const [defaultPricingPlan, setDefaultPricingPlan]=useState(null);
  const [stationList, setStationList]=useState(null);
  const [stationCount, setStationCount]=useState(null);

  // 현재 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 페이지 사이즈(한 페이지에 보여줄 charger 수)
  const [pageSize, setPageSize] = useState(10);
  // 총 페이지 수
  const pageCount = Math.ceil(stationCount/pageSize);
  // 현 페이지가 속한 그룹의 첫번째 페이지 번호 (5개씩 묶음)
  const getInitPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+1;
  }
  // 현 페이지가 속한 그룹의 마지막 페이지 번호 (5개씩 묶음)
  const getEndPage = (page)=>{
    if(page<1)return 1;
    if(pageCount === 0)return 1;
    if(page>pageCount)return pageCount;
    return (Math.ceil(page/5)-1)*5+4;
  }
  // 페이지 이동 함수
  const movePage = (page)=>{
    setPageNum(page);
  }
  // 페이지 버튼 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = pageNum?pageNum:1;
    let initPage = getInitPage(pageNum?pageNum:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    if(result.length === 0){
      result.push(<div className={'page-selector ' + `${"clicked"}`} onClick={()=>{movePage(1)}}>{1}</div>)
    }
    return result;
  }

  useEffect(()=>{
    if(pageNum){
      reqGetChargeRateListOfStation();
    }
  },[pageNum])

  const reqGetChargeRateListOfStation = async(data)=>{
    setLoadingListOn(true);
    let {chargingStationName} = data || {};
    let res = await getChargeRateListOfStation({page:pageNum, size:pageSize, chargingStationName:chargingStationName});
    if(res.status === 200){
      setStationList(res.data.results);
      setStationCount(res.data.total);
    }
    setLoadingListOn(false);
  }

  useEffect(()=>{
    reqGetDefaultPricingPlan();
  },[])

  const reqGetDefaultPricingPlan = async()=>{
    let res = await getDefaultPricingPlan();
    if(res.status === 200){
      setDefaultPricingPlan(res.data);
    }
  }

  const [settingModalOn, setSettingModalOn] = useState(false);
  const [selectedStationName, setSelectedStationName] = useState(null);
  const [selectedStationId, setSelectedStationId] = useState(null);

    const handleKeyPress = async(e) => {
      if (e.key === 'Enter') {
        reqSearchKeyword();
      }
    };
    const reqSearchKeyword = async()=>{
      setLoadingListOn(true);
        await reqGetChargeRateListOfStation({"chargingStationName": searchKeyword});
        setLoadingListOn(false);
    }
  
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loadingListOn, setLoadingListOn] = useState(false);
  
  return (
    <>
    <div className='cpo-station-charging-fee-management-container'>
      {settingModalOn&&<StationChargingFeeSettingModal setOnOff={setSettingModalOn} selectedStationName={selectedStationName} selectedStationId={selectedStationId} reqGetChargeRateListOfStation={reqGetChargeRateListOfStation}/>}
      <div className="cpo-station-charging-fee-management-inner">
        <div className="cpo-charging-fee-setting-modal-search-input-container">
          <input type="text" placeholder={'충전소 이름으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}} onKeyDown={handleKeyPress}/>
          <FaSearch onClick={()=>{reqSearchKeyword()}}/>
        </div>
        <div className="station-charging-fee-list">
            <div className="station-charging-fee-list-column">
              <div className='station-charging-fee-list-column-item'><p>충전소명</p></div>
              <div className='station-charging-fee-list-column-item'><p>완속 요금설정상태</p></div>
              <div className='station-charging-fee-list-column-item'><p>급속 요금설정상태</p></div>
              <div className='station-charging-fee-list-column-item'><p>맞춤 요금 설정</p></div>
              <div></div>
            </div>
            <div className='station-charging-fee-list-content'>
              {
                loadingListOn ? <LoadingList/>:
              <>
              {
                stationList&&(stationList.map((it,idx)=>{
                  return(<StationChargingFeeListItem key={idx} data={it} defaultPricingPlan={defaultPricingPlan} setModalOn={setSettingModalOn} setSelectedStationName={setSelectedStationName} setSelectedStationId={setSelectedStationId}/>)
                }))
              }
              </>
              }
            </div>
            <div className='page-selector-container'>
              {<CgPushChevronLeft onClick={()=>{movePage(1)}} className={`skip ${pageNum===1?"disabled":""}`}/>}
              {<CgChevronLeft onClick={()=>{movePage(getInitPage(pageNum?pageNum:1) -1)}} className={`${pageNum>5?"":"disabled"}`}/>}
              {pageSelectorRendering()}
              {<CgChevronRight onClick={()=>{movePage(getEndPage(pageNum?pageNum:1)+2)}} className={`${(getEndPage(pageNum?pageNum:1) < pageCount-1)?"":"disabled"}`}/>}
              {<CgPushChevronRight onClick={()=>{movePage(pageCount)}} className={`${pageNum === pageCount || pageCount<=1 ? "disabled":""}`}/>}
              <div className='page-total-info'>{`Total : ${stationCount}`}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CpoStationChargingFeeManagement;