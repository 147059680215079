import React, { useRef, useState, useEffect } from 'react';
import { Outlet, RouterProvider,createBrowserRouter, useNavigate, useLocation } from 'react-router-dom'
import { getCookie, removeCookie, refresh, signedOut } from '../../util/util.js';

import '../../styles/Default.css';
import '../../styles/Introduce.css';
import '../../styles/FindPassword.css';
import '../../styles/label/LabelInitPage.css';

export const LabelMainTemplate = () => {
  let navigator = useNavigate();
  let signedToken = getCookie("signedToken");
  const location = useLocation();

  // useEffect(()=>{
  //   if(signedToken){
  //     navigator("/cpo");
  //   }
  // },[])
  

  return (
    <div className='label-main-template-container'>
      <section>
        <Outlet/>
      </section>
      <footer>
        <div>
          <div className="label-main-template-footer-text">
            <span>회사명 : {process.env.REACT_APP_COMPANY_NAME}</span>
            <span>주소 : {process.env.REACT_APP_COMPANY_ADDRESS}</span>
            <span>사업자등록번호 : {process.env.REACT_APP_COMPANY_BUSINESS_NUMBER}</span>
          </div>
          <div className="label-main-template-footer-text">
            <span>이메일 : {process.env.REACT_APP_COMPANY_EMAIL}</span><br/>
            <span>전화 : {process.env.REACT_APP_COMPANY_PHONE}</span>
            <span>팩스 : {process.env.REACT_APP_COMPANY_FAX}</span>
          </div>
          <div className="label-main-template-footer-text">
            <span>Powered by EMOOV Platform.</span>
            <span>Copyright (C) (주)모니트 All right Reserved.</span>
            <span>1.0.13+15</span>
          </div>
          
        </div>
      </footer>
    
    </div>
  )
}
export default LabelMainTemplate;