import React, { useState, useEffect } from 'react';
import { infraConstructorConverter, managerTypeConverter2 } from '../../util/util';
import { FaChevronDown } from "react-icons/fa6";
import { TbDotsVertical } from "react-icons/tb";

import CpoInfraConstructorManagerEnrollModal from '../modals/CpoInfraConstructorManagerEnrollModal';
import CpoInfraConstructorManagerEditModal from '../modals/CpoInfraConstructorManagerEditModal';
import CpoInfraConstructorManagerDeleteModal from '../modals/CpoInfraConstructorManagerDeleteModal';
export const CpoInfraConstructorListItem = (props) => {
  
  const [tabOpen, setTabOpen] = useState(false);
  const [checked, setChecked] = useState(null);

  useEffect(()=>{
    if(props.checkMode){
      setTabOpen(false);
    }
    else{
      setTabOpen(false);

      setChecked(false);
    }
  },[props.checkMode])



    
  useEffect(()=>{
    // 리스트에서 인덱스 찾기
    let itemIdx = props.checkList.indexOf(props.idx);
    // check true인 경우
    if(checked){
      if(itemIdx !== -1){
        return;
      }
      if(props.settingMode === "edit"){
        props.setCheckList([props.idx]);
      }
      else{
        props.setCheckList([...props.checkList, props.idx]);
      }
    }
    else{
      // false 인 경우
      
      // 만약 없는 경우
      if(itemIdx === -1){
        return;
      }
      // 있는 경우
      else{
        let temp = [...props.checkList];
        temp.splice(itemIdx,1);
        props.setCheckList(temp);
      }
    }
  },[checked])

  useEffect(()=>{
    let itemIdx = props.checkList.indexOf(props.idx);
    if(itemIdx !== -1 && !checked){
      setChecked(true);
    }
    else if(itemIdx === -1 && checked){
      setChecked(false);
    }
  },[props.checkList])




// {
//   "infraConstructorSysId": 0,
//   "companySysId": "string",
//   "companyName": "string",
//   "companyRegNumber": "string",
//   "specialization": "string",
//   "remark": "string",
//   "managers": [
//     {
//       "managerSysId": "string",
//       "name": "string",
//       "position": "string",
//       "team": "string",
//       "contantNumber": "string"
//     }
//   ]
// }

  const [managerEnrollModalOpen, setManagerEnrollModalOpen] = useState(false);
  const [managerEditModalOpen, setManagerEditModalOpen] = useState(false);
  const [managerDeleteModalOpen, setManagerDeleteModalOpen] = useState(false);
  const [managerEditTarget, setManagerEditTarget] = useState(false);
  const [btnOpen, setBtnOpen] = useState(false);

  const [functionBtnOpen, setFunctionBtnOpen] = useState(false);

  useEffect(()=>{
    if(!tabOpen){
      setBtnOpen("close");
    }
    else{
      setBtnOpen(false);
      setFunctionBtnOpen(false);
    }
  },[tabOpen])

  return (
    <>
      {managerEnrollModalOpen && <CpoInfraConstructorManagerEnrollModal setOnOff={setManagerEnrollModalOpen} data={props.data} reqGetInfraConstructorList={props.reqGetInfraConstructorList}/>}
      {managerEditModalOpen && <CpoInfraConstructorManagerEditModal setOnOff={setManagerEditModalOpen} data={{CompanySysId:props.data?.CompanySysId, ...managerEditTarget}} reqGetInfraConstructorList={props.reqGetInfraConstructorList}/>}
      {managerDeleteModalOpen && <CpoInfraConstructorManagerDeleteModal setOnOff={setManagerDeleteModalOpen} data={{CompanySysId:props.data?.CompanySysId, ...managerEditTarget}} reqGetInfraConstructorList={props.reqGetInfraConstructorList}/>}
      <div className={'cpo-infra-constructor-list-item-container '+`${tabOpen ? "open":""}`}>
        <div className={'cpo-infra-constructor-list-item '+`${props.checkMode?"checkMode ":""}` +`${checked?"checked":""}`} onClick={(e)=>{e.stopPropagation(); setTabOpen(!tabOpen)}}>
          {
            !props.checkMode ?
            <>
              <div><p>{props.data?.CompanyName}</p></div>
              <div>{props.data?.Specialization.trim().split(';').map((it)=>{
                if(it === "") return;
                return(
                  <div className='cpo-infra-constructor-badge'>
                    <p>{infraConstructorConverter(it)}</p>
                  </div>
                )
              })}</div>
              <div><p>{props.data?.Remark}</p></div>
              <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
              <div className='handle'>
                <div className="cpo-infra-constructor-manager-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setFunctionBtnOpen(!functionBtnOpen)}}>
                  <div className={"cpo-infra-constructor-manager-item-function-button-list " + `${functionBtnOpen ? "open":""}`}>
                    
                    <div>
                      <div className="cpo-infra-constructor-manager-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setFunctionBtnOpen(false);}}>
                        <div>업체수정</div>
                      </div>
                    </div>
                    <div>
                      <div className="cpo-infra-constructor-manager-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setFunctionBtnOpen(false);}}>
                        <div className='delete'>업체삭제</div>
                      </div>
                    </div>
                  </div>
                  <TbDotsVertical/>
                </div>
              </div>
            </>
            :
            <>
            <div>
              <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                <span className="checkbox_icon"></span>
              </label>
            </div>
            <div><p>{props.data?.CompanyName}</p></div>
            <div>{props.data?.Specialization.trim().split(';').map((it)=>{
              if(it === "") return;
              return(
                <div className='cpo-infra-constructor-badge'>
                  <p>{infraConstructorConverter(it)}</p>
                </div>
              )
            })}</div>
            <div><p>{props.data?.Remark}</p></div>
            <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
            
          </>
          }
          
        </div>
        <div className='cpo-infra-constructor-list-item-content'>
          <div className='cpo-infra-constructor-list-item-content-header'>
            <p>담당자 정보</p>
            <div className='cpo-infra-constructor-manager-append-button' onClick={()=>{setManagerEnrollModalOpen(true)}}>담당자 추가</div>
          </div>
          <div className="cpo-infra-constructor-list-manager-container">
            {
            props.data?.Managers&&props.data?.Managers.length!==0 ?
            <div className="cpo-infra-constructor-list-manager-item-column">
              <div>N</div>
              <div>담당자명</div>  
              <div>부서</div>
              <div>직책</div>
              <div>연락처</div>
            </div>
            :
            <div className="cpo-infra-constructor-list-manager-item-column">
              <div></div>
              <div>담당자 정보 없음</div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            }
            <div className="cpo-infra-constructor-list-manager-item-container">
              {
                props.data?.Managers&&props.data?.Managers.length!==0&&props.data?.Managers.map((item,itemIdx)=>{
                  return(
                    <ManagerListItem data={{...item, idx:itemIdx+1}}
                    managerEditModalOpen={managerEditModalOpen}
                    managerDeleteModalOpen={managerDeleteModalOpen}
                    setManagerEditTarget={setManagerEditTarget}
                    btnOpen={btnOpen}
                    />
                  )
                })
              }
            </div>          
          </div>
        </div>
      </div>
    </>
  )
}
export default CpoInfraConstructorListItem;



const ManagerListItem = (props) => {
  const[btnOpen, setBtnOpen] = useState(false);
  useEffect(()=>{
    if(props.btnOpen==="close"){
      setBtnOpen(false);
    }
  },[props.btnOpen])
  return(
    <div className="cpo-infra-constructor-list-manager-item">
      <div>{props.data?.idx}</div>
      <div>{props.data?.Name}</div>
      <div>{props.data?.Team}</div>
      <div>{props.data?.Position}</div>
      <div>{props.data?.ContantNumber}</div>
      <div className='handle'>
        <div className="cpo-infra-constructor-manager-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
          <div className={"cpo-infra-constructor-manager-item-function-button-list " + `${btnOpen ? "open":""}`}>
            
            <div>
              <div className="cpo-infra-constructor-manager-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(false); props.setManagerEditTarget(props.data); props.setManagerEditModalOpen(true);}}>
                <div>담당자수정</div>
              </div>
            </div>
            <div>
              <div className="cpo-infra-constructor-manager-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(false); props.setManagerEditTarget(props.data); props.setManagerDeleteModalOpen(true);}}>
                <div className='delete'>담당자삭제</div>
              </div>
            </div>
          </div>
          <TbDotsVertical/>
        </div>
      </div>
    </div>
  )
}