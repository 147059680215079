import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";
import CpoGroupInvitation from '../../components/modals/CpoGroupInvitation.js';
import CpoInvitationItem from '../../components/list/CpoInvitationItem.js';
import CloudAlertRight from '../../components/alerts/CloudAlertRight';

import { getSendEmailList } from '../../api/account/accountApi.js';

const CpoInvitationManagement = (props) => {
  // redux
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:18}));
  },[])



  
  const [searchKeyword, setSearchKeyword] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [checked, setChecked] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const [checkList, setCheckList] = useState([]);

  const reqGetSendEmailList = async()=>{
    let temp = await getSendEmailList();
    setEmailList(temp);
  }

  useEffect(()=>{
    reqGetSendEmailList();
  },[])

  useEffect(()=>{
    if(checked){
      let temp = new Array(emailList.length).fill(0).map((_, i) => i)
      setCheckList(temp);
    }
    else{
      setCheckList([]);
    }
  },[checked])
  const [cloudAlertOn, setCloudAlertOn] = useState(false);
  useEffect(()=>{
    if(cloudAlertOn){
      setTimeout(() => {
        setCloudAlertOn(false);
      }, 2000);
    }
  },[cloudAlertOn])
  return (
    <div className='cpo-invitation-management-container'>
      {modalOpen&&<CpoGroupInvitation setOnOff={setModalOpen} reqGetSendEmailList={reqGetSendEmailList}/>}
      <div className="cpo-invitation-management-inner">
        <div className={"cpo-invitation-management-content-container "}>
          <div className="cpo-invitation-management-content-header">
            
            {/* <div className='cpo-invitation-management-content-search-container'>
              <div className="cpo-invitation-management-content-search-filter-button">검색필터<PiSlidersHorizontal/></div>
              <div className="cpo-invitation-management-content-search-input-container">
                <input type="text" value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
                <FaSearch/>
              </div>
            </div> */}
            <div></div>
            <div className='cpo-invitation-management-content-function-button-container'>
              <div className='cpo-invitation-management-content-function-button enroll' onClick={()=>{setModalOpen(true)}}>초대</div>
              <div className='cpo-invitation-management-content-function-button delete' onClick={()=>{setDeleteMode(true); if(deleteMode){setCloudAlertOn(true);}}}>
                삭제
                {cloudAlertOn&&<CloudAlertRight data={"업데이트 예정인 기능입니다."}/>}
              </div>
              {deleteMode && <div className='cpo-invitation-management-content-function-button cancel' onClick={()=>{setDeleteMode(false);setChecked(false);setCheckList([]);}}>취소</div>}
            </div>
          </div>
          <div className={'cpo-invitation-management-item-list-grid-container '}>
            
              <div className="cpo-invitation-management-content-item-list-container">
                {
                  !deleteMode ?
                  <div className="cpo-invitation-management-content-item-column tab0">
                    <div>이메일</div>
                    <div>상태</div>
                    <div>전송일시</div>
                    <div>수락일시</div>
                  </div>
                  :
                  <div className="cpo-invitation-management-content-item-column tab1">
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                    </div>
                    <div>이메일</div>
                    <div>상태</div>
                    <div>전송일시</div>
                    <div>수락일시</div>
                  </div>
                }
                

                {
                  emailList && emailList.map((it, idx)=>{
                    return(
                      <>
                        <CpoInvitationItem idx={idx} checkList={checkList} setCheckList={setCheckList} data={it} deleteMode={deleteMode}/>
                      </>
                    );
                  })
                }
                
              </div>
              
            </div>
          </div>
      </div>
    </div>
  )
}
export default CpoInvitationManagement;
